import { CALIDA_GROUP_COMPANY_IDS, POST_GROUP_COMPANY_IDS, TX_GROUP_COMPANY_IDS } from "../customisations/constants";
import { assignToObjectKeys, simpleDeepMerge } from "../utils/object";

export default Object.assign(commonTranslations(), simpleDeepMerge(commonCustomisations(), customisations()));

function commonTranslations() {
    return {
        joineerContact: {
            salesEmail: "info{'@'}joineer.ch",
            salesPhone: "+41\u00A044\u00A0510\u00A038\u00A000",
        },
        addLinks:"Add links?",
        whatYouwrite: "What you write",
        whatIsShown:"What is shown",
        anonymValues:"Numeric values are always recorded anonymously",
        pauseSurvey: "Pause survey",
        surveyPaused: "Survey paused",
        events: {
            start: {
                title: "Survey starts",
                description: "E-mails/SMS with the invitation to participate is sent out to all",
            },
            reminder: {
                title: "Reminder",
                description: "E-mail/SMS reminding the participants who have not filled out yet",
            },
            end: {
                title: "Survey ends",
                description: "Last day of fillout, after which the results are available."
            },
            today: "Today",
            endSurveyNow: "End now",
            confirmation: "Confirmation",
            endSurveyConfirmationMsg: "Are you sure you would like to end this survey? \n This CAN NOT be undone",
            endSurveyScheduled: "Survey ended, please allow 15-30 minutes for processing",
        },
        nextDay: "on the next working day",
        changeTeam: "Change team",
        pickNewTeam: "Pick a new {0} team from the organisation structure below.",
        currentTeam: "Current team",
        activeSurveyMoveWarning:
            "Moving a member during an active survey deletes their current answers. After the move, it will be necessary to refill the survey for the new team.",

        startDateNextSurvey: "Start date of the next survey",
        interval: {
            label: "Survey interval",
            text: "How often do you want to have the survey?",
            everyw: "Every week",
            every2w: "Every 2 weeks",
            every3w: "Every 3 weeks",
            monthly: "Every month",
            every6w: "Every 6 weeks",
            every2m: "Every 2 months",
            every3m: "Every 3 months",
            every4m: "Every 4 months",
            none: "Manual",
        },
        rolesTitle: "Roles",
        roleTitle: "Role",
        adminOfTeams: "Admin of",
        addCompanyAdmin: "Add a company admin",
        tags: "Tags",
        masterfile: "Company master file",
        masterfileText: "Helpful tips and validation tool for the masterfile: ",
        processdatafile: "Process Data",
        processdatafileDescription:
         "Here you will find exciting process data for your organisation. You can also track the development of the process data over several surveys.",
        processDataMoreInfo: "More Info",

        processDataInfo: {
            title: {
                survey: "Survey",
                surveyEndDate: "Survey End Date",
                avgQuestionPerTeam: "Average Questions per Team",
                participants: "Participants",
                respondents: "Respondents",
                participationRate: "Participation Rate (%)",
                totalFF: "Total FFs",
                transparencyRate: "Transparency Rate (%)",
                FFPerRespondents: "FFs per Respondent per Question",
                handlingRate: "FF Handling Rate (%)",
                tasksCreated: "Tasks Created",
                closingRate: "Closing Rate (%)",
                delegationRate: "Delegation Rate (%)",
            },
            desc: {
                survey: "Increasing sequence.",
                surveyEndDate: "The date on which each survey was ended.",
                avgQuestionPerTeam: "The average number of questions presented to each team in the survey.",
                participants: "The total number of individuals invited to participate in the survey.",
                respondents: "The number of participants who actually responded to the survey.",
                participationRate: "The percentage of invited participants who completed the survey.",
                totalFF: "The total count of Feedforwards (FFs) received in the survey.",
                transparencyRate: "The percentage of FFs that were non-anonymous compared to the total number of FFs.",
                FFPerRespondents: "The average number of FFs given by each respondent for each question.",
                handlingRate: "The percentage of FFs that were either discussed or where a task has been created.",
                tasksCreated: "The total number of tasks created, including those not directly derived from FFs.",
                closingRate: "The percentage of tasks that were marked 'done', in relation to the total tasks created.",
                delegationRate: "The percentage of delegated tasks, in relation to the total tasks created.",
            },
        },

        profile: {
            name: "Profile name",
            details: "Profile details",
            activatePassword: "Activate password",
            passwordTooltip: "Enable password login and disable email link login. This cannot be undone.",
            passwordActiveTooltip: "Password is active",
            externalAccount: "External account"
        },

        account: {
            myaccount: "My Account",
            pendingEmail: "Pending Email"
        },


        myProfile: "My profile",
        teamDetails: "Team details",
        userTeamMoveWarning: "Adding {0} to {1} removes them from {2}.",
        selectUser: "Select member",
        selectUserQR: "Select a member to download their QR code as a PDF",

        userTeamMoveConfirm: "To confirm the move, click 'Save' again.",
        selectExistingUser: "Select an existing member",
        addNew: "Create new",
        addExisting: "Add existing",
        errorCreatingUser: "Error creating member",
        pleaseEnterOneFollowing: "Please enter at least one of the following:",

        createMember: "Add a member",
        createAdmin: "Add an admin",
        dialCode: "Dial code",
        loadingProfile: "Loading profile",
        firstName: "First name",
        lastName: "Last name",
        updateName: "Update name",
        updateEmail: "Update e-mail address",
        updateMobile: "Update mobile phone",
        errorMobile: "Error updating mobile number",
        errorEmail: "Error updating e-mail address",
        enterMobileText: "Mobile should be entered with a dialcode eg 4178322222",
        updateLanguage: "Update language",
        deleteProfileConfirmation:
            "Are you sure you wish to delete this profile from the organisation?",
        profileDeleted: "This profile is now deleted",
        directingToPrevious: "Directing to previous page",
        deleteProfile: "Delete profile",
        noDeleteEmailError:
            "Can't delete e-mail since at least one contact method needs to be registered. Please add a mobile number to the profile in order to delete the e-mail address.",
        noDeleteMobileError:
            "Can't delete mobile number since at least one contact method needs to be registered. Please add an e-mail to the profile in order to delete the mobile number.",

        // OLD
        entitiesAdministratedBy: "Teams and structures administrated by {0}",
        someGuides: "Here are some guides for you!",
        "20people": "+20 people",
        addEntityToCompany: "Add to company",
        detailsPage: "Details page",
        createEntityText1:
            "In order to create a new {0} structure or a {1} team, click under where it should go.",
        createEntityLvl0Text1:
            "In order to create a new {0} team, click on the {1} Company.",
        createEntityText2:
            "Once created, you can go to their Details page to add admins/members or continue creating.",
        createEntityQuestion: "What would you like to create?",
        nameofNewTeam: "Name of the new team",
        nameofNewStructure: "Name of the new structure",
        openAll: "Open all",
        closeAll: "Close all",
        mandatoryLangTooltip:
            "The mandatory language is determined by the organisation",
        noActiveSurveys: "No active surveys at the moment",
        teamAdmins: "Team admins",
        structureAdmins: "Structure admins",
        teamMove: "Move team",
        structureMove: "Move structure",
        moveText:
            "Click on the structure under which {0} should be. Unavailable options are grayed out.",
        structureLabel: "Structure",
        moveHere: "Move here",
        currentLocation: "Current location",
        teamRename: "Rename team",
        teamDelete: "Delete team",
        structureRename: "Rename structure",
        structureDelete: "Delete structure",
        deleteEntityConfirmation: "Are you sure you wish to delete {0}?",
        structureDeleteInfo:
            "NB: Teams and structures under {0} will also be deleted. Members from deleted teams will not participate in pulse surveys and will be listed on the 'Members' page without an allocated team.",
        teamDeleteInfo:
            "NB: After deletion members from this team will be listed on the 'Members' page without an allocated team. Teamless members do not participate in pulse surveys.",
        newName: "New name",
        cantMoveTopStructure: "Can't move a top level structure",
        memberRemove: "Remove member",
        adminRemove: "Remove admin",
        removeInfo:
            "PS: Once removed, teamless members can be found and reassigned on the 'Members' page.",
        adminRemoveConfirmation:
            "Are you sure you wish to remove {0} as an admin from {1}?",
        memberRemoveConfirmation:
            "Are you sure you wish to remove {0} as a member from {1}?",

        tfaCountry: "Country",
        tfaInvalid: "Invalid number",
        tfaRemove0: "Please remove 0",
        tfaRequired: "Number is required",
        labelMobilePhone: "Mobile phone",
        twoFactorTitle: "Two-Factor Authentication",
        twoFactorIntro:
            "In order to see the results once the survey is finished, we ask you activate two-factor authentification. Please enter your mobile phone number where authentication codes could be sent in the future. If you do not do that you can not access the results.",
        twoFactorNoNr:
            "I do not give my number and will not have access to the results.",
        twoFactorNoNrExplanation:
            "Without providing your number you will not be able to access the results dashboard after the survey. Your number will be used only for authentication purposes. If you choose not to provide your number now you can provide it to your supervisor later to enable two-factor authentication for yourself.",
        twoFactorFirst:
            "This information can be viewed after entering a code sent to your mobile phone.",
        emptyResult: "empty result",
        noTeamsFoundWith: "No team names found with {0}",
        removeFromTeam: "Remove member",
        removeAsAdmin: "Remove admin",
        noAdmins: "No admins",
        noMembers: "No members",
        seeDetails: "See details",
        loggingOut: "Logging out",
        calculate: "Calculate",
        calculateENPSavgTitle: "Calculate average ENPS",
        calculateENPSavgLabel: "Average ENPS of:",
        calculateENPSavgSelect: "Select entities to calculate the average ENPS:",
        pwProtectedMessage:
            "Account is password protected. Please login with your password",
        passwordError: "Error setting password",
        passwordLengthError: "Password needs to be at least 7 characters long",
        passwordMismatch: "Passwords don't match",
        passwordChangeSuccess: "Password changed successfully! Log in {0}",
        labelNewPassword: "New password",
        labelRepeatNewPassword: "Repeat new password",
        setNewPassword: "Please set a new password of at least 7 characters.",
        getNewPwLink: "Get a new reset password link {0}",
        resetPw: "Reset password",
        resetPwTokenInvalid: "Invalid reset password link",
        forgotPwTitle: "Forgot password?",
        forgotPwText:
            "We will send you a e-mail with a link where you can reset your password.",
        fillingOutForTeam: "You're filling out for {0}.",
        orContinueWith: "continue with",
        level: "Level",
        showTodo: "Show my To-Do list",
        hideTodo: "Hide my To-Do list",
        statistics: "Statistics",
        commentTooLong: "Maximum comment length is 280 characters",
        allDiscussedNr: "Super, all {0} are discussed!",
        allDiscussed: "Super, all Feedforwards were discussed!",
        notEnoughAnswers: "This time not enough members filled out the survey",
        notEnoughAnswersTeamName:
            "This time not enough members in team {0} filled out the survey",
        startPrep: "Benefit from the team inputs and start preparing",
        expandQuestionComments: "Expand the question to see comments",
        pickValidTeam: "Pick a team that participated in the survey",
        devGeneralExplanation:
            "This is the standard deviation of all ratings in the survey. Smaller number means team members have similar opinions. Higher number means that the team should probably discuss a few topics where opinions differed.",
        avgGeneralExplanation:
            "This is the average for all questions in the survey. Smaller average means there's more potential for improvement.",
        signInWith: "Sign in with ",
        whatsNextTitle: "What's next?",
        whatsNextText: "Uplift {0}, one tick box at a time",
        viewDiscussion: "View discussion",
        startDiscussion: "Start discussion",
        manageSurvey: "Manage survey",
        discussionFor: "Discussion for",
        helpTitle: "Tips & tricks",
        helpText: "Help for discussing the results can be found here",
        finishSurvey: "Finish survey",
        reviewResults: "Prepare discussion",
        reviewResultsHint: "Check out and sort the questions",
        scheduleMeeting: "Schedule a team meeting",
        scheduleMeetingHint:
            "Organize an approx. 45 min team meeting outside this app for the discussion",
        doDiscussion: "Discuss the results in team",
        doDiscussionHint:
            "Click 'Start Discussion' at the top of the page. Discuss Feedforwards as a team and create tasks when necessary",
        resultText1:
            "Order the questions for the discussion according to their importance.",
        resultText2: "To do this, click and drag the {0} icon.",
        handBuchText: "Preparation guide",
        thisTimeNoFF: "This time there were {0} from your team",
        bravo: "Bravo",
        wow: "Wow",
        ffFromTeam: "from your team",
        discussionCompletedPraise: "Super, you've discussed all Feedforwards!",
        showStats: "Show statistics",
        hideStats: "Hide statistics",
        questionLegend: "Question legend",
        orderExplanation: "Questions will be in Discussion Mode in this order",
        avgExplanation:
            "Color coded to reflect how well team members have graded this question. The bigger the number, the better!",
        devExplanation:
            "Color coded to reflect difference of opinion in the team. Smaller number means team members have similar opinions. Higher number means that the team should discuss this topic.",
        nrFFs: "Number of Feedforwards",
        nrFFExplanation:
            "Number of comments left by team members for this question",

        adminLvls: "Administration levels",
        profession: "Profession",
        gender: "Gender",
        office: "Office",
        location: "Location",
        age: "Age",
        keyboardInfoMessage:
            "You can click through the survey by using the number keys keyboard.",
        editAnswers: "Edit answers",
        activeSurvey: "Active survey",
        activeSurveys: "Active surveys",
        surveyTeamLoadError: "Team list cannot be shown: {0}",
        peopleWereInvitedFrom: "{0} people {1} were invited to fill out the survey",
        fromEntity: "from {0}",
        fromWholeCompany: "from the whole company",
        personFilledOut: "1 person filled out the survey",
        peopleFilledOut: "{0} people filled out the survey",
        discussionVideoTitle: "Impulse Team discussion video",
        gdpr01: "Consent notice requirement",
        gdpr02: "Thank you for using Joineer.\nTo be consistent with data protection laws, we would like to inform you that next to your name and e-mail address we collect the following information:",
        gdpr03: "• Anonymous quantitative answers to the Teambarometer questions.",

        gdpr04: "• Optional anonymous or publicly given qualitative feedforward.",
        gdpr04pub: "• Optional qualitative feedforward.",

        // not shown with "anonflag" setting:
        gdpr05: "The given answers will be seen by your team members, the team- and company administrator.",

        gdpr06: "All the data will be stored on servers in Switzerland and not be given to third parties.",

        // not shown with "anonflag" setting:
        gdpr07: "You can access your personal data at any time via the link to this app.",

        gdpr08: "We kindly ask you to take notice and agree to these terms.",
        gdprLinkMessage: "", //For more information click {link}
        gdprLinkTitle: "", //here
        gdprLinkUrl: "", //https://www.app.com
        gdpr09: "If you have any questions, please feel free to contact us (info{'@'}joineer.ch).",
        gdpr10: "Thank you for your confidence.",
        gdpr11: "Sincerely yours,",
        gdpr12: "Joineer",
        gdprIgiveMyConsent: "I understand and give my consent",
        introTitle1: "Welcome to Teambarometer",
        introTitle2: "Your opinion counts - and it counts constantly",
        introTitle3: "FeedFORWARD - not feedback",
        introTitle4: "Fill out - Discuss - Benefit",
        introTitle5: "Ready to go?",
        introText1:
            "We are very happy to see you participate - because your opinion counts. Please read the following pages carefully.",
        introText2:
            "With Joineer you will be asked for your opinion several times a year in a pulse-like survey. This way the mood is measured over time and the improvements can be compared accordingly.",
        introText3:
            "We're not a complaint box. Don't criticize what went wrong in the past, but share how things can be improved in the future. Future-oriented, positive and solution-oriented - simply feedforward!",
        introText4:
            "After the survey, the inputs are displayed on the dashboard. The most important thing is that you discuss the results in the team! This is the only way the Teambarometer can be effective.",
        introText5L:
            "Everything clear? If you have any questions," +
            " feel free to contact us at info{'@'}joineer.ch." +
            " On the next page log in with your e-mail and password and let's go!",
        introText5:
            "Everything clear? If you have any questions, feel free to contact us at info{'@'}joineer.ch. Here we go!",
        introSkip: "Skip intro",
        surveyToFill: "Survey to fill out",
        hi: "Hi",
        surveyStartTitle: "Hi {0}, glad to have you!",
        startGoMessage:
            "Share what can be done better in the future - positive and solution-oriented with Feedforward.",
        startGoMessage2:
            "The results are presented on the dashboard and discussed in the teams.",
        pulseSurvey: "Regular survey",
        reference: "Reference",
        surveyEndTitle: "Bravo, nearly done...",
        surveyEndMessage:
            "Have you submitted all your Feedforwards? If so, click 'Submit now'. If you want to change something or write further comments, you can use the 'Back' button to go back to the questions.",
        surveyEndLowRatingWarning: "You have rated all questions with a 1 - that's the lowest possible score. Are you sure you want to submit your results like that?",
        startSurvey: "Start survey",
        questionsInSurvey: "Questions in survey",
        pickFromCatalog: "Pick from catalog",
        createQuestion: "Create a question",
        sendReminderTooltip: "Send a reminder e-mail",
        sendReminders: "Send {0} reminders",
        sendReminder: "Send 1 reminder",
        addMembers: "Add members",
        addMember: "Add member",
        addAdmin: "Add admin",
        availableSoon: "Available soon!",
        remind: "Remind",
        remindAll: "Remind all",
        respondedQ: "Responded?",
        hasNotFilled: "This member has not filled out the survey",
        hasFilled: "This member has filled out the survey",
        membersPerPage: "Members per page",
        yes: "yes",
        no: "no",
        endSurvey: "End survey",
        general: "General",
        surveysPaused: "Regular surveys are paused",
        noIntervalResults: "No interval results available",
        status: "Status",
        results: "Results",
        planned: "PLANNED",
        ongoing: "ONGOING",
        finished: "FINISHED",
        surveyTitle: "Survey title",
        startDate: "Start date",
        endDate: "End date",
        participants: "Participants",
        total: "Total",
        totalParticipants: "Total participants",
        page: "Page",
        previousPage: "Previous page",
        nextPage: "Next page",
        or: "or",
        none: "none",
        today: "today",
        here: "here",
        select: "Select",

        roles: {
            superadmin: "Company admin",
            admin: "Team admin",
            member: "Member",
        },
        greeting: "Welcome",
        buttons: {
            settings: "Options",
            saveChanges: "Save changes",
            reload: "Reload",
            filter: "Filter",
            close: "Close",
            surveyGo: "Let's do this",
            surveyEnd: "Submit now",
            go: "Go",
            start: "Start",
            cancel: "Cancel",
            reset: "Reset",
            resetAll: "Reset all",
            resetSelection: "Reset selection",
            back: "Back",
            backdash: "Back to dashboard",
            reloadComponent: "Reload component",
            next: "Next",
            finish: "Finish",
            finalise: "Finalise",
            save: "Save",
            ok: "Ok",
            reopen: "Reopen",
            delete: "Delete",
            send: "Send",
            markDone: "Discussed",
            done: "Done",
            moveUp: "Delegate",
            newTask: "New task",
            expand: "Expand all",
            collapse: "Minimise all",
            details: "Details",
            new: "New",
            edit: "Edit",
            add: "Add",
            addLanguage: "Add language",
            addQuestion: "Add question",
            create: "Create",
            askExpert: "Ask the expert",
            goTo: "Go to",
            showAllFF: "Show all Feedforwards",
            hideAllFF: "Hide all Feedforwards",
            changeOrder: "Change order",
            continue: "Continue",
            yesDelete: "Yes, delete",
            yesRemove: "Yes, remove",
        },
        snackbar: {
            detailsUpdated: "Details updated",
            remindersSent: "Selected reminders sent!",
            ffClosed: "Feedforward marked as discussed",
            ffOpened: "Feedforward opened",
            ffEscalated: "Feedforward sent to review by management",
            taskClosed: "Task closed",
            taskOpened: "Task reopened",
            taskCreated: "Task created",
            taskDeleted: "Task deleted",
            taskDelegated: "Task delegated",
            emailSent: "E-mail sent",
            emailConfirmationSent: "E-mail confirmation sent",
            error: "Error",
            warning: "Warning",
            success: "Success",
            forgotPWemailSent: "E-mail sent if account exists",
            copiedToClipboard: "Copied to clipboard",
            downloading: "Downloading",
            downloadError: "Download error!",
            questionDeleted: "Question was deleted",
            questionAdded: "New question was added",
            questionEdited: "Question was edited",
            reordered: "Questions were reordered",
            surveyDeleted: "Survey deleted",
            memberAdded: "Member was added to survey",
            memberRemovedFromSurvey: "Member was removed from survey",
            startChanged: "Survey start date was changed",
            endChanged: "Survey end date was changed",
            titleChanged: "Survey title was changed",
            memberRemoved: "Member was removed",
            adminRemoved: "Admin was removed",
            teamCreated: "Team was created",
            structureCreated: "Structure was created",
            teamDeleted: "Team was deleted",
            structureDeleted: "Structure was deleted",
            structureRenamed: "Structure was renamed",
            teamRenamed: "Team was renamed",
            teamMoved: "Team was moved",
            structureMoved: "Structure was moved",
            newMemberCreated: "New member was added",
            newAdminCreated: "New admin was added",
            surveyEnded: "Survey end was scheduled, and will close in the next 15 minutes",
            surveyUpdated: "Survey was updated",
            useImportTool: "To create lower level structures please use the excel import tool, or contact Joineer Support",
            feedbackSubmitted: "Your feedback helps us improve. Thank you!",
        },
        tooltips: {
            editTeamName: "Edit the name",
            deleteTranslations: "Delete {lang} translations",
            discussed: "Mark Feedforward as discussed",
            createTask: "Create task from Feedforward",
            createFreeTask: "Create a task not related to any specific Feedforward",
            closeTask: "Mark task as completed",
            moveUp: "Move task up to admins",
            deleteTask: "Delete task",
            reopenFF: "Reopen Feedforward",
            addComment: "Add comment",
            discussedDisabled:
                '"Mark Feedforward as discussed" disabled in preview mode',
            disabled: "Disabled in preview mode",
            hideFF: "Mark Feedforward as handled in a One-on-One and don't show in Discussion mode",
            showFF: "Show Feedforward in Discussion Mode",
            required: "Required",
            pressEnter: "Press Enter to save",
            surveyCanNotBeDeleted: "Only planned surveys can be deleted.",
        },
        nav: {
            structure: "Organisation structure",
            companyAdmins: "Company admins",
            calendar: "Survey calendar",
            dashboard: "Dashboard",
            discussion: "Discussion mode",
            reports: "Reports",
            delegatedTasks: "Delegated tasks",
            teamTasks: "Team tasks",
            team: "Team",
            members: "Members",
            interval: "Interval",
            adhoc: "Adhoc surveys",
            questions: "Questions",
            profile: "Profile",
            logout: "Log out",
            intercomChat: "Chat with us!",
            qrCodes: "QR letters",
            tfa: "Two-factor authentication",
            ffanalysis: "FeedForward Analysis",
            feedForwards: "FeedForwards",
            emailTemplates: "Emails"
        },
        selectSurvey: {
            customised: "customised",
            choose: "Choose a survey to review in Discussion Mode",
            now: "now",
            team: "Team",
            selectTeam: "Select team",
            placeholder: "Select survey",
            goDiscussion: "Go to discussion mode",
            selectModeLabel: "Select viewing mode",
            preview: "Preview mode",
            prepare: "Prepare",
            previewBody:
                "This is a preview of the Discussion Mode before the actual Teammeeting. You can hide certain Feedforwards in case you prefer to resolve them in a one-on-one meeting",
            present: "Present",
            presentBody:
                "Present and discuss the results with your team. {0} Actionable Feedforwards can be turned into team tasks",
            noTeam: "No such team",
            noSurvey: "No surveys completed yet",
            comeBack:
                "Come back to discuss the results once your first survey is complete!",
            handbuchLink:
                "Tips and tricks for a discussion of the results in the team can be found {here}",
            questionOrder: "Discussion order",
            original: "as in survey",
            overall: {
                average: "Overall average",
                deviation: "Overall standard deviation",
            },
        },
        discussionMode: {
            question: "Question",
            survey: "survey",
            anonym: "Anonymous",
            noFF: 'No Feedforwards, click "Next"',
            noDiscussed: "Feedforwards marked as discussed {0} will appear here",
            noTasks: "Feedforwards turned into tasks {0} will appear here",
            createTask: "Create a task from Feedforward",
            feedforwards: "Feedforwards",
            discussed: "Discussed",
            tasks: "Tasks",
            moveUpTo: "Move task up to:",
            highlight: "Delegate this Feedforward",
            describe: "Describe why this is important to be passed on",
            selectLabel: "For the attention of:",
            unable: "Unable to solve within team?",
            hiddenFFs:
                "The Feedforwards below are hidden from Discussion Mode presentation. The number of hidden Feedforwards will still be shown",
            nrhiddenFFs: "Number of Feedforwards handled in a One-on-One:",
        },
        achievements: {
            title: "Achievements",
            intro: "Here's what's been accomplished already",
        },
        tasks: {
            taskPage: "Task page",
            taskBannerText:"Tasks created from discussion",
            createTitle: "Create task",
            taskDisabled: "Creating tasks is disabled in preview mode",
            titlePlaceholder: "Task title",
            title: "Title",
            commentPlaceholder: "Optional comment",
            origin: "Originated from",
            addComment: "Add comment",
            comments: "Comments",
            noComments: "No comments",
            dueDate: "Due date",
            dueBy: "Due by",
            setDueDate: "Set a due date",
            assign: "Assign task to",
            assignedTo: "Assigned to",
            other: "other",
            enterTitle: "Please enter task title",
            myTasks: "My tasks",
            teamTasks: "Team tasks",
            openTasks: "Open tasks",
            completedTasks: "Completed tasks",
            deleteTitle: "Delete task",
            deleteBody: "Are you sure you wish to delete this task permanently?",
            noTasks: "No open tasks",
            delegatedExplanation:
                "List of Feedforwards or team tasks that were delegated for the attention of company admins.",
            noCompletedTasks: "No completed tasks yet",
            noDelegatedTasks: "No delegated tasks yet",
            noMembersAvailable: "No members available",
            assigneeRequiredMessage: "Please select a member to assign the task to",
            sendEmail: "Send task summary via e-mail",
            calendarEntry: "With calendar entry",
            calendarEntryNo: "Without calendar entry",
            commentDeletedBy: "Comment deleted by {updatedBy}",
            deleteComment: "Delete this comment?",
            updateComment: "Save changes",
            edited: "edited",
            editComment: "Edit comment",
            afterDelete:
                "After deletion the this will be shown in the task comment history:",
        },
        completion: {
            title: "Well done!",
            tasksCreated: "Tasks created",
            ffDiscussed: "Feedforwards discussed",
            go: "Go to taskboard",
        },
        intro: {
            title: "Select question order",
            intro: "Based on the results we would recommend to discuss Feedforwards in the following order. Drag {0} and drop to change order",
            start: "Start discussion",
        },
        login: {
            title: "Login",
            pw: "Password",
            forgot: "Forgot password?",

            withLink: " with direct link",
            withPassword: " with password",
            checkEmail:
                "If you have an account with this e-mail address, your login link will be sent to you now",
            incorrectEmail: "Incorrect or missing e-mail",
            labelSendLink: "Send link",
            labelLinkSent: "Request successful",

            enterNameAndPassword: "Please enter your e-mail and password.",
            enterEmail:
                "Please enter your e-mail to retrieve a direct log-in link.",
            resetSent: "Reset password link sent to e-mail!",
            getLink: "Retrieve login link",
            selectYourCompany: "Select your survey/company",
        },
        admin: {
            title: "Admin board",
            allTeams: "All teams",
            allSurveys: "All surveys",
            selectedTeams: "Selected teams",
            deselectAll: "Deselect all",
            selectAll: "Select all",
            created: "Created on",
            markDone: "Mark as resolved",
            done: "Resolve",
            resolved: "Resolved",
            latestComment: "Latest comment",
            lessComments: "less comments...",
            moreComments: "more comments...",
            empty: "Nothing here yet",
            deleteConfirmation: "Are you sure you wish to delete this permanently?",
        },
        stats: {
            decreased: "decreased",
            increased: "increased",
            avg1: "This is the average team rating for this question. Smaller average means there's more potential for improvement. ",
            avg2: "Compared to the previous survey the result has {0} by {1}%.",
            dev1: "This is the standard deviation of the ratings for this question. Smaller number means the team members have similar opinions. Higher number means that the team should discuss this topic. ",
            dev2: "Compared to the previous survey the differences have {0} by {1}.",
            graphIntro: "Below graphic may help interpret the results.",
            devGraph1Label: "Agreed",
            devGraph1Explanation: "One heart, one soul.",
            devGraph2Label: "Diverse",
            devGraph2Explanation: "Deviations in normal range.",
            devGraph3Label: "Need for discussion",
            devGraph3Explanation: "You assess the situation differently.",
            avgGraph1Label: "Improve",
            avgGraph1Explanation: "Great potential for improvement exists.",
            avgGraph2Label: "Neutral",
            avgGraph2Explanation: "Sufficient. Improve selectively.",
            avgGraph3Label: "Keep it up",
            avgGraph3Explanation: "Give and receive praise.",
            histogramTitle: "Distribution of answers",
            histogramNr: "Number of people",
            histogramGrade: "Grade",
            histogramAnswered: " people answered ",
        },
        report: {
            title: "Teambarometer report",
            selectLabel: "Viewing report for",
            summary: "Summary",
            all: "All",
            pleaseWait: "Loading report... please wait",
            noData: "No report available",
            searchLabel: "Search",
            name: "Name",
            totalFF: "Total Feedforwards",
            noFFsInSurvey: "No Feedforwards were submitted in this survey",
            closedFF: "Discussed Feedforwards",
            totalTasks: "Total Tasks",
            taskedFF: "Tasked Feedforwards",
            delegatedTasks: "Delegated Tasks",
            closedTasks: "Closed Tasks",
            wholeCompany: "Whole company",
            advancedTeamCompare: "Team Compare",
            discussionSummary: "Discussion summary",
            discussionSummaryText:
                "Overview of the result discussions held within the company. Check out in which teams the inputs were already discussed or implemented.",
            download: "Download",
            downloadSummary: "Download summary (.pptx)",
            preparingFile: "Preparing file...",
            presentation: "Presentation",
            matrixTitle: "Matrix heatmap",
            matrixText: "Color-coded overview of answers per team and question.",
            matrixTextMinFill:
                "Teams without enough answers are excluded from the report",
            selectReport: "Select report",
            displayError:
                "Table can not be generated. Please try downloading the results instead.",
            averages: "Averages",
            deviations: "Standard deviations",
            enps: "ENPS",
            enpsScore: "ENPS score",
            answerNr: "Number of answers",
            surveyEnded: "Survey ended",
            "1dayAgo": "{0} day ago",
            xdaysAgo: "{0} days ago",
            pastSurveys: "Survey history",
            endedAt: "Ended At",
            team: "Team",
            teams: "Teams",
            company: "Company",
            companyGoal: "Company goal",
            handleFeedforwards: "handle all Feedforwards",
            howManyHandled: "{0} out of {1} Feedforwards handled",
            handlingRate: "Handling rate",
            FFHandleTitleCompanyAdmin: "How well are your teams progressing?",
            FFHandleTextCompanyAdmin:
                "To make the survey a team success, it's important to handle all Feedforwards soon after the survey.",
            "2weeksafter":
                "2 weeks after the survey the table is colored by Feedforward handling rate. The colors present the % of Feedforwards that are discussed or tasked within the team.",
        },
        genericApiErrorMessage: "Operation could not be performed",
        errors: {
            selectUser: "Please select a member first",
            submit: "Error submitting survey",
            invalidFormat: "Invalid format",
            notPossibleRunningSurvey: "Not possible during a running survey",
            oops: "Oops!",
            "error-4000":
                "Not enough data available for this survey and selected tags",
            noData: "No data",
            noDataYet: "No data yet",
            notEnoughData:
                "Not enough data available for this survey and selected tags",
            notEnoughResponseData: "Not enough response data available",
            displayError: "Table can not be generated",
            generalError: "Something went wrong",
            featureNotEnabled:
                "This feature is not enabled on your account. Please contact us at info{'@'}joineer.ch or +41\u00A044\u00A0510\u00A038\u00A000 for more information.",
            featureNotAllowed: "This feature is not enabled for your user role",
            missingFields: "Please fill out all fields",
            noPermission: "No permission to view this page",
            incorrectUrl: "Incorrect URL",
            incorrectEmail: "Incorrect e-mail format",
            login400: "Missing e-mail or other login information",
            login401: "Password or username is incorrect",
            login401token: "Login token is incorrect",
            login403: "Account is password protected. Please login with your password.",
            login403authMethodNotAllowed: "The authentication method '{method}' is not permitted for your company account. Please contact your administrator.",
            login403urlLoginNotAllowed: "Url login is not allowed. Password login needed.",
            "enps-question-already-exists": "Only one question can be marked as the ENPS question.",
        },
        dashboard: {
            participation: "Participation",
            participants: "Participants",
            feedforwards: "Feedforwards",
            pariticantsTooltip: "{0} participants out of a total of {1} submitted their responses in this survey",
            feedforwadTooltip: "{0} feedforwards have been submitted in this survey",
            showCompanyHint: "Show Company level data too",
            company: "Company",
            companyLine: "Company (line)",
            companyStatistics: "Company (statistics)",
            allYourTeams: "All your Teams",
            allYourTeamsLine: "All your Teams (line)",
            allYourTeamsStatistics: "All your Teams (statistics)",
            surveySuccessfullySubmitted: "Survey successfully submitted",
            surveyFilledOutByOutOf: "{0} out of {1}",
            surveyResults: "Survey results",
            summary: "Summary",
            users: "Users",
            resultsFor: "Results for",
            wholeCompany: "Whole company",
            ownTeam: "Own team",
            ownTeams: "Own teams",
            team: "Team",
            inTeam: "in {0}",
            adminOf: "admin of: {0}",
            noTagsMessage: "To perform tag based filtering add tags to users",
            compareWith: "Compare with",
            compareExplanation: "Compare results against the following:",
            questionOverview: "Question overview",
            average: "Average",
            deviation: "Standard deviation",
            companyHistory: "Company average over past surveys",
            compareHistory: "Average over past surveys",
            filterExplanation: "Filter survey results by tags:",
            "dev-good": "Agreed",
            "dev-normal": "Diverse",
            "dev-bad": "Very diverse",
            distributionAnswers: "Distribution of answers",
            surveyDate: "Survey date",
            companyAvg: "Company average",
            positive: "positive",
            negative: "negative",
            noFFs: "No Feedforwards",
            perMember: "per member",
            otherTags: "Other tags",

            waitingSurvey: "Waiting for the first survey to start on ",
            waitingTeam: "Waiting for your team to fill out the survey",
            surveyStatus: "Survey status: ",
            meanWhile: "Meanwhile you can:",
            here: "here",
            addMembers: "Add new users {0}",
            editQ: "Edit questions {0}",
            editInterval: "Edit interval {0}",

            scheduleSurvey: "Schedule a Survey",
            takeSurvey: "Take Survey",
            editYourSurveyAnswersUntil: "Edit your answers (until {0})",
            surveyInProgressTitle: "Survey in Progress",
            surveyStatusLoading: "loading ...",
            surveyStatusRunning: "Running",
            surveyStatusFilled: "Running",
            surveyStatusScheduled: "Scheduled",
            surveyStatusFirstScheduled: "Scheduled",
            surveyStatusWaiting: "Waiting",
            surveyDetailsLoading: "",
            surveyDetailsRunning: "You haven't completed the survey. Share your insights until {0}!",
            surveyDetailsRunningNonMember: "The current survey will end on {0}!",
            surveyDetailsFilled: "You can update your survey answers until {0}",
            surveyDetailsFilledNonMember: "The current survey will end on {0}!",
            surveyDetailsScheduled: "The survey will start on {0}",
            surveyDetailsFirstScheduled: "Waiting for the first survey on {0}",
            surveyDetailsWaiting: "Schedule a survey ...",
            surveyEditTitle: "Your opinion counts",
        },
        teamMemberDisplay: {
            title: "Members which participated in the survey",
            noResponse: "No members from this team have responded to the survey"
        },
        questionEditor: {
            questionsTitle: "Questions",
            questionsIntro:
                "Questions with a yellow {0} background are asked company wide or in a superordinate department. Questions with a blue {1} background are only asked in the selected team/department.",
            questionsIntroType:
                "Questions of the same type show a link icon {0} when hovering over. Survey statistics to these questions are compared with each other.",
            survey: "Survey",
            company: "Company",
            companyQ:
                "View and edit company wide questions that each team and department has in their survey",
            teamSelectLabel: "Viewing questions for:",
            currentSurvey: "Ongoing survey",
            pastSurvey: "Past survey",
            nextSurvey: "Next survey",
            noEdit: "Past surveys are here for reference and may not be edited",
            adminQuestionNoEdit:
                "This question is fixed within the company or department. May only be adjusted by a department or company admin",
            notAsked: "Not asked",
            noQuestionEdit: "This is a higher level question and can not be edited",
            noQuestionEditView:
                "Can only be edited on a higher department or company view level",
            addError: "Question can not be added:",
            editError: "Question can not be edited:",
            deleteError: "Question can not be deleted:",
            nextError: "Can not retrieve next survey:",
            catalogIntro:
                "Be inspired by our catalogue of questions. Open the categories and select a question by clicking on it. Once you have assigned a question to your survey, you can change the illustration and text using the edit icon {0}.",
            questions: {
                question: "Question",
                type: "Type",
                illustration: "Illustration",
                topic: "Topic",
                preview: "preview",
                dragOrder: "Drag {0} and drop to change question order.",
                addQuestion: {
                    en: "English",
                    de: "German",
                    it: "Italian",
                    fr: "French",
                    pl: "Polish",
                    sk: "Slovakian",
                    hu: "Hungarian",
                },
                mandatory: "Mandatory language",
                language: "Language",
                chooseLang: "Choose language",
                example: "Example: ",
                exampleQ: "Are you satisfied with the contents of your job?",
                exampleT: "Work tasks",
            },
            addQuestion: {
                addTitle: "Add question to survey",
                newTitle: "New",
                catalogTitle: "Catalog",
                reuseTitle: "Reuse",
                new: "Make your own custom question to fit your team's needs",
                catalog: "Choose a new question from the catalog",
                reuse: "Add a previously used question to the survey",
                noReuse: "No question history yet",
                newType:
                    "Create a new question measuring your team's responses about a certain topic",
                newLinkedType:
                    "Create a new question that is linked with a previously used question",
                linkedTooltip1:
                    "This option is good for creating questions that measure the same thing, but are worded differently or asked under a new angle.",
                linkedTooltip2:
                    "Connecting similar questions allows convenient and visual statistics between surveys as shown below.",
                linkTitle: "Link question",
                enps: "ENPS",
                enpsLabelTooltip: "This question will be included in the Employee Net Promoter Score (eNPS) report, which measures how likely employees are to recommend the organization.",
                enpsSwitchTooltip: "Enable this to include the question in the Employee Net Promoter Score (eNPS) report, which measures how likely employees are to recommend the organization.",
                textTitle: "Question text",
                copyNotice:
                    "Texts from the selected question have been copied below for your convenience",
                noLangContact:
                    "If you'd like to add a language not listed here, please {0} us and we're happy to add it manually for you",
                contact: "contact",
                langsUsed:
                    "All {0} default languages are used. If you'd like to add more languages, please {1} us and we're happy to add it manually for you.",
                allPreviousQuestionsLinked: "All the previous questions have been linked or added already.\n\n You can add more questions from the catalog or create new ones",
            },
            search: {
                mayAdd: "You may add a previously used question into the survey",
                searchLabel: "Search from existing questions",
                willBeLinked:
                    "Statistics for the question you create now will be compared to the below question:",
                askedBefore: "This question has been asked before in:",
            },
            dialogs: {
                editQ: "Edit question",
                editExplanation: "Edit wording, illustration or add new languages",
                deleteExplanation: "Delete question from survey",
                deleteTitle: "Delete question",
                deleteConfirmation: "Would you like to delete this question?",
                deleteLong:
                    "Deleting the question means that it will not appear in any future surveys, but you will still see it in past statistics",
            },
        },
        forms: {
            search: "Search",
        },
        adhoc: {
            description1: "One-off surveys",
            surveyDetails: "Survey details",
            detailsDescription: "Set title and dates",
            participantsDescription: "Choose who fills out the survey",
            participantsMinFill1:
                "At least 1 member would have to fill out the survey for results to be calculated",
            participantsMinFillLarger:
                "At least {0} members would have to fill out the survey for results to be calculated",
            notEnoughMembers: "Not enough members in survey! Minimum: {0}",
            questionsDescription: "Choose questions for the survey",
            review: "Review",
            reviewDescription: "Summary of the survey",
            activeFilters: "Active filters",
            filterByEntities: "Filter by entities",
            active: "active",
            filterByTags: "Filter by tags",
            reset: "Reset all filters and deletes participants added to survey",
            removeFromSurvey: "Remove participant from survey",
            usersAddedToSurvey: " participants added to survey",
            questionsAdded: "Questions added to the survey",
            surveyPeriod: "Survey period",
            editParticipants: "Go back and edit survey participants",
            editPeriod: "Go back and edit survey period",
            editQuestions: "Go back and edit survey questions",
            editTitle: "Go back and edit survey title",
            datesWrongError:
                "Oops! Please make sure the end date is after the start date",
            surveySuccess: "Survey created successfully",
            surveyDeleteConfirmation:
                "Are you sure you wish to delete this survey?",
            noMembersFound:
                "No members found with current filters. Try removing some of the active filters to broaden the selection or {0} filters",
            manageSurvey: "Manage survey",
            endNow15min:
                "This action will stop the ongoing survey. Changes will take effect in 15 minutes.",
            endNowConfirmation: "Are you sure you wish to end this survey?",
        },
        askExpert: {
            text: "Difficult situation in the team? Unsure how to discuss the results in the team? {0} will be happy to support you in challenging questions.",
            subject: "Subject",
            from: "From:",
            to: "To:",
            messagePlaceholder: "I have a following question...",
            askQuestions: "If you have questions, ",
            yourExpertHere: "ask your expert here",
        },
        survey: {
            admin: "Admin",
            team: "Team",
            opener: "In the future I think it would be good to",
            publicComment: "Public comment",
            anonComment: "Anonymous comment",
            infoNumericalValueResponse:
                "Numerical value: Is collected without your name",
            infoAnonComment:
                "Anonymous comment: Is collected without your name",
            infoPublicComment:
                "Public Comment: Your name is visible to your team and admins",
            labelNoAnswerGiven: "No answer given",
            labelCantAnswer: "I cannot answer this question",
            infoFeedforward: "What is a good Feedforward and how to write it? Watch the video",
            teamQuestion: "Team question",
            whatIsAFeedforward: "What is a feedforward?",
            errors: {
                surveyFinished: "This survey has already finished, you can view the results on the dashboard",
                getSurveyQuestion: "There was an error retrieving this question",
                surveySettings: "There was an error retrieving the settings",
                saveAnswer: "There was an error while saving this answer",
            }
        },
        remindOrSuspend: {
            resultsCannotBePrepared: "Engage Your Members",
            of: "of",
            teamMembersCompleted: "team members have completed the survey",
            suspendOrRemind: "Suspend or remind",
            remind: "Remind",
            suspend: "Suspend",
            membersWithIncompleteSurvey: "Members with incomplete survey",
            notifyRemindersSentAndSuspended: "Members reminded or suspended!",
            notifyRemindersSent: "Members reminded!",
            notifySuspended: "Members suspended!",
            errorSendingReminders: "Suspend or remind could not be completed",
            selectAll: "Select all",
            searchMembers: "Search members or team...",
            labelNoMember: "No members found",
            all: "All",
            nobodyToSuspendOrRemind: "All members have filled out the survey already",
            membersLeftToFillCurrentSurvey: "member left to fill out the current survey | members left to fill out the current survey",
            membersHaveFilledOutTheCurrentSurvey: "member filled out the current survey | members filled out the current survey",
            surveyWillEndOn: "The survey will end on {0}",
            listOfMembersToRemindDidNotLoad: "List of members to remind or suspend could not be retrieved",
            reloadRemindMemberList: "Reload remind list",
        },
        tfa: {
            title: "Two-factor authentication",
            description: "To ensure your security, we've sent a verification code to {0}. Please enter the code below to access your dashboard.",
            invalidCode: "Invalid code. Please try again.",
            smsSent: "SMS with verification code sent to {0}!",
            notReceived: "Verification code not received?",
            resend: "Resend",
            resendIn: "Resend in {0} seconds."

        },
        videoModal: {
            gotIt: "Got it"
        },
        feedforwards: {
            overallSummary: "Overall Insights",
            summary: "Insights",
            teamQuestions: "Team questions",
            aiTooltip: "Written by Joineer AI",
            download: "Download",
            summaryObservations: "Observations",
            summaryObservationsPlaceholder: "Please provide your observations",
            searchFeedforwards: "Search feedforwards",
            noFeedforwardsFoundWith: "No feedforwards found with {0}, try a different search term or narrowing your search",
            copyToClipboard: "Copy summary to clipboard",
            copyToClipboardSuccessMsg: "Summary copied to clipboard",
            tags: {
                incomplete: "incomplete",
                inaccurate: "inaccurate",
                offensive: "offensive",
                disagree: "disagree",
                badStyling: "bad styling",
                agree: "agree",
                complete: "complete",
                correct: "correct",
                wellWritten: "well written",
                easyToRead: "easy to read",
                other: "other",
            }
        },
        emailTemplateFields: {
            someLangsAreEmpty: "Some of the template translations have no content",
            translations: "Translations",
            hide: "Hide",
            show: "Show",
            variables: "Variables",
            subject: "Subject:",
            title: "Title:",
            greeting: "Greeting:",
            paragraph1: "Paragraph 1:",
            buttonText: "Button text",
            paragraph2: "Paragraph 2:",
            errorSavingTemplate: "Error saving template",
            removeCustomisation: "Remove customisation",
            removeCustomisedTemplateHint: "This will delete this customised template for '{0}' and the default one will be sent instead",
            cancel: "Cancel",
            save: "Save",
            deleteTemplateQuestion: "Do you wish to delete this e-mail template?",
            deleteTemplateQuestionExplanation: "After deletion default e-mail templates will be used instead.",
            yesDelete: "Yes, delete",
            emailSubjectLine: "Email subject line",
        },
        companyTemplates:{
            emailTemplate: "Email Template",
            emailTemplates: "Email Templates",
            templateEdited: "Template edited",
            backToTemplates: "back to templates",
            badTemplateFormat: "Please make sure only valid variables are used: listed above, and all start with {'{'}{'{'} and end with {'}'}{'}'} \nDetails: ",
            noTemplateSelected: "No template selected",
            emailNeededForTestSend: "(set your email in your @:nav.profile to receive previews)",
            sendOnlyForEmails: "Preview can only be sent for email templates",
            previewEmailSent: "E-mail sent to {0} (check spam), in language {1}",
            templateDeleted: "Template deleted",
            templateCreated: "Template created",
            SURVEY_PREPARATION: "survey preparation",
            SURVEY_START: "survey start",
            SURVEY_REMINDER: "survey reminder",
            SURVEY_END: "survey end",
            DM_REMINDER: "dm reminder",
            ADHOC_START: "adhoc start",
            ADHOC_REMINDER: "adhoc reminder",
            ADHOC_END: "adhoc end",
            EMAIL: "email",
            SMS: "sms",
            noAdhocUpsellPrompt: "Adhoc feature not activated. Please contact Joineer for more information at {0} or {1}",
            goToTemplatePage: "Go to template page",
            preview: "preview",
            generalEMailSettings: "General e-mail settings",
            previewSendHint: "Sends a test {0} to YOUR {1}",
            email: "email",
            mobile: "mobile",
            saveAndSendTest: "Save & send test",
            sendTest: "Send Test",
            errorSendingTemplate: "Error sending template",
            error: "Error",
            channel: "Channel",
            event: "Event",
            details: "Details",
            customise: "Customise",
        },
        companyConfig: {
            all: "all",
            companyConfigNote: "These settings apply to {0} e-mail templates for this company. To adjust or change any of these settings please contact {1} or {2}",
            backgroundColor: "Background color:",
            showBanner: "Show banner:",
            showBannerWithAColorBackground: "Show banner with a color background",
            bannerColor: "Banner color:",
            showLogo: "Show logo",
            ":": ":",
            logoSetupNote: "To set up the company logo please contact {0} or {1}",
            showCompanyLogoInTheEMail: "Show company logo in the e-mail",
            footerImage: "Footer image:",
            footerLink: "Footer link:",
        },
        tiptap: {
            editLink: "Edit link",
            url: "URL",
        },
    };
};

function commonCustomisations() {

    return Object.assign({}, calidaGroup(), postGroup(), txGroup());

    function calidaGroup() {

        return assignToObjectKeys(CALIDA_GROUP_COMPANY_IDS, {
            introTitle1: "Welcome to the Joineer Survey",
            introTitle3: "FeedFORWARD",
            introText3:
                "We are not a complaint box. Use the survey to tell us how we can improve in the future. Forward-looking, positive and solution-oriented – in short feedforward!",
            introTitle4: "Complete – Discuss – Profit",
            introText4:
                "After the survey, the results and comments of the team are visible to the team members. This timely transparency enables you to exchange inputs and discuss measures! This is how the survey unfolds its impact.",
            introTitle5: "Ready?",
            introText5:
                "Contact info{'@'}joineer.ch for technical problems or joineer{'@'}calida.com for general questions. Here we go!",
            gdpr02: "Thank you for using Joineer. We would like to inform you transparently about the privacy policy and anonymity. We collect the following information through the survey:",
            gdpr03: "a) your name\nb) your business e-mail address, if you have one\nc) anonymous quantitative ratings in the form of scale scores from 1-6\nd) qualitative comments in the text boxes:",
            gdpr04: 'For each question, in line with our open and trust-based corporate culture in the CALIDA GROUP, we offer the possibility to share the comments transparently with names within the team. The ratings and comments are only seen by you, your team members and the HR responsibles. Access to the data is handled restrictively according to the "need-to-know principle".',
            gdpr06: "For those authorised to access the data, it is easily accessible via the invitation e-mail or the QR code. All the data will be stored on servers in Switzerland and not be given to third parties.",
            //gdpr07 hidden with current implementation, adding it to gdpr06
            gdpr07: "For those authorised to access the data, it is easily accessible via the invitation e-mail or the QR code.",
            gdpr09: "If you have any questions about data protection, please feel free to contact us at info{'@'}joineer.ch. If you have any questions regarding anonymity, please contact us at joineer{'@'}calida.ch.",
            gdpr10: "Thank you for your trust!",
        }, {});
    }

    function postGroup() {

        return assignToObjectKeys(POST_GROUP_COMPANY_IDS, {
            introTitle1: "Welcome to the Swiss Post team barometer.",
            introText1:
                "We look forward to your input. The survey will take a few minutes, and answers will  not be anonymous. This means that your answers will be visible to others. On the next page, you'll learn how you can answer the questions in the team barometer. ",
            introTitle2: "What this survey is about",
            introText2:
                'The main purpose of the team barometer is to collect information on how you perceive the culture on a personal level, in your team and overall in your work environment  at the present time. Collecting this information will help your team gain a common understanding of the current culture and derive implementation measures for cultural and transformational development.\n\nPlease answer these questions spontaneously and honestly – there are no right or wrong answers. Besides a quantitative evaluation, you will also be asked to provide a suggestion for improvement for the future. Please take the time you need to do this. These are highly important to our team because they help us better understand your assessments and derive effective measures.\n\nNow, click on "Let\'s do this"  to start the team barometer! If you experience technical problems while completing the survey, please use the chat function in the team barometer.',
            gdpr02: "Thank you for using the Teambarometer. It is used by Swiss Post in collaboration with the company Joineer. To comply with data protection regulations, we would like to inform you that next to your name and e-mail address we collect the following information:",
            gdpr03: "• Anonymous quantitative values for the Teambarometer questions.",
            gdpr04: "• Optional personal qualitative comments / feedforwards.",
            gdpr04pub: "• Optional personal qualitative comments / feedforwards.",
            gdpr05: "The answers can be viewed by your team members, the team and company administrator.",
            gdpr06: "Please do not share sensitive data or confidential information through Joineer. By giving your consent, you confirm that you take this into account.",
            gdpr07: "",
            gdprLinkMessage: "All data will be used for specific purposes and shared with the following third parties within the framework of data protection regulations: ",
            gdprLinkTitle: "link",
            gdprLinkUrl: "https://joineer.com/wp-content/uploads/2024/03/Auftrags_Datenverarbeitung-nach-DSGVO.pdf",
            gdpr09: "Thank you for your consent.",
            gdpr10: "You can access your personal data at any time via the link to the Teambarometer. If you have any questions, please feel free to contact us at info{'@'}joineer.ch",
            gdpr12: 'Joineer and the "Culture of Tomorrow" team',
        }, {});
    }

    function txGroup() {

        return assignToObjectKeys(TX_GROUP_COMPANY_IDS, {
            //gdpr01: "",
            gdpr02: "Thank you for using Joineer.\n\nWe hereby want to transparently inform you about the privacy policy and anonymity. We collect the following information through the survey:\n\na) Your name\nb) Your business email address\nc) Anonymous quantitative assessments in the form of scale values from 1-10\nd) Qualitative comments in the text fields\n\nYour team's results will be evaluated and displayed if at least three team members participated in the survey. In this case, your comments are visible personally or anonymized (depending on your setting) for your team members and for your direct supervisor. Your anonymized comments will be seen by your department heads and by your member of the senior management.\n\nAnonymized data may be shared with a partner company for data analysis. Once the analysis is complete, the data is deleted from the partner company.\n\nAll data is stored on servers in Switzerland. You can access your personal data at any time via the link to this app. If you have any questions, please feel free to contact us: info{'@'}joineer.ch.",
            gdpr03: "",
            gdpr04: "",
            gdpr05: "",
            gdpr06: "",
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "",
            gdprLinkUrl: "",
            gdprLinkTitle: "",
            gdprIgiveMyConsent: "I understand and give my consent",
            gdpr09: "",
            gdpr10: "",
            gdpr11: "",
            gdpr12: "Thank you for your trust!\n\nBest regards\nJoineer",
        }, {});
    }
}

function customisations() {
    return {

        // Brugg Lifting
        "64983brl304366969": {

            gdpr01: "Declaration of consent ",
            gdpr02: "Thank you for using Joineer. \n" +
                "\n" +
                "We would like to inform you that we collect the following personal information: your name and email address. In case of login via mobile phone or 2-Factor-Authentication also your mobile number. \n" +
                "\n" +
                "If your company would like to evaluate the results according to socio-demographic characteristics (e.g. age, function group), this data will also be collected.\n" +
                "\n" +
                "You can view your personal information at any time via the link to the app.\n" +
                "\n" +
                "In addition, we collect the following result information:",
            gdpr03: "• Anonymous quantitative responses (values)",

            gdpr04: "• Optional public or anonymous qualitative comments (feedforwards)",
            gdpr04pub: "• Optional qualitative feedforward.",

            // not shown with "anonflag" setting:
            gdpr05: "For your company, the results of a team will be analysed and displayed if at least the following number of people of a team have participated in the survey: 3. In this case, your results information will be seen by your team members, the division administrators and the company administrators.",

            gdpr06: "All the data will be stored on servers in Switzerland and not be given to third parties.",

            // not shown with "anonflag" setting:
            gdpr07: "",

            gdpr08: "We kindly ask you to take notice and agree to these terms.",
            gdprLinkMessage: "", //For more information click {link}
            gdprLinkTitle: "", //here
            gdprLinkUrl: "", //https://www.app.com
            gdpr09: "If you have any questions, please feel free to contact us: info@joineer.ch",
            gdpr10: "Thank you for your trust!",
            gdpr11: "Best regards",
            gdpr12: "Joineer AG",
            gdprIgiveMyConsent: "I give my consent",

        },


        // TX Tamedia
        "23213tam223139668": {
            //gdpr01: "",
            gdpr02: "Thank you for using Joineer.\n\nWe hereby want to transparently inform you about the privacy policy and anonymity. We collect the following information through the survey:\n\na) Your name\nb) Your business email address\nc) Anonymous quantitative assessments in the form of scale values from 1-10\nd) Qualitative comments in the text fields\n\nYour team's results will be evaluated and displayed if at least five team members participated in the survey. In this case, your comments are visible personally or anonymized (depending on your setting) for your team members and for your direct supervisor. Your anonymized comments will be seen by your department heads and by your member of the senior management.\n\nAnonymized data may be shared with a partner company for data analysis. Once the analysis is complete, the data is deleted from the partner company.\n\nAll data is stored on servers in Switzerland. You can access your personal data at any time via the link to this app. If you have any questions, please feel free to contact us: info{'@'}joineer.ch.",
            gdpr03: "",
            gdpr04: "",
            gdpr05: "",
            gdpr06: "",
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "",
            gdprLinkUrl: "",
            gdprLinkTitle: "",
            gdprIgiveMyConsent: "I understand and give my consent",
            gdpr09: "",
            gdpr10: "",
            gdpr11: "",
            gdpr12: "Thank you for your trust!\n\nBest regards\nJoineer",
        },

        // AWA Nullmessung: special texts:
        "19681ANU402980805": {
            gdpr05: "",
            startGoMessage:
                "Share what can be done better in the future - positive and solution-oriented with Feedforward.",
        },

        //Rittmeyer nullmessung special texts:
        "34824rin660736891": {
            gdpr01: "Declaration of consent",
            gdpr02: "We would like to inform you that we collect the following personal information: your name and e-mail address. In case of login via mobile phone or 2-Factor-Authentication also your mobile number. If your company would like to evaluate the results according to socio-demographic characteristics (e.g. age, function group), this data will also be collected. In addition, we collect the following result information:",
            gdpr03: "• Anonymous quantitative responses (values)",
            gdpr04: "• Optional public or anonymous qualitative comments (Feedforwards)",
            gdpr05: "For your company, the results of a team are evaluated and displayed if at least four people of a team have participated in the survey. The result information is seen by the company administrators.",
            gdpr07: "You can view your personal information at any time via the link to the app.",
            gdpr08: "",
            gdpr10: "Thank you for your trust!",
            gdpr11: "Best regards,",
            gdpr12: "Joineer AG",
        },

        // Huba special texts:
        "40627hub451626967": {
            gdpr05: "The given answers will be seen by your company administrator.",
        },

        // JOB AG special texts
        "44155job679947446": {
            gdpr02: "Thank you for taking part in the JOB AG employee survey.",
            gdpr03: "Participation is voluntary and in accordance with data protection laws.The processing of your personal data for the purpose of conducting the employee survey is based on your consent.",
            gdpr04: "",
            gdpr04pub: "",
            gdpr06: "This can be revoked at any time.",
            gdprLinkMessage:
                "Alle Informationen zur Teilnahme sowie die Datenschutzhinweise findest Du im ",
            gdprLinkTitle: "JOB MAX",
            gdprLinkUrl:
                "https://job-ag.staffbase.com/content/page/61f3f2c225f22906a5b06a17",
            gdpr09: "",
            gdpr11: "JOB AG",
            gdpr12: "",
            gdprIgiveMyConsent: "I hereby consent!",
        },

        // POST AG special texts
        "55287qpo363914313": {
            introTitle1: "Welcome to culture check",
            introText1: "We are very happy about your participation.",
            introTitle2: "Your opinion counts",
            introText2:
                "The survey takes a few minutes and is collected and evaluated in a personalised manner.",
            introTitle3: "What is this culture check about?",
            introText3:
                "The primary purpose of this culture check is to gather information on how you perceive the culture personally, in the team and in your own area as a whole at present time. The collection of this information will help your team to create a common understanding of the current culture in order to subsequently define measures for the implementation of culture and transformation development.",
            introTitle4: "Be open",
            introText4:
                'Please answer these questions spontaneously and honestly, there is no "right" or "wrong". In addition to a quantitative assessment, you will also be asked for a suggestion for improvement in the future. It helps if you give these suggestions publicly (not anonymously).',
        },

        // Zuger Kantonalbank special texts:
        "56719zkb691551221": {
            startGoMessage:
                "Share what can be done better in the future - positive and solution-oriented with Feedforward.",
        },

        // RUAG special texts:
        "651545c98fbb90562f4c065c": {
            gdpr01: "Declaration of consent",
            gdpr02: "Thank you for using Joineer.\nWe would like to inform you that we collect the following personal information: your name and email address.\nYou can view your personal information at any time by clicking on the link to the app.\n\nIn addition, we collect the following results information:",
            gdpr03: "• Anonymous quantitative responses (values)",
            gdpr04: "",
            gdpr05: "The results are seen by the department and company administrators.\nFor your company, results will be shown at the team level if at least the following number of people from a team participated in the survey: 5.",
            gdpr06: "All data is stored on servers in Switzerland and will not be shared with third parties. For more information see Joineer Privacy Policy (https://joineer.com/datenschutz/).",
            gdpr07: "",
            gdprIgiveMyConsent: "I give my consent",
            gdpr08: "",
            gdpr09: "If you have any questions, please feel free to contact us: info{'@'}joineer.ch.",
            gdpr10: "Thank you for your trust!",
            gdpr11: "Best regards",
            gdpr12: "Joineer AG",
            introTitle1: "Welcome to the RUAG Employee Barometer",
            introTitle2: "We are here for you",
            introText1:
                "We are very happy about your participation - because your opinion counts!",
            introText2:
                "Feel free to contact us with any questions at info{'@'}joineer.ch. Here we go!",
            startGoMessage: "",
            surveyEndMessage: "Click 'Submit now'. If you want to change something, you can use the 'Back' button to go back to the questions.",
        },

        //Swissinfo special texts:
        "22801SWI182608558": {
            gdpr01: "Declaration of consent",
            gdpr02: "Thank you for using Joineer.\nWe would like to inform you that we collect the following personal information: your name and email address. In case of login via mobile phone or 2-Factor-Authentication also your mobile number.\nIf your company would like to evaluate the results according to socio-demographic characteristics (e.g. age, function group), this data will also be collected.\nYou can view your personal information at any time via the link to the app. \n\nIn addition, we collect the following result information:",
            gdpr03: "• Anonymous quantitative responses (values)",
            gdpr04: "• Personal qualitative comments (Feedforwards)",
            gdpr04pub: "• Personal qualitative comments (Feedforwards)",
            gdpr05: "For your company, the results of a team will be analysed and displayed if at least the following number of people of a team have participated in the survey: 1. In this case, your results information will be seen by your team members, the division administrators and the company administrators.",
            gdpr06: "All data will be stored on servers in Switzerland and will not be shared with third parties.",
            gdpr07: "",
            gdprIgiveMyConsent: "I give my consent",
            gdpr08: "",
            gdpr09: "If you have any questions, please feel free to contact us: info{'@'}joineer.ch",
            gdpr10: "Thank you for your trust!",
            gdpr11: "Best regards",
            gdpr12: "Joineer AG"
        },

        //Swica special texts:
        "69493swi532328968": {
            gdpr05: "The given answers will be seen by your company administrator.",
        },

        // DIS special texts:
        // "80568dis750356676": {
        "70129dis673402979": {
            introTitle1: "Welcome to Teambarometer",
            introText1:
                "A trustworthy and direct feedback discussion helps us and each individual to develop further. Such conversations are very important to us. We want to offer another possibility for feedback several times a year with Teambarometer from Joineer pulse surveys",
            introTitle3: "Shape the future with us!",
            introText3:
                "We, the management of the Digital Infrastructure Services business unit, want to understand what makes you tick and what can we do better. This survey is one way to let us know what you think. Use it and shape the future together with us!",
        },

        // Franke test company texts
        "77868reg649392509": {
            introTitle1: "Welcome to the “One Franke” Teambarometer",
            introTitle2: "",
            introTitle3: "Your opinion counts - and it counts constantly",
            introTitle4: "Discuss and Benefit",
            introTitle5: "We win together",
            introText1:
                "Our shared goals, teamwork, and the commitment of each individual employee are of central importance for the “One Franke”-Journey.\n\nThank you for participating.",
            introText2:
                "“Our goal is to continuously improve our performance and our shared culture. Each and every one of us can make an active contribution towards this. I am convinced that the Teambarometer has a huge positive impact on our journey. Communicate your ideas, discuss them in the team, take action where possible and delegate the topics you can’t solve. This continuous improvement process gives us the needed structure and leads to ongoing development. I do it with my team and I am suggesting that you do it with yours as well.”\n\nYours, Parik Wohlhauser",
            introText3:
                "We want to get better. And that works only with your contribution. That is why we want to have your opinion about how things are going and your ideas about how we can improve. Please focus on FeedFORWARD. What is the best possible future? Share your ideas about how we can improve, solution-orientied and positive.",
            introText4:
                "Most important step in the process is that you discuss your ideas in the team and transfer them to actions. Small steps forward. With one action per session per team, we already create a huge impact. Use the discussion mode for a structured meeting and keep track of your development.",
            introText5:
                "“By collaborating and supporting each other across the globe”\n\n Are you ready? If you have any questions feel free to contact the guys from Joineer at info{'@'}joineer.ch or our culture lead at culture{'@'}franke.com.\n\nLet’s go!",
        },

        //Fatzer special texts:
        "85916fat786585735": {
            gdpr01: "Declaration of consent",
            gdpr02: "Thank you for using Joineer.\nWe would like to inform you that we collect the following personal information: your name and email address. In case of login via mobile phone or 2-Factor-Authentication also your mobile number.\n\nIf your company would like to evaluate the results according to socio-demographic characteristics (e.g. age, function group), this data will also be collected. In addition, we collect the following result information:",
            gdpr03: "• Anonymous quantitative responses (values)",
            gdpr04: "• Optional public or anonymous qualitative comments (Feedforwards)",
            gdpr05: "For your company, the results of a team will be analysed and displayed if at least the following number of people of a team have participated in the survey: 3. In this case, your results information will be seen by your team members, the division administrators and the company administrators.",
            gdpr06: "All data will be stored on servers in Switzerland and will not be shared with third parties.",
            gdpr07: "You can view your personal information at any time via the link to the app.",
            gdprIgiveMyConsent: "I give my consent",
            gdpr10: "Thank you for your trust!",
            gdpr11: "Best regards",
            gdpr12: "Joineer AG"
        },

        // KuZu special texts:
        "91358txk194486012": {
            gdpr03: "• Anonymous quantitative answers to the questions of the management survey CS.",
            gdpr05: "The answers given will be made available to managers of CS and company administrators.",
            gdpr07: "",

            startGoMessage:
                "Share what can be done better in the future - positive and solution-oriented with Feedforward.",
        },

        //Post Kommunikations services special texts
        "95462pks109762813": {

          introTitle1: "Welcome to the Swiss Post team barometer.",
          introText1:
              "We look forward to your input. The survey will take a few minutes, and answers will  not be anonymous. This means that your answers will be visible to others. On the next page, you'll learn how you can answer the questions in the team barometer. ",
          introTitle2: "What this survey is about",
          introText2:
              'The main purpose of the team barometer is to collect information on how you perceive the culture on a personal level, in your team and overall in your work environment  at the present time. Collecting this information will help your team gain a common understanding of the current culture and derive implementation measures for cultural and transformational development.\n\nPlease answer these questions spontaneously and honestly – there are no right or wrong answers. Besides a quantitative evaluation, you will also be asked to provide a suggestion for improvement for the future. Please take the time you need to do this. These are highly important to our team because they help us better understand your assessments and derive effective measures.\n\nNow, click on "Let\'s do this"  to start the team barometer! If you experience technical problems while completing the survey, please use the chat function in the team barometer.',
          gdpr02: "Thank you for using the Teambarometer. It is used by Swiss Post in collaboration with the company Joineer. To comply with data protection regulations, we would like to inform you that next to your name and e-mail address we collect the following information:",
          gdpr03: "• Anonymous quantitative values for the Teambarometer questions.",
          gdpr04: "• Optional anonymous or personal qualitative comments / feedforwards.",
          gdpr04pub: "• Optional anonymous or personal qualitative comments / feedforwards.",
          gdpr05: "The results of a team are displayed if at least 3 people of a team have participated in the survey. The answers can be viewed by your team members, the team and company administrator.",
          gdpr06: "Please do not share sensitive data or confidential information through Joineer. By giving your consent, you confirm that you take this into account.",
          gdpr07: "",
          gdprLinkMessage: "All data will be used for specific purposes and shared with the following third parties within the framework of data protection regulations: ",
          gdprLinkTitle: "link",
          gdprLinkUrl: "https://joineer.com/wp-content/uploads/2024/03/Auftrags_Datenverarbeitung-nach-DSGVO.pdf",
          gdpr09: "Thank you for your consent.",
          gdpr10: "You can access your personal data at any time via the link to the Teambarometer. If you have any questions, please feel free to contact us at info{'@'}joineer.ch",
          gdpr12: 'Joineer and the "Culture of Tomorrow" team'
        },

        // Post Tutorial Oriana
        "14055for495089302": {
            gdpr01: "Declaration of consent",
            gdpr02: "Thank you for using Joineer. \n" +
                "We would like to inform you that we collect the following personal information: your name and email address. In case of login via mobile phone or 2-Factor-Authentication also your mobile number. \n" +
                "If your company would like to evaluate the results according to socio-demographic characteristics (e.g. age, function group), this data will also be collected.\n" +
                "You can view your personal information at any time via the link to the app.\n\nIn addition, we collect the following result information:",
            gdpr03: "• Anonymous quantitative responses (values)",
            gdpr04pub: "• Public qualitative comments (feedforwards)",

            // not shown with "anonflag" setting:
            gdpr05: "For your company, the results of a team will be analysed and displayed if at least the following number of people of a team have participated in the survey: 1. In this case, your results information will be seen by your team members, the division administrators and the company administrators.\n",

            gdpr06: "All the data will be stored on servers in Switzerland and not be given to third parties.",

            // not shown with "anonflag" setting:
            gdpr07: "",

            gdpr08: "",
            gdprLinkMessage: "", //For more information click {link}
            gdprLinkTitle: "", //here
            gdprLinkUrl: "", //https://www.app.com
            gdpr09: "If you have any questions, please feel free to contact us (info{'@'}joineer.ch).",
            gdpr10: "Thank you for your confidence.",
            gdpr11: "Sincerely yours,",
            gdpr12: "Joineer",
            gdprIgiveMyConsent: "I give my consent",
        },

        //BVGer services special texts
        "52780bvg479791730": {
            gdpr01: "Declaration of consent",
            gdpr02: "Thank you for using Joineer.\nWe would like to inform you that we collect the following personal information: your name and email address. In case of login via mobile phone or 2-Factor-Authentication also your mobile number.\nIf your company would like to evaluate the results according to socio-demographic characteristics (e.g. age, function group), this data will also be collected.\nYou can view your personal information at any time via the link to the app.\n\nIn addition, we collect the following result information:",
            gdpr03: "• Anonymous quantitative responses (values)",
            gdpr04: "• Optional public or anonymous qualitative comments (feedforwards)",
            // not shown with "anonflag" setting:
            gdpr05: "For your company, the results of a team will be analysed and displayed if at least the following number of people of a team have participated in the survey: 3. In this case, your results information will be seen by your team members, the division administrators and the company administrators.",
            gdpr06: "All data will be stored on servers in Switzerland and will not be shared with third parties.",
            // not shown with "anonflag" setting:
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "", //For more information click {link}
            gdprLinkTitle: "", //here
            gdprLinkUrl: "", //https://www.app.com
            gdpr09: "If you have any questions, please feel free to contact us: info{'@'}joineer.ch",
            gdpr10: "Thank you for your trust!",
            gdpr11: "Best regards",
            gdpr12: "Joineer AG",
            gdprIgiveMyConsent: "I give my consent",
        },

        //Tamedia pulse special texts
        "677d1ecb16c428775054f9de": {
            gdpr01: "Declaration of consent",
            gdpr02: "Thank you for taking part in the Tamedia Pulse Check.\nWe would like to inform you that we collect the following personal information: your name and email address. In case of login via mobile phone or 2-Factor-Authentication also your mobile number.\nIf your company would like to evaluate the results according to socio-demographic characteristics (e.g. work region, function group), this data will also be collected.\nYou can view your personal information at any time via the link to the app.\n\nIn addition, we collect the following result information:",
            gdpr03: "• Anonymous quantitative responses (values)",
            gdpr04: "• Anonymous qualitative comments (feedforwards)",
            // not shown with "anonflag" setting:
            gdpr05: "For your company, the results of a department will be analysed and displayed if at least four persons have participated in the survey. In this case, your results information will be seen by your team members, the division administrators and the company administrators.",
            gdpr06: "All data will be stored on servers in Switzerland and will not be shared with third parties.",
            // not shown with "anonflag" setting:
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "", //For more information click {link}
            gdprLinkTitle: "", //here
            gdprLinkUrl: "", //https://www.app.com
            gdpr09: "If you have any questions, please feel free to contact mission.x{'@'}tx.group.",
            gdpr10: "Thank you for your trust!",
            gdpr11: "Best regards",
            gdpr12: "Tamedia & Joineer AG",
            gdprIgiveMyConsent: "I give my consent",
            introTitle1: "Welcome to the Tamedia Pulse Check",
            introText2:
                "With the Tamedia Pulse Check you will be asked for your opinion every month." +
                " This way the mood is measured over time and the improvements can be compared accordingly.",
        },
    };
}
