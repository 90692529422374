<template>
    <v-card
        flat
        class="mt-0 yellow-gradient"
        :class="{ topBorder: mode === 'ffTask' }"
    >
        <v-divider v-if="mode === 'ffTask'"></v-divider>
        <v-card-title class="text-h6 pb-0 d-flex align-center">
            <v-icon color="yellow-darken-1" start class="me-2">mdi-clipboard-plus-outline</v-icon>
            {{ $t("tasks.createTitle") }}
            <v-spacer></v-spacer>
            <span class="text-caption" v-if="mode === 'ffTask'">
                <a @click="dialog = true" class="text-primary cursor-pointer">
                  {{ $t("discussionMode.unable") }}
                </a>
              </span>
        </v-card-title>
        <DiscussionEscalate
            v-if="mode === 'ffTask'"
            :discussionId="discussionId"
            :feedforwardItem="feedforwardItem"
            :surveyQuestion="surveyQuestion"
            :dialog="dialog"
            @dialogStatus="dialog = $event"
            @escalated="closePanel"
        />

        <v-container class="pt-0">
            <v-form v-model="isFormValid" ref="taskForm">
                <v-text-field
                    v-model="summary"
                    variant="underlined"
                    :label="`${$t('tasks.titlePlaceholder')}*`"
                    class="mb-1"
                    :rules="[rules.required]"
                    prepend-icon="mdi-format-title"
                ></v-text-field>

                <!-- addComments -->
                <v-card id="commentbox" border flat>
                    <v-textarea
                        v-model="comment"
                        class="text-body-2"
                        variant="solo"
                        no-resize
                        flat
                        rows="2"
                        name="comment"
                        :placeholder="`${$t('tasks.commentPlaceholder')}...`"
                    ></v-textarea>
                </v-card>
                <!-- /addComment -->

                <v-row justify="space-between" no-gutters class="mt-3">
                    <!-- date picker -->
                    <v-col cols="12" sm="4">
                        <v-menu
                            v-model="pickermenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template #activator="{ props }">
                                <!-- TODO: VUE 3: END density-->
                                <v-text-field
                                    clearable
                                    @click:clear="isDateChosen = false"
                                    density="compact"
                                    :label="pickerLabel"
                                    :model-value="renderDate()"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="props"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                hide-header
                                v-model="pickerdate"
                                @update:model-value="pickermenu = false"
                                :min="today"
                                :locale="$i18n.locale"
                                :first-day-of-week="1"
                                show-week
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <!-- /date picker -->

                    <!-- person picker -->
                    <v-col cols="12" sm="7">
                        <!-- TODO: VUE 3: END density-->
                        <v-select
                            clearable
                            density="compact"
                            :items="assignees"
                            item-value="id"
                            item-title="displayName"
                            :label="`${$t('tasks.assign')}:`"
                            prepend-icon="mdi-account-circle"
                            :no-data-text="$t('tasks.noMembersAvailable')"
                            v-model="assignedTo"
                            :rules="assigneeRequired ? [v => !!v || `${$t('tasks.assigneeRequiredMessage')}`] : []"
                        ></v-select>
                    </v-col>
                    <!-- /person picker -->
                </v-row>
            </v-form>
            <v-container>
                <v-row class="mt-3">
                    <v-btn @click="closePanel" class="text-white" color="grey-darken-1">{{
                        $t("buttons.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        @click="saveTask"
                        :disabled="loading"
                        class="yellow-darken-1 text-white createTaskBtn"
                        color="yellow-darken-1"
                        >{{ $t("buttons.save") }}</v-btn
                    >
                </v-row>
            </v-container>
        </v-container>
        <v-alert v-if="errorMessage" type="error" variant="outlined">{{
            errorMessage
        }}</v-alert>
    </v-card>
</template>

<script>
import { createTask } from "../api/tasks.api";
import { taskFeedForward } from "../api/feedforward.api";
import DiscussionEscalate from "@/components/DiscussionEscalate";
import globalFunctions from "@/utils/globalFunctions";
import moment from "moment";
import { mapStores } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";
export default {
    props: {
        feedforwardItem: {},
        surveyQuestion: {},
        discussionId: {},
        questionId: {},
        mode: {},
        assignees: {
            type: Array,
            required: true
        },
        assigneeRequired: {
            type: Boolean,
            default: false
        },
    },
    inject: ["reloadTasks"],
    components: {
        DiscussionEscalate
    },
    // TODO: V3-end
    data: () => ({
        pickerdate: new Date(),
        today: new Date(), //for disabling past dates in date picker
        isDateChosen: false,
        pickermenu: false,
        isFormValid: false,
        summary: "",
        assignedTo: null,
        comment: null,
        dialog: false,
        errorMessage: null,
        loading: false
    }),
    computed: {
        ...mapStores( useSnackbarStore ),

        pickerLabel() {
            return this.isDateChosen
                ? `${this.$t("tasks.dueDate")}:`
                : `${this.$t("tasks.setDueDate")}:`;
        },
        // rules for mandatory fields
        rules() {
            return {
                required: value => !!value || this.$t("tooltips.required")
            };
        }
    },
    watch: {
        pickerdate() {
            this.isDateChosen = true;
        }
    },

    methods: {
        renderDate() {
            return this.isDateChosen
                ? moment(this.pickerdate).format("DD/MM/YYYY")
                : "";
        },
        closePanel() {
            this.$emit("panelClosed", false);
        },
        saveTask() {
            this.$refs.taskForm.validate()

            if(!this.isFormValid){
                return
            } else {
                this.loading = true;
                this.errorMessage = null;
                //gather data from origin FF and user-entered details
                let task = {
                    summary: this.summary,
                    assignedTo: { id: this.assignedTo },
                    // omit FF if task is not created from a FF
                    feedForwardId: this.feedforwardItem ? this.feedforwardItem.id : null,
                    discussionId: this.discussionId,
                    questionId: this.questionId ? this.questionId : null
                };
                this.isDateChosen
                    ? (task.dueDate = new Date(this.pickerdate))
                    : (task.dueDate = null);
                this.comment ? (task.comment = this.comment) : "";
                
                createTask(task).then(response => {
                    task = response.data;
                    this.$emit("FFTasked", "TASKED");
                    // mark FF tasked if task is created from a FF
                    if (this.feedforwardItem) {
                        taskFeedForward(this.feedforwardItem.id);
                    }
                    this.reloadTasks();
                    this.$emit("panelClosed", false); //close panel in parent OpenFeedForward.vue
                    this.snackbarStore.showSnackbar([true, "taskCreated"]); //notification for user
                    this.resetFields();
                    globalFunctions.piwikAnalytics(['trackEvent','Task actions', 'Created']);
                })
                .catch(error => {
                    this.errorMessage = globalFunctions.parseError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
            }
        },

        resetFields() {
            this.comment = "";
            this.summary = "";
            this.assignedTo = "";
        }
    }
};
</script>

<style scoped>
.topBorder {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

#commentbox.v-card {
    border-radius: 4px !important;
}
</style>
