import { TX_GROUP_COMPANY_IDS } from "../customisations/constants";
import { assignToObjectKeys, simpleDeepMerge } from "../utils/object";

export default Object.assign(commonTranslations(), simpleDeepMerge(commonCustomisations(), customisations()));

function commonTranslations() {
    return {
        addLinks:"Add links?",
        whatYouwrite: "What you write",
        whatIsShown:"What is shown",
        anonymValues:"Values are always recorded anonymously.",
        pauseSurvey: "Pause survey",
        surveyPaused: "Survey paused",
        events: {
            start: {
                title: "Survey starts",
                description: "E-mails/SMS with the invitation to participate is sent out to all",
            },
            reminder: {
                title: "Reminder",
                description: "E-mail/SMS reminding the participants who have not filled out yet",
            },
            end: {
                title: "Survey ends",
                description: "Last day of fillout, after which the results are available."
            },
            today: "Today",
        },
        nextDay: "on the next working day",
        changeTeam: "Change team",
        pickNewTeam: "Pick a new {0} team from the organisation structure below.",
        currentTeam: "Current team",
        activeSurveyMoveWarning:
            "Moving a member during an active survey deletes their current answers. After the move, it will be necessary to refill the survey for the new team.",

        startDateNextSurvey: "Start date of the next survey",
        interval: {
            label: "Survey interval",
            text: "How often do you want to have the survey?",
            everyw: "Every week",
            every2w: "Every 2 weeks",
            every3w: "Every 3 weeks",
            monthly: "Every month",
            every6w: "Every 6 weeks",
            every2m: "Every 2 months",
            every3m: "Every 3 months",
            every4m: "Every 4 months",
        },
        rolesTitle: "Roles",
        roleTitle: "Role",
        adminOfTeams: "Admin of",
        addCompanyAdmin: "Add a company admin",
        tags: "Tags",
        masterfile: "Company master file",
        masterfileText: "Helpful tips and validation tool for the masterfile: ",
        processdatafile: "Dados de processo",
        processdatafileDescrption: "Aqui você encontrará dados de processos interessantes para sua organização. Você também pode acompanhar o desenvolvimento dos dados do processo em diversas pesquisas.",
        processDataMoreInfo: "Mais info",
        processDataInfo: {
            title: {
                survey: "Survey",
                surveyEndDate: "Survey End Date",
                avgQuestionPerTeam: "Average Questions per Team",
                participants: "Participants",
                respondents: "Respondents",
                participationRate: "Participation Rate (%)",
                totalFF: "Total FFs",
                transparencyRate: "Transparency Rate (%)",
                FFPerRespondents: "FFs per Respondent per Question",
                handlingRate: "FF Handling Rate (%)",
                tasksCreated: "Tasks Created",
                closingRate: "Closing Rate (%)",
                delegationRate: "Delegation Rate (%)",
            },
            desc: {
                survey: "Increasing sequence.",
                surveyEndDate: "The date on which each survey was ended.",
                avgQuestionPerTeam: "The average number of questions presented to each team in the survey.",
                participants: "The total number of individuals invited to participate in the survey.",
                respondents: "The number of participants who actually responded to the survey.",
                participationRate: "The percentage of invited participants who completed the survey.",
                totalFF: "The total count of Feedforwards (FFs) received in the survey.",
                transparencyRate: "The percentage of FFs that were non-anonymous compared to the total number of FFs.",
                FFPerRespondents: "The average number of FFs given by each respondent for each question.",
                handlingRate: "The percentage of FFs that were either discussed or where a task has been created.",
                tasksCreated: "The total number of tasks created, including those not directly derived from FFs.",
                closingRate: "The percentage of tasks that were marked 'done', in relation to the total tasks created.",
                delegationRate: "The percentage of delegated tasks, in relation to the total tasks created.",
            },
        },

        myProfile: "My profile",
        teamDetails: "Team details",
        selectUser: "Select user",
        selectUserQR: "Select a user to download their QR code as a PDF",
        pleaseEnterOneFollowing: "Please enter at least one of the following:",
        errorCreatingUser: "Error creating user",
        userTeamMoveWarning:
            "NB: This user is currently in another team. Adding {0} to {1} removes them from {2}.",
        userTeamMoveConfirm: "To confirm the move, click 'Save' again.",
        selectExistingUser: "Select an existing user",
        addNew: "Create new",
        addExisting: "Add existing",
        createMember: "Create a new member",
        createAdmin: "Create a new admin",
        dialCode: "Dial code",
        loadingProfile: "Loading profile",
        firstName: "First name",
        lastName: "Last name",
        updateName: "Update name",
        updateEmail: "Update email address",
        updateMobile: "Update mobile phone",
        errorMobile: "Error updating mobile number",
        errorEmail: "Error updating e-mail address",
        enterMobileText: "Mobile should be entered with a dialcode eg 4178322222",
        updateLanguage: "Update language",
        deleteProfileConfirmation:
            "Are you sure you wish to delete this profile from the organisation?",
        profileDeleted: "This profile is now deleted",
        directingToPrevious: "Directing to previous page",
        deleteProfile: "Delete profile",
        noDeleteEmailError:
            "Can't delete e-mail since at least one contact method needs to be registered. Please add a mobile number to the profile in order to delete the e-mail address.",
        noDeleteMobileError:
            "Can't delete mobile number since at least one contact method needs to be registered. Please add an e-mail to the profile in order to delete the mobile number.",

        // OLD
        entitiesAdministratedBy: "Teams and structures administrated by {0}",
        someGuides: "Here are some guides for you!",
        "20people": "+20 people",
        addEntityToCompany: "Add to company",
        detailsPage: "Details page",
        createEntityText1:
            "In order to create a new {0} structure or a {1} team, click under where it should go.",
        createEntityLvl0Text1:
            "In order to create a new {0} team, click on the {1} Company.",
        createEntityText2:
            "Once created, you can go to their Details page to add admins/members or continue creating other teams/structures.",
        createEntityQuestion: "What would you like to create?",
        nameofNewTeam: "Name of the new team",
        nameofNewStructure: "Name of the new structure",
        mandatoryLangTooltip:
            "Mandatory question language is set by the organization",
        noActiveSurveys: "No active surveys at the moment",
        teamAdmins: "Team admins",
        structureAdmins: "Structure admins",
        teamMove: "Move team",
        structureMove: "Move structure",
        moveText:
            "Click on the structure under which {0} should be. Unavailable options are grayed out.",
        structureLabel: "Structure",
        moveHere: "Move here",
        currentLocation: "Current location",
        teamRename: "Rename team",
        teamDelete: "Delete team",
        structureRename: "Rename structure",
        structureDelete: "Delete structure",
        deleteEntityConfirmation: "Are you sure you wish to delete {0}?",
        structureDeleteInfo:
            "NB: Teams and structures under {0} will also be deleted. Members from deleted teams will not participate in pulse surveys and will be listed on the 'Members' page without an allocated team.",
        teamDeleteInfo:
            "NB: After deletion members from this team will be listed on the 'Members' page without an allocated team. Teamless members do not participate in pulse surveys.",
        newName: "New name",
        cantMoveTopStructure: "Can't move a top level structure",
        memberRemove: "Remove member",
        adminRemove: "Remove admin",
        removeInfo:
            "PS: Once removed, teamless members can be found and reassigned on the 'Members' page.",
        adminRemoveConfirmation:
            "Are you sure you wish to remove {0} as an admin from {1}?",
        memberRemoveConfirmation:
            "Are you sure you wish to remove {0} as a member from {1}?",
        tfaCountry: "País",
        tfaInvalid: "Número inválido",
        tfaRemove0: "Por favor remova 0",
        tfaRequired: "O número é necessário",
        labelMobilePhone: "Telemóvel",
        twoFactorTitle: "Two-Factor Authentication",
        twoFactorIntro:
            "In order to see the results once the survey is finished, we ask you activate two-factor authentification. Please enter your mobile phone number where authentication codes could be sent in the future. If you do not do that you can not access the results.",
        twoFactorNoNr:
            "I do not give my number and will not have access to the results.",
        twoFactorNoNrExplanation:
            "Without providing your number you will not be able to access the results dashboard after the survey. Your number will be used only for authentication purposes. If you choose not to provide your number now you can provide it to your supervisor later to enable two-factor authentication for yourself.",
        twoFactorFirst:
            "This information can be viewed after entering a code sent to your mobile phone.",
        noTeamsFoundWith: "No team names found with {0}",
        removeFromTeam: "Remove member",
        removeAsAdmin: "Remove admin",
        noAdmins: "No admins",
        noMembers: "No members",
        seeDetails: "See details",
        loggingOut: "Sair",
        calculate: "Calcular",
        calculateENPSavgTitle: "Calcular a média ENPS",
        calculateENPSavgLabel: "Média ENPS de:",
        calculateENPSavgSelect: "Seleccionar entidades para calcular a média ENPS:",
        pwProtectedMessage:
            "A conta está protegida por palavra-passe. Por favor, inicie sessão com a sua palavra-passe.",
        passwordError: "Error setting password",
        passwordLengthError: "A senha tem de ter pelo menos 7 caracteres",
        passwordMismatch: "As palavras-passe não correspondem.",
        passwordChangeSuccess: "Palavra-passe alterada com sucesso. Entrar {0}.",
        labelNewPassword: "Nova palavra-passe",
        labelRepeatNewPassword: "Repete a nova palavra-passe",
        setNewPassword:
            "Por favor, define uma nova palavra-passe de pelo menos 7 caracteres.",
        getNewPwLink: "Obtenha {0} uma nova ligação para redefinir a palavra-passe",
        resetPw: "Redefinir Palavra-passe",
        resetPwTokenInvalid: "Ligação de redefinição inválida da palavra-passe",
        forgotPwTitle: "Esqueceste-te da palavra-passe?",
        forgotPwText:
            "Enviar-te-emos um e-mail com um link onde poderás redefinir a tua palavra-passe.",
        fillingOutForTeam: "You're filling out for {0}.",
        orContinueWith: "continue with",
        level: "Level",
        showTodo: "Show my To-Do list",
        hideTodo: "Hide my To-Do list",
        statistics: "Statistics",
        commentTooLong: "Comprimento máximo dos comentários é de 280 caracteres",
        allDiscussedNr: "Super, all {0} are discussed!",
        allDiscussed: "Super, all Feedforwards were discussed!",
        notEnoughAnswers: "This time not enough members filled out the survey",
        notEnoughAnswersTeamName:
            "This time not enough members in team {0} filled out the survey",
        startPrep: "Benefit from the team inputs and start preparing",
        expandQuestionComments: "Expand the question to see comments",
        pickValidTeam: "Pick a team that participated in the survey",
        devGeneralExplanation:
            "This is the standard deviation of all ratings in the survey. Smaller number means team members have similar opinions. Higher number means that the team should probably discuss a few topics where opinions differed.",
        avgGeneralExplanation:
            "This is the average for all questions in the survey. Smaller average means there's more potential for improvement.",
        signInWith: "Entre com ",
        whatsNextTitle: "What's next?",
        whatsNextText: "Uplift {0}, one tick box at a time",
        viewDiscussion: "View discussion",
        startDiscussion: "Start discussion",
        manageSurvey: "Manage survey",
        discussionFor: "Discussion for",
        helpTitle: "Tips & tricks",
        helpText: "Help for discussing the results can be found here",
        finishSurvey: "Finish survey",
        reviewResults: "Prepare discussion",
        reviewResultsHint: "Check out and sort the questions",
        scheduleMeeting: "Schedule a team meeting",
        scheduleMeetingHint:
            "Organize an approx. 45 min team meeting outside this app for the discussion",
        doDiscussion: "Discuss the results in team",
        doDiscussionHint:
            "Click 'Start Discussion' at the bottom of the page. Discuss Feedforwards as a team and create tasks when necessary",
        resultText1:
            "Here you can review questions and change their discussion order.",
        resultText2:
            "To reorder questions by their importance, click and drag the {0} icon.",
        handBuchText: "Preparation guide",
        thisTimeNoFF: "This time there were {0} from your team",
        bravo: "Bravo",
        wow: "Wow",
        ffFromTeam: "from your team",
        discussionCompletedPraise: "Super, you've discussed all Feedforwards!",
        showStats: "Show statistics",
        hideStats: "Hide statistics",
        questionLegend: "Question legend",
        orderExplanation: "Questions will be in Discussion Mode in this order",
        avgExplanation:
            "Color coded to reflect how well team members have graded this question. The bigger the number, the better!",
        devExplanation:
            "Color coded to reflect whether team members have similar or very different opinions on this question. The smaller the number, the better!",
        nrFFs: "Number of Feedforwards",
        nrFFExplanation:
            "Number of comments left by team members for this question",
        adminLvls: "Administration levels",
        profession: "Profession",
        gender: "Gender",
        office: "Office",
        location: "Location",
        age: "Idade",
        keyboardInfoMessage:
            "Podes passar através do inquérito usando as teclas númericas do teclado.",
        editAnswers: "Editar Respostas",
        activeSurvey: "Active survey",
        activeSurveys: "Active surveys",
        peopleWereInvitedFrom:
            "{0} people {1} were invited to fill out the survey ",
        fromEntity: "from {0}",
        fromWholeCompany: "from the whole company",
        personFilledOut: "1 person filled out the survey",
        peopleFilledOut: "{0} people filled out the survey",
        discussionVideoTitle: "Impulse Team discussion",
        gdpr01: "Declaração de consentimento",
        gdpr02: "Obrigado por usares o Joineer. Para ser coerente como as leis de proteção de ados, gostaríamos de te informar que além do teu nome e e-mail recolhemos as seguintes informações:",
        gdpr03: "Respostas quantitativas anónimas às perguntas do Teambarometer",

        gdpr04: "Opcional e anónimo, ou público, feedforward qualitativo",
        gdpr04pub: "Opcional feedforward qualitativo",

        gdpr05: "As respostas dadas serão vistas pelos membros da tua equipa, e pelo administrador da empresa.",
        gdpr06: "Todos os dados serão armazenados em servidores na Suíça e não serão fornecidos a terceiros.",
        gdpr07: "Puoi accedere ai tuoi dati personali in qualsiasi momento tramite il link di questa applicazione.",
        gdpr08: "Pedimos-te que tomes conhecimento e concordes com estes termos.",
        gdprLinkMessage: "", //For more information click
        gdprLinkTitle: "", //here
        gdprLinkUrl: "", //https://www.app.com
        gdpr09: "Se tiveres alguma dúvida, não hesites em entrar em contato (info@joineer.ch).",
        gdpr10: "Obrigado pela tua confiança.",
        gdpr11: "Com os melhores cumprimentos",
        gdpr12: "Joineer",
        gdprIgiveMyConsent: "Eu compreendo e dou o meu consentimento",
        introTitle1: "Bem-vindo ao Teambarometer",
        introTitle2: "A tua opinião conta - agora, e sempre",
        introTitle3: "FeedFORWARD - não feedback",
        introTitle4: "Responde - Debate - Benefícia ",
        introTitle5: "Pronto para começar?",
        introText1:
            "Estamos muito felizes pela tua participação porque a tua opinião conta. " +
            "Por favor, lê atentamente as páginas seguintes.",
        introText2:
            "Com o Joineer a tua opinião será pedida várias vezes por ano, através de um inquérito breve." +
            "Desta forma, o ânimo é medido ao longo do tempo e as melhorias podem ser comparadas de acordo.",
        introText3:
            "Nós não somos um livro de reclamações. Não critiques o que correu mal no passado, mas em vez disso partilha a forma como as coisas podem melhorar no futuro." +
            "Olhamos para o futuro, positivos e orientados para soluções - Feedforward!",
        introText4:
            "Após o inquérito, as entradas são afixadas no dashboard." +
            " O mais importante é que discutes os resultados no espaço da equipa!" +
            " Esta é a única forma do Teambarometer ser eficaz.",
        introText5L:
            "Está tudo claro? Se tiveres alguma dúvida ou pergunta não hesites em entrar em contato connosco em info@joineer.ch." +
            "Na página seguinte inicia a sessão com o teu e-mail e palavra-passe. Vamos!",
        introText5:
            "Tudo claro? Se tiveres alguma dúvida ou pergunta não hesites em entrar em contato connosco em info@joineer.ch. Aqui vamos nós!",
        introSkip: "Saltar introdução",
        surveyToFill: "Survey to fill out",
        hi: "Olá",
        surveyStartTitle: ", é um prazer ter-te!",
        startGoMessage:
            "Partilha o que pode melhorado no futuro- de uma forma positiva e focada em soluções com o Feedforward.",
        startGoMessage2:
            "Os resultados serão apresentados no dashboard e discutidos pelas equipas.",
        pulseSurvey: "Regular survey",
        reference: "Reference",
        surveyEndTitle: "Bravo, nearly done...",
        surveyEndMessage:
            "Have you submitted all your Feedforwards? If so, click 'Submit now'. If you want to change something or write further comments, you can use the 'Back' button to go back to the questions.",
        startSurvey: "Start survey",
        questionsInSurvey: "Questions in survey",
        pickFromCatalog: "Pick from catalog",
        createQuestion: "Create a question",
        sendReminderTooltip: "Send a reminder e-mail",
        sendReminders: "Send {0} reminders",
        sendReminder: "Send 1 reminder",
        addMembers: "Add members",
        addMember: "Add member",
        addAdmin: "Add admin",
        availableSoon: "Available soon!",
        remind: "Remind",
        remindAll: "Remind all",
        respondedQ: "Responded?",
        hasNotFilled: "This member has not filled out the survey",
        hasFilled: "This member has filled out the survey",
        membersPerPage: "Membros por página",
        yes: "sim",
        endSurvey: "End survey",
        general: "Gerais",
        surveysPaused: "Os inquéritos regulares são pausados",
        noIntervalResults: "Não há resultados de intervalo disponíveis",
        status: "Estatuto",
        results: "Resultados",
        planned: "PLANEJADO",
        ongoing: "EM CURSO",
        finished: "FINALIZADO",
        surveyTitle: "Título do inquérito",
        startDate: "Data de início",
        endDate: "Data de fim",
        participants: "Participantes",
        total: "Total",
        totalParticipants: "Total participants",
        page: "Página",
        previousPage: "Página anterior",
        nextPage: "Página seguinte",
        or: "ou",
        none: "nenhuma",
        today: "hoje",
        here: "aqui",
        structure: "Estrutura da organização",
        roles: {
            superadmin: "Administrador da empresa",
            admin: "Chefe da equipa",
            member: "Membro",
        },
        greeting: "Bem-vindo",
        buttons: {
            settings: "Options",
            saveChanges: "Save changes",
            reload: "Reload",
            filter: "Filtro",
            close: "Fechar",
            surveyGo: "Vamos a isto",
            surveyEnd: "Submeter agora",
            go: "Vá",
            start: "Começar",
            cancel: "Cancelar",
            reset: "Reinicialização",
            resetAll: "Reiniciar tudo",
            resetSelection: "Reiniciar selecção",
            back: "Voltar",
            backdash: "Voltar ao dashboard",
            next: "Próximo",
            finish: "Terminar",
            finalise: "Concluir",
            save: "Salvar",
            reopen: "Reabrir",
            delete: "Eliminar",
            send: "Enviar",
            markDone: "Discutido",
            done: "Concluído",
            moveUp: "Delegado",
            newTask: "Nova tarefa",
            expand: "Expandir todos",
            collapse: "Minimizar tudo",
            details: "Detalhes",
            new: "Novo",
            edit: "Editar",
            add: "Adicionar",
            addLanguage: "Adicionar língua",
            addQuestion: "Adicionar pergunta",
            create: "Criar",
            askExpert: "Pergunte ao perito",
            goTo: "Ir para",
            showAllFF: "Mostrar todos Feedforwards",
            hideAllFF: "Esconder todos os Feedforwards",
            changeOrder: "Change order",
            continue: "Continuar",
            yesDelete: "Yes, delete",
            yesRemove: "Yes, remove",
        },
        snackbar: {
            detailsUpdated: "Details updated",
            remindersSent: "Selected reminders sent!",
            ffClosed: "Feedforward marcado como discutido",
            ffOpened: "Feedforward aberto",
            ffEscalated: "Feedforward enviado para revisão pela gerência",
            taskClosed: "Tarefa concluída",
            taskOpened: "Tarefa reaberta",
            taskCreated: "Tarefa criada",
            taskDeleted: "Tarefa eliminada",
            taskDelegated: "Tarefa delegada",
            emailSent: "E-mail enviado!",
            forgotPWemailSent: "Email sent if account exists",
            downloading: "Descarregamento",
            downloadError: "Erro no download!",
            questionDeleted: "A pergunta foi eliminada",
            questionAdded: "Foi adicionada uma nova pergunta",
            questionEdited: "A pergunta foi editada",
            reordered: "As perguntas foram reordenadas",
            surveyDeleted: "O inquérito é eliminado",
            memberAdded: "Member was added to survey",
            memberRemovedFromSurvey: "Member was removed from survey",
            startChanged: "Survey start date was changed",
            endChanged: "Survey end date was changed",
            titleChanged: "Survey title was changed",
            memberRemoved: "Member was removed",
            adminRemoved: "Admin was removed",
            teamCreated: "Team was created",
            structureCreated: "Structure was created",
            teamDeleted: "Team was deleted",
            structureDeleted: "Structure was deleted",
            structureRenamed: "Structure was renamed",
            teamRenamed: "Team was renamed",
            teamMoved: "Team was moved",
            structureMoved: "Structure was moved",
            newMemberCreated: "New member was created",
            newAdminCreated: "New admin was added",
            surveyUpdated: "Survey was updated",
        },
        tooltips: {
            editTeamName: "Edit the name",
            deleteTranslations: "Delete {lang} translations",
            discussed: "Marca Feedforward como discutido",
            createTask: "Criar tarefa a partir do Feedforward",
            createFreeTask:
                "Criar uma tarefa não relacionada a nenhum Feedforward específico",
            closeTask: "Marcar tarefa como concluída",
            moveUp: "Mover tarefa para os administradores",
            deleteTask: "Eliminar tarefa",
            reopenFF: "Reabrir Feedforward",
            addComment: "Adicionar comentáriot",
            discussedDisabled:
                '"Marca Feedforward como discutido" desactivado no modo de pré-visualização',
            disabled: "Desactivado no modo de pré-visualização",
            hideFF: "Marcar Feedforward para ser tratado de forma individual e não mostrar em modalidade de discussão",
            showFF: "Mostrar Feedforward na modalidade de discussão",
            required: "Obrigatório",
            pressEnter: "Clique em Enter para guardar",
            surveyCanNotBeDeleted:
                "Só os inquéritos planeados podem ser eliminados.",
        },
        nav: {
            companyAdmins: "Company admins",
            calendar: "Survey calendar",
            dashboard: "Dashboard",
            discussion: "Modalidade de discussão",
            reports: "Relatórios",
            delegatedTasks: "Tarefas delegadas",
            teamTasks: "Tarefas de equipa",
            team: "Equipa",
            members: "Membros",
            interval: "Intervalo",
            adhoc: "Adhoc inquéritos",
            questions: "Perguntas",
            profile: "Perfil",
            logout: "Terminar a sessão",
            intercomChat: "Converse connosco!",
            qrCodes: "QR letters",
            ffanalysis: "FeedForward Analysis"
        },
        selectSurvey: {
            customised: "Customised",
            choose: "Escolha uma sondagem para rever na modalidade de discussão",
            now: "agora",
            team: "Equipa",
            selectTeam: "Seleccionar equipa",
            placeholder: "Selecione a sondagem",
            goDiscussion: "Ir para a modalidade de discussão",
            selectModeLabel: "Selecione o modalidade de visualização",
            preview: "Modalidade de pré-visualização",
            prepare: "Preparar",
            previewBody:
                "Esta é uma pré-visualização da modalidade de discussão antes do Team-Meeting real. Você pode esconder certos Feedforwards caso prefira resolvê-los em uma reunião um-a-um",
            present: "Apresentar",
            presentBody:
                "Apresente e discuta os resultados com a sua equipa.{0} Os Feedforwards acionáveis podem ser transformados em tarefas de equipa.",
            noTeam: "Não existe tal equipa",
            noSurvey: "Ainda nenhuma sondagem concluída",
            comeBack:
                "Volte para discutir os resultados assim que a sua primeira sondagem estiver concluída!",
            handbuchLink:
                "Dicas e truques para uma discussão bem sucedida dos resultados na equipa podem ser encontrados {here}",
            questionOrder: "Ordem de discussão",
            original: "como no inquérito",
        },
        discussionMode: {
            question: "Pergunta",
            survey: "sondagem",
            anonym: "Anônimo",
            noFF: 'Nenhum Feedforward, clique em "Seguinte"',
            noDiscussed:
                "Os Feedforwards marcados como discutidos {0} aparecerão aqui",
            noTasks: "Feedforwards transformados em tarefas {0} aparecerão aqui",
            createTask: "Criar uma tarefa a partir do Feedforward",
            feedforwards: "Feedforwards",
            discussed: "Discutido",
            tasks: "Tarefas",
            moveUpTo: "Delegar tarefa a:",
            highlight: "Delegar este Feedforward",
            describe: "Descreva porque é importante que isto seja passado adiante",
            selectLabel: "Para a atenção de:",
            unable: "Não é possível resolvê-lo em equipa?",
            hiddenFFs:
                "Os Feedforwards abaixo estão escondidos da apresentação em modalidade de discussão. O número de Feedforwards ocultos ainda será mostrado",
            nrhiddenFFs:
                "Número de Feedforwards tratados em um encontro individual:",
        },
        achievements: {
            title: "Progressos",
            intro: "Aqui está o que já foi alcançado",
        },
        tasks: {
            taskPage: "Página de tarefas",
            taskBannerText: "Tarefas criadas a partir da discussão",
            createTitle: "Criar tarefa",
            taskDisabled:
                "A criação de tarefas está desactivada no modo de pré-visualização",
            titlePlaceholder: "Título da tarefa",
            title: "Título",
            commentPlaceholder: "Comentário opcional",
            origin: "Originado de",
            addComment: "Adicionar comentário",
            comments: "Comentários",
            noComments: "Sem comentários",
            dueDate: "Data de finalização",
            dueBy: "Devido por",
            setDueDate: "Defina uma data de finalização",
            assign: "Atribuir tarefa a",
            assignedTo: "Atribuído a",
            other: "outro",
            enterTitle: "Por favor, introduza o título da tarefa",
            myTasks: "Minhas tarefas",
            teamTasks: "Tarefas da equipa",
            openTasks: "Tarefas abertas",
            completedTasks: "Tarefas concluídas",
            deleteTitle: "Eliminar tarefa",
            deleteBody:
                "Tem a certeza que deseja apagar esta tarefa permanentemente?",
            noTasks: "Nenhuma tarefa aberta",
            delegatedExplanation:
                "Lista de Feedforwards ou tarefas de equipa que foram delegadas para a atenção dos administradores.",
            noCompletedTasks: "Ainda nenhuma tarefa concluída",
            noDelegatedTasks: "Ainda sem tarefas delegadas",
            sendEmail: "Enviar resumo de tarefas via e-mail",
            assigneeRequiredMessage: "Por favor, seleciona um membro para atribuir a tarefa",
            calendarEntry: "Com entrada de calendário",
            calendarEntryNo: "Sem entrada de calendário",
            commentDeletedBy: "Comentário eliminado por {updatedBy}",
            deleteComment: "Apagar este comentário?",
            updateComment: "Salvar alterações",
            edited: "editado",
            editComment: "Editar comentário",
            afterDelete:
                "Depois da eliminação, isto será mostrado no histórico de comentários das tarefas:",
        },
        completion: {
            title: "Muito bem!",
            tasksCreated: "Tarefas criadas",
            ffDiscussed: "Feedforwards discutidos",
            go: "Ir para o quadro de tarefas",
        },
        intro: {
            title: "Baseando-nos nos resultados, recomendamos discutir os Feedforwards na seguinte ordem. Arrastar {0} e largar para alterar a ordem",
            intro: "Com base nos resultados, recomendamos discutir os Feedforwards na seguinte ordem. Arraste {0} e solte para alterar a ordem",
            start: "Iniciar a discussão",
        },
        login: {
            title: "Iniciar sessão",
            pw: "Senha",
            forgot: "Esqueceu a password?",
            withLink: " com ligação directa",
            withPassword: " com senha",
            checkEmail:
                "Se tiver uma conta com este endereço electrónico, a sua ligação de início de sessão ser-lhe-á enviada agora.",
            incorrectEmail: "Correio electrónico incorrecto ou em falta",
            labelSendLink: "Enviar link",
            labelLinkSent: "Pedir com sucesso",

            enterNameAndPassword:
                "Por favor introduza o seu e-mail e palavra-chave.",
            enterEmail:
                "Por favor introduza o seu e-mail para recuperar uma ligação directa de início de sessão.",
            resetSent: "Reset password link sent to email!",
            getLink: "Retrieve login link",
            selectYourCompany: "Select your company"
        },
        admin: {
            title: "Quadro da administração",
            allTeams: "Todas as equipas",
            allSurveys: "Todas as sondagens",
            selectedTeams: "Equipas seleccionadas",
            deselectAll: "Desmarcar tudo",
            selectAll: "Selecionar tudo",
            created: "Criado em",
            markDone: "Marcar como resolvido",
            done: "Resolvido",
            latestComment: "Último comentário",
            lessComments: "menos comentários...",
            moreComments: "mais comentários...",
            empty: "Ainda não há aqui nada",
            deleteConfirmation:
                "Tem a certeza que deseja apagar isto permanentemente?",
        },
        stats: {
            decreased: "diminuído",
            increased: "aumentado",
            avg1: " é o valor médio da equipa para esta pergunta. Um valor médio mais pequeno significa que há mais potencial de melhoria. ",
            avg2: "Em comparação com a sondagem anterior, o resultado tem {0} de {1}%",
            dev1: " é o desvio padrão das avaliações para esta pergunta. Um número menor significa que os membros da equipa têm opiniões semelhantes. Um número maior significa que a equipe deve discutir este tópico. ",
            dev2: "Em comparação com a sondagem anterior, as diferenças foram {0} de {1}.",
            graphIntro: "O gráfico abaixo pode ajudar a interpretar os resultados",
            devGraph1Label: "Concordo",
            devGraph1Explanation: "Um coração, uma alma.",
            devGraph2Label: "Diversos",
            devGraph2Explanation: "Desvios no intervalo normal.",
            devGraph3Label: "Necessidade de discussão",
            devGraph3Explanation: "Você avalia a situação de forma diferente.",
            avgGraph1Label: "Melhore",
            avgGraph1Explanation: "Existe um grande potencial de melhoria.",
            avgGraph2Label: "Neutro",
            avgGraph2Explanation: "Suficiente. Melhore seletivamente.",
            avgGraph3Label: "Continue assim",
            avgGraph3Explanation: "Dar e receber elogios.",
            histogramTitle: "Distribuição das respostas",
            histogramNr: "Número de pessoas",
            histogramGrade: "Grau",
            histogramAnswered: " as pessoas responderam ",
        },
        report: {
            title: "relatório do Teambarometer",
            selectLabel: "Ver relatório para",
            company: "Empresa",
            summary: "Resumo",
            all: "Todos",
            pleaseWait: "Relatório em carregamento... por favor aguarde.",
            noData: "Não há relatório disponível",
            searchLabel: "Pesquisa",
            name: "Nome",
            totalFF: "Total Feedforwards",
            noFFsInSurvey:
                "Não foram deixados quaisquer Feedforwards neste inquérito",
            closedFF: "Feedforwards discutidos",
            totalTasks: "Total de tarefas",
            taskedFF: "Feedforward de tarefas",
            delegatedTasks: "Tarefas delegadas",
            closedTasks: "Tarefas concluídas",
            wholeCompany: "Toda a empresa",
            discussionSummary: "Resumo da discussão",
            discussionSummaryText:
                "Visão geral das discussões sobre os resultados realizadas no seio da empresa. Verificar em que equipas os comentários já foram discutidos ou implementados.",
            download: "Descarregar",
            downloadSummary: "Download do resumo (.pptx)",
            preparingFile: "A preparar o ficheiro...",
            presentation: "Apresentação",
            matrixTitle: "Mapa do calor matricial",
            matrixText:
                "Visão geral das respostas por equipa e pergunta através de código de cores.",
            matrixTextMinFill:
                "Equipas sem respostas suficientes são excluídas do relatório",
            selectReport: "Selecione o relatório",
            displayError:
                "A tabela não pode ser gerada. Por favor, tente antes fazer o download dos resultados.",
            averages: "Valores médios",
            deviations: "Desvios padrão",
            enps: "ENPS",
            enpsScore: "Classificação ENPS",
            answerNr: "Número de respostas",
            surveyEnded: "Inquérito terminado ",
            "1dayAgo": "há {0} dia",
            xdaysAgo: "há {0} dias",
            companyGoal: "Objectivo da empresa",
            handleFeedforwards: "tratar de todas as Feedforwards",
            howManyHandled: "{0} em cada {1} Feedforwards foram tratados",
            handlingRate: "Taxa de tratamento",
            FFHandleTitleCompanyAdmin:
                "Até que ponto as vossas equipas estão a progredir bem?",
            FFHandleTextCompanyAdmin:
                "Para que a sondagem seja um sucesso de equipa, é importante tratar de todos os Feedforwards logo após a sondagem.",
            "2weeksafter":
                "2 semanas após o inquérito, a tabela é colorida pela taxa de manuseamento Feedforward. As cores apresentam a % de Feedforward que são discutidas ou encarregadas dentro da equipa.",
        },
        errors: {
            selectUser: "Please select a user first",
            submit: "Erro ao submeter o inquérito",
            invalidFormat: "Formato inválido",
            notPossibleRunningSurvey: "Not possible during a running survey",
            oops: "Oops!",
            "error-4000":
                "Não há dados suficientes disponíveis para esta sondagem e etiquetas selecionadas",
            noData: "Sem dados",
            notEnoughData:
                "Não há dados suficientes disponíveis para esta sondagem e etiquetas selecionadas",
            notEnoughResponseData:
                "Não há dados de resposta suficientes disponíveis",
            displayError: "A tabela não pode ser gerada",
            generalError: "Algo correu mal",
            featureNotEnabled:
                "Esta funcionalidade não é activada na sua conta. Por favor contacte-nos para info@joineer.ch ou +41 44 510 38 00 para mais informações.",
            featureNotAllowed:
                "Esta funcionalidade não está activada para o seu papel de utilizador",
            missingFields: "Por favor preencha todos os campos",
            noPermission: "Sem permissão para ver esta página",
            incorrectUrl: "URL incorrecto",
            incorrectEmail: "Formato de e-mail incorrecto",
            login400: "E-mail ou outra informação de login em falta",
            login401: "A palavra-passe ou nome de utilizador está incorrecta",
            login401token: "A ficha de login está incorrecta",
            login403: "A conta está protegida por palavra-passe. Por favor, inicie sessão com a sua palavra-passe.",
            login403authMethodNotAllowed: "O método de autenticação '{method}' não é permitido para a sua conta empresarial. Entre em contacto com o seu administrador.",
            login403urlLoginNotAllowed: "Login via URL não permitido. É necessário efetuar login com password.",
        },
        dashboard: {
            participation: "Participação",
            surveyResults: "Resultados da sondagem",
            summary: "Sumário",
            resultsFor: "Resultados para",
            wholeCompany: "Toda a empresa",
            compareWith: "Compare com",
            compareExplanation: "Compare os resultados com os seguintes:",
            questionOverview: "Resumo das perguntas",
            average: "Valor médio",
            deviation: "Desvio padrão",
            companyHistory: "Valor médio da empresa nas sondagens passadas",
            compareHistory: "Valor médio das sondagens passadas",
            filterExplanation: "Filtrar os resultados da sondagem por etiquetas:",
            "dev-good": "Concordo",
            "dev-normal": "Diversos",
            "dev-bad": "Muito diversos",
            distributionAnswers: "Distribuição das respostas",
            surveyDate: "Data da sondagem",
            companyAvg: "Valor médio da empresa",
            positive: "positivo",
            negative: "negativo",
            noFFs: "Nenhum Feedforward",
            perMember: "por membro",
            otherTags: "Outras etiquetas",

            waitingSurvey: "À espera que o primeiro inquérito tenha início a ",
            waitingTeam: "À espera que a sua equipa preencha o inquérito",
            surveyStatus: "Estado do inquérito: ",
            meanWhile: "Entretanto, pode: ",
            here: "aqui",
            addMembers: "Adicionar novos utilizadores {0}",
            editQ: "Editar perguntas {0}",
            editInterval: "Editar intervalo {0}",
        },
        teamMemberDisplay: {
            title: "Membros que participaram da pesquisa",
            noResponse: "Nenhum membro desta equipe respondeu à pesquisa"
        },
        questionEditor: {
            questionsTitle: "Perguntas",
            questionsIntro:
                "Perguntas com base amarela {0} e símbolo são estabelecidas ao nível da empresa ou departamento superior. As perguntas com base {1} azul são perguntas de equipa ou de departamento a nível de visão actual.",
            questionsIntroType:
                "Perguntas do mesmo tipo mostram um símbolo de ligação {0} ao pairar. As estatísticas das sondagens a estas perguntas são comparadas umas com as outras.",
            survey: "Sondagem",
            company: "Empresa",
            companyQ:
                "Visualizar e editar as questões de âmbito empresarial que cada equipa e departamento tem na sua sondagem",
            teamSelectLabel: "Perguntas para ver:",
            currentSurvey: "Sondagem em curso",
            pastSurvey: "Sondagem anterior",
            nextSurvey: "Próxima sondagem",
            noEdit: "As sondagens anteriores estão aqui para referência e não podem ser editados",
            adminQuestionNoEdit:
                "Esta questão é resolvida dentro da empresa ou departamento. Só pode ser ajustada por um administrador de departamento ou de empresa",
            noQuestionEdit:
                "Esta é uma questão de nível superior e não pode ser editada",
            noQuestionEditView:
                "Apenas pode ser editado a um nível mais alto de visão de departamento ou empresa",
            addError: "A pergunta não pode ser adicionada.",
            editError: "A pergunta não pode ser editada.",
            deleteError: "A pergunta não pode ser eliminada.",
            nextError: "Não é possível recuperar a próxima sondagem.",
            catalogIntro:
                "Deixe-se inspirar pelo nosso catálogo de perguntas. Abra as categorias e escolha uma pergunta clicando nela. Assim que atribuir uma pergunta à sua pesquisa, você pode alterar a ilustração e o texto pelo ícone de edição {0}.",
            questions: {
                question: "Pergunta",
                type: "Tipo",
                illustration: "Ilustração",
                topic: "Tópico",
                preview: "pré-visualização",
                dragOrder:
                    "Arrastar {0} e largar para alterar a ordem das perguntas.",
                addQuestion: {
                    en: "Inglês",
                    de: "Alemão",
                    it: "Italiano",
                    fr: "Francês",
                    pl: "Polaco",
                    sk: "Eslovaco",
                    hu: "Húngaro",
                },
                mandatory: "Idioma obrigatório",
                language: "Idioma",
                chooseLang: "Escolher idioma",
                example: "Exemplo: ",
                exampleQ: "Está satisfeito com o conteúdo do seu trabalho?",
                exampleT: "Tarefas de trabalho",
            },
            addQuestion: {
                addTitle: "Adicionar pergunta a sondagem",
                newTitle: "Novo",
                catalogTitle: "Catálogo",
                reuseTitle: "Reutilização",
                new: "Faça a sua própria pergunta personalizada para se adequar às necessidades da sua equipa",
                catalog: "Choose a new question from the catalog",
                reuse: "Adicionar uma pergunta previamente utilizada a sondagem",
                noReuse: "Ainda nenhuma história de perguntas",
                newType:
                    "Crie uma nova pergunta medindo as respostas da sua equipa sobre um determinado tópico",
                newLinkedType:
                    "Criar uma nova pergunta que esteja ligada a uma pergunta anteriormente utilizada",
                linkedTooltip1:
                    "Esta opção é boa para criar perguntas que medem a mesma coisa, mas são formuladas de forma diferente ou colocadas sob um novo ângulo.",
                linkedTooltip2:
                    "A ligação de perguntas semelhantes permite estatísticas visuais e convenientes entre sondagens, como se mostra abaixo.",
                linkTitle: "Ligar pergunta",
                textTitle: "Texto da pergunta",
                copyNotice:
                    "Os textos da pergunta seleccionada foram copiados abaixo para sua conveniência",
                noLangContact:
                    "Se desejar adicionar um idioma não listado aqui, por favor {0} e teremos todo o gosto em adicioná-lo manualmente para si",
                contact: "contacte-nos",
                langsUsed:
                    "Todos os {0} idiomas predefinidos são utilizados. Se desejar adicionar mais idiomas, por favor {1} e teremos todo o gosto em adicioná-los manualmente para si.",
            },
            search: {
                mayAdd: "Pode adicionar uma pergunta previamente utilizada na sondagem",
                searchLabel: "Pesquisa a partir de perguntas existentes",
                willBeLinked:
                    "Statistics for the question you create now will be compared to the below question:",
                askedBefore: "This question has been asked before in:",
            },
            dialogs: {
                editQ: "Editar pergunta",
                editExplanation:
                    "Editar texto, ilustração ou adicionar novos idiomas",
                deleteExplanation: "Eliminar pergunta da sondagem",
                deleteTitle: "Eliminar pergunta",
                deleteConfirmation: "Gostaria de eliminar esta pergunta?",
                deleteLong:
                    "Eliminar a pergunta significa que não aparecerá em nenhuma sondagem futura, mas continuará a vê-la nas estatísticas passadas",
            },
        },
        forms: {
            search: "Pesquisa",
        },
        adhoc: {
            description1: "Inquéritos únicos",
            surveyDetails: "Detalhes do inquérito",
            detailsDescription: "Definir título e datas",
            participantsDescription: "Escolha quem preenche o inquérito",
            participantsMinFill1:
                "Pelo menos 1 membro teria de preencher o inquérito para que os resultados pudessem ser calculados",
            participantsMinFillLarger:
                "Pelo menos {0} os membros teriam de preencher o inquérito para que os resultados pudessem ser calculados",
            notEnoughMembers:
                "Não há membros suficientes no inquérito! Mínimo: {0}",
            questionsDescription: "Escolher perguntas para o inquérito",
            review: "Revisão",
            reviewDescription: "Resumo do inquérito",
            activeFilters: "Filtros activos",
            filterByEntities: "Filtrar por entidades",
            active: "activo",
            filterByTags: "Filtrar por etiquetas",
            reset: "Repor todos os filtros e eliminar os participantes acrescentados ao inquérito",
            removeFromSurvey: "Retirar participante do inquérito",
            usersAddedToSurvey: " participantes acrescentados ao inquérito",
            questionsAdded: "Questões adicionadas ao inquérito",
            surveyPeriod: "Período do Inquérito",
            editParticipants: "Volte atrás e edite os participantes no inquérito",
            editPeriod: "Voltar atrás e editar período de inquérito",
            editQuestions: "Voltar atrás e editar perguntas de inquéritos",
            editTitle: "Voltar atrás e editar título do inquérito",
            datesWrongError:
                "Oops! Por favor certifique-se de que a data final é após a data de início",
            surveySuccess: "Inquérito criado com sucesso",
            surveyDeleteConfirmation:
                "Tens a certeza que desejas apagar este inquérito?",
            noMembersFound:
                "Não foram encontrados membros com filtros actuais. Tente remover alguns dos filtros activos para alargar a selecção ou reiniciar os filtros {0}",
            manageSurvey: "Manage survey",
            endNow15min:
                "This action will stop the ongoing survey. Changes will take effect in 15 minutes.",
            endNowConfirmation: "Are you sure you wish to end this survey?",
        },
        askExpert: {
            text: "Situação difícil na equipa? Não tem a certeza de como discutir os resultados na equipa? {0} terá todo o prazer em apoiá-lo em questões difíceis.",
            subject: "Assunto",
            from: "De:",
            to: "Para:",
            messagePlaceholder: "Tenho a seguinte pergunta...",
            askQuestions: "If you have specific questions, ",
            yourExpertHere: "ask your expert here",
        },
        survey: {
            admin: "Admin",
            team: "Equipa",
            opener: "No futuro, penso que seria bom",
            publicComment: "Comentário público",
            anonComment: "Comentário anónimo",
            infoNumericalValueResponse:
                "Valor numérico: é coletado sem o seu nome",
            infoAnonComment:
                "Comentário anónimo: é coletado sem o seu nome",
            infoPublicComment:
                "Comentário público: O teu nome é visível para a tua equipa e administradores",
            labelNoAnswerGiven: "Nenhuma resposta dada",
            labelCantAnswer: "Não posso responder a esta pergunta",
        },
        "genericApiErrorMessage": "A operação não pôde ser realizada",
    };
};

function commonCustomisations() {

    return Object.assign({}, txGroup());

    function txGroup() {

        return assignToObjectKeys(TX_GROUP_COMPANY_IDS, {
            // no PT version yet
        }, {});
    }
}

function customisations() {
    return {
        // no customisations yet
    };
}
