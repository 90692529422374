const rxFunctionName = /^.+\)/;

// $devMode only, chainable logger
function l(...args) {
    if (!window.$devMode) return;

    console.log(...args);
    return args && args[0];
}

function le(...args) {
    if (!window.$devMode) return;

    console.error(...args);
    return args && args[0];
}

function lw(...args) {
    if (!window.$devMode) return;

    console.warn(...args);
    return args && args[0];
}

// copy through JSON
function j(...args) {
    if (!window.$devMode) return;
    if (typeof args === "undefined") return undefined;
    if (typeof args[0] === "undefined") return undefined;

    try {
        return JSON.parse(JSON.stringify(args[0], getSimpleCircularReplacer(), 2));
    } catch (ex) {
        le('[devUtils].j - error:', ex);
        le('[devUtils].j could not copy:', args[0]);
        return '[devUtils].j - error';
    }
}

// log as POJO
function lj(...args) {
    if (!window.$devMode) return;

    return args && l(...args.map(arg => j(arg)));
}

function s(...args) {
    if (!window.$devMode) return;
    if (!args) return String(args);

    try {
        return args.length > 1
            ? args.map(arg => JSON.stringify(arg, getSimpleCircularReplacer(), 2))
            : JSON.stringify(args[0], getSimpleCircularReplacer(), 2);
    } catch (ex) {
        le('[devUtils].s - error:', ex);
        le('[devUtils].s could not JSON.stringify:', ...args);
        return '[devUtils].s - error';
    }
}

function ls(...args) {
    if (!window.$devMode) return;

    return args && l(...args.map(arg => s(arg)));
}

/*const circularReference = {otherData: 123};
(<any>circularReference).myself = circularReference;

function getCircularReplacer() {
  const seen = new WeakMap();
  const lastValue : any = {};
  const lastKey : string | undefined = undefined;
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return '[circular]';
      }
      if (lastValue[key] === value) {
        seen.set(value, key); // val is the key on purpose
      } else {
          seen.delete(lastValue);
      }
      lastValue = value;
      lastKey = key;
    }
    return value;
  };
};

const stringified = JSON.stringify(circularReference, getCircularReplacer());
console.log(stringified);*/


function getSimpleCircularReplacer() {
    const markerProperties = ['id', '_id', 'name'];
    let cache = new WeakSet();
    let circularMarkCache = new WeakMap();
    var CIRCULAR_MARK = '[circular]';
    setTimeout(()=> { cache = null as any; }, 1000);
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {

            if (cache!.has(value)) {
                if (Array.isArray(value)) {
                    if (!circularMarkCache.has(value)) {
                        circularMarkCache.set(value, `CIRCULAR_MARK: array(${value.length})`);
                    }

                    return circularMarkCache.get(value);
                } else {

                    if (!circularMarkCache.has(value)) {
                        let result = markerProperties.map(prop => Object.hasOwnProperty.call(value, prop) ? `${prop}: ` + value[prop] : null).filter(i => i);

                        circularMarkCache.set(value, result.length
                            ? (CIRCULAR_MARK + ': ' + result.join(', '))
                            : CIRCULAR_MARK)
                    }

                    return circularMarkCache.get(value);
                }
            };

            cache!.add(value);
            return value;
        } else if (typeof value === 'function') {
            return `[function] ${rxFunctionName.exec(value.toString())?.[0] || ''}`;
        } else {
            return value;
        }
    };
};

const filters = {
    pretty: function(value) {
        if (!window.$devMode) return;

        if (typeof value === "undefined") return 'undefined';
        if (isNaN(value)) return 'NaN';

        try {
            if (typeof value === 'string') {
                value = JSON.parse(value);
            }
        } catch { }

        try {
            return JSON.stringify(value, null, 2);
        } catch (ex) {
            le(ex);

            return '';
        }
    },
};

export {
    l,
    le,
    lj,
    ls,
    lw,
    j,
    s,
    filters,
};