<template>
    <v-snackbar
        app
        location="bottom"
        class="pa-5"
        :color="storeOptions?.color || snackbar[computedType]?.color || options?.color || color || 'grey-darken-1'"
        v-model="snackVal"
        :timeout="timeout"
        style="z-index: 10000"
    >
        <div class="d-flex align-center msg-container">
            <v-icon class="mr-2">{{
                storeOptions?.icon
                    || snackbar[computedType]?.icon
                    || icon
                    || options?.icon
                    || 'mdi-information-outline'
            }}</v-icon>
            {{
                storeOptions?.msg && ($te(storeOptions?.msg)
                        ? $t(storeOptions?.msg)
                        : storeOptions?.msg)
                    || ($te("snackbar." + computedType)
                        ? $t("snackbar." + computedType)
                        : $te(computedType)
                            ? $t(computedType)
                            : (options?.msg && $te(options?.msg))
                                ? $t(options?.msg)
                                : (options?.msg || computedType))
            }}
        </div>
    </v-snackbar>
</template>

<script>
import { useSnackbarStore } from '@/store/snackbar.store';
import { mapStores } from 'pinia';

export default {
    name: "",
    props: ['color', 'icon', 'type', 'duration', 'options'],
    components: {},
    data: () => ({
        MIN_DURATION: 500,
        snackbar: {
            default: {
                color: "primary",
                icon: "mdi-information-outline"
            },
            success: {
                color: "success",
                icon: "mdi-check-bold"
            },
            error: {
                color: "error",
                icon: "mdi-alert-circle-outline"
            },
            warning: {
                color: "warning",
                icon: "mdi-alert-outline"
            },
            createdSuccess: {
                color: "green",
                icon: "mdi-plus",
            },
            createdError: {
                color: "error",
                icon: "mdi-plus",
            },
            createdWarning: {
                color: "warning",
                icon: "mdi-plus",
            },
            editedSuccess: {
                color: "primary",
                icon: "mdi-pencil",
            },
            editedError: {
                color: "error",
                icon: "mdi-pencil",
            },
            editedWarning: {
                color: "warning",
                icon: "mdi-pencil",
            },
            deletedSuccess: {
                color: "grey darken-1",
                icon: "mdi-delete",
            },
            deletedError: {
                color: "error",
                icon: "mdi-delete",
            },
            deletedWarning: {
                color: "warning",
                icon: "mdi-delete",
            },
            ffClosed: {
                color: "green",
                icon: "mdi-check-bold"
            },
            ffOpened: {
                color: "primary",
                icon: "mdi-upload"
            },
            ffEscalated: {
                color: "warning",
                icon: "mdi-account-arrow-right"
            },
            taskClosed: {
                color: "green",
                icon: "mdi-check-bold"
            },
            taskOpened: {
                color: "primary",
                icon: "mdi-upload"
            },
            taskCreated: {
                color: "primary",
                icon: "mdi-plus"
            },
            taskDeleted: {
                color: "grey",
                icon: "mdi-delete"
            },
            taskDelegated: {
                color: "warning",
                icon: "mdi-account-arrow-right"
            },
            emailSent: {
                color: "primary",
                icon: "mdi-email-check-outline"
            },
            copiedToClipboard: {
                color: "primary",
                icon: "mdi-check-bold"
            },
            downloading: {
                color: "primary",
                icon: "mdi-download"
            },
            downloadError: {
                color: "error",
                icon: "mdi-alert-outline"
            },
            feedbackSubmitted: {
                color: "success",
                icon: "mdi-check-bold"
            },
            reordered: {
                color: "primary",
                icon: "mdi-format-list-numbered"
            },
            questionAdded: {
                color: "green",
                icon: "mdi-plus"
            },
            questionDeleted: {
                color: "grey-darken-1",
                icon: "mdi-delete"
            },
            questionEdited: {
                color: "primary",
                icon: "mdi-pencil"
            },
            surveyDeleted: {
                color: "grey-darken-1",
                icon: "mdi-delete"
            },
            memberRemovedFromSurvey: {
                color: "grey-darken-1",
                icon: "mdi-delete"
            },
            memberAdded: {
                color: "green",
                icon: "mdi-plus"
            },
            startChanged: {
                color: "primary",
                icon: "mdi-calendar-edit"
            },
            endChanged: {
                color: "primary",
                icon: "mdi-calendar-edit"
            },
            titleChanged: {
                color: "primary",
                icon: "mdi-pencil"
            },
            remindersSent: {
                color: "green",
                icon: "mdi-email-check-outline"
            },
            memberRemoved: {
                color: "grey-darken-1",
                icon: "mdi-delete"
            },
            adminRemoved: {
                color: "grey-darken-1",
                icon: "mdi-delete"
            },
            teamDeleted: {
                color: "grey-darken-1",
                icon: "mdi-delete"
            },
            structureDeleted: {
                color: "grey-darken-1",
                icon: "mdi-delete"
            },
            structureRenamed: {
                color: "primary",
                icon: "mdi-pencil"
            },
            teamRenamed: {
                color: "primary",
                icon: "mdi-pencil"
            },
            teamCreated: {
                color: "primary",
                icon: "mdi-plus"
            },
            structureCreated: {
                color: "green",
                icon: "mdi-plus"
            },
            teamMoved: {
                color: "green",
                icon: "mdi-arrow-right"
            },
            structureMoved: {
                color: "green",
                icon: "mdi-arrow-right"
            },
            newMemberCreated: {
                color: "green",
                icon: "mdi-plus"
            },
            newAdminCreated:{
                color: "green",
                icon: "mdi-plus"
            },
            surveyEnded: {
                color: "primary",
                icon: "mdi-flag-checkered"
            },
            surveyUpdated: {
                color: "primary",
                icon: "mdi-pencil"
            },
            detailsUpdated: {
                color: "primary",
                icon: "mdi-pencil"
            },
            emailConfirmationSent: {
                color: "primary",
                icon: "mdi-pencil"
            }
        }
    }),
    methods: {},
    computed: {
        ...mapStores( useSnackbarStore ),

        snackVal: {
            get() {
                return this.snackbarStore.show;
            },
            set(val) {
                this.snackbarStore.showSnackbar([val, this.storeType, this.snackbarStore.duration]);
            }
        },

        computedType() {
            return this.storeType || this.type || this.options?.type;
        },

        storeType() {
            return this.snackbarStore.options?.type || this.snackbarStore.type;
        },

        storeOptions() {
            l('[computed] storeOptions:', this.snackbarStore.options);

            return this.snackbarStore.options;
        },
        timeout() {
            let timeout = 1500;
            const durationSrc = this.duration || this.snackbarStore.duration || this.snackbarStore.options?.duration;

            if (typeof durationSrc === 'string') {
                switch (durationSrc) {
                    case 'short': timeout = 1500; break;
                    case 'medium': timeout = 2500; break;
                    case 'long': timeout = 5000; break;
                }
            } else {
                const duration = +durationSrc;
                timeout = ((duration > (this.snackbarStore.MIN_DURATION || this.MIN_DURATION)) && duration) || timeout;
            }

            return timeout;
        }
    }
};
</script>

<style>
    .msg-container {
        font-size: 1.3rem;
        white-space: preserve-breaks;
    }
</style>
