<template>
    <!-- TODO: VUE 3: END density-->
    <v-footer class="pointer-events-none" color="transparent" app>
        <v-spacer></v-spacer>

        <v-menu location="top">
            <!-- TODO: V3 end -->
            <template #activator="{ props }">
                <v-btn variant="text" v-bind="props" :ripple="false" class="mr-15 pointer-events-initial">
                    <span style="font-size: 14px" class="text-grey"
                        >{{ currentLang }}
                    </span>
                    <!-- TODO: V3 end -->
                    <v-icon class="ml-1" color="grey">mdi-web</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    :class="{ 'active-lang': locale == getSpecificLocale(langWithLocale) }"
                    @click="changeLanguage(langWithLocale)"
                    v-for="langWithLocale in currentAllowedLocalesRef"
                    :key="langWithLocale.value"
                >
                    <v-list-item-title>{{
                        getGenericLocale(langWithLocale).toUpperCase()
                    }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-footer>
</template>

<script setup>
    import { computed } from "vue";
    import { useI18n } from "vue-i18n";
    import { changeLanguage, currentAllowedLocalesRef, currentLang, getGenericLocale, getSpecificLocale } from '@/utils/languageHelpers';

    const { locale } = useI18n();
</script>

<style>
.active-lang {
    background: rgb(222, 238, 252) !important;
}
.pointer-events-initial {
    pointer-events: initial;
}
.pointer-events-none {
    pointer-events: none;
}
</style>
