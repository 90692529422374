import moment from 'moment';

const dateDifference = function(date) {
    // used in Discussion Summary to say "Survey ended X days ago"
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    let date1 = new Date(date);
    let date2 = new Date(); //today

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
        date1.getFullYear(),
        date1.getMonth(),
        date1.getDate()
    );
    const utc2 = Date.UTC(
        date2.getFullYear(),
        date2.getMonth(),
        date2.getDate()
    );

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

const sortNewestFirst = function(items) {
    return items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }

const formatDate = (date, format = "YYYY-MM-DD") => {
    if(date instanceof Date) {
        return moment(date).format(format);
    }
    window.le(`Error in formatDate: Expected a valid Date object but received "${date}"`)
    return null;
}  
  
export {
    dateDifference, sortNewestFirst, formatDate
};
