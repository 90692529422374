<template>
    <div class="bounce-in centered pos-relative">
        <div v-if="arePropsValid">
            <div style="max-width:150px">
                <canvas
                    v-if="curatedDeviation !== null"
                    :id="`${compareStat ? 'compareChart' : 'chart'}-${id}`"
                ></canvas>
            </div>

            <div class="radialLabel mt-2">
                {{ invalid
                    ? "N/A"
                    : curatedDeviation == 0.0
                        ? "0"
                        : curatedDeviation
                }}
            </div>
            <div v-if="!invalid" class="text-small">
                {{ deviationText ? $t(deviationText).toUpperCase() : "" }}
            </div>

            <div
                v-if="curatedChange && !hideChange && !invalid"
                :class="` text-${devChangeColor(change)} mt-1`"
            >
                <v-icon
                    v-if="change !== 0"
                    :color="devChangeColor(change)"
                >
                    {{ change > 0 ? "mdi-menu-up" : "mdi-menu-down" }}</v-icon
                >
                <span v-if="change === 0">🠖</span>
                {{ curatedChange }}
            </div>
            <!-- filler block to visually line up other elements when there's no deviation change info -->
            <div v-else style="background: transparent; height: 25px">
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue';
import { devChangeColor, isValidNr } from "../../utils/dashboardCode.js";
import ScaleGraph from "@/components/ScaleGraph";
export default {
    //compareStat only used for rendering reasons to make sure side by side charts have different ids
    name: "DeviationNr",
    props: [ "invalid", "deviation", "topGrade", "change", "id", "compareStat", "hideChange" ],
    components: { ScaleGraph },
    mounted() {
        //this.generateGauge();
    },
    data: () => ({
        deviationText: null,
        gaugeColorPoints: { 6: [0.66, 1.26, 2.2], 10: [1.51, 3.5, 5] },
        myGauge: null,
        notValid: { valid: false, deviation: 0, id: 'id-' + Math.random(), topGrade: 6, changeDeviation: 0 },
    }),
    methods: {
        devChangeColor(num) {
            return devChangeColor(num);
        },
        generateGauge() {
            let config = {
                type: "gauge",
                data: {
                    datasets: [
                        {
                            value: this.gaugeValue,
                            minValue: -0.01,
                            data: this.gaugeColorPoints[this.topGrade],
                            //data: [0.5, 1, 2.5] for topGrade 6 this means green bracket from 0 - 0.5
                            backgroundColor: this.gaugeColors, //generated to highlight current bracket (green/orange/red)
                            borderWidth: 2
                        }
                    ]
                },
                options: {
                    events: [], //empty array to make the gauge readonly
                    responsive: true,
                    title: {
                        display: false
                    },
                    layout: {
                        padding: {
                            bottom: 5
                        }
                    },
                    needle: {
                        // Needle circle radius as the percentage of the chart area width
                        radiusPercentage: 2,
                        // Needle width as the percentage of the chart area width
                        widthPercentage: 2,
                        // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
                        lengthPercentage: 60,
                        // The color of the needle
                        color: "#424242"
                    },
                    valueLabel: {
                        //hiding default gauge label
                        display: false,
                        color: "transparent",
                        backgroundColor: "transparent",
                        bottomMarginPercentage: 10,
                        fontSize: 20,
                        padding: {
                            top: 5,
                            bottom: 5
                        }
                        // formatter: (value) => {
                        // return '$' + Math.round(value);
                    }
                }
            };

            nextTick(() => {
                let ctx = document
                    .getElementById(
                        `${this.compareStat ? "compareChart" : "chart"}-${this.id}`
                    )
                    .getContext("2d");
                this.myGauge = new Chart(ctx, config);
            });
        }
    },
    computed: {
        arePropsValid() {
            return ["curatedDeviation", "topGrade", "id" ]
                .every(prop => (this[prop] !== null && this[prop] !== undefined));
        },
        gaugeValue() {
            // in case deviation is larger than the estimated maximum, put the gauge needle in the end
            if (
                this.curatedDeviation > this.gaugeColorPoints[this.topGrade][2]
            ) {
                return this.gaugeColorPoints[this.topGrade][2];
            } else {
                //otherwise usual position
                return this.curatedDeviation;
            }
        },
        gaugeColors() {
            let colors = ["#E5E5E5", "#E5E5E5", "#E5E5E5"];

            if (this.invalid) {
                return colors;
            }

            const deviationBreakPointsLookup = {
                '6': [1.25, 0.65],
                '10': [3.5, 1.5],
            }

            if (deviationBreakPointsLookup[this.topGrade]) {
                if (this.deviation > deviationBreakPointsLookup[this.topGrade][0]) {
                    this.deviationText = "dashboard.dev-bad";

                    colors[2] = "#F44336";
                } else if (this.deviation > deviationBreakPointsLookup[this.topGrade][1]) {
                    this.deviationText = "dashboard.dev-normal";

                    colors[1] = "#FFA107";
                } else {
                    this.deviationText = "dashboard.dev-good";

                    colors[0] = "#4CAF50";
                }
            } else {
                le('unsupported topGrade value, allowed are: 6, 10. Actual:', this.topGrade);
            }

            return colors;
        },
        curatedDeviation() {
            if ((this.invalid === true) || !isValidNr(this.deviation)) {
                return 0;
            } else {
                return this.deviation.toFixed(2);
            }
        },
        curatedChange() {
            if ((this.invalid === true) || !isValidNr(this.change)) {
                return null;
            } else {
                return this.change < 0
                    ? (this.change * -1).toFixed(2)
                    : this.change.toFixed(2);
            }
        }
    },
    watch: {
        $props: {
          handler() {
            if (this.arePropsValid) {
                this.generateGauge();
            }
          },
          deep: true,
          immediate: true,
        },
    },
};
</script>

<style>

</style>
