<template>
    <!-- custom alert element since vuetify's alert doesn't wrap text  -->
    <div v-if="type" class="myAlert" :class="type + '-alert'">

        <slot name="customIcon"></slot>
        <v-icon v-if="!iconless" class="mr-3" size="large" :color="icons[type].color">{{icons[type].icon}}</v-icon>
        <div>
            <div v-if="title" class="mb-1">
                <strong>{{ title }}</strong>
            </div>
            <div v-if="message">
                {{ message }}
            </div>
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ["type", "title","message", "iconless"],
    components: {},
    data: () => ({
        icons: {
            error: {
                color: "red",
                icon: "mdi-alert",
            },
            success: {
                color: "green",
                icon: "mdi-check",
            },
             warning: {
                    color: "orange-darken-1",
                icon: "mdi-alert",
            },
              info: {
                    color: "primary",
                icon: "mdi-information",
            }
        },
    }),
    methods: {},
    computed: {

    },
};
</script>

<style scoped>
.myAlert {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px 10px;
    margin-top: 6px;
     margin-bottom: 6px;
}

.error-alert {
    border: 1px solid rgb(239, 71, 71);
    background-color: rgb(255, 235, 235);
    color: rgb(239, 71, 71);
}

.warning-alert {
    border: 1px solid #FB8C00;
    background-color: rgb(255, 247, 235);
    color: #FB8C00
}

.success-alert {
    border: 1px solid rgb(52, 177, 30);
    background-color: rgb(229, 253, 225);
    color: rgb(52, 177, 30);
}

.info-alert {
    border: 1px solid #2196F3;
    background-color: #eaf6ff;
    color: #2196F3;
}

</style>
