let env;
try {
    if (!import.meta.env) {
        throw new Error('meta.env is empty, trying node.js mode...');
    }

    env = import.meta.env;
} catch {
    env = process.env;
}

const LANGUAGES = freezeInDev({
    EN: 'en',
    DE: 'de',
    FR: 'fr',
    IT: 'it',
    PT: 'pt',
    SK: 'sk',
});

const LANG_LOCALES = freezeInDev({
    EN_GB: 'en-GB',
    DE_CH: 'de-CH',
    FR: 'fr',
    IT: 'it',
    PT: 'pt',
    SK: 'sk',
});

const LANG_LOCALES_ARRAY = Object.values(LANG_LOCALES);

let localStorage_DEFAULT_LOCALE_KEY;
try {
    localStorage_DEFAULT_LOCALE_KEY = localStorage.getItem(DEFAULT_LOCALE_KEY);
} catch { }


const DEFAULT_LOCALE_KEY = 'defaultLocale';
const DEFAULT_LOCALE = localStorage_DEFAULT_LOCALE_KEY || getSpecificLocale(env.VITE_I18N_LOCALE) || LANG_LOCALES.EN_GB;
const DEFAULT_LANGUAGE = getGenericLocale(DEFAULT_LOCALE);
const FALLBACK_LOCALE = getSpecificLocale(env.VITE_I18N_FALLBACK_LOCALE) || LANG_LOCALES.EN_GB;
const FALLBACK_LANGUAGE = getGenericLocale(FALLBACK_LOCALE);
const VIDEO_LOCALES = [ LANG_LOCALES.EN_GB, LANG_LOCALES.DE_CH, LANG_LOCALES.FR ];

function freezeInDev(obj) {
    try {
        var isDev = window.$devMode;
    } catch {
        var isDev = env.NODE_ENV === 'development';
    }

    if (isDev) {
        return Object.freeze(obj);
    } else {
        return obj;
    }
}

function getSpecificLocale(locale) {
    if (!locale) {
        try {
            window.le('locale had no value, locale ===', locale);
        } catch {
            console.error('locale had no value, locale ===', locale);
        }

        return '';
    }

    return locale === LANGUAGES.EN
        ? LANG_LOCALES.EN_GB
        : locale === LANGUAGES.DE
            ? LANG_LOCALES.DE_CH
            : locale;
};

function getGenericLocale(locale) {
    if (!locale || (typeof locale !== 'string')) return '';

    return locale.split('-')[0];
};

export default {
    LANGUAGES,
    LANG_LOCALES,
    LANG_LOCALES_ARRAY,
    DEFAULT_LOCALE_KEY,
    DEFAULT_LOCALE,
    DEFAULT_LANGUAGE,
    FALLBACK_LOCALE,
    FALLBACK_LANGUAGE,
    VIDEO_LOCALES,
    getSpecificLocale,
    getGenericLocale
};