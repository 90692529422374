<template>
    <v-dialog v-model="dialogState" max-width="400">
        <v-card>
            <v-toolbar flat color="grey-lighten-5">
                <v-toolbar-title>{{ $t('updateLanguage') }}</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn @click="$emit('dialog', false)" icon variant="text"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
                <!-- TODO: V3 end > for language change... -->
                <v-select :items="supportedLangs" v-model="newLang" class="my-3" :menu-props="{ bottom: true, offsetY: true }">
                    <template #item="{ props }">
                        <v-list v-bind="props">
                            <v-list-item >
                                <Flag :locale="props.title" class="langFlag" />
                                {{ getGenericLocale(props.title).toUpperCase() }}
                            </v-list-item>
                        </v-list>
                    </template>
                    <template #selection="{ item }">
                        <div class="d-flex align-center" >
                            <Flag :locale="item.value" class="langFlag" />
                            <v-spacer />
                            {{ getGenericLocale(item.value).toUpperCase() }}
                        </div>
                    </template>
                </v-select>

                <v-alert v-if="error" type="error" variant="outlined" class="mb-4">
                    {{ error }}
                </v-alert>
                <v-btn :disabled="loading" size="large" @click="updateLang" color="primary"
                    >{{ $t("buttons.save") }}
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { updateUserDetails } from "@/api/users.api";
import Flag from "@/components/QuestionEditor/Flag.vue";
import { ref, computed, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import globalFunctions from "@/utils/globalFunctions";
import { getGenericLocale, getSpecificLocale, setLanguageLocale } from "@/utils/languageHelpers";
import { useSnackbarStore } from "@/store/snackbar.store";
import { useAuthStore } from "@/store/auth.store";
import { useAppStore } from "@/store/app.store";

export default {
    props: ["user", "dialog", "isMyProfile"],
    components: { Flag },
    setup(props, { emit }) {
        const newLang = ref(null);
        const loading = ref(false);
        const error = ref(null);
        const { t } = useI18n();
        const snackbarStore = useSnackbarStore();
        const authStore = useAuthStore();
        const appStore = useAppStore();

        const updateLang = () => {
            globalFunctions.piwikAnalytics([
                'trackEvent',
                'User management actions',
                'Updated language via Profile',
                newLang.value
            ]);
            error.value = null;
            loading.value = true;
            let updatedUser = { ...props.user};
            updatedUser.language = newLang.value;

            updateUserDetails(props.user.id, updatedUser)
                .then((resp) => {
                    authStore.updateLanguage(resp?.data?.language); 
                    snackbarStore.showSnackbar([true, "detailsUpdated"]);

                    if (resp?.data?.language) {
                        setLanguageLocale(getSpecificLocale(resp?.data?.language));
                    }
                    
                    emit("dialog", false);
                    emit("refresh");
                })
                .catch((error) => {
                    error.value = globalFunctions.parseError(error);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const setFields = () => {
            error.value = null;

            if (props.user.language) {
                newLang.value = props.user.language
            } else {
                newLang.value = null;
            }
        };

        const dialogState = computed({
            get: () => {
                return props.dialog;
            },
            set: (val) => {
                emit("dialog", val);
            },
        });

        const supportedLangs = computed(() => {
            return appStore.supportedLangs.map(getGenericLocale);
        });

        watch(
            () => props.dialog,
            (val) => {
                if (val) {
                    setFields();
                }
            },
        );

        onMounted(setFields); // Intentionally placed at the bottom

        return {
            dialogState,
            error,
            getGenericLocale,
            newLang,
            loading,
            supportedLangs,
            updateLang,
            t,
        };
    },
};
</script>

<style scoped>
.langFlag {
    margin-right: 6px;
    margin-top: -2px;
}
</style>
