const EMAIL_PATTERN =
    /^$|^(([a-zA-Z0-9_\-.+]+)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailRules = (t) => [
    (v) => !!v || t("tooltips.required"),
    (v) => {
        return (
               v === null
            || v === undefined
            || EMAIL_PATTERN.test(v)
            || t("errors.incorrectEmail")
        );
    },
];

const pwRules = (t) => [
    (v) => !!v || t("tooltips.required"),
    (v) => v.length >= 7 || t("passwordLengthError"),
];


const numberRules = (t)=> [
    (v) => /^[0-9]*$/.test(v) || t("tfaInvalid"),
];

const fullNumberRules = (t) => [
    (v) =>
        /^(\+|00)[0-9]+$|^[0-9]*$/.test(v?.trim()) || t("tfaInvalid"),
];

const swissNumberRules = (t) => [
    (v) => /^[^0]/.test(v) || t("tfaRemove0"),
    (v) => (v && v.length <= 9) || t("tfaInvalid"),
];

const required = (t, msg) => [
    (v) => (v === 0) || !!v || t(msg || "tooltips.required"),
];
const nameRules = (t) => [
    (v) => !!v || t("tooltips.required"),
    (v) => (v && v.length < 40) || "Too long",
];

export {
    emailRules,
    pwRules,
    numberRules,
    fullNumberRules,
    swissNumberRules,
    required,
    nameRules,
};
