<template>
    <!-- hover tooltip used in tag dashboard, discussion stats and within discussion mode -->
    <v-tooltip
        location="bottom"
        :max-width="$vuetify.display.xs ? '100vw' : 500"
        content-class="bg-transparent"
        v-if="topGrade"
    >
        <template #activator="{props }">
            <span v-bind="props">
                <slot name="trigger"> </slot>
            </span>
        </template>

        <v-card color="grey-lighten-4" class="elevation-8">
            <v-card-text class="text-body-1">
                <span class="text-bold pr-1"
                    >Ø {{ roundUp(stats.average) }}.</span
                >

                <span v-if="generalAverage">
                    {{ $t("avgGeneralExplanation") }}
                </span>

                <span v-if="questionAverage">
                    {{ $t("stats.avg1") }}
                </span>

                <div
                    v-if="showChange"
                    class="mt-2"
                >
                    <i18n-t keypath="stats.avg2">
                        <span
                            :class="avgChange > 0 ? 'text-green' : 'text-red'"
                            >{{
                                avgChange > 0
                                    ? $t("stats.increased")
                                    : $t("stats.decreased")
                            }}</span
                        >

                        <span>{{
                            avgChange < 0 ? avgChange * -1 : avgChange
                        }}</span>
                    </i18n-t>
                </div>

                <div class="mt-3">
                    {{ $t("stats.graphIntro") }}
                    <ScaleGraph :mode="'avg'" />
                </div>
            </v-card-text>
        </v-card>
    </v-tooltip>
</template>

<script>
import ScaleGraph from "@/components/ScaleGraph";
import { useAppStore } from "@/store/app.store";
import { roundUp, calculateAvgChange } from "@/utils/dashboardCode.js";
import { mapStores } from "pinia";
export default {
    props: ["stats", "generalAverage", "questionAverage", "hideChange"],
    components: { ScaleGraph },
    data: () => ({}),
    methods: {
        roundUp(val) {
            return roundUp(val);
        },
    },
    computed: {
        ...mapStores(useAppStore),

        topGrade() {
            return this.appStore.topGrade;
        },
        avgChange() {
            return calculateAvgChange(this.topGrade, this.stats.changeAverage);
        },
        showChange(){
          return  !this.hideChange && this.stats.hasOwnProperty('changeAverage') && this.stats.changeAverage !== null && this.stats.changeAverage !== 0
        }
    },
};
</script>
