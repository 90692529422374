<template>
    <!-- /discussion -->
    <div style="max-width: 1100px">
        <ModalVideo
            :isVideoDialogVisible="videoDialog2"
            @update:isVideoDialogVisible="videoDialog2 = $event"
            :url="tutorialUrlData"
            @update:onend="onend($event)"
        ></ModalVideo>

        <Alert v-if="errorMessage" :type="'error'" :message="errorMessage" />

        <Loader v-if="loading" />


        <div v-else>
            <div
                v-if="Array.isArray(allDiscussions) && allDiscussions.length > 0"
            >
                <!-- banner -->
                <div
                    class="header-banner pattern-bg mt-0 mt-sm-6"
                    style="min-height: 150px; position: relative"
                >
                    <v-card-text class="pa-5">
                        <div class="d-flex flex-wrap">
                            <h1 class="dash-h1">
                                {{ $t("nav.discussion") }}
                            </h1>

                            <v-spacer></v-spacer>
                            <v-btn
                                size="small"
                                v-if="validSurvey"
                                variant="text"
                                class=""
                                @click="fetchSlides"
                                :loading="fetchingSlides"
                                theme="dark"
                                color="primary"
                                :title="$t('report.downloadSummary')"
                                ><v-icon start size="small">mdi-download</v-icon>
                                {{ $t("report.presentation") }}
                            </v-btn>
                        </div>

                        <div
                            class="banner-text mt-6"
                            style="font-size: 1.15rem"
                        >
                            <v-row>
                                <v-col>
                                    <div class="mr-2">
                                        {{
                                            isAdmin
                                                ? $t("manageSurvey")
                                                : $t("questionEditor.survey")
                                        }}
                                    </div>

                                    <v-select
                                        class="surveySelector"
                                        variant="solo"
                                        flat
                                        :item-title="surveyText"
                                        v-model="surveyId"
                                        @update:model-value="filterSurveys"
                                        bg-color="#E9F5FF"
                                        :items="sortedDiscussions"
                                        item-value="surveyId"
                                        :label="$t('selectSurvey.placeholder')"
                                        hide-details
                                    >
                                        <template
                                            #prepend-inner
                                            v-if="$vuetify.display.smAndUp"
                                        >
                                            <v-icon
                                                color="#00458D"
                                                style="margin-right: 2px"
                                                >mdi-calendar-arrow-right</v-icon
                                            >
                                        </template>

                                        <template #selection="{ item }">
                                            <span class="banner-text">
                                                {{ surveyText(item.raw) }}
                                            </span>
                                        </template>
                                        <!-- TODO VUE-3: END -->
                                        <template #item="{ props }">
                                            <v-list-item v-bind="props">
                                            </v-list-item>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col>
                                    <div class="mx-2">
                                        {{ $t("discussionFor") }}
                                    </div>

                                    <div>
                                        <v-autocomplete
                                            bg-color="#E9F5FF"
                                            variant="solo"
                                            flat
                                            v-model="selectedTeam"
                                            @update:model-value="filterSurveys"
                                            :items="availableTeams"
                                            :label="
                                                $t('selectSurvey.selectTeam')
                                            "
                                            hide-details
                                            ><template
                                                #prepend-inner
                                                v-if="
                                                    $vuetify.display.smAndUp
                                                "
                                            >
                                                <v-icon
                                                    color="#00458D"
                                                    style="margin-right: 2px"
                                                    >mdi-account-group</v-icon
                                                >
                                            </template>
                                            <template #no-data>
                                                <v-list-item>
                                                    <v-list-item-title>
                                                        {{
                                                            $t(
                                                                "selectSurvey.noTeam"
                                                            )
                                                        }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </template></v-autocomplete
                                        >
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </div>
                <!-- /banner -->

                <v-row no-gutters class="mt-2" v-if="validSurvey">
                    <v-col cols="12" md="8" lg="9">
                        <Questions
                            :highLightQuestions="highLightQuestions"
                            :discussionId="discussionId"
                            :totalFeedForwards="totalFeedForwards"
                            :stats="discussionSummary"
                            :toDos="toDos"
                            :discussionDone="discussionDone"
                        />
                    </v-col>
                    <v-col
                        cols="4"
                        lg="3"
                        class="pl-2"
                        v-if="$vuetify.display.mdAndUp"
                    >
                        <v-card
                            class="slide-in"
                            style="background-color: #fff8b9"
                            flat
                            border
                            :key="discussionId"
                        >
                            <!-- FF kitty -->
                            <div
                                class="pos-relative"
                                style="
                                    overflow: hidden;
                                    background-color: #fffeee;
                                "
                            >
                                <div
                                    class="d-flex align-center"
                                    style="width: 100%"
                                >
                                    <div
                                        class="pa-3"
                                        style="
                                            width: 70%;
                                            min-width: 70%;
                                            z-index: 2;
                                        "
                                    >
                                        <div>
                                            <div v-if="totalFeedForwards === 0">
                                                <i18n-t keypath="thisTimeNoFF">
                                                    <strong
                                                        >{{
                                                            $t(
                                                                "dashboard.noFFs"
                                                            )[0].toLowerCase() +
                                                            $t(
                                                                "dashboard.noFFs"
                                                            ).substring(1)
                                                        }}
                                                    </strong>
                                                </i18n-t>
                                            </div>

                                            <div v-if="totalFeedForwards > 0">
                                                <div v-if="FFhandleRate < 100">
                                                    {{ $t("bravo") }},
                                                    <br />
                                                    <strong
                                                        class="text-purple"
                                                        style="
                                                            font-size: 1.2rem;
                                                        "
                                                        >{{
                                                            totalFeedForwards
                                                        }}
                                                        {{
                                                            $t(
                                                                "discussionMode.feedforwards"
                                                            )
                                                        }}</strong
                                                    ><br />{{
                                                        $t("ffFromTeam")
                                                    }}!
                                                </div>

                                                <div
                                                    v-if="FFhandleRate === 100"
                                                >
                                                    <i18n-t
                                                        v-if="
                                                            totalFeedForwards >
                                                            1
                                                        "
                                                        keypath="allDiscussedNr"
                                                    >
                                                        <strong
                                                            class="text-purple"
                                                            style="
                                                                font-size: 1.2rem;
                                                            "
                                                            >{{
                                                                totalFeedForwards
                                                            }}
                                                            {{
                                                                $t(
                                                                    "discussionMode.feedforwards"
                                                                )
                                                            }}</strong
                                                        >
                                                    </i18n-t>

                                                    <span v-else
                                                        >{{
                                                            $t("allDiscussed")
                                                        }}
                                                    </span>
                                                </div>

                                                <v-progress-linear
                                                    style="border-radius: 5px"
                                                    v-model="FFhandleRate"
                                                    color="purple-lighten-1"
                                                    height="30"
                                                    :bg-opacity="0.3"
                                                    :bg-color="'purple lighten-1'"
                                                    class="mt-2"
                                                >
                                                    <template #default>
                                                        <span
                                                            class="text-caption"
                                                            >{{
                                                                discussionSummary.taskedFeedForwards +
                                                                discussionSummary.closedFeedForwards
                                                            }}/{{
                                                                totalFeedForwards
                                                            }}
                                                            <span
                                                                >{{
                                                                    $t(
                                                                        "buttons.markDone"
                                                                    ).toLowerCase()
                                                                }}
                                                            </span></span
                                                        >
                                                    </template>
                                                </v-progress-linear>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- image placeholder -->
                                    <div style="width: 30%">
                                        <img
                                            v-if="
                                                FFpicture && FFhandleRate < 100
                                            "
                                            style="width: 100%; height: auto"
                                            :src="`/images/${FFpicture}.png`"
                                        />

                                        <img
                                            :style="`width: ${
                                                $vuetify.display.mdAndUp
                                                    ? 150
                                                    : 100
                                            }px`"
                                            style="
                                                z-index: 1;
                                                height: auto;
                                                position: absolute;
                                                right: -40px;
                                                transform: translateY(-50%);
                                            "
                                            v-if="FFhandleRate === 100"
                                            src="/images/celebrate-slim.png"
                                        />
                                    </div>
                                </div>
                            </div>

                            <!-- tips and tricks -->
                            <div class="pa-3">
                                <div class="d-flex align-center">
                                    <v-img
                                        v-if="FFpicture"
                                        class="slide-in mr-2"
                                        width="100"
                                        max-width="38"
                                        src="/images/bulb-slim.png"
                                    ></v-img>
                                    <div>
                                        <h3>
                                            {{ $t("helpTitle") }}
                                        </h3>
                                        <div style="color: #a07313">
                                            {{ $t("helpText") }}
                                        </div>
                                    </div>
                                </div>

                                <v-container
                                    class="pa-5"
                                    style="font-size: 1.2rem"
                                >
                                    <!-- TL handbuch -->
                                    <div
                                        v-if="canShowHandbuch"
                                        class="d-flex align-center"
                                    >
                                        <div class="mr-2">&#128221;</div>

                                        <div>
                                            {{ $t("handBuchText") }}
                                            <v-btn
                                                @click="showHandbuchDialog"
                                                class="txt-btn"
                                                color="primary"
                                                variant="text"
                                                id="handbuchLink"
                                                >{{ $t("here") }}</v-btn
                                            >
                                        </div>
                                    </div>

                                    <div
                                        class="mt-5 d-flex align-center"
                                        v-if="canShowAskExpert"
                                    >
                                        <div class="mr-2">&#128231;</div>
                                        <AskExpert />
                                    </div>
                                    <div
                                        class="mt-5 d-flex align-center"
                                        v-if="canShowDiscussionVideo"
                                    >
                                        <div class="mr-2">&#128250;</div>
                                        <div>
                                            {{ $t("discussionVideoTitle") }}
                                            <v-btn
                                                @click="showDMVideoDialog"
                                                class="txt-btn"
                                                color="primary"
                                                id="discussionVideoLink"
                                                variant="text"
                                                ><span>
                                                    {{ $t("here") }}</span
                                                ></v-btn
                                            >
                                        </div>
                                    </div>

                                    <div class="mt-5 d-flex align-center">
                                        <div>
                                            <v-img
                                                width="1.6rem"
                                                src="/images/clipboard.png"
                                            ></v-img>
                                        </div>
                                        <div
                                            class="pl-2 todoTip d-flex align-center hover-pointer"
                                        >
                                            <div @click="showTodo = !showTodo">
                                                {{
                                                    showTodo
                                                        ? $t("hideTodo")
                                                        : $t("showTodo")
                                                }}
                                            </div>

                                            <v-btn
                                                class="expandTodoBtn"
                                                :ripple="false"
                                                variant="plain"
                                                @click="showTodo = !showTodo"
                                                icon
                                                >
                                                <v-icon>
                                                    {{ showTodo
                                                        ? "mdi-chevron-up"
                                                        : "mdi-chevron-down"
                                                    }}
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-container>
                            </div>

                            <!-- expanded todo list -->
                            <v-slide-y-transition>
                                <div
                                    v-if="showTodo"
                                    class="mt-n4"
                                    style="background-color: #fffeee"
                                >
                                    <Todo
                                        :discussionId="discussionId"
                                        :selectedTeam="selectedTeam"
                                        @highLightQuestions="highLightQuestions = $event"
                                        :FFhandleRate="FFhandleRate"
                                        @discussionDone="
                                            discussionDone = $event
                                        "
                                        @setTodos="toDos = $event"
                                    />
                                </div>
                            </v-slide-y-transition>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- invalid survey (not enough respondents) -->
                <v-row no-gutters class="mt-2" v-if="validSurvey === false">
                    <v-col>
                        <v-card
                            flat
                            color="transparent"
                            max-width="500"
                            class="mx-auto"
                            :key="discussionId"
                        >
                            <v-card-text
                                class="d-flex align-center"
                                style="font-size: 1.2rem"
                            >
                                <TBicon
                                    :width="160"
                                    :icon="'pen'"
                                    :rating="1"
                                />
                                <div v-if="!selectedTeam">
                                    {{ $t("notEnoughAnswers") }}
                                </div>
                                <i18n-t v-else keypath="notEnoughAnswersTeamName">
                                    {{ selectedTeam }}
                                </i18n-t>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-card
                    flat
                    color="transparent"
                    v-if="!discussionId && !selectedTeam"
                    max-width="400"
                    class="mx-auto"
                >
                    <v-card-text
                        class="d-flex align-center"
                        style="font-size: 1.2rem"
                    >
                        <v-img
                            class="slide-in mr-4"

                            :max-width="100"
                            src="/images/kitty_search.png"
                            style=""
                        ></v-img>
                        <div>
                            {{ $t("pickValidTeam") }}
                        </div>
                    </v-card-text>
                </v-card>
            </div>

            <v-card
                flat
                color="transparent"
                v-else
                max-width="600"
                class="mx-auto"
            >
                <v-card-text
                    class="d-flex align-center"
                    style="font-size: 1.2rem"
                >
                    <v-img
                        class="fade-in mr-4"
                        src="/images/nodata.png"
                        style="max-width: 150px; width: 150px"
                    ></v-img>
                    <div>
                        <p class="text-h5 text-black">
                            {{ $t("selectSurvey.noSurvey") }}
                        </p>
                        <p>{{ $t("selectSurvey.comeBack") }}</p>
                        
                        <!-- TODO VUE 3-END: arrow_back -> mdi-arrow-left  -->
                        <v-btn @click="toDashboard" size="large" color="primary">
                            <v-icon start>mdi-arrow-left</v-icon>
                            {{ $t("buttons.backdash") }}
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <v-dialog
            v-model="handBuchDialog"
            :max-height="
                handBuchSelection || $i18n.locale !== 'de-CH' ? '90vh' : 800
            "
            :max-width="
                handBuchSelection || $i18n.locale !== 'de-CH' ? '90vw' : 600
            "
        >
            <v-card
                :height="
                    handBuchSelection || $i18n.locale !== 'de-CH'
                        ? '90vh'
                        : 'auto'
                "
                :width="
                    handBuchSelection || $i18n.locale !== 'de-CH'
                        ? '90vw'
                        : 'auto'
                "
            >
                <div class="d-flex">
                    <!-- TODO VUE 3-END: arrow_back -> mdi-arrow-left  -->
                    <v-btn
                        v-show="handBuchSelection"
                        variant="text"
                        @click="handBuchSelection = null"
                        class="mt-1 ml-1"
                        ><v-icon start>mdi-arrow-left</v-icon
                        >{{ $t("buttons.back") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn size="large" icon variant="text" @click="handBuchDialog = false"
                        ><v-icon>mdi-close-circle</v-icon></v-btn
                    >
                </div>

                <div
                    v-if="$i18n.locale === 'de-CH'"
                    :style="{
                        height: handBuchSelection || $i18n.locale !== 'de-CH' ? '90vh' : '100%',
                        width: handBuchSelection || $i18n.locale !== 'de-CH' ? '90vw' : '100%',
                        overflow: 'hidden',
                    }"
                >
                    <!-- show buttons offering between a normal handbuch and one for bigger teams -->
                    <v-container v-if="!handBuchSelection" class="text-center">
                        <div class="mb-9">{{ $t("someGuides") }}</div>
                        <v-btn
                            @click="handBuchSelection = 'normal'"
                            variant="flat"
                            rounded
                            theme="dark"
                            color="joineer"
                            size="x-large"
                            class="py-4"
                            >{{ $t("handBuchText") }}
                        </v-btn>
                        <br />
                        <div class="my-5">{{ $t("or") }}</div>
                        <v-btn
                            @click="handBuchSelection = 'large'"
                            variant="flat"
                            rounded
                            theme="dark"
                            color="joineer"
                            size="x-large"
                            class="py-4 mb-4"
                        >
                            <div class="d-flex flex-column">
                                <div>{{ $t("handBuchText") }}</div>
                                <div
                                    class="text-caption text-pink-lighten-4"
                                >
                                    {{ $t("20people") }}
                                </div>
                            </div>
                        </v-btn>
                    </v-container>
                    <iframe
                        v-else
                        style="height: 100%; width: 100%; border: none"
                        :src="`https://tbpics.joineer.com/documents/${
                            handBuchSelection === 'normal'
                                ? 'TL_handbuch_2021'
                                : 'prepguide_large'
                        }_${getGenericLocale($i18n.locale)}.pdf`"
                    >
                    </iframe>
                </div>
                <div v-else style="height: 90vh; width: 90vw; overflow: hidden;">
                    <iframe
                        style="height: 100%; width: 100%; border: none"
                        :src="`https://tbpics.joineer.com/documents/TL_handbuch_2021_${getGenericLocale($i18n.locale)}.pdf`"
                    >
                    </iframe>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="videoDialog" max-width="800">
            <v-card>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn size="large" icon  variant="text" @click="videoDialog = false" class="ma-1"
                        ><v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </div>
                <!-- TODO: V3-end -->
                <v-card-text>
                    <YouTube
                        class="w-100"
                        width="720"
                        :src="videoUrl"
                        ref="youtube"
                        @ready="onReady"
                    ></YouTube>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed, watch, onMounted } from "vue";
    import { useRoute, useRouter } from 'vue-router';
    import globalFunctions from "@/utils/globalFunctions";
    import moment from "moment";
    import { useDisplay } from 'vuetify';
    import { useI18n } from "vue-i18n";
    import * as useI18nAll from "vue-i18n";
    import { saveAs } from "file-saver";
    import {
        getAllDiscussions,
        getTeamSlides,
        getStatistics,
    } from "../api/discussion.api";
    import AskExpert from "@/components/SelectSurvey/AskExpert.vue";

    import Todo from "@/components/SelectSurvey/Todo.vue";
    import Questions from "@/components/SelectSurvey/Questions.vue";
    import ModalVideo from "@/components/global/ModalVideo.vue";
    import { showTutorial, tutorialUrl } from "@/services/tutorial.service.js";
    import { calculateHandleRate } from "@/utils/dashboardCode.js";
    import Loader from "@/components/global/Loader.vue";
    import Alert from "@/components/global/Alert.vue";
    import TBicon from "@/components/global/TBicon.vue";

    import {
        discussionSurvey,
        discussionSummary,
        discussionDisplay,
    } from "@/models/discussion";

    import { VIDEO_LOCALES, getGenericLocale } from "@/utils/languageHelpers";
    import { useSnackbarStore } from "@/store/snackbar.store";
    import { useAuthStore } from "@/store/auth.store";
    import { useAppStore } from "@/store/app.store";


    const router = useRouter();
    const useI18n_All = useI18n(); // TODO: V3 migration check locale.value is working or not
    const { locale } = useI18n_All; // TODO: V3 migration check locale.value is working or not
    const display = useDisplay();
    const name = 'SelectSurvey';
    const autoplay = ref<0 | 1>(1);
    const errorMessage = ref<string | null>(null);
    const loading = ref<boolean>(true);
    const videoDialog2 = ref<boolean>(false);
    const videoElementId = ref<string | null>(null);
    const sortedDiscussions = ref<discussionDisplay[] | undefined>(); //simplified survey item in dropdown
    const surveyId = ref<string>(''); //by default the latest survey
    const selectedTeam = ref<null | string>(null);
    const discussionId = ref<string | null | undefined>(); //specific team survey to proceed with
    const allDiscussions = ref<null | discussionSurvey[]>(null);
    const discussionSummary = ref<null | discussionSummary>(null); // nr of FFs etc
    const totalFeedForwards = ref(0);
    const toDos = ref<any>([]);
    const discussionDone = ref<boolean | null>(null);
    const validSurvey = ref<null | boolean>(null); //whether currently selected survey has enough statistics to count
    const handBuchDialog = ref<boolean>(false);
    const handBuchSelection = ref<null | 'normal' | 'large'>(null); //'large' stands for large team version
    const fetchingSlides = ref<boolean>(false);
    const videoDialog = ref<boolean>(false);
    const highLightQuestions = ref<boolean>(false); //styling variable
    const showTodo = ref<boolean>(false); //whether todo section is expanded
    const dmVideos = {
        'en-GB': "EJVOdeM25NM",
        'de-CH': "i63vwECqSSs",
        fr: "nkEAEsnb_SU",
    };

    const tutrialUrls = ref({
        'de-CH': 'https://tbpics.joineer.com/videos/tutorial/Diskussionsmodus tutorial.mp4',
        'en-GB': 'https://tbpics.joineer.com/videos/tutorial/Discussion Mode Tutorial EN.mp4',
    });
    // TODO: v3-end
    const youtube = ref(null);
    const videoUrl = ref(`https://www.youtube.com/watch?v=${dmVideos[locale.value]}&rel=0&enablejsapi=1&origin=http://localhost:8081&widgetid=3`);
    const snackbarStore = useSnackbarStore();
    const authStore = useAuthStore();
    const appStore = useAppStore();

    onMounted(() => {
        autoplay.value = 1;
        errorMessage.value = null;
        loading.value = true;
        videoElementId.value = 'video_' + new Date().valueOf() + Math.random().toString().substr(2);
        videoDialog2.value = showTutorialData.value;

        getAllDiscussions()
            .then((response) => {
                const data = response.data;
                // stop when there are no surveys yet
                if (!data || data.length === 0) {
                    allDiscussions.value = [];
                    return;
                } else if (data.length > 0) {
                    allDiscussions.value = data.reverse();
                    if (allDiscussions.value) {
                        // make a collection of individual team surveys happening in the same period
                        let surveyIds: any = [];
                        let surveySets: discussionDisplay[] = [];
                        for (let i = 0; i < allDiscussions.value.length; i++) {
                            if (
                                surveyIds.indexOf(
                                    allDiscussions.value[i].surveyId
                                ) === -1
                            ) {
                                surveyIds.push(allDiscussions.value[i].surveyId);
                                surveySets.push({
                                    surveyId: allDiscussions.value[i].surveyId,
                                    startedAt: allDiscussions.value[i].startedAt,
                                    endedAt: allDiscussions.value[i].endedAt,
                                });
                            }
                        }

                        sortedDiscussions.value = surveySets;
                        surveyId.value = sortedDiscussions.value[0].surveyId; //set latest survey to be default option
                        selectedTeam.value = availableTeams.value[0];
                        filterSurveys();
                    }
                }
            })
            .catch((error) => {
                errorMessage.value = globalFunctions.parseError(error);
            })
            .finally(() => (loading.value = false));
    })



    const sortDiscussions = (discussions: discussionSurvey[]) => {
        let sortedDiscussions = [];
        if (!discussions || discussions.length === 0) {
            return sortedDiscussions;
        } else {
            discussions.reverse();
        }
    }

    // get a specific team's discussionId for the given survey
    const filterSurveys = () => {
        if (allDiscussions.value) {
            let discussion = allDiscussions.value.find(
                (discussion) =>
                    discussion.teamName === selectedTeam.value &&
                    discussion.surveyId === surveyId.value
            );

            if (discussion) {
                discussionId.value = discussion.id;
            } else {
                discussionId.value = null;
                selectedTeam.value = null;
            }
        }
    }

    const fetchStats = () => {
        if (discussionId.value) {
            getStatistics(discussionId.value).then((response) => {
                discussionSummary.value = response.data;
                totalFeedForwards.value = response.data.totalFeedForwards;

                if (response.data.statistic == null) {
                    validSurvey.value = false;
                    toDos.value = [];
                    discussionDone.value = null;
                } else {
                    validSurvey.value = true;
                }
            });
        } else {
            discussionSummary.value = null;
            totalFeedForwards.value = 0;
            validSurvey.value = null;
            toDos.value = [];
            discussionDone.value = null;
        }
    }

    const showHandbuchDialog = () => {
        globalFunctions.piwikAnalytics([
            "trackEvent",
            "Discussion actions",
            "Show DM Handbuch",
            locale.value as string,
        ]);
        handBuchDialog.value = true;
    }

    const showDMVideoDialog = () => {
        globalFunctions.piwikAnalytics([
            "trackEvent",
            "Discussion actions",
            "Show DM video",
            locale.value as string,
        ]);
        videoDialog.value = true;
    }

    const onend = (val: boolean) => {
        window.l('in onend', val);
        if (val) {
            videoDialog2.value = false; //hide when finished
        }
    }

    const startPresentation = () => {
        router.push({ path: `/discussion/${discussionId.value}` });
    }

    const fetchSlides = () => {
        fetchingSlides.value = true;
        getTeamSlides(discussionId.value, locale.value as string)
            .then((response) => {
                globalFunctions.piwikAnalytics([
                    "trackEvent",
                    "Discussion actions",
                    "Slides downloaded",
                ]);
                snackbarStore.showSnackbar([true, "downloading"]);
                let fileName =
                    response.headers["content-disposition"].split(
                        "filename="
                    )[1];
                let contentType = response.headers["content-type"];

                saveAs(
                    new Blob([response.data], { type: contentType }),
                    fileName
                );
            })
            .catch((error) => {
                console.error("Error downloading team slides:", error);
                snackbarStore.showSnackbar([true, "downloadError"]);
            })
            .finally(() => {
                fetchingSlides.value = false;
            });
    }

    const toDashboard = () => {
        router.push({name: 'tb'});
    }

    const renderDate = (date) => {
        if (display.mdAndUp.value) {
            //@ts-ignore
            return moment(date).format("ddd DD MMM YYYY");
        } else {
            //@ts-ignore
            return moment(date).format("DD/MM/YY");
        }
    }
    const surveyText = (item) => {
        let startTime = renderDate(item.startedAt);
        let endTime = renderDate(item.endedAt);

        return startTime + " - " + endTime;
    }

    const companyId = computed(() => authStore.companyId); 

    const canShowAskExpert = computed(() => {
        return appStore.checkFeature("ASK_EXPERT")
    })
    const canShowDiscussionVideo = computed(() => {
        window.l('in canShowDiscussionVideo', locale, useI18nAll, useI18n_All, VIDEO_LOCALES);

        return VIDEO_LOCALES.includes(locale.value as string);
    })
    const FFpicture = computed(() => {
        if (
            totalFeedForwards.value !== null &&
            totalFeedForwards.value !== undefined
        ) {
            if (FFhandleRate.value === 100) {
                return "celebrate-slim";
            } else {
                return totalFeedForwards.value === 0 ? "kitty_search" : "gift2";
            }
        } else {
            return null;
        }
    })
    const FFhandleRate = computed(() => {
        if (discussionSummary.value) {
            let handleRate = calculateHandleRate(
                discussionSummary.value.totalFeedForwards,
                discussionSummary.value.closedFeedForwards,
                discussionSummary.value.taskedFeedForwards
            );
            return handleRate;
        } else {
            return 0;
        }
    })
    const canShowHandbuch = computed(() => {
        const handbuchLanguages = ['en', 'en-GB', 'de', 'de-CH', 'fr', 'it'];
        // whether we can show the user our TL handbuch PDF
        return handbuchLanguages.includes(locale.value);
    })
    const userRoles = computed(() => authStore.userRoles); 

    const isAdmin = computed(() => authStore.isAdmin); 

    const availableTeams = computed(() => {
        if (allDiscussions.value && surveyId.value) {
            let teams: string[] = [];
            allDiscussions.value.filter((discussion) => {
                if (
                    // if not already added to teams (unique)
                    teams.indexOf(discussion.teamName) === -1 &&
                    // if this team did a particular survey (same surveyId)
                    discussion.surveyId === surveyId.value
                ) {
                    teams.push(discussion.teamName);
                }
            });
            teams.sort(function (a, b) {
                return (a as string)
                    .toLowerCase()
                    .localeCompare((b as string).toLowerCase());
            });
            return teams;
        } else {
            return [];
        }
    })

    //--- tutorial

    const showTutorialData = computed(() => {
        return showTutorial(name, tutrialUrls.value, locale.value as string);
    })

    const tutorialUrlData = computed(() => {
        return tutorialUrl(tutrialUrls.value, locale.value as string);
    })
    const onReady = () => {
      if (youtube.value) {
        //@ts-ignore
        youtube.value.playVideo();
      }
    };

    watch(discussionId, fetchStats);

    watch(videoDialog, (val) => {
        if (!val) {
            //@ts-ignore
            youtube.value.pauseVideo();
        }
    })
</script>

<style>
.expandTodoBtn::before {
    color: #f7ee97 !important;
}

.v-select.surveySelector .v-select__selections input {
    width: 0 !important;
    min-width: 0 !important;
}

.todoTip {
    border-radius: 4px;
    transition: background-color 0.1s linear;
}

.todoTip:hover {
    background-color: #f7ee97;
}

.dialog_content_class {
    height: 100%;
    width: 100%;
}

.w-100 {
    width: 100%
}
</style>
