<template> 
    <nav class="d-print-none">
        <!-- TODO: VUE 3: END density-->
        <v-app-bar
            density="compact"
            clipped-left
            color="primary"
            class="d-print-none pl-0"
        >
            <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
            <v-toolbar-title
                class="pl-3"
                style="font-size: 20px"
                v-if="$vuetify.display.smAndUp"
            >
                {{ pageTitle }}
                <span v-if="addOnEnabled">| Teambarometer</span>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu offset-y>
                <template #activator="{ props }">
                    <v-btn v-bind="props" :ripple="false" variant="text">
                        <span class="languageLabel">{{
                            language
                        }}</span>
                        <v-icon class="ml-1">mdi-web</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        :class="{ 'active-lang': locale === lang }"
                        @click="
                            locale = lang;
                            changeLanguage(lang);
                        "
                        v-for="lang in languageSelectMenu"
                        :key="lang"
                    >
                        <v-list-item-title>{{
                            getGenericLocale(lang).toUpperCase()
                        }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn variant="text" icon @click="logOut" :title="t('nav.logout')">
                <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" style="height: 100%" color="grey-lighten-4">
            <div class="d-flex align-center pa-3" v-if="user">
                <v-avatar class="mr-2" color="amber" size="30">
                                        <span
                                            class="text-white"
                                            style="font-size: 1rem"
                                        >
                                            {{ $mixin.initials(user) }}
                                        </span>
                </v-avatar>
                <div class="text-caption ml-2">
                    <div>{{ $t("greeting") }}, {{ user.firstName }} {{ user.lastName.replace(/ /g, '&nbsp;') }}</div>
                    <div class="text-grey">
                        {{ renderedRoles }}
                    </div>
                </div>
            </div>

            <v-divider></v-divider>
            <!-- TODO: VUE 3: END density-->
            <v-list
                id="navList"
                v-if="flags && availableMenuItems"
                density="compact"
                class="pt-0"
            >
                <div v-for="item in availableMenuItems" :key="item.title">
                    <v-divider v-if="item.upperSeparator"></v-divider>
                    <v-list-item :to="item.link" :ripple="false">
                        <template #prepend>
                            <v-icon>{{ item.icon }}</v-icon>
                        </template>
                        <v-list-item-title>
                            {{
                                item.title.includes("nav.")
                                    ? t(item.title)
                                    : item.title
                            }}<span class="newLabel" v-if="item.beta">BETA</span>
                            <span class="newLabel" v-if="item.new">
                                {{ t("buttons.new") }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="item.separator"></v-divider>
                </div>

                <v-list-group
                    v-if="isCompanyAdmin"
                    value="Administration"
                    :ripple="false"
                >
                    <template #activator="{ props }">
                        <v-list-item
                            v-bind="props"
                            title="Administration"
                            prepend-icon="mdi-cog"
                        ></v-list-item>
                    </template>
                    <v-list-item v-if="isTemplateEditorEnabled" :to="getEmailTemplatesLink" :ripple="false">
                        <template #prepend>
                            <v-icon class="ml-4">mdi-email-outline</v-icon>
                        </template>
                        <v-list-item-title>{{
                            $t("nav.emailTemplates")
                        }} <span class="newLabel">BETA</span></v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/calendar" :ripple="false">
                        <template #prepend>
                            <v-icon class="ml-4">mdi-calendar</v-icon>
                        </template>
                        <v-list-item-title>{{
                            $t("nav.calendar")
                        }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/qr" :ripple="false">
                        <template #prepend>
                            <v-icon class="ml-4">mdi-qrcode</v-icon>
                        </template>
                        <v-list-item-title>{{
                            $t("nav.qrCodes")
                        }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="!flags?.includes('MASTERFILE_DISABLED')" to="/masterfile" :ripple="false">
                        <template #prepend>
                            <v-icon class="ml-4">mdi-microsoft-excel</v-icon>
                        </template>
                        <v-list-item-title>Masterfile</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/processdatafile" :ripple="false">
                        <template #prepend>
                            <v-icon class="ml-4">mdi-chart-line</v-icon>
                        </template>
                        <v-list-item-title>{{ $t("processdatafile") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/companyadmins" :ripple="false">
                        <template #prepend>
                            <v-icon class="ml-4">mdi-account-cog</v-icon>
                        </template>
                        <v-list-item-title
                            >{{ $t("nav.companyAdmins") }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-img
                class="mt-5"

                src="/LogoJoineer.png"
                height="30px"
            >
            </v-img>


            <div class="versionLabel">
                {{ version }}
            </div>
        </v-navigation-drawer>
    </nav>
</template>

<script setup>
    import { computed, ref, onMounted, watch } from "vue";
    import { useI18n } from "vue-i18n";
    import { useRoute } from "vue-router";
    import { VERSION } from "@/utils/globalconsts.js";
    import { setAvailableMenuItems } from "@/utils/navigationMenu.js";
    import { getGenericLocale, changeLanguage } from '@/utils/languageHelpers';
    import { useAuthStore } from "@/store/auth.store";
    import { useAppStore } from "@/store/app.store";

    const { t, locale } = useI18n();
    const language = computed(()=> getGenericLocale(locale.value));

    const route = useRoute();
    const authStore = useAuthStore();
    const appStore = useAppStore();

    const availableMenuItems = ref(null);
    const version = ref(VERSION);
    const adminMenuExpanded = ref(false);
    const adminRoutes = ["QR", "calendar", "masterfile", "companyadmins"];

    const setMenuItems = () => {
        const items = setAvailableMenuItems(flags.value, userRoles.value);
        availableMenuItems.value = window.$devMode
            ? items
            : items.filter(
                (item) => !item.title.includes("nav.ffanalysis")
            );
    };

    onMounted(() => {
        setMenuItems();
        version.value = VERSION;
        if (adminRoutes.includes(route.name)) {
            adminMenuExpanded.value = true;
        }
    });

    const showReactions = computed(() => {
        return appStore.showReactions;
    });
    const flags = computed(() => {
        return appStore.featureFlags;
    });
    const languageSelectMenu = computed(() => {
        return appStore.supportedLangs;
    });
    const pageTitle = computed(() => {
        return user.value ? appStore.userSettings?.companyName : "";
    });
    const getEmailTemplatesLink = computed(() => {
        return `/email-templates/${authStore.user.companyId}`;
    });
    const isTemplateEditorEnabled = computed(() => {
        return  appStore.checkFeature("TEMPLATE_EDITOR_ENABLED");
    });
    
    const userRoles = computed(() => authStore.userRoles);

    const renderedRoles = computed(() => {
        if (userRoles.value && userRoles.value.length > 0) {
            let role;
            //display user friendly roles
            if (userRoles.value.includes("ROLE_SUPERADMIN")) {
                role = t("roles.superadmin");
            } else if (userRoles.value.includes("ROLE_ADMIN")) {
                role = "Admin";
            } else if (userRoles.value.includes("ROLE_MEMBER")) {
                role = t("roles.member");
            }
            if (userRoles.value.includes("ROLE_ROOT")) {
                role = role.concat(", Root");
            }
            return role;
        } else {
            return "";
        }
    });
    const user = computed(() => {
        return authStore.user;
    });

    const drawer = computed({
        // whether left side navigation menu is visible)
        get: () => {
            return appStore.navDrawer
        },
        set: (newVal) => {
            appStore.showNavDrawer(newVal);
        },
    });
    const addOnEnabled = computed(
        () => !["adhoc-create", "adhoc-dashboard"].includes(route.name)
    );
    const isCompanyAdmin = computed(() => authStore.isCompanyAdmin);

    // watch user feature flags and set menu items when they change
    watch(() => flags.value, setMenuItems, { deep: true });
    watch(
        () => route.name,
        (val) => {
            if (adminRoutes.includes(val)) {
                adminMenuExpanded.value = true;
            }
        }
    );

    const logOut = () => authStore.logout();

    const toggleDrawer = () => appStore.toggleNavDrawer();

    const initials = (user) => {
        if (user && user.firstName && user.lastName) {
            return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
        }
        return "";
    };
</script>

<style scoped>


.v-list-group__items .v-list-item {
    padding-inline-start: 16px !important;
}

</style>

<style>
/* width */
::-webkit-scrollbar {
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(185, 185, 185);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(151, 151, 151);
}

#navList .v-list-item__title {
    line-height: 1.2rem;
}

.active-lang {
    background: rgb(222, 238, 252) !important;
}

.languageLabel {
    font-size: 1.15em;
}

.versionLabel {
    font-size: 0.8rem;
    color: rgb(157, 157, 157);
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
}
</style>
