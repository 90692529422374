import { CALIDA_GROUP_COMPANY_IDS, LUNGENLIGA_GROUP_COMPANY_IDS, POST_GROUP_COMPANY_IDS, TX_GROUP_COMPANY_IDS } from "../customisations/constants";
import { assignToObjectKeys, simpleDeepMerge } from "../utils/object";

export default Object.assign(commonTranslations(), simpleDeepMerge(commonCustomisations(), customisations()));

function commonTranslations() {
    return {
        joineerContact: {
            salesEmail: "info{'@'}joineer.ch",
            salesPhone: "+41\u00A044\u00A0510\u00A038\u00A000",
        },
        addLinks:"Links hinzufügen?",
        whatYouwrite: "Was du schreibst",
        whatIsShown:"Was wird gezeigt",
        anonymValues:"Skalenwerte werden immer anonymisiert erfasst",
        pauseSurvey: "Umfrage pausieren",
        surveyPaused: "Umfrage pausiert",
        surveyStarts: "Umfrage startet",
        events: {
            start: {
                title: "Umfrage startet",
                description: "E-Mail/SMS mit der Einladung zur Teilnahme wird an alle versendet",
            },
            reminder: {
                title: "Erinnerung",
                description: "E-Mail/SMS zur Erinnerung an die Teilnehmer, die noch nicht ausgefüllt haben",
            },
            end: {
                title: "Umfrage endet",
                description: "Letzter Tag zum Ausfüllen. Die Ergebnisse sind am nächsten Tag verfügbar",
            },
            today: "Heute",
            endSurveyNow: "Jetzt beenden",
            confirmation: "Bestätigung",
            endSurveyConfirmationMsg: "Bist du sicher, dass du diese Umfrage beenden möchtest? \n Dies KANN NICHT rückgängig gemacht werden.",
            endSurveyScheduled: "Umfrage beendet, bitte dem System 15-30 Minuten zur Verarbeitung geben.",
        },
        nextDay: "am nächsten Arbeitstag",
        changeTeam: "Team ändern",
        pickNewTeam:
            "Wähle ein neues {0} Team aus der untenstehenden Organisationsstruktur.",
        currentTeam: "Aktuelles Team",
        activeSurveyMoveWarning:
            "Wenn du ein Mitglied während einer aktiven Umfrage verschiebst, werden seine/ihre aktuellen Antworten gelöscht. Nach dem Verschieben muss die Umfrage für das neue Team neu ausgefüllt werden",

        startDateNextSurvey: "Startdatum der nächsten Umfrage",
        interval: {
            label: "Umfrage Intervall",
            text: "Wie oft möchtest du die Umfrage durchführen?",
            everyw: "Jede Woche",
            every2w: "Alle 2 Wochen",
            every3w: "Alle 3 Wochen",
            monthly: "Jeden Monat",
            every6w: "Alle 6 Wochen",
            every2m: "Alle 2 Monate",
            every3m: "Alle 3 Monate",
            every4m: "Alle 4 Monate",
            none: "Manuell",
        },
        rolesTitle: "Rollen",
        roleTitle: "Rolle",
        adminOfTeams: "Admin von",
        addCompanyAdmin: "Firmenadmin hinzufügen",
        tags: "Tags",
        masterfile: "Masterfile",
        masterfileText: "Hilfreiche Tipps und Validierer für das Masterfile: ",
        processdatafile: "Prozessdaten",
        processdatafileDescription: "Hier erhältst du spannende Prozessdaten für deine Organisation. Zudem kannst du die Entwicklung der Prozessdaten über  mehrere Umfragen hinweg verfolgen.",
        processDataMoreInfo: "Mehr Info",
        processDataInfo: {
            title: {
                survey: "Survey",
                surveyEndDate: "Survey End Date",
                avgQuestionPerTeam: "Average Questions per Team",
                participants: "Participants",
                respondents: "Respondents",
                participationRate: "Participation Rate (%)",
                totalFF: "Total FFs",
                transparencyRate: "Transparency Rate (%)",
                FFPerRespondents: "FFs per Respondent per Question",
                handlingRate: "FF Handling Rate (%)",
                tasksCreated: "Tasks Created",
                closingRate: "Closing Rate (%)",
                delegationRate: "Delegation Rate (%)",
            },
            desc: {
                survey: "Zunehmende Reihenfolge.",
                surveyEndDate: "Das Datum, an dem jede Umfrage beendet wurde.",
                avgQuestionPerTeam: "Die durchschnittliche Anzahl der Fragen, die jedem Team in der Umfrage gestellt wurde.",
                participants: "Die Gesamtzahl der Personen, die zur Teilnahme an der Umfrage eingeladen wurden.",
                respondents: "Die Anzahl der Personen, die tatsächlich an der Umfrage teilgenommen haben.",
                participationRate: "Der Prozentsatz der Personen, die die Umfrage abgeschlossen haben im Vergleich zu den eingeladenen Personen.",
                totalFF: "Die Gesamtzahl der Feedforwards (FFs), die in der Umfrage eingegangen sind.",
                transparencyRate: "Der Prozentsatz der nicht-anonymen FFs im Vergleich zur Gesamtzahl der FFs.",
                FFPerRespondents: "Die durchschnittliche Anzahl der FFs, die von einer Person, die an der Umfrage teilgenommen hat, für jede Frage gegeben wurden.",
                handlingRate: "Der prozentuale Anteil der FFs, die als 'besprochen' markiert oder aus denen eine Aufgabe erstellt wurde.",
                tasksCreated: "Die Gesamtzahl der Aufgaben, die erstellt wurden, einschließlich der Aufgaben, die nicht direkt aus den FFs abgeleitet wurden.",
                closingRate: "Der Prozentsatz der Aufgaben, die als 'erledigt' markiert wurden, im Vergleich zur Gesamtzahl der erstellten Aufgaben.",
                delegationRate: "Der Prozentsatz der delegierten Aufgaben, im Vergleich zur Gesamtanzahl der erstellten Aufgaben.",
            },
        },

        profile: {
            name: "Profilname",
            details: "Profildetails",
            activatePassword: "Passwort aktivieren",
            passwordTooltip: "Aktiviere die Passwortanmeldung und deaktiviere die Anmeldung über E-Mail-Link. Dies kann nicht rückgängig gemacht werden.",
            passwordActiveTooltip: "Passwort ist aktiviert.",
            externalAccount: "Externer Account"
        },

        account: {
            myaccount: "Mein Account",
            pendingEmail: "Ausstehende E-Mail"
        },

        myProfile: "Mein Profil",
        teamDetails: "Details zum Team",
        selectUser: "Mitglied auswählen",
        selectUserQR:
            "Wähle einen User aus, um den QR-Code als PDF herunterzuladen",
        userTeamMoveWarning:
            "Durch die Verschiebung in {1} wird {0} aus {2} entfernt.",
        userTeamMoveConfirm:
            "Um die Verschiebung zu bestätigen, klicke erneut auf 'Speichern'.",
        selectExistingUser: "Ein bestehendes Mitglied auswählen",
        addNew: "Neu erstellen",
        addExisting: "Bestehende auswählen",
        errorCreatingUser: "Fehler beim Erstellen eines Mitglieds",
        pleaseEnterOneFollowing:
            "Bitte gib mindestens einen der folgenden Punkte an:",

        createMember: "Mitglied hinzufügen",
        createAdmin: "Admin hinzufügen",
        dialCode: "Vorwahl",
        loadingProfile: "Profil laden",
        firstName: "Vorname",
        lastName: "Nachname",
        updateName: "Namen ändern",
        updateEmail: "E-mail-Adresse ändern",
        updateMobile: "Mobiltelefon ändern",
        errorMobile: "Fehler beim Aktualisieren der Mobiltelefonnummer",
        errorEmail: "Fehler beim Aktualisieren der E-Mail Adresse",
        enterMobileText:
            "Die Nummer sollte mit einer Vorwahl eingegeben werden, z.B. 4178322222",
        updateLanguage: "Sprache ändern",
        deleteProfileConfirmation:
            "Bist du sicher, dass du dieses Profil aus der Organisation löschen möchtest?",
        profileDeleted: "Dieses Profil ist jetzt gelöscht",
        directingToPrevious: "Weiterleitung zur vorherigen Seite",
        deleteProfile: "Profil löschen",
        noDeleteEmailError:
            "E-Mail kann nicht gelöscht werden, da mindestens eine Kontaktmethode registriert sein muss. Bitte füge eine Mobiltelefonnummer zum Profil hinzu, um die E-Mail-Adresse zu löschen.",
        noDeleteMobileError:
            "Die Mobiltelefonnummer kann nicht gelöscht werden, da mindestens eine Kontaktmethode registriert sein muss. Bitte füge eine E-Mail zum Profil hinzu, um die Mobiltelefonnummer zu löschen.",

        entitiesAdministratedBy: "Von {0} verwaltete Teams und Strukturen:",
        someGuides: "Hier sind einige Leitfäden für dich!",
        "20people": "+20 Personen",
        addEntityToCompany: "Zum Unternehmen hinzufügen",
        detailsPage: "Detailseite",
        createEntityText1:
            "Um eine neue {0} Struktur oder ein {1} Team zu erstellen, klicke unter die Stelle, an der es erscheinen soll.",
        createEntityLvl0Text1:
            "Um ein neues {0} Team zu erstellen, klicke auf die {1} Firma.",
        createEntityText2:
            "Nach der Erstellung kannst du auf die Detailseite gehen, um Admins/Mitglieder hinzuzufügen oder  du kannst weiter erstellen.",
        createEntityQuestion: "Was möchtest du erstellen?",
        nameofNewTeam: "Name des neuen Teams",
        nameofNewStructure: "Name der neuen Struktur",
        openAll: "Alle öffnen",
        closeAll: "Alle schliessen",
        mandatoryLangTooltip:
            "Die obligatorische Sprache wird von der Organisation festgelegt",
        noActiveSurveys: "Zurzeit keine aktiven Umfragen",
        teamAdmins: "Team-Admins",
        structureAdmins: "Struktur-Admins",
        structureLabel: "Struktur",
        teamMove: "Team verlegen",
        structureMove: "Struktur verlegen",
        moveText:
            "Klicke auf die Struktur, unter der {0} sein soll. Nicht verfügbare Optionen sind ausgegraut.",
        moveHere: "Hierher verlegen",
        currentLocation: "Aktueller Standort",
        teamRename: "Team umbenennen",
        teamDelete: "Team löschen",
        structureRename: "Struktur umbenennen",
        structureDelete: "Struktur löschen",
        deleteEntityConfirmation: "Bist du sicher, dass du {0} löschen möchtest?",
        structureDeleteInfo:
            "NB: Teams und Strukturen unter {0} werden ebenfalls gelöscht. Mitglieder von gelöschten Teams nehmen nicht an Pulsumfragen teil und werden auf der Seite 'Mitglieder' ohne ein zugewiesenes Team aufgeführt.",
        teamDeleteInfo:
            "NB: Nach der Löschung werden die Mitglieder dieses Teams auf der Seite 'Mitglieder' ohne ein zugewiesenes Team aufgeführt. Teamlose Mitglieder nehmen nicht an Pulsumfragen teil.",
        newName: "Neuer Name",
        cantMoveTopStructure:
            "Eine Struktur der obersten Ebene kann nicht verschoben werden",
        memberRemove: "Mitglied entfernen",
        adminRemove: "Admin entfernen",
        removeInfo:
            "PS: Einmal entfernte, teamlose Mitglieder können auf der Seite 'Mitglieder' gefunden und neu zugewiesen werden.",
        adminRemoveConfirmation:
            "Bist du sicher, dass du {0} als Admin von {1} entfernen möchtest?",
        memberRemoveConfirmation:
            "Bist du sicher, dass du {0} als Mitglied von {1} entfernen möchtest?",

        tfaCountry: "Land",
        tfaInvalid: "Ungültige Nummer",
        tfaRemove0: "Bitte entfernen 0",
        tfaRequired: "Nummer ist erforderlich",
        labelMobilePhone: "Mobiltelefonnummer",
        twoFactorTitle: "Zwei-Faktor-Authentifizierung",
        twoFactorIntro:
            "Damit du nach Abschluss der Umfrage die Resultate deines Teams einsehen kannst, bitten wir dich jetzt die Zwei-Faktor-Authentifizierung zu aktivieren. Gib deine Mobilnummer an, um den Login-Code in Zukunft zu erhalten. Falls du dies jetzt nicht tust, erhältst du keinen Zugriff auf die Auswertung.",
        twoFactorNoNr:
            "Ich gebe meine Nummer nicht an und erhalte keinen Zugriff auf die Resultate.",
        twoFactorNoNrExplanation:
            "Wenn du deine Nummer nicht angibst, kannst du nach Abschluss der Umfrage nicht auf das Ergebnis-Dashboard zugreifen. Deine Nummer wird ausschliesslich zur Authentifizierung verwendet. Wenn du die Nummer jetzt nicht angibst, kannst du deiner vorgesetzten Person deine Nummer später angeben, um die Zwei-Faktor-Authentizierung doch noch für dich zu aktivieren.",
        twoFactorFirst:
            "Diese Information kann nach Eingabe eines Codes, der an dein Mobiltelefon gesendet wird, angezeigt werden.",
        emptyResult: "leeres Ergebnis",
        noTeamsFoundWith: "Keine Teamnamen mit {0} gefunden",
        removeFromTeam: "Mitglied entfernen",
        removeAsAdmin: "Admin entfernen",
        noAdmins: "Keine admins",
        noMembers: "Keine Mitglieder",
        seeDetails: "Details sehen",
        loggingOut: "Ausloggen",
        calculate: "Berechne",
        calculateENPSavgTitle: "Berechnung der durchschnittlichen ENPS",
        calculateENPSavgLabel: "Durchschnittliche ENPS von:",
        calculateENPSavgSelect:
            "Wähle Teams aus, um die durchschnittliche ENPS zu berechnen:",
        pwProtectedMessage:
            "Das Konto ist passwortgeschützt. Bitte melde dich mit deinem Passwort an.",
        passwordError: "Error setting password",
        passwordLengthError: "Das Passwort muss mindestens 7 Zeichen lang sein.",
        passwordMismatch:
            "Das Passwort stimmt nicht überein. Versuchs nochmal bitte!",
        passwordChangeSuccess: "Passwort erfolgreich geändert. {0} einloggen.",
        labelNewPassword: "Neues Passwort",
        labelRepeatNewPassword: "Wiederhole neues Passwort",
        setNewPassword:
            "Bitte setze ein neues Passwort mit mindestens 7 Zeichen ein.",
        getNewPwLink:
            "{0} bekommst du einen neuen Link zum Zurücksetzen des Passworts",
        resetPw: "Passwort zurücksetzen",
        resetPwTokenInvalid: "Ungültiger Link zum Zurücksetzen des Passworts",
        forgotPwTitle: "Passwort vergessen?",
        forgotPwText:
            "Wir werden dir eine E-Mail mit einem Link schicken, um dein Passwort neu zu setzen.",
        fillingOutForTeam: "Du füllst für {0} aus.",
        orContinueWith: "weiter mit",
        level: "Stufe",
        showTodo: "Meine To-Do-Liste anzeigen",
        hideTodo: "Meine To-Do-Liste ausblenden",
        statistics: "Statistik",
        commentTooLong: "Die maximale Kommentarlänge beträgt 280 Zeichen",
        allDiscussedNr: "Super, ihr habe alle {0} erledigt!",
        allDiscussed: "Super, ihr habe alle Feedforwards erledigt!",
        notEnoughAnswers:
            "Diesmal haben nicht genügend Mitglieder die Umfrage ausgefüllt",
        notEnoughAnswersTeamName:
            "Diesmal haben nicht genügend Mitglieder vom Team {0} die Umfrage ausgefüllt",
        startPrep: "Nutze die Team-Inputs und beginne mit der Vorbereitung",
        expandQuestionComments: "Erweitere die Frage, um Kommentare zu sehen",
        pickValidTeam: "Wähle ein Team, das an der Umfrage teilgenommen hat",
        devGeneralExplanation:
            "Dies ist die Standardabweichung aller Bewertungen in der Umfrage. Eine geringere Zahl bedeutet, dass die Teammitglieder ähnliche Meinungen haben. Eine höhere Zahl bedeutet, dass das Team wahrscheinlich ein paar Themen diskutieren sollte, bei denen die Meinungen auseinandergehen.",
        avgGeneralExplanation:
            "Dies ist der Durchschnitt für alle Fragen in der Umfrage. Ein kleinerer Durchschnitt bedeutet, dass es mehr Potenzial für Verbesserungen gibt",
        signInWith: "Einloggen mit ",
        whatsNextTitle: "Nächste Schritte?",
        whatsNextText: "Schritt für Schritt zum Ziel mit {0}",
        viewDiscussion: "Diskussion ansehen",
        startDiscussion: "Diskussion starten",
        manageSurvey: "Umfrage verwalten",
        discussionFor: "Diskussion für",
        helpTitle: "Tipps & Tricks",
        helpText:
            "Hilfestellungen für die Diskussion der Resultate findest du hier",
        finishSurvey: "Umfrage beenden",
        reviewResults: "Diskussion vorbereiten",
        reviewResultsHint:
            "Überprüfe die Antworten und sortiere die Fragen für die Diskussion",
        scheduleMeeting: "Teammeeting terminieren",
        scheduleMeetingHint:
            "Organisiere ein Teammeeting (ca. 45 min) ausserhalb dieser App für die Diskussion",
        doDiscussion: "Resultate im Team besprechen",
        doDiscussionHint:
            "Klicke oben auf der Seite 'Diskussion starten'. Besprich die Feedforwards im Team und leite Aufgaben ab, wo nötig",
        resultText1:
            "Ordne die Reihenfolge der Fragen für die Diskussion nach ihrer Wichtigkeit.",
        resultText2: "Klicke und ziehe dafür das {0} Symbol.",
        handBuchText: "Handbuch zur Vorbereitung",
        thisTimeNoFF: "Diesmal gab es {0} von deinem Team",
        bravo: "Bravo",
        wow: "Wow",
        ffFromTeam: "von deinem Team",
        discussionCompletedPraise: "Super, ihr habe alle X Feedforwards erledigt!",
        showStats: "Statistik anzeigen",
        hideStats: "Statistiken ausblenden",
        questionLegend: "Legende zu den Fragen",
        orderExplanation:
            "Die Fragen werden im Diskussionsmodus in dieser Reihenfolge gestellt",
        avgExplanation:
            "Die Farbkodierung zeigt an, wie gut die Teammitglieder diese Frage bewertet haben. Je grösser die Zahl, desto besser!",
        devExplanation:
            "Farbcodierung zur Darstellung von Meinungsverschiedenheiten im Team. Eine geringere Zahl bedeutet, dass die Teammitglieder ähnliche Meinungen haben. Eine höhere Zahl bedeutet, dass das Team dieses Thema diskutieren sollte.",
        nrFFs: "Anzahl der  Feedforwards",
        nrFFExplanation:
            "Anzahl der von den Teammitgliedern hinterlassenen Kommentare zu dieser Frage",

        adminLvls: "Verwaltungsstufe",
        profession: "Beruf",
        gender: "Geschlecht",
        office: "Büro",
        location: "Standort",
        age: "Alter",
        keyboardInfoMessage:
            "Du kannst dich mit den Zahlen-Tasten auf deiner Tastatur durch die Umfrage klicken.",
        editAnswers: "Antworten editieren",
        activeSurvey: "Aktive Umfrage",
        activeSurveys: "Aktive Umfragen",
        surveyTeamLoadError: "Teamliste kann nicht angezeigt werden: {0}",
        peopleWereInvitedFrom: "{0} Personen {1} wurden eingeladen",
        fromEntity: "aus {0}",
        fromWholeCompany: "aus der gesamten Firma",
        personFilledOut: "1 Person hat die Umfrage ausgefüllt",
        peopleFilledOut: "{0} Personen haben die Umfrage ausgefüllt",
        discussionVideoTitle: "Team Impuls Video",
        gdpr01: "Einverständniserklärung",
        gdpr02: "Danke, dass du Joineer benutzt. Um den Datenschutzbestimmungen gerecht zu werden, möchten wir dich darüber informieren, dass wir neben deinem Namen und deiner E-Mail-Adresse die folgenden Informationen erfassen:",
        gdpr03: "• Anonyme quantitative Antworten auf die Fragen des Teambarometers.",

        gdpr04: "• Wahlweise anonyme oder öffentlich gegebene qualitative Kommentare.",
        gdpr04pub: "• Qualitative Kommentare.",

        // not shown when company settings.anonym = true
        gdpr05: "Die gegebenen Antworten werden von deinen Teammitgliedern, dem Team- und Firmen-Administrator gesehen.",

        gdpr06:
            "Alle Daten werden auf Servern in der Schweiz gespeichert" +
            " und nicht an Dritte weitergegeben.",

        // not shown when company settings.anonym = true
        gdpr07: "Deine persönlichen Daten kannst du über den Link zu dieser App jederzeit abrufen.",

        gdpr08: "Wir bitten dich, dies zu beachten und diesen Bedingungen zuzustimmen.",
        gdprLinkMessage: "", //For more information click {link}
        gdprLinkTitle: "", //here
        gdprLinkUrl: "", //https://www.app.com
        gdpr09: "Wenn du Fragen hast, kannst du uns gerne kontaktieren: info{'@'}joineer.ch.",
        gdpr10: "Vielen Dank für dein Vertrauen!",
        gdpr11: "Beste Grüsse",
        gdpr12: "Joineer",
        gdprIgiveMyConsent: "Ich gebe meine Zustimmung",
        introTitle1: "Willkommen beim Teambarometer",
        introTitle2: "Deine Meinung zählt - und zwar konstant",
        introTitle3: "FeedFORWARD – kein Feedback",
        introTitle4: "Ausfüllen - Besprechen - Profitieren",
        introTitle5: "Startklar?",
        introText1:
            "Wir freuen uns sehr über deine Teilnahme - denn deine Meinung zählt." +
            " Bitte lies darum die folgenden Seiten genau durch.",
        introText2:
            "Mit dem Teambarometer wirst du mehrmals im Jahr - pulsartig - nach deiner Meinung gefragt." +
            " So wird die Stimmung über die Zeit gemessen und sind die Verbesserungen entsprechend vergleichbar.",
        introText3:
            "Wir sind kein Kummerkasten. Kritisiere nicht, was in Vergangenheit falsch lief," +
            " sondern teile mit, wie man es in Zukunft besser machen kann. Zukunftsgerichtet," +
            " positiv und lösungsorientiert - Feedforward eben!",
        introText4:
            "Nach der Umfrage werden die Inputs auf dem Dashboard dargestellt." +
            " Das Wichtigste ist, dass ihr die Resultate im Team besprecht!" +
            " Nur so entfaltet der Teambarometer seine Wirkung.",
        introText5L:
            "Alles verstanden? Melde dich bei Fragen ungeniert an uns." +
            " Logge dich auf der nächsten Seite mit deiner E-Mail-Adresesn und" +
            " deinem Passwort ein und lege los!",
        introText5:
            "Alles verstanden? Wende dich bei Fragen ungeniert an uns unter" +
            " info{'@'}joineer.ch. Jetzt geht es los!",
        introSkip: "Intro überspringen",
        surveyToFill: "Umfrage zum Ausfüllen",
        hi: "Hallo",
        surveyStartTitle: "Hallo {0}, schön dass du hier bist.",
        startGoMessage:
            "Teile mit, was in Zukunft besser gemacht werden kann - positiv und lösungsorientiert mit Feedforward.",
        startGoMessage2:
            "Die Resultate werden auf dem Dashboard präsentiert und in den Teams besprochen.",
        pulseSurvey: "Regelmässige Umfrage",
        reference: "Hinweis",
        surveyEndTitle: "Bravo, fast fertig...",
        surveyEndMessage:
            "Hast du alle deine Feedforwards abgegeben? Wenn ja, dann klicke jetzt auf 'Abgeben'. Falls du noch etwas ändern oder weitere Kommentare schreiben möchtest, dann kommst du mit dem 'Zurück' Knopf zu den Fragen.",
        surveyEndLowRatingWarning: "Du hast bei allen Fragen eine 1, also die tiefste Bewertung, abgegeben. Bist du sicher, dass du die Resultate so abschicken willst?",
        startSurvey: "Umfrage starten",
        questionsInSurvey: "Fragen in der Umfrage",
        pickFromCatalog: "Auswahl aus dem Katalog",
        createQuestion: "Eine Frage erstellen",
        sendReminderTooltip: "Eine Erinnerungs-E-Mail senden",
        sendReminders: "{0} Erinnerungen senden",
        sendReminder: "1 Erinnerung senden",
        addMembers: "Mitglieder hinzufügen",
        addMember: "Mitglied hinzufügen",
        addAdmin: "Admin hinzufügen",
        availableSoon: "Bald verfügbar!",
        remind: "Erinnern",
        remindAll: "Alle erinnern",
        respondedQ: "Ausgefüllt?",
        hasNotFilled: "Dieses Mitglied hat die Umfrage nicht ausgefüllt",
        hasFilled: "Dieses Mitglied hat die Umfrage ausgefüllt",
        membersPerPage: "Mitglieder pro Seite",
        yes: "ja",
        no: "nein",
        endSurvey: "Umfrage beenden",
        general: "Allgemein",
        surveysPaused: "Regelmässige Erhebungen werden pausiert",
        noIntervalResults: "Keine Intervall Resultate vorhanden",
        status: "Status",
        results: "Resultate",
        planned: "GEPLANT",
        ongoing: "LAUFEND",
        finished: "BEENDET",
        surveyTitle: "Titel der Umfrage",
        startDate: "Startdatum",
        endDate: "Enddatum",
        participants: "Teilnehmer:innen",
        total: "Total",
        totalParticipants: "Anzahl Teilnehmer:innen",
        page: "Seite",
        previousPage: "Vorherige Seite",
        nextPage: "Nächste Seite",
        or: "oder",
        none: "fehlt",
        today: "heute",
        here: "hier",
        select: "Wählen",

        roles: {
            superadmin: "Firmenadmin",
            admin: "Team-Admin",
            member: "Mitglied",
        },
        greeting: "Willkommen",
        buttons: {
            settings: "Optionen",
            saveChanges: "änderungen speichern",
            reload: "Nachladen",
            filter: "Filter",
            close: "Schliessen",
            surveyGo: "Loslegen",
            surveyEnd: "Abgeben",
            go: "Gehen",
            start: "Starten",
            cancel: "Abbrechen",
            reset: "Zurücksetzen",
            resetAll: "Alle zurücksetzen",
            resetSelection: "Auswahl zurücksetzen",
            back: "Zurück",
            backdash: "Zurück zum Dashboard",
            reloadComponent: "Komponente neu laden",
            next: "Weiter",
            finish: "Beenden",
            finalise: "Fertigstellen",
            save: "Speichern",
            ok: "Okay",
            delete: "Löschen",
            send: "Senden",
            markDone: "Besprochen",
            done: "Erledigt",
            moveUp: "Delegieren",
            newTask: "Aufgabe Erstellen",
            reopen: "Wieder öffnen",
            expand: "Alle erweitern",
            collapse: "Alle minimieren",
            details: "Details",
            new: "Neu",
            edit: "Bearbeiten",
            add: "Hinzufügen",
            addLanguage: "Sprache hinzufügen",
            addQuestion: "Frage hinzufügen",
            create: "Erstellen",
            askExpert: "Den Experten fragen",
            goTo: "Zu den",
            showAllFF: "Alle Feedforward einblenden",
            hideAllFF: "Alle Feedforward ausblenden",
            changeOrder: "Reihenfolge ändern",
            continue: "Weiter",
            yesDelete: "Ja, löschen",
            yesRemove: "Ja, entfernen",
        },
        snackbar: {
            detailsUpdated: "Details aktualisiert",
            remindersSent: "Ausgewählte Erinnerungen gesendet!",
            ffClosed: "Feedforward ist als besprochen markiert",
            ffOpened: "Feedforward wieder öffnen",
            ffEscalated: "Feedforward zur Überprüfung ans Management gesendet",
            taskClosed: "Aufgabe abgeschlossen",
            taskOpened: "Aufgabe wieder öffnen",
            taskCreated: "Aufgabe erstellt",
            taskDeleted: "Aufgabe entfernt",
            taskDelegated: "Aufgabe delegiert",
            emailSent: "E-mail versandt!",
            emailConfirmationSent: "E-Mail-Bestätigung gesendet",
            error: "Fehler",
            warning: "Warnung",
            success: "Erfolg",
            forgotPWemailSent: "E-Mail versandt, wenn Konto vorhanden",
            copiedToClipboard: "In die Zwischenablage kopiert",
            downloading: "Herunterladen...",
            downloadError: "Download-Fehler!",
            questionDeleted: "Frage wurde gelöscht",
            questionAdded: "Neue Frage wurde hinzugefügt",
            questionEdited: "Frage wurde bearbeitet",
            reordered: "Fragen wurden neu geordnet",
            surveyDeleted: "Umfrage wird gelöscht",
            memberAdded: "Mitglied wurde zur Umfrage hinzugefügt",
            memberRemovedFromSurvey: "Mitglied wurde aus der Umfrage entfernt",
            startChanged: "Startdatum der Umfrage wurde geändert",
            endChanged: "Enddatum der Umfrage wurde geändert",
            titleChanged: "Titel der Umfrage wurde geändert",
            memberRemoved: "Mitglied wurde entfernt",
            adminRemoved: "Admin wurde entfernt",
            teamCreated: "Team wurde gegründet",
            structureCreated: "Struktur wurde gegründet",
            teamDeleted: "Team wurde gelöscht",
            structureDeleted: "Struktur wurde gelöscht",
            structureRenamed: "Struktur wurde umbenannt",
            teamRenamed: "Team wurde umbenannt",
            teamMoved: "Team wurde verlegt",
            structureMoved: "Struktur wurde verlegt",
            newMemberCreated: "Neues Mitglied wurde hinzugefügt",
            newAdminCreated: "Neuer Admin wurde hinzugefügt",
            surveyEnded: "Das Ende der Umfrage wurde eingeplant und sie wird in den nächsten 15 Minuten beendet.",
            surveyUpdated: "Umfrage wurde aktualisiert",
            useImportTool: "Um tiefer liegende Strukturen zu erstellen, verwende bitte den Masterfile-Import oder kontaktiere den Joineer Support.",
            feedbackSubmitted: "Dein Feedback hilft uns, uns zu verbessern. Danke!",
        },
        tooltips: {
            editTeamName: "Name bearbeiten",
            deleteTranslations: "{lang}e Übersetzungen löschen",
            discussed: "Feedforward als besprochen markieren",
            createTask: "Aufgabe aus Feedforward erstellen",
            createFreeTask:
                "Aufgabe erstellen, die nicht mit dem Feedforward zusammenhängt",
            closeTask: "Aufgabe als erledigt markieren",
            moveUp: "Aufgabe an Administratoren verschieben",
            deleteTask: "Aufgabe löschen",
            reopenFF: "Feedforward wieder öffnen",
            addComment: "Kommentar hinzufügen",
            discussedDisabled:
                '"Feedforward als besprochen markieren" im Vorschaumodus deaktiviert',
            disabled: "Im Vorschaumodus deaktiviert",
            hideFF: "Feedforward in einem One-on-One besprechen und nicht im Besprechungsmodus anzeigen",
            showFF: "Feedforward im Diskussionsmodus anzeigen",
            required: "Erforderlich",
            pressEnter: "Zum Speichern drücken Sie Enter",
            surveyCanNotBeDeleted: "Nur geplante Umfragen können gelöscht werden.",
        },
        nav: {
            structure: "Organisationsstruktur",
            companyAdmins: "Firmenadmins",
            calendar: "Umfragetermine",
            dashboard: "Dashboard",
            discussion: "Diskussionsmodus",
            reports: "Berichte",
            delegatedTasks: "Delegierte Aufgaben",
            teamTasks: "Team Aufgaben",
            team: "Team",
            members: "Mitglieder",
            interval: "Intervall",
            adhoc: "Adhoc Umfragen",
            questions: "Fragen",
            profile: "Profil",
            logout: "Ausloggen",
            intercomChat: "Chat mit uns!",
            qrCodes: "QR-Briefe",
            tfa: "Zwei-Faktor-Authentifizierung",
            ffanalysis: "FeedForward Analysis",
            feedForwards: "FeedForwards",
            emailTemplates: "Emails"
        },
        selectSurvey: {
            customised: "Benutzerdefiniert",
            choose: "Wählen Sie eine Umfrage, die im Diskussionsmodus angezeigt werden soll",
            now: "jetzt",
            placeholder: "Umfrage auswählen",
            team: "Team",
            selectTeam: "Team auswählen",
            goDiscussion: "In den Diskussionsmodus wechseln",
            selectModeLabel: "Anzeigemodus auswählen",
            preview: "Vorschau-Modus",
            prepare: "Vorbereiten",
            previewBody:
                "Dies ist eine Vorschau des Besprechungsmodus vor der Teambesprechung. Man kann bestimmte Feedforwards ausblenden, falls diese lieber in einer Einzelbesprechung gelöst werden möchten",
            present: "Präsentieren",
            presentBody:
                "Präsentation und Besprechung der Ergebnisse im Team. {0}Feedforwards können in Teamaufgaben umgewandelt werden",
            noTeam: "Kein solches Team",
            noSurvey: "Noch keine Umfragen abgeschlossen",
            comeBack:
                "Komm zurück, um die Ergebnisse zu besprechen, sobald eure erste Umfrage abgeschlossen ist!",
            handbuchLink:
                "Tipps und Tricks für die Besprechung der Resultate im Team findest du {here}",
            questionOrder: "Diskussionsreihenfolge",
            original: "wie in der Umfrage",
            overall: {
                average: "Allgemeiner Durchschnitt",
                deviation: "Allgemeine Standardabweichung",
            },
        },
        discussionMode: {
            question: "Frage",
            survey: "Umfrage",
            anonym: "Anonym",
            noFF: 'Keine Feedforwards, klick "Weiter"',
            noDiscussed:
                "Feedforwards, die als besprochen markiert sind {0}, werden hier angezeigt",
            noTasks:
                "Feedforwards, die in Aufgaben {0} umgewandelt wurden, werden hier angezeigt",
            createTask: "Eine Aufgabe aus Feedforward erstellen",
            feedforwards: "Feedforwards",
            discussed: "Diskutiert",
            tasks: "Aufgaben",
            moveUpTo: "Verschiebe die Aufgabe zu:",
            highlight: "Dieses Feedforward delegieren",
            describe:
                "Beschreibe, warum dies wichtig ist, um weitergegeben zu werden",
            selectLabel: "Zu Handen von:",
            unable: "Keine Lösung im Team gefunden?",
            hiddenFFs:
                "Folgende Feedforwards werden im Diskussionsmodus ausgeblendet. Die Anzahl der ausgeblendeten Feedforwards wird weiterhin angezeigt",
            nrhiddenFFs:
                "Anzahl der Feedforwards, die im One-on-One besprochen werden:",
        },
        achievements: {
            title: "Erfolge",
            intro: "Folgendes wurde bereits erreicht",
        },
        tasks: {
            taskPage: "Aufgabe Seite",
            taskBannerText:"Aufgaben, die aus der Diskussion entstehen",
            createTitle: "Aufgabe erstellen",
            taskDisabled:
                "Das Erstellen einer Aufgabe ist im Vorschaumodus deaktiviert",
            titlePlaceholder: "Titel der Aufgabe",
            title: "Titel",
            commentPlaceholder: "Optionaler Kommentar",
            origin: "Entstanden aus",
            addComment: "Kommentar hinzufügen",
            comments: "Kommentare",
            noComments: "Keine Kommentare",
            dueDate: "Frist",
            dueBy: "Fällig bis",
            setDueDate: "Frist setzen",
            assign: "Aufgabe zuweisen",
            assignedTo: "Zugewiesen an",
            other: "andere",
            enterTitle: "Bitte gib den Titel der Aufgabe ein",
            myTasks: "Meine Aufgaben",
            teamTasks: "Team Aufgaben",
            openTasks: "Offene Aufgaben",
            completedTasks: "Abgeschlossene Aufgaben",
            deleteTitle: "Aufgabe löschen",
            deleteBody: "Möchtest Du diese Aufgabe wirklich löschen?",
            noTasks: "Keine offenen Aufgaben",
            delegatedExplanation:
                "Liste der Feedforwards oder Teamaufgaben, die an die Administratoren delegiert wurden.",
            noCompletedTasks: "Noch keine erledigten Aufgaben",
            noDelegatedTasks: "Noch keine delegierten Aufgaben",
            assigneeRequiredMessage: "Bitte wähle ein Mitglied aus, dem die Aufgabe zugewiesen werden soll",
            sendEmail: "Aufgabenzusammenfassung per E-Mail senden",
            calendarEntry: "Mit Kalendereintrag",
            calendarEntryNo: "Ohne Kalendereintrag",
            commentDeletedBy: "Kommentar gelöscht durch {updatedBy}",
            deleteComment: "Diesen Kommentar löschen?",
            updateComment: "Änderungen speichern",
            edited: "bearbeitet",
            editComment: "Kommentar bearbeiten",
            afterDelete:
                "Nach dem Löschen wird dies im Kommentarverlauf angezeigt:",
        },
        completion: {
            title: "Gut gemacht!",
            tasksCreated: "Aufgaben erstellt",
            ffDiscussed: "Feedforwards diskutiert",
            go: "Zum Taskboard",
        },
        intro: {
            title: "Wähle die Reihenfolge der Fragen",
            intro: "Basierend auf den Ergebnissen empfehlen wir, die Feedforwards in folgender Reihenfolge zu diskutieren. Ziehe {0} am Item, um die Reihenfolge zu ändern",
            start: "Diskussion beginnen",
        },
        login: {
            title: "Login",
            pw: "Passwort",
            forgot: "Passwort vergessen?",

            withLink: " mit direktem Link",
            withPassword: " mit Passwort",
            checkEmail:
                "Falls ein Account mit dieser E-Mail-Adresse existiert, wird dir dein Login Link jetzt zugestellt.",
            incorrectEmail: "Falsche oder fehlende E-Mail",
            labelSendLink: "Link senden",
            labelLinkSent: "Anfrage erfolgreich",

            enterNameAndPassword:
                "Bitte gib deine E-Mail-Adresse und dein Passwort jetzt ein.",
            enterEmail:
                "Bitte gib deine E-Mail-Adresse, um einen direkten Log-in-Link zu erhalten.",
            resetSent:
                "Die E-Mail, um das Passwort zu setzen, wurde an dich gesendet.",
            getLink: "Login-Link abrufen",
            selectYourCompany: "Wählen Sie Ihre Umfrage/Ihr Unternehmen aus"
        },
        admin: {
            title: "Admin Board",
            allTeams: "Alle Teams",
            allSurveys: "Alle Umfragen",
            selectedTeams: "Ausgewählte Teams",
            deselectAll: "Alle abwählen",
            selectAll: "Alle auswählen",
            created: "Erstellt am",
            markDone: "Erledigt",
            done: "Erledigt",
            resolved: "Erledigt",
            latestComment: "Letzter Kommentar",
            lessComments: "weniger Kommentare...",
            moreComments: "mehr Kommentare...",
            empty: "Noch nichts hier",
            deleteConfirmation: "Möchtest du diese dauerhaft löschen?",
        },
        stats: {
            decreased: "gesunken",
            increased: "gestiegen",
            avg1: " ist die durchschnittliche Teambewertung für diese Frage. Ein tieferer Durchschnitt bedeutet, dass Verbesserungspotenzial vorhanden ist.",
            avg2: "Im Vergleich zur letzten Umfrage ist das Ergebnis um {1}% {0}.",
            dev1: " ist die Standardabweichung der Antworten für diese Frage. Eine tiefere Standardabweichung bedeutet, dass das Team eine ähnliche Meinungen hat. Je höher die Zahl, desto eher besteht Diskussionspotential im Team.",
            dev2: "Im Vergleich zur letzten Umfrage ist das Ergebnis um {1} {0}.",
            graphIntro:
                "Die folgende Grafik kann bei der Interpretation der Ergebnisse helfen",
            devGraph1Label: "Einig",
            devGraph1Explanation: "Ein Herz, eine Seele.",
            devGraph2Label: "Differenziert",
            devGraph2Explanation: "Abweichungen im normalen Bereich.",
            devGraph3Label: "Diskussions-bedarf",
            devGraph3Explanation: "Ihr schätzt die Situation verschieden ein.",
            avgGraph1Label: "Verbessern",
            avgGraph1Explanation: "Grosses Potential für Verbesserungen vorhanden.",
            avgGraph2Label: "Neutral",
            avgGraph2Explanation: "Genügend. Punktuell verbessern.",
            avgGraph3Label: "Weiter so",
            avgGraph3Explanation: "Lob geben und annehmen.",
            histogramTitle: "Verteilung der Antworten",
            histogramNr: "Anzahl der Personen",
            histogramGrade: "Grad",
            histogramAnswered: " Personen antworteten ",
        },
        report: {
            title: "Teambarometer-Bericht",
            selectLabel: "Bericht anzeigen für",
            summary: "Zusammenfassung",
            all: "Alles",
            pleaseWait: "Bericht wird geladen... bitte warten",
            noData: "Kein Bericht verfügbar",
            searchLabel: "Suche",
            name: "Name",
            totalFF: "Gesamte Feedforwards",
            noFFsInSurvey:
                "In dieser Umfrage wurden keine Feedforwards hinterlassen",
            closedFF: "Besprochene Feedforwards",
            totalTasks: "Gesamte Aufgaben",
            taskedFF: "Aufgaben aus den Feedforwards",
            delegatedTasks: "Delegierte Aufgaben",
            closedTasks: "Geschlossene Aufgaben",
            wholeCompany: "Gesamte Firma",
            advancedTeamCompare: "Teamvergleich",
            discussionSummary: "Diskussion Zusammenfassung",
            discussionSummaryText:
                "Überblick über die Resultate Diskussion innerhalb der Organisation. Sehe in welchen Teams die Inputs bereits besprochen oder umgesetzt wurden.",
            download: "Herunterladen",
            downloadSummary: "Zusammenfassung herunterladen (.pptx)",
            preparingFile: "Datei vorbereiten...",
            presentation: "Präsentation",
            matrixTitle: "Matrix heatmap",
            matrixText: "Farbcodierte Übersicht der Antworten pro Team und Frage.",
            matrixTextMinFill:
                "Teams, die nicht genügend Antworten geben, werden aus dem Bericht ausgeschlossen.",
            selectReport: "Bericht auswählen",
            displayError:
                "Die Tabelle kann nicht generiert werden. Bitte versuche stattdessen, die Ergebnisse herunterzuladen.",
            averages: "Durchschnitte",
            deviations: "Standardabweichungen",
            enps: "ENPS",
            enpsScore: "ENPS-Bewertung",
            answerNr: "Anzahl der Antworten",
            surveyEnded: "Umfrage endete ",
            "1dayAgo": "vor {0} Tag",
            xdaysAgo: "vor {0} Tagen",
            pastSurveys: "Umfrageverlauf",
            endedAt: "Endete um",
            team: "Team",
            teams: "Teams",
            company: "Firma",
            companyGoal: "Ziel der Firma",
            handleFeedforwards: "alle Feedforwards bearbeiten",
            howManyHandled: "{0} von {1} Feedforwards wurden bearbeitet",
            handlingRate: "Bearbeitungsrate",
            FFHandleTitleCompanyAdmin: "Wie gut kommen deine Teams voran?",
            FFHandleTextCompanyAdmin:
                "Damit die Umfrage ein Teamerfolg wird, ist es wichtig, dass alle Feedforwards bald nach der Umfrage bearbeitet werden.",
            "2weeksafter":
                "2 Wochen nach der Umfrage wird die Tabelle nach der Bearbeitungsrate der Feedforwards eingefärbt. Die Farben zeigen den Prozentsatz der Feedforwards, die im Team diskutiert oder bearbeitet werden.",
        },
        genericApiErrorMessage: "Operation konnte nicht durchgeführt werden",
        errors: {
            selectUser: "Bitte wähle zuerst einen User aus",
            submit: "Fehler beim Senden der Umfrage",
            invalidFormat: "Falsches Format",
            notPossibleRunningSurvey:
                "Nicht möglich während einer laufenden Umfrage",
            oops: "Hoppla!",
            "error-4000":
                "Nicht genügend Daten für diese Erhebung und die ausgewählten Tags verfügbar",
            noData: "Keine Daten",
            noDataYet: "Noch keine Daten",
            notEnoughData:
                "Nicht genügend Daten für diese Erhebung und die ausgewählten Tags verfügbar",
            notEnoughResponseData: "Nicht genügend Antwortdaten",
            displayError: "Die Tabelle kann nicht generiert werden",
            generalError: "Etwas ist schief gelaufen",
            featureNotEnabled:
                "Diese Funktion ist in deinem Konto nicht aktiviert. Bitte kontaktiere uns unter info{'@'}joineer.ch oder +41\u00A044\u00A0510\u00A038\u00A000 für weitere Informationen.",
            featureNotAllowed:
                "Diese Funktion ist für deine Benutzerrolle nicht aktiviert",
            missingFields: "Bitte alle Felder ausfüllen",
            noPermission: "Keine Erlaubnis, diese Seite zu sehen",
            incorrectUrl: "Fehlerhafte URL",
            incorrectEmail: "E-Mail-Format nicht korrekt",
            login400: "Fehlende E-Mail oder andere Anmeldeinformationen",
            login401: "Passwort oder Benutzername ist falsch",
            login401token: "Login-Token ist falsch",
            login403: "Das Konto ist passwortgeschützt. Bitte melde dich mit deinem Passwort an.",
            login403authMethodNotAllowed: "Die Authentifizierungsmethode '{method}' ist für Ihr Unternehmenskonto nicht zulässig. Bitte wenden Sie sich an Ihren Administrator.",
            login403urlLoginNotAllowed: "Eine Anmeldung per URL ist nicht zulässig. Eine Anmeldung per Passwort ist erforderlich.",
            "enps-question-already-exists": "Nur eine Frage kann als ENPS-Frage markiert werden.",
        },
        dashboard: {
            participation: "Teilnahme",
            participants: "Umfrageteilnehmer:innen",
            feedforwards: "Feedforwards",
            pariticantsTooltip: "{0} von {1} Mitglieder haben bereits an der Umfrage teilgenommen",
            feedforwadTooltip: "In dieser Umfrage wurden {0} Feedforwards eingereicht",
            showCompanyHint: "Zeige Daten auf Unternehmensebene an",
            company: "Firma",
            companyLine: "Firma (Diagramm)",
            companyStatistics: "Firma (Statistik)",
            allYourTeams: "Alle deine Teams",
            allYourTeamsLine: "Alle deine Teams (Diagramm)",
            allYourTeamsStatistics: "Alle deine Teams (Statistik)",
            surveySuccessfullySubmitted: "Umfrage erfolgreich übermittelt",
            surveyFilledOutByOutOf: "{0} von {1}",
            surveyResults: "Ergebnisse der Umfrage",
            summary: "Zusammenfassung",
            users: "Nutzer",
            resultsFor: "Ergebnisse für",
            wholeCompany: "Gesamte Firma",
            ownTeam: "Eigenes Team",
            ownTeams: "Eigene Teams",
            team: "Team",
            inTeam: "im {0}",
            adminOf: "Admin: {0}",
            noTagsMessage: "Um Tag-basiert zu filtern, füge den Nutzern Tags hinzu",
            compareWith: "Vergleiche mit",
            compareExplanation:
                "Vergleiche die Ergebnisse mit den folgenden Angaben:",
            questionOverview: "Fragenübersicht",
            average: "Durchschnitt",
            deviation: "Standardabweichung",
            companyHistory: "Firmen-Durchschnitt der letzten Umfragen",
            compareHistory: "Durchschnitt der vergangenen Umfragen",
            filterExplanation: "Umfrageergebnisse nach Tags filtern:",
            "dev-good": "Einig",
            "dev-normal": "Differenziert",
            "dev-bad": "Sehr Differenziert",
            distributionAnswers: "Verteilung der Antworten",
            surveyDate: "Datum der Umfrage",
            companyAvg: "Durchschnitt der Firma",
            positive: "positiv",
            negative: "negativ",
            noFFs: "Keine Feedforwards",
            perMember: "pro Mitglied",
            otherTags: "Andere Tags",

            waitingSurvey: "Wartet auf die erste Umfrage am ",
            waitingTeam: "Wartet darauf, dass dein Team die Umfrage ausfüllt",
            surveyStatus: "Umfragestatus: ",
            meanWhile: "In der Zwischenzeit kannst du:",
            here: "hier",
            addMembers: "Neue Benutzer {0} hinzufügen",
            editQ: "Fragen {0} bearbeiten",
            editInterval: "Intervall {0} bearbeiten",

            scheduleSurvey: "Umfrage planen",
            takeSurvey: "An Umfrage teilnehmen",
            editYourSurveyAnswersUntil: "Bearbeite deine Antworten (bis {0})",
            surveyInProgressTitle: "Umfrage läuft gerade",
            surveyStatusLoading: "wird geladen ...",
            surveyStatusRunning: "Läuft",
            surveyStatusFilled: "Läuft",
            surveyStatusScheduled: "Geplant",
            surveyStatusFirstScheduled: "Erste Umfrage geplant",
            surveyStatusWaiting: "Warten",
            surveyDetailsLoading: "",
            surveyDetailsRunning: "Du hast die Umfrage noch nicht ausgefüllt. Teile deine Meinung bis {0}!",
            surveyDetailsRunningNonMember: "Die aktuelle Umfrage endet am {0}!",
            surveyDetailsFilled: "Du kannst deine Umfrageantworten bis zum {0} anpassen.",
            surveyDetailsFilledNonMember: "Die aktuelle Umfrage endet am {0}!",
            surveyDetailsScheduled: "Die Umfrage beginnt am {0}",
            surveyDetailsFirstScheduled: "Warte auf die erste Umfrage am {0}",
            surveyDetailsWaiting: "Plane eine Umfrage ...",
            surveyEditTitle: "Deine Meinung zählt",
        },
        teamMemberDisplay: {
            title: "Mitglieder, die die Umfrage ausgefüllt haben",
            noResponse: "Keine Mitglieder aus diesem Team haben die Umfrage ausgefüllt.",
        },
        questionEditor: {
            questionsTitle: "Fragen",
            questionsIntro:
                "Fragen mit einem gelben {0} Hintergrund sind Fragen, die im ganzen Unternehmen oder in einer übergeordneten Einheit gestellt werde. Fragen mit einem blauen {1} Hintergrund werden nur der gewählten Einheit gestellt.",
            questionsIntroType:
                "Fragen desselben Typs zeigen ein Link-Symbol {0}, wenn man den Mauszeiger darüber bewegt. Umfragestatistiken zu diesen Fragen werden miteinander verglichen.",
            survey: "Umfrage",
            company: "Firma",
            companyQ:
                "Anzeigen und Bearbeiten von unternehmensweiten Fragen, die jedes Team und jede Abteilung in ihrer Umfrage hat",
            teamSelectLabel: "Ansichtsfragen für:",
            currentSurvey: "Laufende Umfrage",
            pastSurvey: "Vorherige Umfrage",
            nextSurvey: "Nächste Umfrage",
            noEdit: "Vergangene Umfrage werden hier als Referenz gezeigt. Diese können nicht mehr bearbeitet werden.",
            adminQuestionNoEdit:
                "Diese Frage ist innerhalb der Firma oder Abteilung festgelegt. Sie kann nur von einem Abteilungs- oder Firmenadmin angepasst werden",
            notAsked: "Nicht gefragt",
            noQuestionEdit:
                "Dies ist eine übergeordnete Frage und kann nicht bearbeitet werden",
            noQuestionEditView:
                "Nur auf einer höheren Ebene der Abteilungs- oder Firmenansicht bearbeitet werden",
            addError: "Frage kann nicht hinzugefügt werden.",
            editError: "Frage kann nicht bearbeitet werden.",
            deleteError: "Frage kann nicht gelöscht werden.",
            nextError: "Nächste Umfrage kann nicht abgerufen werden.",
            catalogIntro:
                "Lasse dich von unserem Fragenkatalog inspierieren. Öffne die Kategorien und wähle eine Frage aus, indem du darauf klickst. Sobald du eine Frage deiner Umfrage zugeteilt hast, kannst du die Illustration und den Text über das Bearbeiten-Symbol {0} verändern.",
            questions: {
                question: "Frage",
                type: "Typ",
                illustration: "Illustration",
                topic: "Thema",
                preview: "Vorschau",
                dragOrder:
                    "Ziehe {0} und lass los, um die Reihenfolge der Fragen zu ändern.",
                addQuestion: {
                    en: "Englisch",
                    de: "Deutsch",
                    it: "Italienisch",
                    fr: "Französisch",
                    pl: "Polnisch",
                    sk: "Slowakisch",
                    hu: "Ungarisch",
                },
                mandatory: "Obligatorische Sprache",
                language: "Sprache",
                chooseLang: "Sprache wählen",
                example: "Beispiel: ",
                exampleQ: "Bist du mit dem Inhalt Deiner Arbeit zufrieden?",
                exampleT: "Arbeitsaufgaben",
            },
            addQuestion: {
                addTitle: "Frage zur Umfrage hinzufügen",
                newTitle: "Neu",
                catalogTitle: "Katalog",
                reuseTitle: "Wiederverwendung",
                new: "Erstelle deine eigene Frage, um sie an die Bedürfnisse deines Teams anzupassen",
                catalog: "Wähle eine neue Frage aus dem Katalog aus",
                reuse: "Füge eine zuvor verwendete Frage zur Umfrage hinzu",
                noReuse: "Noch keine Frage-Historie",
                newType:
                    "Erstelle eine neue Frage, mit welcher Du die Einschätzungen Deines Teams zu einem bestimmten Thema misst",
                newLinkedType:
                    "Erstelle eine neue Frage, die mit der zuvor verwendeten Frage verknüpft ist",
                linkedTooltip1:
                    "Diese Option ist geeignet, um Fragen zu erstellen, die dasselbe messen, aber die anders formuliert sind oder die unter einem neuen Blickwinkel gestellt werden.",
                linkedTooltip2:
                    "Das Verbinden ähnlicher Fragen ermöglicht praktische und visuelle Auswertungen zwischen den Umfragen, wie es in der folgenden Abbildung gezeigt ist.",
                linkTitle: "Frage verknüpfen",
                enps: "ENPS",
                enpsLabelTooltip: "Diese Frage wird im Employee Net Promoter Score (eNPS) Bericht berücksichtigt, der misst, wie wahrscheinlich es ist, dass Mitarbeiter das Unternehmen empfehlen.",
                enpsSwitchTooltip: "Aktivieren Sie, um diese Frage im Employee Net Promoter Score (eNPS) Bericht zu berücksichtigen, der die Empfehlung durch Mitarbeiter misst.",
                textTitle: "Fragetext",
                copyNotice:
                    "Texte aus der ausgewählten Frage sind im folgenden zu Ihrer Unterstützung kopiert worden",
                noLangContact:
                    "Wenn Du eine Sprache hinzufügen möchtest, die hier nicht aufgelistet ist, {0} uns bitte und wir werden diese gerne manuell für Dich eintragen",
                contact: "kontaktiere",
                langsUsed:
                    "Alle {0} vorgegebenen Sprachen werden verwendet. Wenn Du weitere Sprachen hinzufügen möchtest, {1} uns bitte und wir werden diese sehr gerne manuell für Dich eintragen.",
                allPreviousQuestionsLinked: "Alle vorherigen Fragen wurden bereits verlinkt oder hinzugefügt. Sie können weitere Fragen aus dem Katalog hinzufügen oder neue erstellen.",
            },
            search: {
                mayAdd: "Du kannst eine bereits verwendete Frage in die Umfrage eintragen.",
                searchLabel: "Suche aus vorhandenen Fragen",
                willBeLinked:
                    "Die Statistiken für die jetzt generierte Frage werden mit der unterstehenden Frage verglichen:",
                askedBefore: "Diese Frage wurde bereits gestellt in:",
            },
            dialogs: {
                editQ: "Frage bearbeiten",
                editExplanation:
                    "Wortlaut, Abbildung ändern oder neue Sprachen hinzufügen",
                deleteExplanation: "Frage aus der Umfrage löschen",
                deleteTitle: "Frage löschen",
                deleteConfirmation: "Möchtest Du diese Frage löschen?",
                deleteLong:
                    "Das Löschen der Frage bedeutet, dass diese in zukünftigen Umfragen nicht mehr angezeigt wird, aber Du kannst sie weiterhin in vergangenen Statistiken sehen",
            }
        },
        forms: {
            search: "Suche",
        },
        adhoc: {
            description1: "Einmalige Umfragen",
            surveyDetails: "Details zur Umfrage",
            detailsDescription: "Titel und Daten einstellen",
            participantsDescription: "Wähle aus, wer die Umfrage ausfüllt",
            participantsMinFill1:
                "Mindestens 1 Mitglied muss die Umfrage ausfüllen, damit die Ergebnisse berechnet werden können.",
            participantsMinFillLarger:
                "Mindestens {0} Mitglieder müssten die Umfrage ausfüllen, damit die Ergebnisse berechnet werden können.",
            notEnoughMembers:
                "Nicht genügend Mitglieder in der Umfrage! Minimum: {0}",
            questionsDescription: "Wähle Fragen für die Umfrage",
            review: "Überprüfung",
            reviewDescription: "Zusammenfassung der Umfrage",
            activeFilters: "Aktive Filter",
            filterByEntities: "Filtern nach Teams",
            active: "aktive",
            filterByTags: "Filtern nach Tags",
            reset: "Setzt alle Filter zurück und löscht zur Umfrage hinzugefügte Teilnehmer:innen",
            removeFromSurvey: "Teilnehmer:innen aus der Umfrage entfernen",
            usersAddedToSurvey: " Teilnehmer:innen zur Umfrage hinzugefügt",
            questionsAdded: "Fragen zur Umfrage hinzugefügt",
            surveyPeriod: "Zeitraum der Umfrage",
            editParticipants: "Zurückgehen und Umfrageteilnehmer:innen bearbeiten",
            editPeriod: "Zurückgehen und Umfragetezeitraum bearbeiten",
            editQuestions: "Zurückgehen und Fragen bearbeiten",
            editTitle: "Zurückgehen und Titel der Umfrage bearbeiten",
            datesWrongError:
                "Ups! Bitte stelle sicher, dass das Enddatum nach dem Startdatum liegt",
            surveySuccess: "Umfrage erfolgreich erstellt",
            surveyDeleteConfirmation:
                "Bist du sicher, dass du diese Umfrage löschen möchtest?",
            noMembersFound:
                "Keine Mitglieder mit aktuellen Filtern gefunden. Versuche, einige der aktiven Filter zu entfernen, um die Auswahl zu erweitern, oder setze die Filter züruck {0}",
            manageSurvey: "Umfrage verwalten",
            endNow15min:
                "Mit dieser Aktion wird die laufende Umfrage gestoppt. Die Änderungen werden in 15 Minuten wirksam.",
            endNowConfirmation:
                "Bist du sicher, dass du diese Umfrage beenden möchtest?",
        },
        askExpert: {
            text: "Schwierige Situation im Team? Unsicher, wie du die Resultate im Team diskutieren sollst? Bei Fragen unterstützt dich gerne ein:e {0}.",
            subject: "Betreff",
            from: "Von:",
            to: "An:",
            messagePlaceholder: "Ich habe folgende Frage...",
            askQuestions: "Falls du Fragen hast, ",
            yourExpertHere: "kontaktiere Expert:innen hier",
        },
        survey: {
            admin: "Admin",
            team: "Team",
            opener: "Ich denke in Zukunft wäre es gut, wenn",
            publicComment: "Öffentlicher Kommentar",
            anonComment: "Anonymer Kommentar",
            infoNumericalValueResponse:
                "Zahlenwert: Wird ohne deinen Namen erfasst",
            infoAnonComment:
                "Anonymer Kommentar: Wird ohne deinen Namen erfasst",
            infoPublicComment:
                "Öffentlicher Kommentar: Dein Name ist für dein Team und Admins sichtbar",
            labelNoAnswerGiven: "Keine Antwort gegeben",
            labelCantAnswer: "Ich kann diese Frage nicht beantworten",
            infoFeedforward: "Was ist ein gutes Feedforward und wie schreibt man es? Schau dir das Video an",
            teamQuestion: "Teamfrage",
            whatIsAFeedforward: "Was ist Feedforward?",
            errors: {
                surveyFinished: "Diese Umfrage ist bereits abgeschlossen. Du kann die Ergebnisse auf dem Dashboard anzeigen.",
                getSurveyQuestion: "Beim Abrufen dieser Frage ist ein Fehler aufgetreten",
                surveySettings: "Beim Abrufen der Einstellungen ist ein Fehler aufgetreten",
                saveAnswer: "Beim Speichern dieser Antwort ist ein Fehler aufgetreten",
            }
        },
        remindOrSuspend: {
            resultsCannotBePrepared: "Mobilisiere deine Teammitglieder",
            of: "von",
            teamMembersCompleted: "Teammitglieder haben die Umfrage ausgefüllt",
            suspendOrRemind: "Erinnern oder Aussetzen",
            remind: "Erinnern",
            suspend: "Aussetzen",
            membersWithIncompleteSurvey: "Teammitglieder",
            notifyRemindersSentAndSuspended: "Teammitglieder wurden erinnert oder ausgesetzt!",
            notifyRemindersSent: "Teammitglieder wurden erinnert!",
            notifySuspended: "Teammitglieder wurden ausgesetzt!",
            errorSendingReminders: "Aussetzen oder Erinnerung konnte nicht abgeschlossen werden",
            selectAll: "Alle auswählen",
            searchMembers: "Mitglieder oder Team suchen...",
            labelNoMember: "Keine Mitglieder gefunden",
            all: "Alles",
            nobodyToSuspendOrRemind: "Alle Mitglieder haben die Umfrage bereits ausgefüllt",
            membersLeftToFillCurrentSurvey: "Mitglieder übrig, um die aktuelle Umfrage auszufüllen | Mitglieder müssen die aktuelle Umfrage noch ausfüllen",
            membersHaveFilledOutTheCurrentSurvey: "Mitglied hat die aktuelle Umfrage ausgefüllt | Mitglieder haben die aktuelle Umfrage ausgefüllt",
            surveyWillEndOn: "Die Umfrage endet am {0}",
            listOfMembersToRemindDidNotLoad: "Die Liste der Mitglieder, die erinnert oder die ausgesetzt werden sollen, konnte nicht abgerufen werden",
            reloadRemindMemberList: "Erinnerungsliste neu laden",
        },
        tfa: {
            title: "Zwei-Faktor-Authentifizierung",
            description: "Um Ihre Sicherheit zu gewährleisten, haben wir einen Bestätigungscode an {0} gesendet. Bitte geben Sie den Code unten ein, um auf Ihr Dashboard zuzugreifen.",
            invalidCode: "Ungültiger Code. Bitte versuche es erneut.",
            smsSent: "SMS mit Code wurde an {0} gesendet.",
            notReceived: "Code nicht erhalten?",
            resend: "Erneut senden",
            resendIn: "Erneut senden in {0} Sekunden",
        },
        videoModal: {
            gotIt: "Alles klar"
        },
        feedforwards: {
            overallSummary: "Gesamt-Insights",
            summary: "Insights",
            teamQuestions: "Teamfragen",
            aiTooltip: "Erstellt von der Joineer AI",
            download: "Herunterladen",
            summaryObservations: "Anmerkungen",
            summaryObservationsPlaceholder: "Bitte geben deine Anmerkungen an",
            searchFeedforwards: "Feedforwards durchsuchen",
            noFeedforwardsFoundWith: "Mit {0} wurden keine Feedforwards gefunden. Probier's mal mit einem anderen Suchbegriff oder schränke deine Suche ein.",
            copyToClipboard: "Insights in die Zwischenablage kopieren",
            copyToClipboardSuccessMsg: "Insights in die Zwischenablage kopiert",
            tags: {
                incomplete: "unvollständig",
                inaccurate: "ungenaue",
                offensive: "beleidigende",
                disagree: "nicht einverstanden",
                badStyling: "schlechtes Styling",
                agree: "einverstanden",
                complete: "vollständig",
                correct: "korrekt",
                wellWritten: "gut geschrieben",
                easyToRead: "leicht zu lesen",
                other: "andere",
            }
        },
        emailTemplateFields: {
            someLangsAreEmpty: "Einige der Vorlagenübersetzungen haben keinen Inhalt",
            translations: "Übersetzungen",
            hide: "Verbergen",
            show: "Anzeigen",
            variables: "Variablen",
            subject: "Betreff:",
            title: "Titel:",
            greeting: "Gruss:",
            paragraph1: "Absatz 1:",
            buttonText: "Schaltflächentext",
            paragraph2: "Absatz 2:",
            errorSavingTemplate: "Fehler beim Speichern der Vorlage",
            removeCustomisation: "Anpassung entfernen",
            removeCustomisedTemplateHint: "Dies löscht diese angepasste Vorlage für '{0}' und stattdessen wird die Standardvorlage gesendet",
            cancel: "Abbrechen",
            save: "Speichern",
            deleteTemplateQuestion: "Möchtest du diese E-Mail-Vorlage löschen?",
            deleteTemplateQuestionExplanation: "Nach dem Löschen werden stattdessen die Standard-E-Mail-Vorlagen verwendet.",
            yesDelete: "Ja, löschen",
            emailSubjectLine: "E-Mail-Betreffzeile"
        },
        companyTemplates: {
            emailTemplate: "E-Mail-Vorlage",
            emailTemplates: "E-Mail-Vorlagen",
            templateEdited: "Vorlage bearbeitet",
            backToTemplates: "zurück zu den Vorlagen",
            badTemplateFormat: "Stelle sicher, dass nur gültige Variablen verwendet werden: Die Variablen sind oben aufgelistet und beginnen alle mit {'{'}{'{'} und enden mit {'}'}{'}'}. \nDetails: ",
            noTemplateSelected: "Keine Vorlage ausgewählt",
            emailNeededForTestSend: "(Geben Sie Ihre E-Mail-Adresse in Ihrem @:nav.profile ein, um Vorschauen zu erhalten)",
            sendOnlyForEmails: "Vorschau kann nur für E-Mail-Vorlagen gesendet werden",
            previewEmailSent: "E-Mail an {0} gesendet (Spam überprüfen), in Sprache {1}",
            templateDeleted: "Vorlage gelöscht",
            templateCreated: "Vorlage erstellt",
            SURVEY_PREPARATION: "Umfragevorbereitung",
            SURVEY_START: "Umfragestart",
            SURVEY_REMINDER: "Umfrageerinnerung",
            SURVEY_END: "Umfrageende",
            DM_REMINDER: "DM-Erinnerung",
            ADHOC_START: "Adhoc-Start",
            ADHOC_REMINDER: "Adhoc-Erinnerung",
            ADHOC_END: "Adhoc-Ende",
            EMAIL: "E-Mail",
            SMS: "SMS",
            noAdhocUpsellPrompt: "Adhoc-Funktion nicht aktiviert. Bitte kontaktiere Joineer für weitere Informationen unter {0} oder {1}",
            goToTemplatePage: "Zur Vorlagenseite gehen",
            preview: "Vorschau",
            generalEMailSettings: "Allgemeine E-Mail-Einstellungen",
            previewSendHint: "Sendet ein Test-{0} an DEIN {1}",
            email: "E-Mail",
            mobile: "Mobiltelefon",
            saveAndSendTest: "Speichern & Test senden",
            sendTest: "Test senden",
            errorSendingTemplate: "Fehler beim Senden der Vorlage",
            error: "Fehler",
            channel: "Kanal",
            event: "Ereignis",
            details: "Details",
            customise: "Anpassen"
        },
        companyConfig: {
            all: "alle",
            companyConfigNote: "Diese Einstellungen gelten für {0} E-Mail-Vorlagen für dieses Unternehmen. Um diese Einstellungen anzupassen oder zu ändern, kontaktiere bitte {1} oder {2}",
            backgroundColor: "Hintergrundfarbe:",
            showBanner: "Banner anzeigen:",
            showBannerWithAColorBackground: "Banner mit farbigem Hintergrund anzeigen",
            bannerColor: "Bannerfarbe:",
            showLogo: "Logo anzeigen",
            ":": ":",
            logoSetupNote: "Um das Firmenlogo einzurichten, kontaktiere bitte {0} oder {1}",
            showCompanyLogoInTheEMail: "Firmenlogo in der E-Mail anzeigen",
            footerImage: "Fusszeilenbild:",
            footerLink: "Fusszeilenlink:"
        },
        tiptap: {
            editLink: "Link bearbeiten",
            url: "URL",
        },
    };
};

function commonCustomisations() {

    return Object.assign({}, calidaGroup(), postGroup(), txGroup(), lungenligaGroup());

    function calidaGroup() {

        return assignToObjectKeys(CALIDA_GROUP_COMPANY_IDS, {
            introTitle1: "Willkommen bei der Joineer Umfrage",
            introTitle3: "FeedFORWARD",
            introText3:
                "Wir sind kein Kummerkasten. Teile durch die Umfrage mit, wie wir uns in Zukunft verbessern können. Zukunftsgerichtet, positiv und lösungsorientiert – Feedforward eben!",
            introTitle4: "Ausfüllen – Besprechen – Profitieren",
            introText4:
                "Nach der Umfrage sind die Resultate und Kommentare des Teams für die Teammitglieder einsehbar. Diese zeitnahe Transparenz ermöglicht, dass ihr euch über die Inputs austauscht und Massnahmen besprecht! Dadurch entfaltet die Umfrage ihre Wirkung.",
            introTitle5: "Startklar?",
            introText5:
                "Wende dich bei technischen Problemen an info{'@'}joineer.ch oder bei allgemeinen Fragen an joineer{'@'}calida.com. Jetzt geht es los!",
            gdpr02: "Danke, dass du Joineer benutzt. Hiermit möchten wir dich transparent über die Datenschutzbestimmungen und Anonymität informieren. Folgende Informationen erfassen wir durch die Umfrage:",
            gdpr03: "a) Deinen Namen\nb) Deine geschäftliche E-Mail-Adresse, sofern du eine besitzt\nc) Anonyme quantitative Beurteilungen in Form der Skalenwerte von 1-6\nd) Qualitative Kommentare in den Textfeldern",
            gdpr04: "• Für jede Frage einzeln bieten wir im Sinne unserer offenen und vertrauensbasierten Unternehmenskultur in der CALIDA GROUP die Möglichkeit, die Kommentare transparent mit Namen innerhalb des Teams zu teilen. Die Beurteilungen und Kommentare werden lediglich von dir, deinen Teammitgliedern sowie den HR-Verantwortlichen gesehen. Der Zugriff auf die Daten wird bewusst restriktiv nach dem «need-to-know-Prinzip» gehandhabt.",
            gdpr06: "Für die Zugriffsberechtigten sind die Daten leicht über die Einladungsemail oder den QR Code abrufbar. Alle Daten werden auf Servern in der Schweiz gespeichert und nicht an Dritte weitergegeben.",
            //gdpr07 hidden with current implementation, adding it to gdpr06
            gdpr07: "Für die Zugriffsberechtigten sind die Daten leicht über die Einladungsemail oder den QR Code abrufbar.",
            gdpr09: "Wenn du Fragen zum Datenschutz hast, kannst du uns gerne unter info{'@'}joineer.ch erreichen. Bei Fragen bezüglich der Anonymität melde dich unter joineer{'@'}calida.ch.",
        }, {});
    }

    function postGroup() {

        return assignToObjectKeys(POST_GROUP_COMPANY_IDS, {
            introTitle1: "Willkommen beim Teambarometer der Post",
            introText1:
                "Wir freuen uns sehr über deine Teilnahme. Die Umfrage dauert wenige Minuten und wird personalisiert  erhoben und ausgewertet. Deine Antworten sind somit für andere sichtbar. Auf der nächsten Seite erfährst du, wie du den Teambarometer beantworten kannst.",
            introTitle2: "Worum geht's in dieser Befragung",
            introText2:
                "Der Teambarometer dient in erster Linie dazu, Informationen zu sammeln, wie du die Kultur für dich persönlich, im Team und in deinem Arbeitsumfeld als Ganzes  zum jetzigen Zeitpunkt wahrnimmst. Die Sammlung dieser Informationen dient deinem Team dazu, ein gemeinsames Verständnis über die aktuelle Kultur zu schaffen, um anschliessend Massnahmen zur Umsetzung der Kultur- und Transformationsentwicklung abzuleiten.\n\nBitte beantworte diese Fragen ganz spontan und ehrlich, es gibt kein «richtig» oder «falsch». Neben einer quantitativen Beurteilung wirst du auch um einen Verbesserungsvorschlag für die Zukunft gebeten. Bitte nimm dir ausreichend Zeit dafür. Deine Ideen sind für uns als Team von grosser Bedeutung, um gemeinsam zielführend Massnahmen abzuleiten.\n\nKlicke nun auf  «Loslegen»  um den Teambarometer zu starten! Falls du technische Probleme beim Ausfüllen der Befragung hast, nutze die Chat-Funktion im Teambarometer.",
            gdpr02: "Danke, dass du den Teambarometer nutzt. Er wird von der Schweizerischen Post in Zusammenarbeit mit dem Unternehmen Joineer eingesetzt. Um den Datenschutzbestimmungen gerecht zu werden, möchten wir dich darüber informieren, dass wir neben deinem Namen und deiner E-Mail-Adresse die folgenden Informationen erfassen:",
            gdpr03: "• Anonyme quantitative Werte auf die Fragen des Teambarometers.",
            gdpr04: "• Persönlich abgegebene qualitative Kommentare / Feedforwards.",
            gdpr04pub: "• Persönlich abgegebene qualitative Kommentare / Feedforwards.",
            gdpr05: "Die Antworten können von deinen Teammitgliedern, dem Team- und Firmen-Administrator eingesehen werden.",
            gdpr06: "Bitte teile keine sensiblen Daten oder vertrauliche Informationen via Joineer. Mit deiner Zustimmung bestätigst du, dass du dies berücksichtigst.",
            gdpr07: "",
            gdprLinkMessage: "Alle Daten werden zweckgebunden genutzt und im Rahmen der Datenschutzbestimmungen an folgende Dritte weitergegeben: ",
            gdprLinkTitle: "Link",
            gdprLinkUrl: "https://joineer.com/wp-content/uploads/2024/03/Auftrags_Datenverarbeitung-nach-DSGVO.pdf",
            gdpr09: "Vielen Dank für deine Zustimmung.",
            gdpr10: "Deine persönlichen Daten kannst du über den Link des Teambarometers jederzeit abrufen. Wenn du Fragen hast, kannst du uns gerne kontaktieren:\ninfo{'@'}joineer.ch",
            gdpr12: 'Joineer und das Team von "Kultur von Morgen"',

        }, {});
    }

    function txGroup() {

        return assignToObjectKeys(TX_GROUP_COMPANY_IDS, {
            //gdpr01: "",
            gdpr02: "Danke, dass du Joineer benutzt.\n\nHiermit möchten wir dich transparent über die Datenschutzbestimmungen und Anonymität informieren. Folgende Informationen erfassen wir durch die Umfrage:\n\na) Deinen Namen\nb) Deine geschäftliche E-Mail-Adresse\nc) Anonyme quantitative Beurteilungen in Form der Skalenwerte von 1-10\nd) Qualitative Kommentare in den Textfeldern\n\nDie Resultate deines Teams werden ausgewertet und angezeigt, wenn mindestens drei Teammitglieder an der Umfrage teilgenommen haben. In diesem Fall sind deine Kommentare persönlich oder anonymisiert (je nach deiner Einstellung) für deine Teammitglieder und für deinen direkten Vorgesetzten einsehbar. Anonymisiert werden deine Kommentare von deinen Abteilungsleitungen und von deinem Geschäftsleitungs-Mitglied gesehen.\n\nAnonymisierte Daten können zwecks Datenanalyse an ein Partnerunternehmen weitergegeben werden. Nach der Fertigstellung der Analyse werden die Daten beim Partnerunternehmen gelöscht.\n\nAlle Daten werden auf Servern in der Schweiz gespeichert. Deine persönlichen Daten kannst du über den Link zu dieser App jederzeit abrufen.\nWenn du Fragen hast, kannst du uns gerne kontaktieren: info{'@'}joineer.ch.",
            gdpr03: "",
            gdpr04: "",
            gdpr05: "",
            gdpr06: "",
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "",
            gdprLinkUrl: "",
            gdprLinkTitle: "",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung",
            gdpr09: "",
            gdpr10: "",
            gdpr11: "",
            gdpr12: "Vielen Dank für dein Vertrauen!\n\nBeste Grüsse\nJoineer",
        }, {});
    }

    function lungenligaGroup() {

        return assignToObjectKeys(LUNGENLIGA_GROUP_COMPANY_IDS, {
            gdpr01: "Einverständniserklärung",
            gdpr02: "Danke, dass du Joineer benutzt. \n\nWir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon oder 2-Factor-Authentication auch deine Mobilnummer. \n\nSofern dein Unternehmen die Resultate nach soziodemographischen Merkmalen (z.B. Alter, Funktionsgruppe) auswerten möchte, werden auch diese Daten erfasst. \n\nDeine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen. \n\nZusätzlich erfassen wir die folgenden Resultate-Informationen:",
            gdpr03: "• Anonyme quantitative Antworten (Werte)",
            gdpr04: "• Wahlweise öffentlich oder anonym abgegebene qualitative Kommentare (Feedforwards)",
            gdpr04pub: "• Qualitative Kommentare.",
            // not shown when company settings.anonym = true
            gdpr05: "Für dein Unternehmen werden die Resultate eines Teams ausgewertet und angezeigt, wenn mindestens die folgende Anzahl Personen eines Teams an der Umfrage teilgenommen hat: 3. In diesem Fall werden deine Resultate-Informationen von deinen Teammitgliedern, den Bereichs- und den Firmen-Administratoren gesehen.",
            gdpr06: "Alle Daten werden auf Servern in der Schweiz gespeichert und nicht an Dritte weitergegeben.",
            // not shown when company settings.anonym = true
            gdpr07: "Deine persönlichen Daten kannst du über den Link zu dieser App jederzeit abrufen.",
            gdpr08: "",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung",
            gdpr09: "Wenn du Fragen hast, kannst du uns gerne kontaktieren: info{'@'}joineer.ch.",
            gdpr10: "Vielen Dank für dein Vertrauen!",
            gdpr11: "Beste Grüsse",
            gdpr12: "Joineer AG",
        }, {});
    }
}

function customisations() {
    return {

        // Brugg Lifting
        "64983brl304366969": {
            gdpr01: "Einverständniserklärung",
            gdpr02: "Danke, dass du Joineer benutzt.\n" +
                "\n" +
                "Wir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon oder 2-Factor-Authentication auch deine Mobilnummer. \n" +
                "\n" +
                "Sofern dein Unternehmen die Resultate nach soziodemographischen Merkmalen (z.B. Alter, Funktionsgruppe) auswerten möchte, werden auch diese Daten erfasst.\n" +
                "\n" +
                "Deine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen.\n" +
                "\n" +
                "Zusätzlich erfassen wir die folgenden Resultate-Informationen:",
            gdpr03: "• Anonyme quantitative Antworten (Werte)",
            gdpr04: "• Wahlweise öffentlich oder anonym abgegebene qualitative Kommentare (Feedforwards)",

            gdpr04pub: "• Qualitative Kommentare.",

            // not shown when company settings.anonym = true
            gdpr05: "Für dein Unternehmen werden die Resultate eines Teams ausgewertet und angezeigt, wenn mindestens die folgende Anzahl Personen eines Teams an der Umfrage teilgenommen hat: 3. In diesem Fall werden deine Resultate-Informationen von deinen Teammitgliedern, den Bereichs- und den Firmen-Administratoren gesehen.",

            gdpr06:
                "Alle Daten werden auf Servern in der Schweiz gespeichert" +
                " und nicht an Dritte weitergegeben.",

            // not shown when company settings.anonym = true
            gdpr07: "",

            gdpr08: "Wir bitten dich, dies zu beachten und diesen Bedingungen zuzustimmen.",
            gdprLinkMessage: "", //For more information click {link}
            gdprLinkTitle: "", //here
            gdprLinkUrl: "", //https://www.app.com
            gdpr09: "Wenn du Fragen hast, kannst du uns gerne kontaktieren: info@joineer.ch.",
            gdpr10: "Vielen Dank für dein Vertrauen!",
            gdpr11: "Beste Grüsse",
            gdpr12: "Joineer AG",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung"
        },


        // TX Tamedia
        "23213tam223139668": {
            //gdpr01: "",
            gdpr02: "Danke, dass du Joineer benutzt.\n\nHiermit möchten wir dich transparent über die Datenschutzbestimmungen und Anonymität informieren. Folgende Informationen erfassen wir durch die Umfrage:\n\na) Deinen Namen\nb) Deine geschäftliche E-Mail-Adresse\nc) Anonyme quantitative Beurteilungen in Form der Skalenwerte von 1-10\nd) Qualitative Kommentare in den Textfeldern\n\nDie Resultate deines Teams werden ausgewertet und angezeigt, wenn mindestens fünf Teammitglieder an der Umfrage teilgenommen haben. In diesem Fall sind deine Kommentare persönlich oder anonymisiert (je nach deiner Einstellung) für deine Teammitglieder und für deinen direkten Vorgesetzten einsehbar. Anonymisiert werden deine Kommentare von deinen Abteilungsleitungen und von deinem Geschäftsleitungs-Mitglied gesehen.\n\nAnonymisierte Daten können zwecks Datenanalyse an ein Partnerunternehmen weitergegeben werden. Nach der Fertigstellung der Analyse werden die Daten beim Partnerunternehmen gelöscht.\n\nAlle Daten werden auf Servern in der Schweiz gespeichert. Deine persönlichen Daten kannst du über den Link zu dieser App jederzeit abrufen.\nWenn du Fragen hast, kannst du uns gerne kontaktieren: info{'@'}joineer.ch.",
            gdpr03: "",
            gdpr04: "",
            gdpr05: "",
            gdpr06: "",
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "",
            gdprLinkUrl: "",
            gdprLinkTitle: "",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung",
            gdpr09: "",
            gdpr10: "",
            gdpr11: "",
            gdpr12: "Vielen Dank für dein Vertrauen!\n\nBeste Grüsse\nJoineer",
        },

        // AWA Nullmessung: special texts:
        "19681ANU402980805": {
            gdpr05: "",
            startGoMessage:
                "Teile mit, was in Zukunft besser gemacht werden kann - positiv und lösungsorientiert mit Feedforward.",
        },

        //SAWH
        "34805saw645338159": {
            //gdpr01: "",
            gdpr02: "Danke, dass du Joineer benutzt.\n\nWir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon oder 2-Factor-Authentication auch deine Mobilnummer.\n\nSofern dein Unternehmen die Resultate nach soziodemographischen Merkmalen (z.B. Alter, Funktionsgruppe) auswerten möchte, werden auch diese Daten erfasst. Deine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen.\n\nZusätzlich erfassen wir die folgenden Resultate-Informationen:\n\n• Anonyme quantitative Antworten (Werte)\n• Wahlweise öffentlich oder anonym abgegebene qualitative Kommentare (Feedforwards)\n\nFür dein Unternehmen werden die Resultate eines Teams ausgewertet und angezeigt, wenn mindestens die folgende Anzahl Personen eines Teams an der Umfrage teilgenommen hat: 3. In diesem Fall werden deine Resultate-Informationen von deinen Teammitgliedern, den Bereichs- und den Firmen-Administratoren gesehen.\n\nAlle Daten werden auf Servern in der Schweiz gespeichert und nicht an Dritte weitergegeben.",
            gdpr03: "",
            gdpr04: "",
            gdpr05: "",
            gdpr06: "",
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "",
            gdprLinkUrl: "",
            gdprLinkTitle: "",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung",
            gdpr09: "",
            gdpr10: "",
            gdpr11: "",
            gdpr12: "Wenn du Fragen hast, kannst du uns gerne kontaktieren: info{'@'}joineer.ch\n\nVielen Dank für dein Vertrauen!\n\nBeste Grüsse\nJoineer AG",
        },

        //Rittmeyer nullmessung special texts:
        "34824rin660736891": {
            gdpr02: "Danke, dass du Joineer benutzt. Wir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon oder 2-Factor-Authentication auch deine Mobilnummer. Sofern dein Unternehmen die Resultate nach soziodemographischen Merkmalen (z.B. Alter, Funktionsgruppe) auswerten möchte, werden auch diese Daten erfasst. Zusätzlich erfassen wir die folgenden Resultate-Informationen:",
            gdpr03: "• Anonyme quantitative Antworten (Werte)",
            gdpr04: "• Wahlweise öffentlich oder anonym abgegebene qualitative Kommentare (Feedforwards)",
            gdpr05: "Für dein Unternehmen werden die Resultate eines Teams ausgewertet und angezeigt, wenn mindestens vier Personen eines Teams an der Umfrage teilgenommen haben. Die Resultate-Informationen werden von den Firmen-Administratoren gesehen.",
            gdpr07: "Deine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen.",
            gdpr08: "",
            gdpr12: "Joineer AG",
        },

        // Perspecta
        "37121per159542365": {
            introTitle2: "Deine Meinung zählt",
            introText2: "Mit dem Teambarometer begleiten wird das Onboarding. Während der ersten Arbeitsmonate wirst du regelmässig nach deiner Meinung gefragt.",
            introText4:
            "Nach der Umfrage werden die Inputs auf dem Dashboard dargestellt." +
            " Das Wichtigste ist, dass ihr die Resultate gemeinsam besprecht!" +
            " Nur so entfaltet der Teambarometer seine Wirkung.",
            gdpr05: "Die gegebenen Antworten werden von allen Personen, die zur Umfrage eingeladen werden (neue/r Mitarbeitende, Führungskraft, evtl. HR Manager und weitere Ansprechpersonen im Rahmen des Onboardings) sowie von den Coaches der Perspectas gesehen.",
            startGoMessage:
            "Teile mit, was in Zukunft besser gemacht werden kann - positiv und lösungsorientiert mit Feedforward." +
            " Die Resultate werden auf dem Dashboard präsentiert und gemeinsam besprochen.",
        },

        //Huba special texts:
        "40627hub451626967": {
            gdpr05: "Die gegebenen Antworten werden vom Firmen-Administrator gesehen.",
        },

        // JOB AG special texts
        "44155job679947446": {
            gdpr02: "Vielen Dank für Deine Teilnahme an der JOB AG Mitarbeiterbefragung.",
            gdpr03: "Die Teilnahme ist freiwillig und erfolgt im Einklang mit den datenschutzrechtlichen Vorgaben. Die Verarbeitung Deiner personenbezogenen Daten zum Zweck der Durchführung der Mitarbeiterbefragung erfolgt auf Basis Deiner Einwilligung.",
            gdpr04: "",
            gdpr04pub: "",
            gdpr06: "Diese kann jederzeit widerrufen werden.",
            gdprLinkMessage:
                "Alle Informationen zur Teilnahme sowie die Datenschutzhinweise findest Du im ",
            gdprLinkTitle: "JOB MAX",
            gdprLinkUrl:
                "https://job-ag.staffbase.com/content/page/61f3f2c225f22906a5b06a17",
            gdpr09: "",
            gdpr10: "Vielen Dank für Dein Vertrauen!",
            gdpr11: "JOB AG",
            gdpr12: "",
            gdprIgiveMyConsent: "Hiermit willige ich ein!",
        },

        // POST AG special texts
        "55287qpo363914313": {
            introTitle1: "Willkommen beim Kulturcheck",
            introText1: "Wir freuen uns sehr über deine Teilnahme.",
            introTitle2: "Deine Meinung zählt",
            introText2:
                "Die Umfrage dauert wenige Minuten und wird personalisiert erhoben und ausgewertet.",
            introTitle3: "Worum geht’s in diesem Kultur-Check?",
            introText3:
                "Dieser Kultur-Check dient in erster Linie dazu, Informationen zu sammeln, wie du die Kultur für dich persönlich, im Team und dem eigenen Bereich als Ganzes zum jetzigen Zeitpunkt wahrnimmst. Die Sammlung dieser Informationen dient deinem Team dazu, ein gemeinsames Verständnis über die aktuelle Kultur zu schaffen, um anschliessend Massnahmen zur Umsetzung der Kultur- und Transformationsentwicklung zu definieren.",
            introTitle4: "Sei offen",
            introText4:
                "Bitte beantworte diese Fragen ganz spontan und ehrlich, es gibt kein «richtig» oder «falsch». Neben einer quantitativen Beurteilung wirst du auch um einen Verbesserungsvorschlag für die Zukunft gebeten. Natürlich hilft es, wenn du diese Vorschläge öffentlich (nicht anonym) abgibst.",
        },

        // Zuger Kantonalbank special texts:
        "56719zkb691551221": {
            startGoMessage:
                "Teile mit, was in Zukunft besser gemacht werden kann - positiv und lösungsorientiert mit Feedforward.",
            gdpr04: "• Anonyme qualitative Kommentare zu den Fragen des Teambarometers.",
            gdpr04pub:
                "• Anonyme qualitative Kommentare zu den Fragen des Teambarometers.",
        },

        // Anzu test
        "61950d444bc1bf215e448f3c156": {
            // introTitle1: "DE Test Intro title 1",
            //introText1: "DE Test Intro text 1",
        },

        // RUAG special texts:
        "651545c98fbb90562f4c065c": {
            gdpr01: "Einverständniserklärung",
            gdpr02: "Danke, dass du Joineer benutzt.\nWir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse.\nDeine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen.\n\nZusätzlich erfassen wir die folgenden Resultate-Informationen:",
            gdpr03: "• Anonyme quantitative Antworten (Werte)",
            gdpr04: "",
            gdpr05: "Die Resultate werden von den Bereichs- und Firmenadministratoren gesehen.\nFür dein Unternehmen werden die Resultate auf der Ebene des Teams angezeigt, wenn mindestens die folgende Anzahl Personen eines Teams an der Umfrage teilgenommen hat: 5.",
            gdpr06: "Alle Daten werden auf Servern in der Schweiz gespeichert und nicht an Dritte weitergegeben. Für weitere Informationen siehe Joineer Datenschutz (https://joineer.com/datenschutz/).",
            gdpr07: "",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung",
            gdpr08: "",
            gdpr09: "Wenn du Fragen hast, kannst du uns gerne kontaktieren: info{'@'}joineer.ch",
            gdpr10: "Vielen Dank für dein Vertrauen!",
            gdpr11: "Beste Grüsse",
            gdpr12: "Joineer AG",
            introTitle1: "Willkommen beim RUAG Mitarbeitendenbefragung",
            introTitle2: "Wir sind da für dich",
            introText1:
                "Wir freuen uns sehr über deine Teilnahme - denn deine Meinung zählt!",
            introText2:
                "Wende dich bei Fragen ungeniert an uns unter info{'@'}joineer.ch. Jetzt geht es los!",
            startGoMessage: "",
            surveyEndMessage: "Klicke jetzt auf 'Abgeben'. Falls du noch etwas ändern möchtest, dann kommst du mit dem 'Zurück' Knopf zu den Fragen.",
        },

        //SRG special texts:
        "671222b7ff98fb6a8823cf83": {
            gdpr01: "Einverständniserklärung",
            gdpr02: "Danke, dass du Joineer benutzt.\nWir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon oder 2-Factor-Authentication auch deine Mobilnummer.\n\nDeine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen.\n\nZusätzlich erfassen wir die folgenden Resultate-Informationen von dir:",
            gdpr03: "• Quantitative Antworten (Werte)",
            gdpr04: "• Persönliche qualitative Kommentare (Feedforwards)",
            gdpr05: "Für dein Unternehmen werden die Resultate einer Umfrage ausgewertet und angezeigt, wenn mindestens die folgende Anzahl Personen an der Umfrage teilgenommen hat: 1. In diesem Fall werden deine Resultate-Informationen vom Ersteller/von der Erstellerin der Umfrage sowie von den Firmen-Administratoren gesehen.",
            gdpr06: "Alle Daten werden auf Servern in der Schweiz gespeichert und nicht an Dritte weitergegeben.",
            gdpr07: "",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung",
            gdpr08: "",
            gdpr09: "Wenn du Fragen hast, kannst du uns gerne kontaktieren: info{'@'}joineer.ch",
            gdpr10: "Vielen Dank für dein Vertrauen!",
            gdpr11: "Beste Grüsse",
            gdpr12: "Joineer AG",
        },

        //Thermoplan special texts:
        "672393c21d95257d39296bbf": {
            gdpr05: "Für dein Unternehmen werden die Resultate einer Umfrage ausgewertet und angezeigt, wenn mindestens die folgende Anzahl Personen an der Umfrage teilgenommen hat: 3. In diesem Fall werden deine Resultate-Informationen vom Ersteller/von der Erstellerin der Umfrage sowie von den Firmen-Administratoren gesehen.",
        },



      //Swica special texts:
      "69493swi532328968": {
          gdpr05: "Die gegebenen Antworten werden vom Firmen-Administrator gesehen.",
      },


        // DIS special texts:
        // "80568dis750356676": {
        "70129dis673402979": {
            introTitle1: "Willkommen zum Teambarometer",
            introText1:
                "Das vertrauensvolle und direkte Feedbackgespräch hilft uns und jedem Einzelnen, sich weiter zu entwickeln. Solche Gespräche sind uns sehr wichtig. Eine weitere Möglichkeit für die Rückkopplung wollen wir mehrmals pro Jahr mit dem Teambarometer von Joineer - pulsartig - anbieten.",
            introTitle3: "Gestalte die Zukunft mit",
            introText3:
                "Wir, die Geschäftsführung des Geschäftsfeldes Digital Infrastructure Services, wollen verstehen, was Dich bewegt und was wir besser machen können. Diese Umfrage ist eine Möglichkeit, um Dich uns mitzuteilen. Nutze sie und gestalte die Zukunft gemeinsam mit uns!",
        },

        //KPT special texts
        "81812kpt446687047": {
            gdpr02: "Es geht los… bald kannst du den Teambarometer nutzen.",
            gdpr03: "Um den Datenschutzbestimmungen gerecht zu werden, möchten wir dich darüber informieren, dass wir neben deinem Namen und deiner E-Mail Adresse die folgenden Informationen erfassen: ",
            gdpr04:
                "• Quantitative Antworten auf die Fragen des Teambarometers sowie qualitative Kommentare.",
            gdpr04pub:
                "• Quantitative Antworten auf die Fragen des Teambarometers sowie qualitative Kommentare.",
            gdpr05: "Deine Antworten werden von deinen Teammitgliedern, deiner direkten Führungsperson sowie von den Prozessverantwortlichen gesehen.",
            gdpr07: "",
            gdpr12: "KPT & Joineer",
        },

        //Fatzer special texts:
        "85916fat786585735": {
            gdpr01: "Einverständniserklärung",
            gdpr02: "Danke, dass du Joineer benutzt.\nWir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon oder 2-Factor-Authentication auch deine Mobilnummer.\n\nSofern dein Unternehmen die Resultate nach soziodemographischen Merkmalen (z.B. Alter, Funktionsgruppe) auswerten möchte, werden auch diese Daten erfasst. Zusätzlich erfassen wir die folgenden Resultate-Informationen:",
            gdpr03: "• Anonyme quantitative Antworten (Werte)",
            gdpr04: "• Wahlweise öffentlich oder anonym abgegebene qualitative Kommentare (Feedforwards)",
            gdpr05: "Für dein Unternehmen werden die Resultate eines Teams ausgewertet und angezeigt, wenn mindestens die folgende Anzahl Personen eines Teams an der Umfrage teilgenommen hat: 3. In diesem Fall werden deine Resultate-Informationen von deinen Teammitgliedern, den Bereichs- und den Firmen-Administratoren gesehen.",
            gdpr06: "Alle Daten werden auf Servern in der Schweiz gespeichert und nicht an Dritte weitergegeben.",
            gdpr07: "Deine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen.",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung",
            gdpr10: "Vielen Dank für dein Vertrauen!",
            gdpr11: "Beste Grüsse",
            gdpr12: "Joineer AG"
        },

        // KuZu special texts:
        "91358txk194486012": {
            gdpr03: "• Anonyme quantitative Antworten auf die Fragen der Kaderumfrage CS.",
            gdpr05: "Die gegebenen Antworten werden leitenden Mitarbeitenden der CS und den Firmen-Administratoren zugänglich gemacht.",
            gdpr07: "",
            startGoMessage:
                "Teile mit, was in Zukunft besser gemacht werden kann - positiv und lösungsorientiert mit Feedforward.",
        },

        //Post Kommunikations services special texts
        "95462pks109762813": {
          introTitle1: "Willkommen beim Teambarometer der Post",
          introText1:
              "Wir freuen uns sehr über deine Teilnahme. Die Umfrage dauert wenige Minuten und wird personalisiert  erhoben und ausgewertet. Deine Antworten sind somit für andere sichtbar. Auf der nächsten Seite erfährst du, wie du den Teambarometer beantworten kannst.",
          introTitle2: "Worum geht's in dieser Befragung",
          introText2:
              "Der Teambarometer dient in erster Linie dazu, Informationen zu sammeln, wie du die Kultur für dich persönlich, im Team und in deinem Arbeitsumfeld als Ganzes  zum jetzigen Zeitpunkt wahrnimmst. Die Sammlung dieser Informationen dient deinem Team dazu, ein gemeinsames Verständnis über die aktuelle Kultur zu schaffen, um anschliessend Massnahmen zur Umsetzung der Kultur- und Transformationsentwicklung abzuleiten.\n\nBitte beantworte diese Fragen ganz spontan und ehrlich, es gibt kein «richtig» oder «falsch». Neben einer quantitativen Beurteilung wirst du auch um einen Verbesserungsvorschlag für die Zukunft gebeten. Bitte nimm dir ausreichend Zeit dafür. Deine Ideen sind für uns als Team von grosser Bedeutung, um gemeinsam zielführend Massnahmen abzuleiten.\n\nKlicke nun auf  «Loslegen»  um den Teambarometer zu starten! Falls du technische Probleme beim Ausfüllen der Befragung hast, nutze die Chat-Funktion im Teambarometer.",
          gdpr02: "Danke, dass du den Teambarometer nutzt. Er wird von der Schweizerischen Post in Zusammenarbeit mit dem Unternehmen Joineer eingesetzt. Um den Datenschutzbestimmungen gerecht zu werden, möchten wir dich darüber informieren, dass wir neben deinem Namen und deiner E-Mail-Adresse die folgenden Informationen erfassen:",
          gdpr03: "• Anonyme quantitative Werte auf die Fragen des Teambarometers.",
          gdpr04: "• Wahlweise anonym oder persönlich abgegebene qualitative Kommentare / Feedforwards.",
          gdpr04pub: "• Wahlweise anonym oder persönlich abgegebene qualitative Kommentare / Feedforwards.",
            gdpr05: "Die Resultate eines Teams werden angezeigt, wenn mindestens 3 Personen eines Teams an der Umfrage teilgenommen haben. Die Antworten können von deinen Teammitgliedern, dem Team- und Firmen-Administrator eingesehen werden.",
          gdpr06: "Bitte teile keine sensiblen Daten oder vertrauliche Informationen via Joineer. Mit deiner Zustimmung bestätigst du, dass du dies berücksichtigst.",
          gdpr07: "",
          gdprLinkMessage: "Alle Daten werden zweckgebunden genutzt und im Rahmen der Datenschutzbestimmungen an folgende Dritte weitergegeben: ",
          gdprLinkTitle: "Link",
          gdprLinkUrl: "https://joineer.com/wp-content/uploads/2024/03/Auftrags_Datenverarbeitung-nach-DSGVO.pdf",
          gdpr09: "Vielen Dank für deine Zustimmung.",
          gdpr10: "Deine persönlichen Daten kannst du über den Link des Teambarometers jederzeit abrufen. Wenn du Fragen hast, kannst du uns gerne kontaktieren:\ninfo{'@'}joineer.ch",
          gdpr12: 'Joineer und das Team von "Kultur von Morgen"'

        },

        // Atupri
        "95444atp856638338": {
            introTitle1: "Willkommen bei dieser Befragung",
            introText2:
                "Du wirst mehrmals im Jahr pulsartig nach deiner Meinung gefragt. So wird die Stimmung über die Zeit gemessen und sind die Verbesserungen entsprechend vergleichbar.",
            introText4:
                "Nach der Umfrage werden die Inputs auf dem Dashboard dargestellt. Das Wichtigste ist, dass ihr die Resultate im Team besprecht! Nur so entfaltet die Befragung ihre Wirkung.",
        },

        //Frauenfeld
        "95615sfr320742328": {
            gdpr02: "Danke, dass du Joineer benutzt.\n\nWir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon auch deine Mobilnummer.\n\nSofern dein Unternehmen die Resultate nach soziodemographischen Merkmalen (z.B. Alter, Funktionsgruppe) auswerten möchte, werden auch diese Daten erfasst.\n\nDeine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen.\n\nZusätzlich erfassen wir die folgenden Resultate-Informationen: ",
            gdpr03: "• Anonyme quantitative Antworten (Werte).",
            gdpr04: "• Persönliche qualitative Kommentare (Feedforwards)",
            gdpr04pub:"• Persönliche qualitative Kommentare (Feedforwards)",
            gdpr05: "Für dein Unternehmen werden die Resultate eines Teams ausgewertet und angezeigt, wenn mindestens eine Personen eines Teams an der Umfrage teilgenommen hat. In diesem Fall werden deine Resultate-Informationen von deinen Teammitgliedern, den Bereichs- und den Firmen-Administratoren gesehen. Insgesamt werden die Resultate von den direkten Vorgesetzten, Abteilungs- und Amtsleitenden, der Departementsvorsteherschaft im jeweiligen Departement, Mitarbeitenden des Personalamtes, dem Stadtpräsident und der Stadtschreiberin gesehen.",
            gdpr07: "",
            gdpr12: "Joineer AG",
        },

        // Post Tutorial Oriana
        "14055for495089302": {
            gdpr02: "Danke, dass du Joineer benutzt.\nWir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon oder 2-Factor-Authentication auch deine Mobilnummer.\nSofern dein Unternehmen die Resultate nach soziodemographischen Merkmalen (z.B. Alter, Funktionsgruppe) auswerten möchte, werden auch diese Daten erfasst.\n" +
                "Deine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen.\n\nZusätzlich erfassen wir die folgenden Resultate-Informationen:",
            gdpr03: "• Anonyme quantitative Antworten (Werte)",
            gdpr04pub: "• Öffentlich abgegebene qualitative Kommentare (Feedforwards)",

            // not shown when company settings.anonym = true
            gdpr05: "Für dein Unternehmen werden die Resultate eines Teams ausgewertet und angezeigt, wenn mindestens die folgende Anzahl Personen eines Teams an der Umfrage teilgenommen hat: 1. In diesem Fall werden deine Resultate-Informationen von deinen Teammitgliedern, den Bereichs- und den Firmen-Administratoren gesehen.",

            gdpr06:
                "Alle Daten werden auf Servern in der Schweiz gespeichert" +
                " und nicht an Dritte weitergegeben.",

            gdpr07: "",

            gdpr08: "",

            gdpr09: "Wenn du Fragen hast, kannst du uns gerne kontaktieren: info{'@'}joineer.ch.",
            gdpr10: "Vielen Dank für dein Vertrauen!",
            gdpr11: "Beste Grüsse",
            gdpr12: "Joineer",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung"
        },

        //Swissinfo special texts:
        "22801SWI182608558": {
            gdpr01: "Einverständniserklärung",
            gdpr02: "Danke, dass du Joineer benutzt. \nWir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon oder 2-Factor-Authentication auch deine Mobilnummer. \nSofern dein Unternehmen die Resultate nach soziodemographischen Merkmalen (z.B. Alter, Funktionsgruppe) auswerten möchte, werden auch diese Daten erfasst. \nDeine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen. \n\nZusätzlich erfassen wir die folgenden Resultate-Informationen:",
            gdpr03: "• Anonyme quantitative Antworten (Werte)",
            gdpr04: "• Persönliche qualitative Kommentare (Feedforwards)",
            gdpr04pub: "• Persönliche qualitative Kommentare (Feedforwards)",
            gdpr05: "Für dein Unternehmen werden die Resultate eines Teams ausgewertet und angezeigt, wenn mindestens die folgende Anzahl Personen eines Teams an der Umfrage teilgenommen hat: 1. In diesem Fall werden deine Resultate-Informationen von deinen Teammitgliedern, den Bereichs- und den Firmen-Administratoren gesehen.",
            gdpr06: "Alle Daten werden auf Servern in der Schweiz gespeichert und nicht an Dritte weitergegeben.",
            gdpr07: "",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung",
            gdpr08: "",
            gdpr09: "Wenn du Fragen hast, kannst du uns gerne kontaktieren: info{'@'}joineer.ch",
            gdpr10: "Vielen Dank für dein Vertrauen!",
            gdpr11: "Beste Grüsse",
            gdpr12: "Joineer AG"
        },

        "80070sen243729353": {

            gdpr02: "Danke, dass du Joineer benutzt.\nWir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon oder 2-Factor-Authentication auch deine Mobilnummer.\nSofern dein Unternehmen die Resultate nach soziodemographischen Merkmalen (z.B. Alter, Funktionsgruppe) auswerten möchte, werden auch diese Daten erfasst.\n" +
                "Deine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen.\n\nZusätzlich erfassen wir die folgenden Resultate-Informationen:",
            gdpr03: "• Anonyme quantitative Antworten (Werte)",
            gdpr04: "• Wahlweise öffentlich oder anonym abgegebene qualitative Kommentare (Feedforwards)",

            // not shown when company settings.anonym = true
            gdpr05: "Für dein Unternehmen werden die Resultate eines Teams ausgewertet und angezeigt, wenn mindestens die folgende Anzahl Personen eines Teams an der Umfrage teilgenommen hat: 3. In diesem Fall werden deine Resultate-Informationen von deinen Teammitgliedern, den Bereichs- und den Firmen-Administratoren gesehen.",

        },

        "53215seh329615704": {
            introTitle1: "Deine Meinung zählt",
            introTitle2: "FeedFORWARD – kein Feedback",
            introText1:
                "Mit dem Teambarometer wirst du nach deiner Meinung gefragt." +
                " Denn diese ist wichtig!",
            introText2:
                "Wir sind kein Kummerkasten. Kritisiere nicht, was in Vergangenheit falsch lief," +
                " sondern teile mit, wie man es in Zukunft besser machen kann. Zukunftsgerichtet," +
                " positiv und lösungsorientiert - Feedforward eben!"
        },

        //BVGer special texts:
        "52780bvg479791730": {
            gdpr01: "Einverständniserklärung",
            gdpr02: "Wir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon oder 2-Factor-Authentication auch deine Mobilnummer.\nSofern dein Unternehmen die Resultate nach soziodemographischen Merkmalen (z.B. Alter, Funktionsgruppe) auswerten möchte, werden auch diese Daten erfasst.\nDeine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen.\n\nZusätzlich erfassen wir die folgenden Resultate-Informationen:",
            gdpr03: "• Anonyme quantitative Antworten (Werte)",
            gdpr04: "• Wahlweise öffentlich oder anonym abgegebene qualitative Kommentare (Feedforwards)",
            gdpr04pub: "• Qualitative Kommentare",
            // not shown when company settings.anonym = true
            gdpr05: "Für dein Unternehmen werden die Resultate eines Teams ausgewertet und angezeigt, wenn mindestens die folgende Anzahl Personen eines Teams an der Umfrage teilgenommen hat: 3. In diesem Fall werden deine Resultate-Informationen von deinen Teammitgliedern, den Bereichs- und den Firmen-Administratoren gesehen.",
            gdpr06: "Alle Daten werden auf Servern in der Schweiz gespeichert und nicht an Dritte weitergegeben.",
            // not shown when company settings.anonym = true
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "", //For more information click {link}
            gdprLinkTitle: "", //here
            gdprLinkUrl: "", //https://www.app.com
            gdpr09: "Wenn du Fragen hast, kannst du uns gerne kontaktieren: info{'@'}joineer.ch",
            gdpr10: "Vielen Dank für dein Vertrauen!",
            gdpr11: "Beste Grüsse",
            gdpr12: "Joineer AG",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung",
        },

        //Tamedia pulse special texts:
        "677d1ecb16c428775054f9de": {
            gdpr01: "Einverständniserklärung",
            gdpr02: "Danke, dass du am Tamedia Puls Check teilnimmst.\nWir möchten dich darüber informieren, dass wir folgende persönliche Informationen erfassen: deinen Namen und deine E-Mail-Adresse. Im Fall von Login via Mobiltelefon oder 2-Factor-Authentication auch deine Mobilnummer.\nSofern dein Unternehmen die Resultate nach soziodemographischen Merkmalen (z.B. Arbeitsregion, Funktionsgruppe) auswerten möchte, werden auch diese Daten erfasst.\nDeine persönlichen Informationen kannst du über den Link zur App jederzeit einsehen.\n\nZusätzlich erfassen wir die folgenden Resultate-Informationen:",
            gdpr03: "• Anonyme quantitative Antworten (Werte)",
            gdpr04: "• Anonyme qualitative Kommentare (Feedforwards)",
            // not shown when company settings.anonym = true
            gdpr05: "Für dein Unternehmen werden die Resultate einer Abteilung ausgewertet und angezeigt, wenn mindestens vier Personen an der Umfrage teilgenommen haben. In diesem Fall werden die Resultate-Informationen von den Mitgliedern der Abteilung, den Abteilungs- und den Firmen-Administratoren gesehen.",
            gdpr06: "Alle Daten werden auf Servern in der Schweiz gespeichert und nicht an Dritte weitergegeben.",
            // not shown when company settings.anonym = true
            gdpr07: "",
            gdpr08: "",
            gdprLinkMessage: "", //For more information click {link}
            gdprLinkTitle: "", //here
            gdprLinkUrl: "", //https://www.app.com
            gdpr09: "Wenn du Fragen hast, kannst du gerne mission.x{'@'}tx.group kontaktieren.",
            gdpr10: "Vielen Dank für dein Vertrauen!",
            gdpr11: "Beste Grüsse",
            gdpr12: "Tamedia & Joineer AG",
            gdprIgiveMyConsent: "Ich gebe meine Zustimmung",
            introTitle1: "Willkommen beim Tamedia Puls Check",
            introText2:
                "Mit dem Tamedia Puls Check wirst du monatlich nach deiner Meinung gefragt." +
                " So wird die Stimmung über die Zeit gemessen und sind die Verbesserungen entsprechend vergleichbar.",
        },

    };
}
