<template>
    <div>
        <v-dialog
            :fullscreen="$vuetify.display.xs"
            v-model="dialogVal"
            max-width="600"
        >
            <v-card>
                <v-card-title
                    class="text-white bg-orange-darken-1"
                >
                    <v-icon color="white" start
                        >mdi-account-arrow-right</v-icon
                    >
                    {{ $t("tooltips.moveUp") }}
                </v-card-title>

                <v-card-text class="mt-4">
                    <v-form v-model="isFormValid" ref="form">
                        <v-text-field
                            placeholder=""
                            v-model="task.summary"
                            :label="$t('tasks.title')"
                            class="mb-1"
                            :rules="[rules.required]"
                        ></v-text-field>

                        <!-- task origin -->
                            <span class="boxHeader"
                                >{{ $t("tasks.origin") }}:</span
                            >
                            <v-card border flat>
                                <TaskOrigin :task="task" />
                            </v-card>
                        <!-- /task origin -->

                        <v-card border flat class="mt-1">
                            <v-textarea
                                :rules="[rules.required]"
                                class="text-body-2"
                                variant="solo"
                                flat
                                rows="3"
                                name="comment"
                                :placeholder="$t('discussionMode.describe')"
                                v-model="escalateComment"
                            ></v-textarea>
                        </v-card>

                        <v-row class="mt-4" no-gutters>
                            <v-col cols="8">
                                <v-combobox
                                    ref="combo"
                                    hint="tooltips.pressEnter"
                                    :rules="[rules.required]"
                                    item-title="displayName"
                                    prepend-icon="mdi-account"
                                    v-model="selectedAdmin"
                                    :items="escalateAdmins"
                                    :label="$t('discussionMode.selectLabel')"
                                    @keydown="resetValidation"
                                    @keydown.enter="blurCombo"
                                >
                                    <template #message="{ message }">
                                        {{ $t(message) }}
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions class="px-6 pb-6">
                    <v-btn variant="text" theme="dark" color="grey" @click="closeDialog">{{
                        $t("buttons.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        id="escalateTaskBtn"
                        :disabled="!isFormValid"
                        color="primary"
                        @click="escalateTask"
                        >{{ $t("buttons.send") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { delegateTask, addComment, changeTitle } from "../../api/tasks.api";
import globalFunctions from "@/utils/globalFunctions";
import { useSnackbarStore } from "@/store/snackbar.store";
import { mapStores } from "pinia";
import { useAppStore } from "@/store/app.store";
import TaskOrigin from "../global/TaskOrigin.vue";
export default {
    name: "",
    props: ["task", "dialog"],
    components: {
        TaskOrigin,
    },
    mounted() {
        this.appStore.fetchEscalateAdmins();
    },
    data: () => ({
        isFormValid: false,
        selectedAdmin: null,
        escalateComment: "",
    }),
    methods: {
        closeDialog() {
            this.$emit("dialogStatus", false);
        },
        async escalateTask() {
            await addComment(this.task.id, {
                comment: this.escalateComment
            });
            await changeTitle(this.task.id, this.task.summary);

            // check if preselected admin or freeform
            if (this.selectedAdmin.id) {
                await delegateTask(this.task.id, this.selectedAdmin);
            } else {
                await delegateTask(this.task.id, {
                    displayName: this.selectedAdmin,
                    id: null
                });
            }

            globalFunctions.piwikAnalytics(['trackEvent','Task actions', 'Delegated']);
            this.task.delegated = true;
            this.$emit("dialogStatus", false);
            this.escalateComment = "";

            const snackbarStore = useSnackbarStore();
            snackbarStore.showSnackbar([true, "taskDelegated"]);
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        blurCombo() {
            this.$refs.combo.blur();
        }
    },
    computed: {
        ...mapStores( useAppStore ),

        escalateAdmins() {
            return this.appStore.escalateAdmins;
        },

        dialogVal: {
            get() {
                return this.dialog;
            },
            set() {
                this.$emit("dialogStatus", false);
            }
        },
         // rules for mandatory fields
        rules() {
            return {
                required: value => !!value || this.$t("tooltips.required")
            };
        }
    }
};
</script>

<style></style>
