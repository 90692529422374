import {httpClient} from './httpClient';
import { formatDate } from "@/utils/dateHelpers"
const END_POINT = '/tasks';

const getAllTasks = () => httpClient.get(END_POINT);

const createTask = (task) => {
  const formattedDate = {
    ...task,
    dueDate: formatDate(task.dueDate),
  };
  return httpClient.post(END_POINT, formattedDate)
};
const getTask = (task_id) => httpClient.get(`${END_POINT}/${task_id}`);
const assignTask = (task_id, memberId) => httpClient.put(`${END_POINT}/${task_id}/assign`, {id:memberId});
const changeDate = (task_id, newDate) => {
    const formattedDate = {
        ...newDate,
        date: formatDate(newDate.date),
      };

      return httpClient.put(
        `${END_POINT}/${task_id}/dueDate`,
        formattedDate
      );
}
const changeTitle = (task_id, newTitle) => httpClient.put(`${END_POINT}/${task_id}/summary`, {summary: newTitle});
const closeTask = (task_id) => httpClient.put(`${END_POINT}/${task_id}/close`);
const shareTask = (task_id, email, isInvite) => httpClient.post(`${END_POINT}/${task_id}/share`, {emailTo: email, invite: isInvite});

//reopen Task
const openTask = (task_id) => httpClient.put(`${END_POINT}/${task_id}/open`)
const deleteTask = (task_id) => httpClient.delete(`${END_POINT}/${task_id}`)

//escalation
const delegateTask = (task_id, assignee) => httpClient.put(`${END_POINT}/${task_id}/delegate`, assignee)

// task comments
const addComment = (task_id, comment) => httpClient.post(`${END_POINT}/${task_id}/comments`, comment);
const updateComment = (task_id, comment_id, comment) => httpClient.put(`${END_POINT}/${task_id}/comments/${comment_id}`, comment);
const deleteComment = (task_id, comment_id) => httpClient.delete(`${END_POINT}/${task_id}/comments/${comment_id}`);

export {
    getAllTasks,
    getTask,
    deleteTask,
    createTask,
    delegateTask,
    changeDate,
    changeTitle,
    closeTask,
    openTask,
    assignTask,
    shareTask,
    addComment,
    updateComment,
    deleteComment
}
