import { defineStore } from 'pinia';

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useEntitiesStore = defineStore('entities', {
    state: () => ({ 
        expandedEntities: []
    }),

    actions: {
        EXPAND_ENTITY(id) {
            if(!this.expandedEntities.includes(id)){
                this.expandedEntities.push(id)
            }
        },

        COLLAPSE_ENTITY(id) {
            this.expandedEntities = this.expandedEntities.filter(item => item !== id)
        },

        RESET_ENTITY_EXPAND() {
            this.expandedEntities = []
        }
    }
});