import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import colors from 'vuetify/util/colors';
import "vuetify/styles";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import { md } from 'vuetify/iconsets/md'
import { VTreeview } from 'vuetify/labs/VTreeview';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';
import i18n from '@/i18n';

//TODO: v3 migration
const myComponents = {
    ...components,
    VTreeview,
};

export default createVuetify({
    components: myComponents,
    defaults: {
        global: { },
        VAutocomplete: {
            variant: 'underlined',
            color: "primary",
        },
        VDatePicker: {
            variant: 'underlined',
            color: "primary",
        },
        VSelect: {
            variant: 'underlined',
            color: "primary",
        },
        VTextField: {
            variant: 'underlined',
            color: "primary",
        },
    },
    locale: {
        adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
            md
        }
    },
    theme: {
        cspNonce: typeof vuetifyValue !== 'undefined' ? vuetifyValue : '',
        themes: {
            light: {
                colors: {
                    primary: colors.blue.base, // blue
                    //'lighten-6': '#e6f4ff', // light blue
                    accentblue: "#d6efff",
                    joineer: "#9c2d56", // joineer red
                    secondary: "#757575",
                    accent: "#2196F3",
                    error: "#F44336",
                    info: "#FF9800",
                    success: "#8BC34A",
                    background: "#f3f3f3"
                },
            },
        },
    },
});
