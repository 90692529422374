import { defineStore } from 'pinia';

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useSnackbarStore = defineStore('snackbar', {
    state: () => ({ 
        show: false,
        type: null,
        duration: 1700,
        MIN_DURATION: 500,
        options: null,
    }),

    actions: {
        showSnackbar(argOptions) {
            let optionsArray;

            // allow type as the only param
            optionsArray = typeof argOptions === 'string'
                ? [true, argOptions]
                : [...argOptions];

            // default to showing the message
            if (typeof optionsArray[0] !== 'boolean') {
                optionsArray.unshift(true);
            }

            // allow [type, msg], dont treat number strings as a msg
            if ((typeof optionsArray[2] === 'string')
                && (String(parseInt(optionsArray[2], 10)) !== optionsArray[2])) {

                optionsArray[3] = { msg: optionsArray[2] };
                optionsArray[2] = undefined;
            }

            // allow [type, duration, msg]
            if (typeof optionsArray[3] === 'string') {
                optionsArray[3] = { msg: optionsArray[3] };
            }

            let [newSnackStatus, newType, duration, options] = optionsArray;

            this.show = newSnackStatus;
            this.type = newType;
            this.duration = duration;
            this.options = options;
        },
    }
});