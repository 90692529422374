import { useAppStore } from "@/store/app.store";
import { useAuthStore } from "@/store/auth.store";

// check if tutorial 
function showTutorial(name, tutrialUrls, locale) {
    const appStore = useAppStore();
    const authStore = useAuthStore();

    const storageKey = `wasTutorialShown_${name}|${authStore.user?.userId}`;
    const showTutorial =
            (!!authStore.user?.userId)
        && (!!tutrialUrls[locale])
        && (appStore.checkFeature('TUTORIAL_VIDEOS') || !!sessionStorage.getItem('TUTORIAL_VIDEOS'))
        && (!localStorage.getItem(storageKey) || !!sessionStorage.getItem('ignore_wasTutorialShown'));

    if (showTutorial) {
        localStorage.setItem(storageKey, 'true');
    }

    return !!showTutorial;
}

function tutorialUrl(tutrialUrls, locale) {
    const appStore = useAppStore();

    return tutrialUrls[locale]
    	|| tutrialUrls[appStore.mandatoryLang]
    	|| tutrialUrls[appStore.supportedLangs[0]]
    	|| tutrialUrls['de-CH'];
}


export {
    showTutorial,
    tutorialUrl,
};

