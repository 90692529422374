<template>
  <v-img
   class="mt-1"
    v-bind="$attrs"
    style="display: inline-block; max-width: 25px"
    :src="`/images/flags/${lang}.png`"

    width="22"
  ></v-img>
</template>

<script>
import { getGenericLocale } from "@/utils/languageHelpers";

export default {
  props: ["locale"],
  computed: {
    lang () {
      const lang = getGenericLocale(this.locale);
      if (this.locale !== lang) {
        window.lw('locale in <Flag> is not a language');
      }

      return lang;
    }
  },
};
</script>
