<template>
    <!-- single question from the list of survey questions on /discussion landing page -->

    <v-card
        v-if="question"
        class="transitioned"
        border
        flat
        style="margin-top: -1px"
        :class="{
            'my-3': commentsExpanded,
            'hover-pointer': question.ffCount > 0,
            'rounded-tl-0': i > 0,
            'rounded-tr-0': i > 0,
            'rounded-bl-0': i + 1 < totalQuestions,
            'rounded-br-0': i + 1 < totalQuestions
        }"
    >
        <div
            class="d-flex align-center pa-2 py-3 DMquestion"
            @click="toggleShowComments"
            style="font-size: 1rem"
        >
            <div class="dragHandle d-flex hover-drag mr-1">
                <v-icon size="x-small" style="margin-right: -2px"
                    >mdi-arrow-up-down</v-icon
                ><v-icon size="small">mdi-menu</v-icon>
            </div>

            <div class="ml-2 d-flex flex-column">
                <div
                    style="font-size: 1.2rem;
"  v-html="$mixin.parseLinks($mixin.translatedText(question.questionText, $i18n.locale))"
                >
                </div>
                <div class="d-flex mt-1 align-center">
                    <div
                        class=""
                        v-if="question.statistic"
                        :title="$t('dashboard.average')"
                    >
                        Ø
                        <strong
                            :class="avgTextColor(question.statistic.average)"
                            >{{ roundUp(question.statistic.average) }}</strong
                        >
                    </div>
                    <div
                        style=""
                        class="ml-2"
                        v-if="question.statistic"
                        :title="$t('dashboard.deviation')"
                    >
                        <span>↔</span>

                        <strong
                            :class="
                                question.statistic.hasOwnProperty('deviation')
                                    ? devTextColor(question.statistic.deviation)
                                    : ''
                            "
                            >{{ roundUp(question.statistic.deviation) }}</strong
                        >
                    </div>
                    <div style="position: relative;" class="mx-2">
                        <div v-if="question.ffCount < 100">
                            <v-icon color="grey" style="font-size: 1.5rem; "
                                >mdi-comment-outline</v-icon
                            >
                            <div
                                style="position: absolute;  top: 40%; left: 50%;transform: translate(-50%, -50%); font-size: 0.7rem; color: grey"
                            >
                                {{ question.ffCount }}
                            </div>
                        </div>
                        <div v-else>
                            <v-icon color="grey" style="font-size: 1.8rem; "
                                >mdi-comment-outline</v-icon
                            >
                            <div
                                style="position: absolute;  top: 40%; left: 50%;transform: translate(-50%, -50%); font-size: 0.65rem; color: grey"
                            >
                                {{ question.ffCount }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <v-spacer></v-spacer>
            <v-icon
                class="mx-2"
                :class=" { hidden: !question.isTeamQuestion }"
                size="large"
                :title="$t('survey.teamQuestion')"
            >md:diversity_3</v-icon>
            <v-btn class="mr-2 plain-txt learningNugget" variant="text" v-if="canShowAction" @click="handleAction" :title="$mixin.translatedText(
                   action?.descriptions,
                  $i18n.locale
                )">
                <span v-show="$vuetify.display.smAndUp" style="font-size: 1rem;" class="text-primary"
                    >Learning Nugget</span
                ><span style="font-size: 1.5rem;"> &#127891;</span>
            </v-btn>
            <v-btn
                @click.stop="toggleShowComments"
                size="small"
                icon
                variant="text"
                :ripple="false"
                class="mx-1"
                :class=" { hidden: !(question.ffCount > 0) }"
                :title="
                    commentsExpanded ? 'Hide Feedforwards' : 'Show Feedforwards'
                "
                ><v-icon>{{
                    commentsExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
            >
        </div>

        <v-card
            v-if="loadingError"
            border flat
            class="bg-red-lighten-5 pa-3 d-flex"
            style="border: 1px solid red; color: red"
        >
            <div class="d-flex align-center">
                <v-icon color="red" size="large" class="mr-4">mdi-alert</v-icon>
                {{ loadingError }}
            </div>
            <v-btn icon variant="text" color="red" @click="loadingError = null"
                ><v-icon>mdi-close</v-icon></v-btn
            >
        </v-card>

        <v-expand-transition>
            <div v-if="commentsExpanded && comments" >
                <div
                    v-for="(feedforwardItem, commentIdx) in comments"
                    :key="feedforwardItem.id"
                    class="pos-relative mb-5"
                >
                    <v-divider
                        class="mb-4"
                        v-if="commentIdx < comments.length"
                    ></v-divider>

                    <VotingBar
                        v-if="showReactions"
                        :userId="userId"
                        :feedforwardId="feedforwardItem.id"
                        :reactions="feedforwardItem.reactions"
                        @addUpvote="
                            feedforwardItem.reactions.upvote.push(userId)
                        "
                        @removeUpvote="
                            feedforwardItem.reactions.upvote = feedforwardItem.reactions.upvote.filter(
                                id => id !== userId
                            )
                        "
                        @addCelebrate="
                            feedforwardItem.reactions.celebrate.push(userId)
                        "
                        @removeCelebrate="
                            feedforwardItem.reactions.celebrate = feedforwardItem.reactions.celebrate.filter(
                                id => id !== userId
                            )
                        "
                    />
                    <div class="d-flex px-5">
                        <v-avatar
                            color="yellow-darken-1"
                            class="mr-2"
                            size="34"
                        >
                            <v-icon color="white">mdi-account</v-icon>
                        </v-avatar>

                        <div>
                            <p class="mb-0 text-grey">
                                {{
                                    feedforwardItem.author
                                        ? feedforwardItem.author.displayName
                                        : `${$t("discussionMode.anonym")}`
                                }}:
                            </p>
                            <p class="">
                                {{ feedforwardItem.answer }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </v-expand-transition>

        <v-dialog v-model="actionDialog">
            <v-card>
                <v-card-title>Actions</v-card-title>
                <v-card-text>
                    {{ action }}
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { getFeedForwards } from "@/api/discussion.api";
import { roundUp, avgTextColor, devTextColor } from "@/utils/dashboardCode.js";
import VotingBar from "@/components/Voting/VotingBar.vue";
import globalFunctions from "@/utils/globalFunctions";
import { mapStores } from "pinia";
import { useAuthStore } from "@/store/auth.store";
import { useAppStore } from "@/store/app.store";
export default {
    props: ["discussionId", "question", "i", "totalQuestions", "actions"],
    mounted() {},
    components: { VotingBar },
    data: () => ({
        comments: null,
        commentsExpanded: false,
        loadingError: null,
        actionDialog: false
    }),
    methods: {
        handleAction() {
            if (this.action) {
                globalFunctions.piwikAnalytics(['trackEvent','Nugget', 'Clicked']);
                let actionLink = globalFunctions.translatedText(
                    this.action.links,
                    this.$i18n.locale
                );
                window.open(actionLink, "_blank");
            }
        },
        toggleShowComments() {
            if (this.question.ffCount > 0) {
                if (this.commentsExpanded) {
                    this.commentsExpanded = false;
                } else {
                    if (!this.comments) {
                        this.setFeedForwards();
                    } else {
                        this.commentsExpanded = true;
                    }
                }
            }
        },

        roundUp(val) {
            return roundUp(val);
        },
        avgTextColor(avg) {
            return avgTextColor(this.topGrade, avg);
        },
        devTextColor(dev) {
            return devTextColor(this.topGrade, dev);
        },
        setFeedForwards() {
            if (window.$devMode) {
                console.log(
                    "getting Feedforwards for question " + this.question.id
                );
            }
            this.loadingError = null;

            getFeedForwards(this.discussionId, this.question.id)
                .then(response => {
                    if (this.showReactions) {
                        this.comments = response.data.map(comment => {
                            let obj = { ...comment };

                            if (comment.reactions === null) {
                                obj.reactions = { upvote: [], celebrate: [] };
                            }

                            return obj;
                        });
                    } else {
                        this.comments = response.data;
                    }
                    this.commentsExpanded = true;
                })
                .catch(error => {
                    this.loadingError =
                        "Unable to retrieve Feedforwards: " + globalFunctions.parseError(error);
                });
        }
    },
    computed: {
        ...mapStores( useAuthStore, useAppStore ),

        topGrade() {
            return this.appStore.topGrade;
        },
        companyId() {
            return this.authStore.companyId;
        },
        showReactions() {
            return this.appStore.checkFeature("REACTIONS_ENABLED");
        },
        userId() {
            return this.authStore.user.userId;
        },
        action() {
            if (this.actions.length > 0 && this.question.topics.length > 0) {
                let matchingActions = this.actions.filter(item =>
                    this.question.topics.includes(item.topic)
                );

                return matchingActions.length > 0 ? matchingActions[0] : null;

            } else {
                return null;
            }
        },
        canShowAction(){
            if(this.action){
                let link =  globalFunctions.translatedText(
                   this.action.links,
                  this.$i18n.locale
                )
                //make sure that "NO_LINK" Nuggets are not shown in GUI
                return link !== 'NO_LINK'

            }else{
                return null
            }
        }
    },
    watch: {}
};
</script>

<style scoped>
.hidden {
    visibility: hidden;
}

.DMquestion {
    transition: background-color 0.12s linear;
}

.DMquestion:hover {
    background-color: rgb(246, 245, 245);
}
</style>
