import axiosRaw from "axios";
import { jwtDecode } from "jwt-decode";
import { setupCache, buildMemoryStorage, buildKeyGenerator } from 'axios-cache-interceptor';
import Configuration from "../services/configuration";
import router from "@/router";
import { useAuthStore } from "@/store/auth.store";

let API_BASE = '/api/v1/';
const httpClient = axiosRaw.create({
    baseURL: Configuration.value('backendUrl') + API_BASE,
    timeout: 20000,
});

const axiosInstance = axiosRaw.create({
    baseURL: Configuration.value('backendUrl') + API_BASE,
    timeout: 20000,
});

const cacheOptions = {
    //debug: (...args) => { console.log( '[ACI] ', ...args); },
    debug: () => {},
    headerInterpreter: () => 'not enough headers', // ttl from setupOptions or default to (5min)
    methods: ['get', 'GET'],
    cacheTakeover: false,
    storage: buildMemoryStorage(
    /* cloneData default=false*/ false,
    /* cleanupInterval default=false*/ 6*60*1000,
    /* maxEntries default=false*/ 1000
  )
};

const cachedHttpClient = setupCache(axiosInstance, cacheOptions);

const generateCacheId = function generateCacheId(companyId, userId) {
    return buildKeyGenerator(request => ({
        ...request,
        custom: `companyId: ${companyId}, userId: ${userId}`
    }));
}



// -------------- request interceptor --------------

httpClient.interceptors.request.use(attachAuthorizationHeader);
cachedHttpClient.interceptors.request.use(attachAuthorizationHeader);

let reAuthenticationInProgress = null;
const BUFFER_TIME = 1 * 60 * 1000; // 1 minutes in milliseconds

function attachAuthorizationHeader(config) {
    l("in attachAuthorizationHeader");

    const authStore = useAuthStore();
    const user = authStore.user;

    if (user?.accessToken) {
        let decoded;
        try {
            decoded = jwtDecode(user.accessToken);
            l("in attachAuthorizationHeader, decoded?.exp:", decoded?.exp);
        } catch (ex) {
            le("httpClient: error while decoding token", ex);
        }

        if (!decoded || (Date.now() + BUFFER_TIME >= decoded.exp * 1000)) {

            if (!reAuthenticationInProgress) {
                l("httpClient: token expired or not present / valid, re-authenticating")
                reAuthenticationInProgress = authStore.relogin();
            } else {
                l("httpClient: re-authentication already in progress, waiting")
            }

            const thisRequestsReAuth = reAuthenticationInProgress;

            return thisRequestsReAuth.then((user) => {

                try {
                    l("in reAuthenticationInProgress.then, user:", user);
                    config.headers["Authorization"] = "Bearer " + user.accessToken;
                } catch (ex) {
                    l("in reAuthenticationInProgress.then, catch:", ex);
                }

                return config;
            }).catch(reason => {
                le("httpClient: error in reAuthenticationInProgress:", reason);

                return config;
            })
            .finally(() => {
                l("in reAuthenticationInProgress.finally");

                if (thisRequestsReAuth === reAuthenticationInProgress) {
                    reAuthenticationInProgress = null;
                } else {
                    if (reAuthenticationInProgress) {
                        l("in reAuthenticationInProgress.finally, another re-Auth is in progress already");
                    } else {
                        l("in reAuthenticationInProgress.finally, re-Auth was set to null already");
                    }
                }
            });
        } else {
            config.headers["Authorization"] = "Bearer " + user.accessToken;
        }
    }

    return config;
}


// -------------- response interceptor --------------

httpClient.interceptors.response.use(handleResponseSuccess, handleResponseError);
cachedHttpClient.interceptors.response.use(handleResponseSuccess, handleResponseError);

function handleResponseSuccess(response) {
    const isFullHttpLoggingEnabled = false;
    if ($devMode && isFullHttpLoggingEnabled) {
        l("in httpClient, handleResponseSuccess");

        /*if (false && response.config.url.match(/^\/takesurvey/)) {
            lw("httpClient, setting data for url /^\/takesurvey/ to []");
            response.data = [];
        }*/

        console.group(`httpClient response %c${response.config.url} :`, "font-weight: bold; color: green");
        lj('request', response.request);
        lj('data', response.data);
        lj('response', response);
        console.groupEnd();
    }
    return response;
}

// Error handling

/* old format
{
    "error": "Bad Request",
    "status": 400,
    "message": "User with email 'armin@test.joineer.ch' already exists in this company.",
    "path": "/api/v1/users"
    "timestamp": "2022-11-22T14:31:18.274+00:00",
}*/

/* new format
{
    "type": "https://joineer.com/v1/problem/user-duplicate",
    "title": "User already exists",
    "status": 400,
    "detail": "User with email 'mirko@test.joineer.ch' already exists in this company.",
    "instance": "/api/v1/users",
    "timestamp": "2023-06-26T13:30:57.058400024Z"
}*/

let loginPromise = null;

const errorKeysMap = {
    title: 'error',
    detail: 'message',
    instance: 'path',
};

function hydrateErrorForLegacySupport(error) {
    let rawErrorData = error.response?.data;
    let errorData;

    if (rawErrorData instanceof ArrayBuffer) {
        try {
            errorData = JSON.parse(new TextDecoder('utf-8').decode(rawErrorData));
        } catch (ex) {
            errorData = { detail: 'Unknown error', errorProcessingError: ex };
        }
    } else {
        errorData = rawErrorData;
    }

    if (errorData) {
        Object.keys(errorKeysMap).forEach((key) => {
            if (!rawErrorData[errorKeysMap[key]] && errorData[key]) {
                rawErrorData[errorKeysMap[key]] = errorData[key];
            }
        });

        error.message = errorData.message || rawErrorData.message;
    }
}

// Error handler
function handleResponseError(error) {
    lj("in handleResponseError, error?.response: ", error?.response);

    // hydrate message to support legacy data format
    hydrateErrorForLegacySupport(error);

    // Return any error which is not due to authentication back to the calling service
    if (error?.response?.status !== 401 || error?.config?._retry > 1) {

        // Not authenticated ??2FA
        if(    error?.response?.status === 403
            && error?.response?.data?.type === "https://joineer.com/v1/problem/strong-authentication-required") {
            console.log("[httpClient]: Error 403, not authenticated strong. Redirected");

            // redirect to 2fa
            router.push({name: '2fa'});
        }

        return Promise.reject(error);
    }

    if (error?.config?._retry) {
        l('in handleResponseError, _retry:', error?.config?._retry);
    }

    if (error?.config) {
        error.config._retry = ++error.config._retry || 1;
    }

    return afterLogin(loginPromise || (loginPromise = useAuthStore().relogin()), error);
}

function afterLogin(thenable, error) {
    l('in afterLogin, thenable:', thenable);

    return thenable
        .then((user) => {
            l('httpClient re-exec after re-auth: ', user);

            if (user?.accessToken) {
                // New request with new token
                error.config.headers['Authorization'] = `Bearer ${user.accessToken}`;
            } else {
                const ex = 'httpClient re-exec after re-auth NO user?.accessToken: ' + user?.accessToken;
                l(ex);

                return Promise.reject(ex);
            }

            return httpClient.request(error.config);
        }).
        catch(error => {
            if (error?.response?.status === 401) {
                const resp = error.response;
                const msg = '401: '+ resp.config?.baseURL + resp.config?.url.substr(1) + ', '
                            + resp.data?.error + ': ' + resp.data?.message;
                l('httpClient error while re-auth: ', msg);
            } else {
                le('httpClient error while re-auth: ', error);
            }

            hydrateErrorForLegacySupport(error);

            return Promise.reject(error);
        })
        .finally(() => {
            l('httpClient re-auth in finally: ', loginPromise);
            if (loginPromise === thenable) {
                l('httpClient re-auth setting "loginPromise = null", was: ', loginPromise);
                loginPromise = null;
            } else {
                if (loginPromise) {
                    l("in afterLogin.finally, another re-auth is in progress already");
                } else {
                    l("in afterLogin.finally, loginPromise was set to null already");
                }
            }
        });
}


export {
    cachedHttpClient,
    generateCacheId,
    httpClient,
};
