import DOMPurify from "dompurify";
import i18n  from '../i18n'
// global functions made available in the Vue instance via mixins
import { getGenericLocale } from '@/utils/languageHelpers';

function getToStringWithWarning(error) {
    window.l('%cgetToStringWithWarning() is used for: ', 'font-weight: bolder; color: darkorange', error);
    return error?.toString().replace('[object Object]', '').trim();
}

export function getInitials(
    firstName: string | undefined,
    lastName?: string | undefined,
): string {
    if (!firstName && !lastName) {
        return "";
    }

    let firstLetter = toUpperLetter(firstName);
    let lastLetter = toUpperLetter(lastName);

    if (!firstLetter) {
        firstLetter = lastLetter;
        lastLetter = toUpperLetter(lastName, 1);
    }
    if (!lastLetter) {
        lastLetter = toUpperLetter(firstName, 1);
    }
    if (!lastLetter) {
        lastLetter = firstLetter;
    }
    return firstLetter + lastLetter;
};

export function initials(user: {
    displayName?: string;
    firstName?: string;
    lastName?: string;
}): string {
    if (user?.displayName) {
        let displayName = user.displayName.replace(/^ +|(?<= ) +| +$/g, '');
        let nameArr = displayName.split(" ");
        if (nameArr.length > 1) {
            let firstName = nameArr[0];
            let lastName = nameArr[nameArr.length - 1];
            return getInitials(firstName, lastName);
        } else {
            return getInitials(displayName);
        }
    } else if (user?.firstName || user?.lastName) {
        return getInitials(user.firstName, user.lastName);
    } else {
        console.log("Initials: incorrect parameter: " + user);
        return "";
    }
};

function toUpperLetter(str: string | undefined, idx: number = 0): string {
    return ((str ?? '')[idx] || '')?.toUpperCase() || '';
}

const mixins = {
        initials,
        toUpperLetter,
        piwikAnalytics(event): void {
            // sample event:  ['trackEvent','Task actions', 'Comment added']
            // @ts-ignore
            (window._paq || (window._paq = [])).push(event);
        },
        translatedText(texts, lang) {
            if (texts) {
                if (typeof texts == "object") {
                    return texts.hasOwnProperty(lang)
                        ? texts[lang] 
                        : texts.hasOwnProperty(getGenericLocale(lang))
                            ? texts[getGenericLocale(lang)]
                            : Object.values(texts)[0];
                }

                if (typeof texts == "string") {
                    window.lw('texts is already a string:', texts);

                    return texts;
                }
            } else {
                return null;
            }
        },
        parseError(error): string {
            window.l('in parseError', error);
            //window.l('in parseError, stack', new Error().stack);

            /* old format
            {
                "error": "Bad Request",
                "status": 400,
                "message": "User with email 'armin@test.joineer.ch' already exists in this company.",
                "path": "/api/v1/users"
                "timestamp": "2022-11-22T14:31:18.274+00:00",
            }*/

            /* new format
            {
                "type": "https://joineer.com/v1/problem/user-duplicate",
                "title": "User already exists",
                "status": 400,
                "detail": "User with email 'mirko@test.joineer.ch' already exists in this company.",
                "instance": "/api/v1/users",
                "timestamp": "2023-06-26T13:30:57.058400024Z"
            }*/

            /* new format
            {
                "type": "https://joineer.com/v1/problem/excel-data-import-failed",
                "title": "Error while importing data",
                "status": 400,
                "detail": "No Company Admin defined!",
                "instance": "/admin/import/file",
                "timestamp": "2023-06-07T14:02:43.588988752Z"
            }*/

            const { t, te } = i18n.global;
            let errorType = null;

            if (error?.response?.data?.type
                    && te(`errors.${(errorType = error.response.data.type.split('/').pop())}`)) {
                return <string>t(`errors.${errorType}`); //TODO VUE 3 - remove typecast as not needed
            }

            const computedResponseMsg =
                error?.response?.data?.code
                || error?.response?.data?.message
                || error?.message
                || error?.response?.data?.detail
                || error?.response?.title
                || error?.response?.data?.type
                || error?.response?.type
                || getToStringWithWarning(error)
                || 'genericApiErrorMessage';

            const computedTranslatedMsg = te(`errors.${computedResponseMsg}`)
                ? t(`errors.${computedResponseMsg}`)
                : te(computedResponseMsg)
                    ? t(computedResponseMsg)
                    : computedResponseMsg;
            return <string>computedTranslatedMsg; //TODO VUE 3 - remove typecast as not needed
        },
        getErrorsForSettledPromises(promiseResults) {
            return promiseResults.reduce((acc, promise) => {
                return (promise.status !== 'fulfilled')
                    ? acc && (acc.replace(/ ?[.!,] ?$/, '') + ', ' + this.parseError(promise.reason)) || this.parseError(promise.reason)
                    : acc;
            }, '');
        },
        parseLinks(text: string) {
            if (!text) {
                window.le('parseLinks, text has no value:', text);

                return '';
            }
            // Check that the input does not contain any HTML or script tags
            const sanitizedInput = text.replace(/<[^>]+>/gi, "");

            // Use DOMPurify to remove any unwanted HTML tags and attributes
            const cleanInput = DOMPurify.sanitize(sanitizedInput);

            // Find markdown links and replace them with HTML anchor links
            const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
            const html = cleanInput.replace(
                markdownLinkRegex,
                '<a target="_blank" href="$2">$1</a>'
            ).replace(/(?<=[=;,'"])data:/, '');

            // Find naked links (without markdown) and replace them with HTML anchor links
            const linkRegex = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*)/gi;

            const linkedText = html.replace(linkRegex, (match, p1, p2, offset, string) => {
                // Check if the matched link is already within an HTML attribute
                const prevChar = string[offset - 1];
                if (prevChar === '"' || prevChar === "'") {
                    return match;
                }

                return `<a target="_blank" href="${p1}">Link</a>`;
            });

            return linkedText;
        },
        removeLinks(text: string) {
            // removes naked and also markdown links from text
            text = text.replace(/\bhttps?:\/\/\S+\b/g, '');

            // Remove markdown links
            text = text.replace(/\[([^\]]+)\]\((https?:\/\/\S+)?\)/g, '[$1]');

            return text;
        },
};

export interface UserSettings {
    userLanguage: string;
    languages: string[];
}

export default mixins;
