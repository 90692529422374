import { defineStore } from 'pinia';

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useAdhocStore = defineStore('adhoc', {
    state: () => ({ 
        members: [], //detailed list of members for GUI
        survey: {
            startDate: null,
            endDate: null,
            title: null,
            participants: [], //just ids
            questions: []
        },
    }),

    actions: {
        ADD_QUESTION(question) {
            this.survey.questions.push(question);
        },

        DELETE_QUESTION(id) {
            this.survey.questions = this.survey.questions.filter(
                question => question.id !== id
            );
        },
        
        REORDER_QUESTIONS(list) {
            this.survey.questions = list
        },
        
        SET_DETAILS(details) {
            this.survey.startDate = details.startDate;
            this.survey.endDate = details.endDate;
            this.survey.title = details.title;
        },
        
        SET_MEMBERS(members) {
            this.members = members; //set array with names for GUI purposes
            this.survey.participants = members.map(member => member.id) //set array with just ids for API
        },

        RESET_ALL(){
            this.$reset();
        }
    }
});