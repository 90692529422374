<template>
    <div>
        <!-- Google Column chart (vanilla, not material design) https://developers.google.com/chart/interactive/docs/gallery/columnchart -->
        <div
            v-if="isValidData"
            :id="
                `${
                    compareStat ? 'compareHistogram' : 'histogram'
                }-${questionId}`
            "
        ></div>

        <v-alert v-else class="mt-4" type="info" color="grey" variant="outlined"
            >{{ $t("errors.noData") }}
        </v-alert>
    </div>
</template>

<script>
export default {
    //compareStat only used for rendering reasons to make sure side by side charts have different ids
    props: ["questionId", "histogramRaw", "compareStat"],
    components: {},
    mounted() {
        this.createChart();
    },
    data: () => ({}),
    methods: {
        createChart() {
            if (this.isValidData) {
                 // material design disabled since it doesn't support annotations within bars
                /*       google.charts.load("current", {
                    packages: ["corechart", "bar"]
                }); */

                // vanilla
                google.charts.load("current", {
                    packages: ["corechart", "bar"]
                });

                google.charts.setOnLoadCallback(this.drawChart);
            }
        },
        drawChart() {
            let data = google.visualization.arrayToDataTable(this.chartData);
            // material design disabled since it doesn't support annotations within bars
            /*     let chart = new google.charts.Bar(
                document.getElementById(
                    `${this.compareStat ? "compareHistogram" : "histogram"}-${
                        this.questionId
                    }`
                )
            );

            chart.draw(
                data,
                google.charts.Bar.convertOptions(this.chartOptions)
            ); */

            // vanilla
            let chart = new google.visualization.ColumnChart(
                document.getElementById(
                    `${this.compareStat ? "compareHistogram" : "histogram"}-${
                        this.questionId
                    }`
                )
            );

            chart.draw(data, this.chartOptions);
        }
    },
    computed: {
        maximum() {
            let highest = 0;
            this.histogramRaw.forEach(num => {
                if (num > highest) {
                    highest = num;
                }
            });
            return highest;
        },
        isValidData() {
            if (this.histogramRaw) {
                return !this.histogramRaw.every(function(e) {
                    return e === 0;
                });
            } else {
                return false;
            }
        },
        chartData() {
            if (this.histogramRaw) {
                let data = [
                    ["Grade", "Number of people", { role: "annotation" }]
                ];

                for (let index = 0; index < this.histogramRaw.length; index++) {
                    data.push([
                        (index + 1).toString(),
                        this.histogramRaw[index],
                        this.histogramRaw[index] === 0 ? null : this.histogramRaw[index],
                    ]);
                }
                return data;
            } else {
                return [];
            }
        },
        chartOptions() {
            return {
                //theme: 'material',
                animation: {
                    duration: 500,
                    startup: true
                },
                colors: ["#AD52D1"],
                legend: {
                    position: "none"
                },
                backgroundColor: "transparent",
                chartArea: {
                    backgroundColor: "transparent"
                },
                bars: "vertical",
                hAxis: {
                    title: this.$t("stats.histogramGrade"),
                    textStyle: {
                        color: "grey"
                    },
                },
                vAxis: {
                    viewWindow: {
                        min: 0,
                        max: this.maximum > 99
                            ? this.maximum * 1.25
                            : this.maximum > 2
                                ? this.maximum + 1
                                : this.maximum > 1
                                    ? this.maximum + 0.5
                                    : this.maximum > 0
                                        ? this.maximum + 0.25
                                        : 1,
                    },
                    title: this.$t("stats.histogramNr"),
                    textStyle: {
                        color: "grey"
                    },
                }
            };
        },
        lang() {
            return this.$i18n.locale;
        }
    },
    watch: {
        lang() {
            this.createChart(); //generate a new chart with translated labels when locale changes
        }
    }
};
</script>
