// Converting a tree flattened into an array back to a tree structure

export function binarySeek(elements, el) {
    let min = 0;
    let max = elements.length - 1;
    let middle = (min + max) >> 1;
    const elementName = el.compareName;
    let middleName;
    let middleLevel;

    while (min <= max) {
        middleName = elements[middle].compareName;
        middleLevel = elements[middle].level;

        if ((middleLevel === el.level) || (middleLevel && el.level)) {
            if (elementName === middleName)
                return middle
            else (elementName < middleName)
                    ? (max = middle - 1)
                    : (min = middle + 1);
        } else {
            (el.level < middleLevel)
                ? (min = middle + 1)
                : (max = middle - 1);
        }

        middle = (min + max) >> 1;
    }

    return middle + 1;
}
//  TODO- V3-end
export function flattenTree(accummulatorArray, items) {
    const acc = accummulatorArray || [];
    if (items?.constructor === Array) {
        return items.reduce(flattenTree, acc);
    }

    acc.push(items);
    // TODO: VUE 3: END DEBUG
    if (typeof items.forEachDescendant === 'function') {
        items.forEachDescendant((item) => acc.push(item));
    } else {
        le('forEachDescendant is not a function on item:', items);
    }

    return acc;
}

export function forEachAncestor(func, ...args) {
    if (this.parent) {
        func(this.parent, ...args);
        this.parent.forEachAncestor(func, ...args);
    }
}

export function forEachDescendant(func, ...args) {
    //this.children ?? window.l('in forEachDescendant, this:', this); //TODO: remove !!!
    this.children?.forEach((child) => {
        func(child, ...args);
        !child.forEachDescendant ? l('NO forEachDescendant on', child) :
        child.forEachDescendant(func, ...args);
    });
}

export function forEachDescendantExcluding(excludedDescendant, func, ...args) {
    this.children?.forEach((child) => {
        if (child === excludedDescendant) return;

        func(child, ...args);
        child.forEachDescendantExcluding(excludedDescendant, func, ...args);
    });
}

export function getAncestors(item) {
    const ancestors = [];
    let child = item;

    while (child?.parent) {
        ancestors.push(child.parent);
        child = child.parent;
    }

    return ancestors;
}

export function getRootTeams(teams) {
    return teams
        .filter((team) => !team.hasParent)
        .sort((a, b) => {
            if ((a.level === b.level) || (a.level && b.level)) {
                return a.compareName === b.compareName
                    ? 0
                    : a.compareName < b.compareName
                        ? -1
                        : 1;
            }

            return b.level - a.level;
        });
}
 
export function initEntity(team, setFunction, entitiesMap) {
    if (team.children) {
        l(`in initEntity, team already has children (not skipping init)`, team);
        ls(`json:`, team);
        if (setFunction) {
            delete team.children;
        }
    }

    const newTeamProps = {
        children : null,
        compareName : (team.name || '').toUpperCase(),
        disabled : false,
        forEachAncestor : forEachAncestor,
        forEachDescendant : forEachDescendant,
        forEachDescendantExcluding : forEachDescendantExcluding,
        hasParent: null,
        parent : null,
        title : team.name,  //  TODO-V3-end
        value : team.id,
    };

    if (setFunction) {
        Object.entries(newTeamProps).forEach(([key, value]) => {
            setFunction(team, key, value);
        });
    } else {
        Object.assign(team, newTeamProps);
    }

    if (team.parents?.length) {
        team.hasParent = true;
    }

    if (entitiesMap) {
        entitiesMap.set(team.id, team);
    }

    return team;
}

//populate 'team' and its descendants with their respective children
export function generateTeamTree(teams, setFunction, noParent, withMap) {
    const entitiesMap = new Map();
    const teamsMap = new Map();
    const teamDetails = JSON.parse(JSON.stringify(teams))  // TODO-V3-end with v-treeview will check this.
    teamDetails.forEach(team => initEntity(team, setFunction, entitiesMap, teamsMap));

    teamDetails.forEach((team) => {
        team.subEntityIds.forEach((subEntityId) => {
            const child = entitiesMap.get(subEntityId);
            if (!child) {
                le(`no team with subEntityId = ${subEntityId} found in team: `, team,
                   '\nall teams:', teamDetails.map(t=>t.id), teamDetails);
                return;
            }
            child.hasParent = true;
            if (!noParent) {

                if (setFunction) {
                    setFunction(child, 'parent', team);
                } else {
                    child.parent = team;
                }
            }
            if (!team.children) {
                team.children = [];
            }
            team.children.splice(binarySeek(team.children, child), 0, child);
        });
    });

    if (withMap) {
        const teamsMap = new Map();

        teamDetails.forEach(team => !team.children?.length && teamsMap.set(team.id, team));

        return ({
            rootTeams: getRootTeams(teamDetails),
            entityIdToEntityMap: entitiesMap,
            teamIdToTeamMap: teamsMap,
        });
    } else {
        return getRootTeams(teamDetails);
    }
}

export function hydrateChildrenFromEntityIds(team, teams, entitiesMapArg, setFunction) {
    if (team.children) {
        l(`team ${team}, already has children ... skipping hydrateChildrenFromEntityIds for it` )
        return;
    }

    const entitiesMap = entitiesMapArg || new Map();
    if (!entitiesMapArg) {
        l('[expensive] in hydrateChildrenFromEntityIds generating a full map, teams, team', teams, team);
        teams.forEach((team) => {
            entitiesMap.set(team.id, team);
        });
    }
    if (setFunction) {
        setFunction(team, 'children', []);
    } else  {
        team.children = [];
    }

    team.subEntityIds.forEach((subEntityId) => {
        const child = entitiesMap.get(subEntityId);

        if (!child) {
            le(`missing entity with id === ${subEntityId}, for parent ${team} with id ${team.id}` )
            return;
        }

        child.compareName = (child.name || '').toUpperCase();
        team.children.splice(binarySeek(team.children, child), 0, child);
        hydrateChildrenFromEntityIds(child, null, entitiesMap, setFunction);
    });

    return team;
}

export function sumMemberCount(entity){
    let count = entity.memberCount || 0;
    entity?.children?.forEach(child => {
        count += sumMemberCount(child);
    });
    return count;
}

export function sumOnDescendants(entity, field, childField){
    l('in sumOnDescendants, entity:', { entity } );

    if (!entity) {
       le('in sumOnDescendants: no entity, field:', field);
       return 0;
    }

    if (!childField) {
        childField = 'all_' + field;
    }

    if (isNaN(entity[childField])) {
        entity[childField] = entity[field] || 0;

        entity?.children?.forEach(child => {
            entity[childField] += sumOnDescendants(child, field, childField);
        });
    }

    return entity[childField];
}
