<template>
<!-- visual bar from red to green explaining average and deviation stats -->
    <div class="pa-3 mt-2 bg-white">
        <!-- BIG SCREEN GRAPH- -->
        <div v-if="$vuetify.display.smAndUp">
            <!-- AVERAGE -->
            <div v-if="mode === 'avg' && averageGrades">
                <v-row no-gutters>
                    <v-col
                        v-for="grade in averageGrades"
                        :key="`avg.${grade.color}`"
                        cols="3"
                        style="padding: 1px"
                    >
                        <v-sheet class="graph-section pa-2 text-center flex-wrap" :color="grade.color">
                            <div>{{ grade.range }}</div></v-sheet
                        >
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-1">
                    <v-col
                        v-for="description in avgDescriptions"
                        :key="description.title"
                        :cols="description.cols"
                        class="graph-description"
                    >
                        <strong>{{ $t(description.title) }}</strong>
                        <div class="text-caption">
                            {{ $t(description.text) }}
                        </div>
                    </v-col>
                </v-row>
            </div>

            <!-- DEVIATION -->
            <div v-if="mode === 'dev' && deviations">
                <v-row no-gutters>
                    <v-col
                        v-for="deviation in deviations"
                        :key="`dev.${deviation.color}`"
                        cols="4"
                        style="padding: 1px"
                    >
                        <v-sheet class="graph-section pa-2 text-center flex-wrap" :color="deviation.color">
                            <div>{{ deviation.range }}</div></v-sheet
                        >
                        <div class="graph-description">
                            <strong>{{ $t(deviation.title) }}</strong>
                            <div class="text-caption">
                                {{ $t(deviation.text) }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>

        <!-- SMALL SCREEN GRAPH-->
        <div v-else>
            <!-- AVERAGE -->
            <div v-if="mode === 'avg' && averageGrades">
                <div class="mb-2 d-flex align-center">
                    <div>
                        <v-sheet
                            class="graph-mini-section"
                            :color="averageGrades[3].color"
                        >
                            <div>{{ averageGrades[3].range }}</div></v-sheet
                        >
                    </div>

                    <div>
                        <strong> {{ $t("stats.avgGraph3Label") }}</strong>
                        <div class="text-caption">
                            {{ $t("stats.avgGraph3Explanation") }}
                        </div>
                    </div>
                </div>
                <div class="mb-2 d-flex align-center">
                    <div>
                        <v-sheet
                            class="graph-mini-section"
                            :color="averageGrades[2].color"
                        >
                            <div>{{ averageGrades[2].range }}</div></v-sheet
                        >
                    </div>

                    <div>
                        <strong> {{ $t("stats.avgGraph2Label") }}</strong>
                        <div class="text-caption">
                            {{ $t("stats.avgGraph2Explanation") }}
                        </div>
                    </div>
                </div>

                <div class="d-flex align-center">
                    <div>
                        <v-sheet
                            style="margin-bottom: 2px"
                            class="graph-mini-section"
                            :color="averageGrades[1].color"
                        >
                            <div>{{ averageGrades[1].range }}</div></v-sheet
                        >
                        <v-sheet
                            class="graph-mini-section"
                            :color="averageGrades[0].color"
                        >
                            <div>{{ averageGrades[0].range }}</div></v-sheet
                        >
                    </div>

                    <div>
                        <strong> {{ $t("stats.avgGraph1Label") }}</strong>
                        <div class="text-caption">
                            {{ $t("stats.avgGraph1Explanation") }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- DEVIATION-->
            <div v-if="mode === 'dev' && deviations">
                <div
                    v-for="deviation in deviations"
                    :key="`dev-${deviation.color}`"
                    class="d-flex align-center mb-1"
                >
                    <div>
                        <v-sheet
                            class="graph-mini-section"
                            :color="deviation.color"
                        >
                            <div>{{ deviation.range }}</div></v-sheet
                        >
                    </div>

                    <div>
                        <strong>{{ $t(deviation.title) }}</strong>
                        <div class="text-caption">
                            {{ $t(deviation.text) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useAppStore } from '@/store/app.store';
import { mapStores } from 'pinia';

export default {
    props: ["mode"],
    components: {},
    data: () => ({
        avgGrades6: [
            {
                range: "1 - 3",
                color: "red"
            },
            {
                range: "3 - 4",
                color: "orange-darken-1"
            },
            {
                range: "4 - 5",
                color: "yellow-darken-1"
            },
            {
                range: "5 - 6",
                color: "green"
            }
        ],
        avgGrades10: [
            {
                range: "1 - 5",
                color: "red"
            },
            {
                range: "5 - 8",
                color: "orange-darken-1"
            },
            {
                range: "8 - 9",
                color: "yellow-darken-1"
            },
            {
                range: "9 - 10",
                color: "green"
            }
        ],
        avgDescriptions: [
            {
                title: "stats.avgGraph1Label",
                text: "stats.avgGraph1Explanation",
                cols: 6
            },
            {
                title: "stats.avgGraph2Label",
                text: "stats.avgGraph2Explanation",
                cols: 3
            },
            {
                title: "stats.avgGraph3Label",
                text: "stats.avgGraph3Explanation",
                cols: 3
            }
        ],
        devGrades6: [
            {
                range: "0 - 0.65",
                color: "green",
                title: "stats.devGraph1Label",
                text: "stats.devGraph1Explanation"
            },
            {
                range: "0.65 - 1.25",
                color: "orange",
                title: "stats.devGraph2Label",
                text: "stats.devGraph2Explanation"
            },
            {
                range: "1.25 - ...",
                color: "red",
                title: "stats.devGraph3Label",
                text: "stats.devGraph3Explanation"
            }
        ],
        devGrades10: [
            {
                range: "0 - 1.5",
                color: "green",
                title: "stats.devGraph1Label",
                text: "stats.devGraph1Explanation"
            },
            {
                range: "1.5 - 3.5",
                color: "orange",
                title: "stats.devGraph2Label",
                text: "stats.devGraph2Explanation"
            },
            {
                range: "3.5 - ...",
                color: "red",
                title: "stats.devGraph3Label",
                text: "stats.devGraph3Explanation"
            }
        ]
    }),
    methods: {},
    computed: {
        ...mapStores( useAppStore ),

        averageGrades() {
            if (this.topGrade === 6) {
                return this.avgGrades6;
            }
            if (this.topGrade === 10) {
                return this.avgGrades10;
            }

            return null;
        },
        deviations() {
            if (this.topGrade === 6) {
                return this.devGrades6;
            }
            if (this.topGrade === 10) {
                return this.devGrades10;
            }

            return null;
        },

        topGrade() {
            return this.appStore.userSettings?.topGrade;
        }
    }
};
</script>

<style scoped>
.graph-section {
    height: 40px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.graph-mini-section {
    height: 40px;
    width: 80px;
    margin-right: 10px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.graph-description {
    color: #565656 !important;
    text-align: center;
    padding: 10px !important;
}
</style>
