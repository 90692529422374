export {
    arrayDiff,
    assignToObjectKeys,
    copyMatrix,
    equalById,
    equalByIdentity,
    equalByToString,
    findById,
    findRecurseById,
    getSortByFn,
    isSameArray,
    simpleDeepMerge,
};

function arrayDiff(arr1, arr2) {
    const diff = [];
    const lengthArr1 = arr1?.length
    const lengthArr2 = arr2?.length
    let i = 0;

    if (!lengthArr1) return arr2 || [];
    if (!lengthArr2) return arr1 || [];

    for (i = 0; i < lengthArr1; i++) {
        if (arr2.indexOf(arr1[i]) === -1) diff.push(arr1[i]);
    }

    for (i = 0; i < lengthArr2; i++) {
        if (arr1.indexOf(arr2[i]) === -1) diff.push(arr2[i]);
    }

    return diff;
}

function assignToObjectKeys(keys, source, destinationObject) {
    return keys.reduce((obj, key) => { obj[key] = source; return obj; }, destinationObject);
}

function simpleDeepMerge(targetObj, obj) {
  Object.keys(obj).forEach(key => ((targetObj[key]?.constructor === Object) && (obj[key]?.constructor === Object))
    ? simpleDeepMerge(targetObj[key], obj[key])
    : targetObj[key] = obj[key]
  );

  return targetObj;
}

function copyMatrix(matrix) {
    return matrix.map(e => [...e]);
}

function equalById(arr1Elem, arr2Elem) {
    return arr1Elem.id === arr2Elem.id;
}

function equalByIdentity(arr1Elem, arr2Elem) {
    return arr1Elem === arr2Elem;
}

function equalByToString(arr1Elem, arr2Elem) {
    return arr1Elem.toString() === arr2Elem.toString();
}

function findById(array, id) {
    return typeof array?.find === 'function'
        ? array.find( item => item.id === id )
        : undefined;
}


function findRecurseById(id, arr) {
    if (!arr) return;
    if (arr.id === id) return arr;

    arr = (arr.children?.length && arr.children)
            || ((arr.length && (typeof arr !== 'string')) && arr);

    if (arr) {
        let e;
        let i = arr.length;
        while (--i) {
            if (e = this.findObj(id, arr[i])) {
                return e;
            }
        }
    }

    return;
}

function getSortByFn(propertyName) {

    return (item1, item2) => {
        return item1[propertyName] === item2[propertyName]
            ? 0
            : item1[propertyName] > item2[propertyName]
                ? 1
                : -1;
    };
}

function isSameArray(arr1, arr2, comparator) {
    const result = arr1 === arr2
        || (
               (arr1?.length === arr2?.length)
            && (    (arr1?.length === 0)
                || arr1?.every((arr1Elem) => arr2?.some(comparator.bind(null, arr1Elem)))
                )
            );

    l('in isSameArray [...arr1], [...arr2], comparator, result', [...arr1], [...arr2], comparator, result);

    return result;
}