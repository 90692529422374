import { httpClient, cachedHttpClient, generateCacheId } from "./httpClient";
import { getGenericLocale, getSpecificLocale } from "@/utils/languageHelpers";

const mapLangToLangLocale = (response) => {
    if (response?.data?.userLanguage) {
        response.data.userLanguage = getSpecificLocale(response?.data.userLanguage);
    }

    if (response?.data?.languages) {
        response.data.languages = response?.data.languages.map(getSpecificLocale);
    }

    return response;
}

const settings = (companyId, userId) => {
    window.l('before settings call');

    if (companyId && userId) {
        return cachedHttpClient.get("/settings", { cache: { ttl: 200, }, generateKey: generateCacheId(companyId, userId)}).then(mapLangToLangLocale);
    } else {
        return httpClient.get("/settings").then(mapLangToLangLocale);
    }
};

// retrieves userLanguage, feature flags etc
// fallback to languages[0]
const changeUserLanguage = (lang) => httpClient.put(`user/language/${getGenericLocale(lang)}`);

const introShown = () => httpClient.put("user/introshown");

const acceptGDPR = () => httpClient.put("user/gdpraccepted");

export { settings, changeUserLanguage, introShown, acceptGDPR }
