import { defineStore } from 'pinia';
import { jwtDecode } from "jwt-decode";
import AuthService from '@/services/auth.service';


export function isTokenValid(token) {
    if (!token) return false;

    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;  // convert milliseconds to seconds
        window.l("in isTokenValid, decoded.exp:", decoded?.exp);
        window.l("in isTokenValid, decoded.exp > currentTime - 60:", decoded?.exp > currentTime - 60);

        return decoded.exp > currentTime - 60;
    } catch (error) {
        window.le("Failed to decode JWT:", error);
        return false;
    }
}

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useAuthStore = defineStore('auth', {
    state: () => {
        const user = JSON.parse(localStorage.getItem('user'));

        return {
            status: { 
                loggedIn: user && isTokenValid(user.accessToken) 
            },
            user: user && isTokenValid(user.accessToken) ? user : null
        }
    },

    getters: {
        companyId() {
            return this.user ? this.user.companyId : null;
        },
        
        userRoles() {
            return this.user ? this.user.authorities?.map(item => item.authority) : [];
        },

        isRoot() {
            return this.user ? this.userRoles.includes("ROLE_ROOT") : false;
        },

        isCompanyAdmin() {
            return this.user ? this.userRoles.includes("ROLE_SUPERADMIN") : false;
        },

        isTeamAdmin() {
            return this.user ? this.userRoles.includes("ROLE_ADMIN") : false;
        },

        isMember() {
            return this.user ? this.userRoles.includes("ROLE_MEMBER") : false;
        },

        isMemberOnly() {
            if( !this.user ) return false;

            return this.isMember && !(this.isTeamAdmin || this.isCompanyAdmin);
        },
        
        isAdmin() {
            return this.user ? this.isRoot || this.isCompanyAdmin || this.isTeamAdmin : false;
        },

        isImpersonating() {
            l("isImpersonating", this.user);
            return this.user?.isImpersonator || false;
        },

        maxRole() {
            let maxRole = "member";
            
            if (this.isTeamAdmin) {
                maxRole = "teamAdmin";
            }

            if (this.isCompanyAdmin) {
                maxRole = "companyAdmin";
            }

            return maxRole;
        }
    },

    actions: {
        hasPermission: (permission) => {
            return this.user ? this.userRoles.includes(permission) : false;
        },

        loginSuccess(user) {
            this.status.loggedIn = isTokenValid(user.accessToken)
            this.user = user;
        },

        loginFailure(error) {
            window.le("Failed to (re)login:", error);
            this.status.loggedIn = false;
            this.user = null;
        },

        logout() {
            this.status.loggedIn = false;
            this.user = null;
        },

        setIntroShown() {
            console.log("setting local showIntro: false")
            this.user.showIntro = false;
        },

        setGDPRAccepted() {
            console.log("setting local gdprAccepted: true")
            this.user.gdprAccepted = true;
        },
        
        updateName(user) {
            this.user.firstName = user.firstName;
            this.user.lastName = user.lastName
        },

        updateEmail(email) {
            this.user.email = email;
        },

        updateLanguage(language) {
            this.user.language = language;
        },

        async login(user) {
            try {
                return await AuthService.login(user);
            } catch (error) {
                this.loginFailure(error);
                return await Promise.reject(error);
            }
        },

        async urlLogin(token) {
            try {
                return await AuthService.urlLogin(token);
            } catch (error) {
                this.loginFailure(error);
                return await Promise.reject(error);
            }
        },

        relogin() {
            return AuthService.relogin().then(
                user => {
                    this.loginSuccess(user);    
                    return Promise.resolve(user);
                },
                error => {
                    this.loginFailure(error);
                    return AuthService.logout();
                }
            );
        },

        tokenLogin() {
            return AuthService.loginToken().then(
                user => {
                    this.loginSuccess(user);
                    return Promise.resolve(user);
                },
                error => {
                    this.loginFailure(error);   
                    return Promise.reject(error);
                }
            );
        },

        logout() {
            return AuthService.logout();
        },

        stopImpersonation() {
            return AuthService.stopImpersonation();
        },
    }
});