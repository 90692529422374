import { defineStore } from 'pinia';
import { getCompanyIcons, standardIcons} from "@/utils/companyIcons.js"
import { MAX_NORMAL_STRUCTURE_LEVEL }  from '@/components/global/EntityIcon.data.js';

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useQuestionEditorStore = defineStore('questionEditor', {
    state: () => ({ 
        teams: [],
        allTeamIds: null,
        selectedTeamId: null, //-1 stands for company level
        existingQuestions: [],
        categories: null,
        avatars: standardIcons,
    }),

    getters: {
        isCompanyView() {
            return this.selectedTeamId === "-1";
        },

        highestTeamLevel() {
            let highestLevel = 0;
            
            this.teams.forEach(team => {
                if (team.level > highestLevel && team.level <= MAX_NORMAL_STRUCTURE_LEVEL) {
                    highestLevel = team.level;
                }
            });

            return highestLevel;
        }
    },

    actions: {

        RESET() {
            this.$reset();
        },
        
        setQuestionIcons(companyId){
            const companyIcons = getCompanyIcons(companyId);
            this.avatars = companyIcons && Array.isArray(companyIcons) ? companyIcons.concat(standardIcons) : standardIcons;
        },

        loadCategories(categories) {
            this.categories = categories.sort((a) => (Object.prototype.hasOwnProperty.call(a, 'defaultSet') ? 1 : -1))
        },

        setTeams(list) {
            this.teams = list;
            this.allTeamIds = list.map(team => team.id);
        },

        setExistingQuestions(list) {
            this.existingQuestions = list.map(question => question.questionText);
        },

        setTeamId(id) {
            this.selectedTeamId = id;
        },
    }
});