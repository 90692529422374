<template>
    <!-- Team tree for moving the user to another level 0 entity  -->
    <v-container v-if="teamMenu">
        <v-treeview
            :select-strategy="'independent'"
            hoverable
            activatable
            return-object
            :items="teamMenu"
            class="hover-pointer"
        >
            <template
                #prepend="{ item }"
                v-if="$vuetify.display.smAndUp"
            >
            <EntityIcon :entity="item"/>
            </template>
            <!-- TODO Vuetify Migration End #label-> v-slot:append -->
            <template #append="{ item, isActive }">
                 <!-- TODO Vuetify Migration End: will remove this -->
                <!-- <span class="text-black">{{ item.name }} </span> -->
                <span
                    class="text-grey text-caption"
                    v-if="item.id === currentTeamId"
                >
                  ({{ $t('currentTeam') }})

                </span>
                <v-btn
                    v-if="isActive && (!item.children?.length)"
                    :loading="loading"
                    :ripple="false"
                    @click.stop="moveHere(item)"
                    color="primary"
                    class="ml-3"
                    >{{ $t("moveHere") }}
                </v-btn>
            </template>
        </v-treeview>
    </v-container>
</template>

<script>
import { generateTeamTree } from "@/utils/entityListCalc.js";
import globalFunctions from "@/utils/globalFunctions";
import EntityIcon from "../global/EntityIcon.vue";

export default {
    props: ["teams", "currentTeamId", "loading"],
    components: { EntityIcon},
    
    mounted() {
        this.initialize();
    },
    data: () => ({
        teamMenu: null,
        selection: null
    }),

    methods: {
        initialize() {
            this.teamMenu = generateTeamTree(this.teams);
            this.setDisabledState(this.teamMenu);
        },
        moveHere(entity) {
            globalFunctions.piwikAnalytics(['trackEvent','Entity management actions', 'Moved teams via Profile']);
            this.$emit("entityChosen", { name: entity.name, id: entity.id });
        },

        setDisabledState(teamMenu) {
            teamMenu.forEach((team) => {
                team.disabled =  team.level > 0 || team.id === this.currentTeamId; //TODO V3-end

                if (team.subEntityIds.length > 0) {
                    this.setDisabledState(team.children);
                }
            });
        },
    },
    computed: {},
    watch: {},
};
</script>

<style></style>
