import { httpClient } from "./httpClient";
import { formatDate } from "@/utils/dateHelpers"
const END_POINT = '/adhoc';

const getAdhocSurveys = () => httpClient.get(END_POINT);

const getAdhocSurvey = (surveyId) =>  httpClient.get(`${END_POINT}/${surveyId}`);

// User and admins of the adhoc survey
const getAdhocSurveyMembers = (surveyId) =>  httpClient.get(`${END_POINT}/${surveyId}/members`);

// Only if companyAdmin or admin of adhoc survey
const getAdhocSurveyDetails = (surveyId) => httpClient.get(`${END_POINT}/${surveyId}/details`).then((res) => {
  res.data.startedAtDateObject = new Date(res.data.startedAt);
  res.data.endedAtDateObject = new Date(res.data.endedAt);
  return res;
});


const endAdhoc= (surveyId) =>  httpClient.put(`${END_POINT}/${surveyId}/end`);

/* update PLANNED survey, expects full object in below format
 {
    title: "",
    startDate: "",
    endDate: "",
    questions: [],
    participants: []  (list of ids)
} */
const updatePlannedAdhoc = (surveyId, surveyObj) => {
  const formattedSurveyObj = {
    ...surveyObj,
    startDate: formatDate(surveyObj.startDate),
    endDate: formatDate(surveyObj.endDate),
  };

  return httpClient.put(
    `${END_POINT}/${surveyId}`,
    formattedSurveyObj
  );
};  

/* update RUNNING survey, expects only fields that are changed. can NOT update questions or start date anymore
 {
    title: "",
    endDate: "",
    participants: []
} */
const updateRunningAdhoc = (surveyId, updatedFields) => {
    const formattedFields = {
      ...updatedFields,
    };

    if (updatedFields.endDate) {
        formattedFields.endDate = formatDate(updatedFields.endDate);
    }
    
    return httpClient.patch(
      `${END_POINT}/${surveyId}`,
      formattedFields
    );
  };
// expects an object with an array of user ids { participants: ["123123","userid2"] }
// to remind all non-respondents, send { participants: null } or { participants: [] }
const sendAdhocReminder = (surveyId, list) =>  httpClient.post(`${END_POINT}/${surveyId}/reminder`, {participants: list});

const deleteAdhocSurvey = (surveyId) =>  httpClient.delete(`${END_POINT}/${surveyId}`);

const createAdhocSurvey = (survey) => {
  const surveyData = {
      ...survey,
      startDate: formatDate(survey.startDate),
      endDate: formatDate(survey.endDate),
    };

    return httpClient.post(END_POINT, surveyData);
};  

const getAdhocSurveyResults = (surveyId, tags) => {
    let params = new URLSearchParams();
    tags.forEach(tag =>
        params.append("tags", tag)
    );
    let tagList = {
        params: params
    };
    // above generates url dashboard/REC8gQo89X3QuHttg?tags=dev&tags=female
    return httpClient.get(`${END_POINT}/${surveyId}/results`, tagList);
};


// supports entity id and list of tags http://localhost:8080/api/v1/adhoc/users?entityId=36598ent811049248&tags=tag123

 const getUsers = ({searchPage: page, searchSize: size, searchTags: tags, searchEntityId: entityId, searchQuery: query}) => {
    let params = new URLSearchParams();
    params.append("page", page);
    params.append("size", size);

    if(tags !== undefined && tags !== null && tags.length >0 ){
        tags.forEach(tag =>
            params.append("tags", tag)
        );
    }

    if(entityId !== undefined && entityId !== null){
        params.append("entityId", entityId);
    }

    if(query !== undefined && query !== null){
        params.append("query", query);
    }

    let userParams = {
        params: params
    };

  return  httpClient.get(`${END_POINT}/users`, userParams);
}

/* http://localhost:8080/api/v1/adhoc/users?page=0&size=100 would get first page of 100 people, increment page to get next */


/* let exampleUsers =
{
  "page": 0,
  "size": 100,
  "content": [
    {
      "id": "EJDpr2xKCAw9YjZjt",
      "displayName": "The Real Hansruedi Heeb"
    },
    {
      "id": "JX6uSJXhbr7TrsAGu",
      "displayName": "The Real Thomas Bürli"
    }
  ],
  "totalElements": 9,
  "totalPages": 1
} */


const getTags = () =>  httpClient.get(`${END_POINT}/tags`);

const getAdhocEntities = () =>  httpClient.get(`${END_POINT}/entities`);


export {
    endAdhoc,
    getAdhocSurveys,
    getAdhocSurveyMembers,
    getAdhocSurvey,
    getAdhocSurveyDetails,
    getAdhocSurveyResults,
    deleteAdhocSurvey,
    createAdhocSurvey,
    getUsers,
    getTags,
    getAdhocEntities,
    updatePlannedAdhoc,
    updateRunningAdhoc,
    sendAdhocReminder
}
