import { defineStore } from 'pinia';
import { getEscalateAdmins } from '@/api/admin.api';
import i18n from "../i18n";

import { useAuthStore } from './auth.store';
import { useEntitiesStore } from './entities.store';
import { useQuestionEditorStore } from './questionEditor.store';
import { useAdhocStore } from './adhoc.store';

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useAppStore = defineStore('app', {
    state: () => ({
        anonym: false, //default anonym answer mode for a survey fill out (public by default)
        loggingOut: false, //styling variable to hide rest of the UI after user clicks "logout"
        navDrawer: true,
        supportedLangs: [],
        escalateAdmins: null,
        userSettings: null,
        topGrade: null,
        contactLinks: {
            'de-CH': "https://joineer.com/ueber-uns/#kontaktdaten",
            'en-GB': "https://joineer.com/kontakt-en/#kontaktdaten",
            fr: "https://joineer.com/kontakt-fr/#kontaktdaten",
            it: "https://joineer.com/kontakt-it/#kontaktdaten"
        },
        events: {},
    }),

    getters: {
        surveyRunning() {
            return this.userSettings?.pulseSurveyRunning || false;
        },

        featureFlags() {
            return this.userSettings?.featureFlags || null;
        },

        mandatoryLang() {
            return this.userSettings?.languages[0] || null;
        },

        contactLink() {
            return this.contactLinks.hasOwnProperty(i18n.global.locale.value)
                ? this.contactLinks[i18n.global.locale.value]
                : this.contactLinks['en-GB'];
        },
    },

    actions: {
          
        showNavDrawer(newVal) {
            this.navDrawer = newVal;
        },
        
        toggleNavDrawer() {
            this.navDrawer = !this.navDrawer;
        },

        RESET() {
            this.$reset();
        },

        checkFeature(feature) {
            return this.userSettings?.featureFlags.includes(feature) || null;
        },

        // when user changes the default anonymity toggle in the survey question
        changeDefaultAnonym(mode) {
            console.log("setting survey question anonym mode to: " + mode)
            this.anonym = mode;
        },

        setLogoutMode(mode) {
            this.loggingOut = mode;
        },

        // for escalate task/FF autocomplete
        fetchEscalateAdmins() {
            getEscalateAdmins().then(response => {
                this.escalateAdmins = response.data;
            });
        },

        reset() {
            const authStore = useAuthStore();
            const entitiesStore = useEntitiesStore();
            const questionEditorStore = useQuestionEditorStore();
            const adhocStore = useAdhocStore();

            this.$reset();
            entitiesStore.RESET_ENTITY_EXPAND();
            questionEditorStore.RESET();
            adhocStore.RESET_ALL();

            // authStore.logout();
        },

        changeUserLang(lang) {
            if (!this.userSettings) {
                this.userSettings = {}; // Instantiate userSettings if it doesn't exist
            }
            this.userSettings.userLanguage = lang;
        },

        setEventHandler(eventName, eventHandler) {
            if (this.events[eventName]) {
                lw(`store, reassigning event handler for ${eventName} from ${this.events[eventName]} to ${eventHandler}`);
            }

            if (!eventHandler) {
                delete this.events[eventName];
            } else {
                Object.assign(this.events[eventName], eventHandler);
            }
        },

        setRole(role) {
            this.userRole = role;
        },

        setSettings(settings) {
            this.userSettings = settings;
            this.supportedLangs = settings.languages;
            this.topGrade = settings.topGrade;

            if (settings.anonymDefault) {
                this.anonym = true
            }
        },
    }
});