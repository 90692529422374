<template>
    <div>
        <!-- /profile/:userID -->
        <v-card border flat max-width="600">
            <div v-if="loading" class="py-12">
                <v-progress-circular
                    indeterminate
                    size="35"
                    color="grey-lighten-2"
                    class="d-flex mx-auto"
                ></v-progress-circular>
                <div class="text-caption text-center mt-3">
                    {{ $t("loadingProfile") }}
                    ...
                </div>
            </div>
            <div v-else>
                <div v-if="user">
                    <v-toolbar flat class="bg-toolbar">

                        <v-toolbar-title>
                            <v-icon>mdi-account</v-icon>
                            {{ $t("nav.profile") }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-btn
                            class="mr-2"
                            :disabled="isMyProfile"
                            @click="deleteDialog = true"
                            icon
                            variant="text"
                            color="red-darken-3"
                            ><v-icon>mdi-delete</v-icon></v-btn
                        >
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-container>
                        <v-list lines="two" subheader id="profileList">
                            <!-- Name -->
                            <v-list-item>
                                <!-- TODO: V3 end -->
                                <template #prepend>
                                    <v-avatar class="mr-2" color="amber" size="30">
                                        <span
                                            class="text-white"
                                            style="font-size: 1rem"
                                        >
                                            {{ $mixin.initials(user) }}
                                        </span>
                                    </v-avatar>

                                </template>

                                    <v-list-item-subtitle>
                                        {{ $t("report.name") }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{ user.firstName }}
                                        {{ user.lastName }}
                                    </v-list-item-title>

                                <!-- TODO: V3 end -->
                                <template #append>
                                    <v-btn
                                        icon
                                        variant="text"
                                        @click="updateNameDialog = true"
                                        id="updateNameTrigger"
                                    >
                                        <!-- TODO: V3 end -->
                                        <v-icon color="primary">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                            </v-list-item>

                            <v-divider> </v-divider>

                            <!--  E-Mail -->
                            <v-list-item>
                                <template #prepend>
                                    <v-icon>mdi-mail</v-icon>
                                </template>

                                    <v-list-item-subtitle>
                                        E-mail
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{ user.email ? user.email : "-" }}
                                    </v-list-item-title>

                                <template #append>
                                    <v-tooltip
                                        location="bottom"
                                        :disabled="user.primary"
                                    >
                                        <template #activator="{ props }">
                                            <div v-bind="props">
                                                <v-btn
                                                    :disabled="!user.primary"
                                                    icon
                                                    variant="text"
                                                    @click="updateEmailDialog = true"
                                                >
                                                    <v-icon color="primary">mdi-pencil</v-icon>
                                                </v-btn>
                                            </div>
                                        </template>
                                        <span>Email can only be changed if this is the primary profile of the account</span>
                                    </v-tooltip>
                                </template>
                            </v-list-item>

                            <v-divider> </v-divider>

                            <!--  mobile phone -->
                            <v-list-item>
                                <template #prepend>
                                    <v-icon>mdi-cellphone</v-icon>
                                </template>

                                    <v-list-item-subtitle>
                                        {{ $t("labelMobilePhone") }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{
                                            user.mobile ? user.mobile : "-"
                                        }}</v-list-item-title
                                    >

                                <!-- TODO: V3 end -->
                                <template #append>
                                    <v-btn
                                        @click="updateMobileDialog = true"
                                        icon
                                        variant="text"
                                    >
                                        <!-- TODO: V3 end -->
                                        <v-icon color="primary">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                            </v-list-item>

                            <!-- language -->
                            <v-divider> </v-divider>
                            <v-list-item>
                                <!-- TODO: V3 end -->
                                <template #prepend>
                                    <v-icon>mdi-label</v-icon>
                                </template>

                                    <v-list-item-subtitle>
                                        {{
                                            $t(
                                                "questionEditor.questions.language"
                                            )
                                        }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{
                                            user.language
                                                ? user.language.toUpperCase()
                                                : "-"
                                        }}
                                    </v-list-item-title>

                                <!-- TODO: V3 end -->
                                <template #append>
                                    <v-btn
                                        icon
                                        variant="text"
                                        @click="updateLangDialog = true"
                                    >
                                        <!-- TODO: V3 end -->
                                        <v-icon color="primary">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                            </v-list-item>

                            <!-- tags -->
                            <v-divider> </v-divider>
                            <v-list-item>
                                <!-- TODO: V3 end -->
                                <template #prepend>
                                    <v-icon>mdi-label</v-icon>
                                </template>

                                    <v-list-item-subtitle>
                                        {{ $t("tags") }}
                                    </v-list-item-subtitle>
                                    <div
                                        v-if="user.tags && user.tags.length > 0"
                                    >
                                        <div
                                            v-for="tag in user.tags"
                                            :key="tag"
                                            class="displayTag"
                                        >
                                            {{ tag }}
                                        </div>
                                    </div>


                            </v-list-item>

                            <!-- roles -->
                            <v-divider> </v-divider>
                            <v-list-item>
                                <!-- TODO: V3 end -->
                                <template #prepend>
                                    <v-icon>mdi-label</v-icon>
                                </template>

                                    <v-list-item-subtitle>
                                        {{ $t("rolesTitle") }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{ renderedRoles(user.roles) }}
                                    </v-list-item-title>

                            </v-list-item>

                            <!-- Team -->
                            <v-divider></v-divider>
                            <v-list-item>
                                <!-- TODO: V3 end -->
                                <template #prepend>
                                    <v-icon>mdi-account-group</v-icon>
                                </template>

                                    <v-list-item-subtitle>
                                        {{ $t("nav.team") }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title>
                                        {{ user.teamName }}
                                    </v-list-item-title>

                                <!-- TODO: V3 end -->
                                <template #append>
                                    <v-btn
                                        icon
                                        variant="text"
                                        @click="updateTeamDialog = true"

                                    >
                                        <!-- TODO: V3 end -->
                                        <v-icon color="primary">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                            </v-list-item>

                            <!-- Admin Of Teams -->
                            <v-divider></v-divider>
                            <v-list-item>
                                <!-- TODO: V3 end -->
                                <template #prepend>
                                    <v-icon>mdi-label</v-icon>
                                </template>

                                    <v-list-item-subtitle>
                                        {{ $t("adminOfTeams") }}
                                    </v-list-item-subtitle>
                                    <div v-if="user.adminOfTeams && user.adminOfTeams.length > 0">
                                        <div v-for="team in user.adminOfTeams" :key="team">
                                            {{ team }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        -
                                    </div>

                            </v-list-item>
                        </v-list>

                        <v-alert
                            v-if="errorLoadingUser"
                            type="error"
                            variant="outlined"
                        >
                            {{ errorLoadingUser }}
                        </v-alert>
                    </v-container>
                    <UpdateEmailDialog
                        @refresh="initialize"
                        :dialog="updateEmailDialog"
                        :user="user"
                        @dialog="updateEmailDialog = $event"
                    />
                    <UpdateLangDialog
                        @refresh="initialize"
                        :dialog="updateLangDialog"
                        :user="user"
                        @dialog="updateLangDialog = $event"
                    />
                    <UpdateNameDialog
                        :dialog="updateNameDialog"
                        :user="user"
                        :fields="nameFields"
                        :updateMethod="updateUserMethod"
                        @update-success="initialize"
                        @dialog="updateNameDialog = $event"
                    />
                    <UpdateMobileDialog
                        @refresh="initialize"
                        :dialog="updateMobileDialog"
                        :user="user"
                        @dialog="updateMobileDialog = $event"
                    />
                    <UpdateTeamDialog
                        @refresh="initialize"
                        :dialog="updateTeamDialog"
                        :user="user"
                        @dialog="updateTeamDialog = $event"
                        :currentTeam="{ name: user.teamName, id: user.teamId }"
                    />
                    <DeleteProfileDialog
                        :dialog="deleteDialog"
                        :user="user"
                        @dialog="deleteDialog = $event"
                        @deleted="onUserDeleted"
                    />
                </div>
                <v-alert
                    v-if="errorLoadingUser"
                    type="error"
                    variant="outlined"
                    class="ma-3"
                >
                    {{ errorLoadingUser }}
                </v-alert>
            </div>
        </v-card>
    </div>
</template>

<script lang="ts">
import UpdateEmailDialog from "@/components/Profile/UpdateEmailDialog.vue";
import UpdateLangDialog from "@/components/Profile/UpdateLangDialog.vue";
import UpdateNameDialog from "@/components/Profile/UpdateNameDialog.vue";
import UpdateTeamDialog from "@/components/Profile/UpdateTeamDialog.vue";
import UpdateMobileDialog from "@/components/Profile/UpdateMobileDialog.vue";
import DeleteProfileDialog from "@/components/Profile/DeleteProfileDialog.vue";
import {getUserDetails, updateMyDetails, updateUserDetails} from "../api/users.api";
import { ref, computed, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import globalFunctions from "@/utils/globalFunctions";
import { UserDB } from "@/models/user";
import { useAuthStore } from "@/store/auth.store";
export default {
    components: {
        UpdateEmailDialog,
        UpdateLangDialog,
        UpdateNameDialog,
        UpdateMobileDialog,
        DeleteProfileDialog,
        UpdateTeamDialog,
    },

    setup() {
        const route = useRoute();
        const router = useRouter();
        const { t } = useI18n();
        const userId = ref<string | string[]>();
        const user = ref<UserDB | null>(null);
        const loading = ref<boolean>(false);
        const errorLoadingUser = ref<string | null>(null);
        const updateEmailDialog = ref<boolean>(false);
        const updateLangDialog = ref<boolean>(false);
        const updateNameDialog = ref<boolean>(false);
        const updateMobileDialog = ref<boolean>(false);
        const deleteDialog = ref<boolean>(false);
        const updateTeamDialog = ref<boolean>(false);
        const authStore = useAuthStore();

        const nameFields = computed(() => [
            { key: 'firstName', label: t('firstName'), rules: [v => !!v || t('firstNameRequired')] },
            { key: 'lastName', label: t('lastName'), rules: [v => !!v || t('lastNameRequired')] }
        ]);

        const updateUserMethod = async (updatedUser) => {
            await updateUserDetails(userId.value,updatedUser);
        };

        const initialize = () => {
            errorLoadingUser.value = null;
            loading.value = true;
            getUserDetails(userId.value)
                .then((response) => {
                    user.value = response.data;
                    window.l("User data: \n", window.s(user.value));
                })
                .catch((error) => {
                    errorLoadingUser.value = globalFunctions.parseError(error);
                })
                .finally(() => {
                    loading.value = false;
                });
        }

        const renderedRoles = (roles: string[]) => {
            if (roles.length > 0) {
                let mappedRoles = roles.map((role) => {
                    if (role === "SUPERADMIN") {
                        return t("roles.superadmin");
                    } else if (role === "ADMIN") {
                        return "Admin";
                    } else if (role === "MEMBER") {
                        return t("roles.member");
                    } else if (role === "ROOT") {
                        return "Root";
                    } else {
                        return role;
                    }
                });

                return mappedRoles.sort().join(", ");
            } else {
                return "-";
            }
        }

        const myUserId = computed(() => {
            if (authStore.user) {
                return authStore.user.userId;
            } else {
                return null;
            }
        })

        const isMyProfile = computed(() => {
            if (myUserId.value) {
                return myUserId.value === userId.value;
            } else {
                return false;
            }
        })

        const onUserDeleted = () => {
            router.go(-1);
        }

        watch(
            () => route.params.user_id,
            (newUserId) => {
                if (newUserId !== userId.value) {
                // fetch new content when jumping between viewing different users
                userId.value = newUserId;
                initialize();
            }
        })

        onMounted(() => {
            userId.value = route.params.user_id;
            initialize();
        });

        return {
            t,
            user,
            loading,
            errorLoadingUser,
            updateEmailDialog,
            updateLangDialog,
            updateNameDialog,
            updateMobileDialog,
            deleteDialog,
            updateTeamDialog,
            initialize,
            renderedRoles,
            myUserId,
            isMyProfile,
            onUserDeleted,
            nameFields,
            updateUserMethod
        }
    }
}
</script>
