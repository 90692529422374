<template>
    <div>
        <v-card border flat class="my-1">
            <div class="d-flex align-center">

                    <v-textarea
                        v-model.trim="text"
                        class="text-body-2"
                        variant="solo"
                        flat
                        auto-grow
                        hide-details
                        rows="2"
                        name="comment"
                        :placeholder="`${$t('tooltips.addComment')}...`"
                    ></v-textarea>



                    <v-tooltip location="bottom" content-class="bg-grey-lighten-3" open-delay="1000">
                        <template #activator="{ props }">
                            <v-btn
                                :loading="loading"
                                :disabled="loading || !text"
                                v-bind="props"
                                :aria-label="$t('tooltips.addComment')"
                                @click="makeComment"
                                variant="flat"
                                color="primary"
                                class="mx-1 makeCommentBtn"
                            >
                             {{ $t('buttons.save') }}

                            </v-btn>
                        </template>
                        <span class="text-grey-darken-2">{{
                            $t("tooltips.addComment")
                        }}</span>
                    </v-tooltip>

            </div>
        </v-card>
        <!-- TODO: VUE 3: END density-->
        <v-alert v-if="errorMessage" density="compact" type="error" variant="outlined">{{
            errorMessage
        }}</v-alert>
    </div>
</template>
<script>
import { addComment } from "../api/tasks.api";
import globalFunctions from "@/utils/globalFunctions";
export default {
    props: ["task"],
    data: () => ({
        text: "",
        loading: false,
        errorMessage: null
    }),
    methods: {
        makeComment() {
            if (this.text) {
            this.loading = true;
            this.errorMessage = null;
                let newComment = {
                    comment: this.text
                };
                addComment(this.task.id, newComment)
                    .then(response => {
                        globalFunctions.piwikAnalytics(['trackEvent','Task actions', 'Comment added'])
                        this.task.comments.push(response.data);
                        this.text = "";
                    })
                    .catch(error => {
                        this.errorMessage = globalFunctions.parseError(error);
                    })
                    .finally(() => this.loading = false);
            }
        }
    },
    computed: {}
};
</script>

<style>
.sendComment {
    transform: rotate(-20deg);
}
</style>
