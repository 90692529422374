<template>
    <!-- list of questions with FFs -->
    <div>
        <v-progress-circular
            v-if="loading"
            size="50"
            class="d-flex mx-auto mt-5"
            indeterminate
            color="grey-lighten-1"
        ></v-progress-circular>
        <v-card
            v-if="!loading && allQuestions"
            border flat
            class="questionsCard pa-3 pt-5"
            :class="{ highlighted: highLightQuestions }"
        >
            <div class="d-flex align-center mt-1 ml-4">
                <div>
                    <h3>
                        {{ $t("reviewResults") }}
                        <v-btn
                            @click="showHelpDialog"
                            icon
                            class="align-self-start mr-2 mt-n1"
                            size="small"
                            variant="text"
                            ><v-icon color="grey-lighten-1"
                                >mdi-help-circle-outline</v-icon
                            ></v-btn
                        >
                    </h3>
                    <div class="dark-text">
                        <span class="pr-1">{{ $t("resultText1") }}</span>
                        <i18n-t keypath="resultText2"
                            ><span>
                                <v-icon size="x-small" style="margin-right: -2px"
                                    >mdi-arrow-up-down</v-icon
                                ><v-icon size="small">mdi-menu</v-icon></span
                            ></i18n-t
                        >
                    </div>
                </div>
            </div>

            <v-card-text class="" v-if="allQuestions && topGrade">
                <div
                    class="mb-1"
                    :class="{ 'd-flex': $vuetify.display.smAndUp }"
                >
                    <v-menu location="bottom" offset-y>
                        <template #activator="{ props }">
                            <v-btn
                                variant="text"
                                color="primary"
                                class="orderBtn"
                                v-bind="props"
                                style="text-transform: inherit; "
                                :ripple="false"
                            >
                                <v-icon size="small" color="primary" class="mr-1 ml-n1"
                                    >mdi-chevron-down</v-icon
                                >
                                <span class="text-primary">{{
                                    $t("selectSurvey.questionOrder")
                                }}</span>

                                <span v-if="selectedFilterModeIdx && filterModes[selectedFilterModeIdx]"
                                    >:<v-icon class="pl-1" size="small">{{
                                        filterModes[selectedFilterModeIdx].icon
                                    }}</v-icon>

                                    {{
                                        $t(
                                            filterModes[selectedFilterModeIdx]
                                                .text
                                        )
                                    }}</span
                                >
                            </v-btn>
                        </template>

                        <v-list
                                v-model:selected="selectedFilterModeIdx"
                                color="primary"
                            >
                                <v-list-item
                                    v-for="(mode , index) in filterModes"
                                    :key="mode.action"
                                    :value="index"
                                    @click="handleFilter(mode.action)"
                                >
                                    <v-list-item-title>
                                        <v-icon size="small" start>{{
                                            mode.icon
                                        }}</v-icon
                                        >{{ $t(mode.text) }}</v-list-item-title
                                    >
                                </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-spacer v-if="$vuetify.display.smAndUp"></v-spacer>
                    <v-btn
                        v-if="stats && stats.statistic"
                        color="grey-darken-3"
                        variant="text"
                        :ripple="false"
                        style="text-transform: inherit"
                        @click="toggleShowStats"
                    >
                        <v-icon size="small" color="grey-darken-1" class="ml-n1">{{
                            showStats ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon
                        ><span
                            v-if="$vuetify.display.smAndUp"
                            class="mr-1"
                            >{{
                                showStats ? $t("hideStats") : $t("showStats")
                            }}</span
                        >
                        <span v-else>{{ $t("statistics") }} </span>
                    </v-btn>
                </div>

                <Stats
                    v-if="showStats"
                    :discussionId="discussionId"
                    :stats="stats"
                />

                <v-btn
                    id="startDiscussionBtn"
                    v-if="toDos"
                    variant="flat"
                    class="mb-6 d-flex mx-auto hover-btn"
                    @click="startPresentation"
                    size="x-large"
                    color="primary"
                >
                    {{ $t("startDiscussion") }}
                </v-btn>

                <draggable
                    class="list-group"
                    v-model="allQuestions"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="endDrag"
                >
                    <transition-group
                        type="transition"
                        :name="!drag ? 'flip-list' : null"
                    >
                        <div
                            v-for="(question, i) in allQuestions"
                            :key="question.id"
                        >
                            <QuestionItem
                                :question="question"
                                :i="i"
                                :discussionId="discussionId"
                                :totalQuestions="allQuestions.length"
                                :actions="actions"
                            />
                        </div>
                    </transition-group>
                </draggable>
            </v-card-text>

            <v-dialog
                v-model="helpDialog"
                style="z-index: 9001"
                max-width="650"
            >
                <v-card>
                    <div class="d-flex align-center">
                        <v-spacer></v-spacer>
                        <h2
                            class=" py-3"
                            style="font-family: Patua One, sans-serif;"
                        >
                            {{ $t("questionLegend") }}
                        </h2>
                        <v-spacer></v-spacer
                        ><v-btn class="mr-2" @click="helpDialog = false" icon variant="text"
                            ><v-icon>mdi-close</v-icon></v-btn
                        >
                    </div>

                    <v-card-text style="font-size: 1rem">
                        <v-img

                            class="mx-auto"
                            max-width="90%"
                            :src="`/images/helpers/legend-${questionLegendLang}.jpg`"
                        ></v-img>

                        <ol>
                            <li>
                                <strong>Drag & drop</strong>.
                                <i18n-t keypath="resultText2">
                                    <span>
                                        <v-icon
                                            size="x-small"
                                            style="margin-right: -2px"
                                        >
                                            mdi-arrow-up-down
                                        </v-icon>
                                        <v-icon size="small">mdi-menu</v-icon>
                                    </span>
                                </i18n-t>
                            </li>
                            <v-divider class="my-3"></v-divider>
                            <li>
                                <strong>Ø {{ $t("dashboard.average") }}</strong>. {{ $t("avgExplanation") }}

                                <ScaleGraph :mode="'avg'" />
                            </li>
                            <v-divider class="my-3"></v-divider>
                            <li>
                                <strong
                                    >↔ {{ $t("dashboard.deviation") }}</strong
                                >. {{ $t("devExplanation") }}
                            </li>
                            <ScaleGraph :mode="'dev'" />
                            <v-divider class="my-3"></v-divider>
                            <li>
                                <strong>{{ $t("nrFFs") }}</strong
                                >. {{ $t("nrFFExplanation") }}
                            </li>
                            <v-divider class="my-3"></v-divider>
                            <li>
                                {{ $t("expandQuestionComments") }}
                            </li>
                        </ol>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
        <v-alert type="error" variant="outlined" v-if="error">{{error}}</v-alert>
    </div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
import Stats from "@/components/SelectSurvey/Stats.vue";
import ScaleGraph from "@/components/ScaleGraph";
import {
    getAllQuestions,
    reorderQuestions,
    resetQuestionOrder,
    getDiscussionActions
} from "@/api/discussion.api";
import { roundUp, avgTextColor } from "@/utils/dashboardCode.js";
import QuestionItem from "@/components/SelectSurvey/QuestionItem.vue";
import globalFunctions from "@/utils/globalFunctions";
import { getGenericLocale } from "@/utils/languageHelpers";
import { mapStores } from 'pinia';
import { useAppStore } from '@/store/app.store';

export default {
    props: [
        "discussionId",
        "totalFeedForwards",
        "stats",
        "highLightQuestions",
        "toDos",
        "discussionDone"
    ],
    mounted() {
        this.setData();

    },
    components: { QuestionItem, ScaleGraph, Stats, draggable: VueDraggableNext },
    data: () => ({
        loading: true,
        error: null,
        drag: false, //whether user is currently drag and dropping to reorder questions
        allQuestions: null,
        actions: [], //discussion actions when company has ACTION_ENGINE feature flag
        helpDialog: false,
        showStats: false,
        selectedFilterModeIdx: null,
        filterModes: [
            {
                text: "buttons.reset",
                action: "resetQuestions",
                icon: "mdi-refresh"
            },
            {
                text: "nrFFs",
                action: "sortByFFDesc",
                icon: "mdi-arrow-down"
            },
            {
                text: "nrFFs",
                action: "sortByFFInc",
                icon: "mdi-arrow-up"
            },
            {
                text: "dashboard.average",
                action: "sortByAvgDesc",
                icon: "mdi-arrow-down"
            },
            {
                text: "dashboard.average",
                action: "sortByAvgInc",
                icon: "mdi-arrow-up"
            },
            {
                text: "dashboard.deviation",
                action: "sortByDevDesc",
                icon: "mdi-arrow-down"
            },
            {
                text: "dashboard.deviation",
                action: "sortByDevInc",
                icon: "mdi-arrow-up"
            }
        ]
    }),
    methods: {
        getActions(){
              getDiscussionActions(this.discussionId).then((res) => {
              if(window.$devMode){
              console.log(res.data.actions.length + " actions found for discussion ID " + this.discussionId)
              console.log(JSON.stringify(res.data.actions))
              }
                this.actions = res.data.actions

            })

        },
        showHelpDialog() {
            globalFunctions.piwikAnalytics([
                "trackEvent",
                "Discussion actions",
                "Show question legend"
            ]);
            this.helpDialog = true;
        },
        toggleShowStats() {
            if (!this.showStats) {
                globalFunctions.piwikAnalytics([
                    "trackEvent",
                    "Discussion actions",
                    "Show statistics"
                ]);
            }
            this.showStats = !this.showStats;
        },
        handleFilter(action) {
            this[action]();
        },
        startPresentation() {
            this.$router.push({ path: `/discussion/${this.discussionId}` });
        },
        saveQuestionOrder(questions) {
            reorderQuestions(
                this.discussionId,
                questions.map(question => question.id)
            );
            globalFunctions.piwikAnalytics([
                "trackEvent",
                "Discussion actions",
                "Questions reordered"
            ]);
        },
        endDrag() {
            this.drag = false;
            this.filterMode = null;

            this.saveQuestionOrder(this.allQuestions);
        },
        handleMissingStats(a, b) {
            //logic putting questions with no stats in the bottom and not to reorder if neither have stats
            return a.statistic && !b.statistic ? -1 : 1;
        },
        resetQuestions() {
            resetQuestionOrder(this.discussionId).then(() => {
                if (window.$devMode) {
                    console.log("resetting DM question order");
                }
                getAllQuestions(this.discussionId).then(response => {
                    this.allQuestions = response.data;
                });
                this.selectedFilterModeIdx=null;
            });
        },
        sortByFFDesc() {
            this.allQuestions.sort((a, b) => {
                return a.ffCount < b.ffCount ? 1 : -1;
            });

            this.saveQuestionOrder(this.allQuestions);
        },
        sortByFFInc() {
            this.allQuestions.sort((a, b) => {
                return a.ffCount > b.ffCount ? 1 : -1;
            });

            this.saveQuestionOrder(this.allQuestions);
        },
        sortByAvgDesc() {
            this.allQuestions.sort((a, b) => {
                if (a.statistic && b.statistic) {
                    // higher average goes first
                    return a.statistic.average < b.statistic.average ? 1 : -1;
                } else {
                    return this.handleMissingStats(a, b);
                }
            });

            this.saveQuestionOrder(this.allQuestions);
        },
        sortByAvgInc() {
            this.allQuestions.sort((a, b) => {
                if (a.statistic && b.statistic) {
                    // lower average goes first
                    return a.statistic.average > b.statistic.average ? 1 : -1;
                } else {
                    return this.handleMissingStats(a, b);
                }
            });

            this.saveQuestionOrder(this.allQuestions);
        },
        sortByDevDesc() {
            this.allQuestions.sort((a, b) => {
                if (a.statistic && b.statistic) {
                    //  higher deviation goes first
                    return a.statistic.deviation < b.statistic.deviation
                        ? 1
                        : -1;
                } else {
                    return this.handleMissingStats(a, b);
                }
            });

            this.saveQuestionOrder(this.allQuestions);
        },
        sortByDevInc() {
            this.allQuestions.sort((a, b) => {
                if (a.statistic && b.statistic) {
                    //  lower deviation goes first
                    return a.statistic.deviation > b.statistic.deviation
                        ? 1
                        : -1;
                } else {
                    return this.handleMissingStats(a, b);
                }
            });

            this.saveQuestionOrder(this.allQuestions);
        },

        roundUp(val) {
            return roundUp(val);
        },
        setData() {
            this.loading = true;
            this.error = null;
            this.allQuestions = null


            if (this.appStore.checkFeature("ACTION_ENGINE")) {
                this.getActions()
            }

            //retrieve questions asked in selected survey
            getAllQuestions(this.discussionId)
                .then(response => {
                    this.allQuestions = response.data;
                })
                 .catch((error) => {
                    this.error = globalFunctions.parseError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        avgTextColor(avg) {
            return avgTextColor(this.topGrade, avg);
        }
    },
    computed: {
        ...mapStores( useAppStore ),

        topGrade() {
            return this.appStore.topGrade;
        },
        questionLegendLang() {
            const lang = getGenericLocale(this.$i18n.locale);
            const supportedLangs = ["en", "de", "fr", "it"];
            if (supportedLangs.includes(lang)) {
                return lang;
            } else {
                return "en";
            }
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                handle: ".dragHandle"
            };
        }
    },
    watch: {
        discussionId(val) {
            if (val) {
                this.setData();
                this.selectedFilterModeIdx = null;
            } else {
                this.selectedFilterModeIdx = null;
                this.allQuestions = null;
            }
        }
    }
};
</script>

<style scoped>
.questionsCard {
    transition: all 0.13s linear;
}

.highlighted {
    border: 1px solid rgb(85, 161, 223) !important;
    /*  background: rgb(227, 241, 252);
background: linear-gradient(180deg, rgb(206, 230, 249) 0%, rgba(255,255,255,0.01) 15%); */
    background: linear-gradient(
        180deg,
        rgb(222, 239, 253) 0%,
        rgba(255, 255, 255, 0.01) 10%
    );
}

.orderBtn::before {
    color: transparent !important;
}

.orderBtn {
    transition: background-color 0.1s linear;
}

.orderBtn:hover {
    background-color: #d3eafb !important;
}
</style>
