<template>
    <v-container class="fill-height login-success" style="position: relative">
        <v-row justify="center" align="center" v-if="showGreeting">
            <v-col cols="12" sm="10" md="8" lg="7" xl="6">
                <v-card class="elevation-12">
                    <v-card-text class="pt-6 px-12 pb-12">

                        <div class="mt-12 text-h4 text-center">
                            {{ $t("hi") }}
                            <span v-if="currentUserName">, {{ currentUserName }}</span>!
                        </div>
        
                        <v-img 
                            class="pulse quick-fade-in d-flex mx-auto" 
                            src="/images/surveyIcons/coffee/5.png" 
                            width="200">
                        </v-img>
        
                        <section class="mt-12">
                            <h3 class="text-h6 text-center text-primary text-capitalize">
                                {{ $t('login.selectYourCompany') }}
                            </h3>

                            <v-select 
                                v-if="memberships && memberships.length > 1" 
                                class="mt-6"
                                v-model="selectedMembership" 
                                :items="memberships"
                                item-title="companyName" 
                                item-value="membershipId" 
                                :label="$t('select')"
                                variant="solo"
                                @update:model-value="onMembershipChange"
                            >
                            </v-select>
                        </section>
        
        
                        <v-progress-circular 
                            v-if="!selectedMembership && (!memberships || memberships.length <= 1)"
                            class="d-flex mx-auto" indeterminate color="primary"
                        >
                        </v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
    import { settings } from "@/api/settings.api";
    import { getSurveys } from "@/api/takeSurvey";
    import { ref, computed, onMounted, inject, onUnmounted } from "vue";
    import { useRouter, useRoute } from "vue-router";
    import { useI18n } from "vue-i18n";
    import globalFunctions from "../utils/globalFunctions";
    import { setLanguageLocale } from "@/utils/languageHelpers";
    import { profiles, selectProfile } from "@/api/auth.api";
    import { useQuestionEditorStore } from "@/store/questionEditor.store";
    import { useAuthStore } from "@/store/auth.store";
    import { useAppStore } from "@/store/app.store";

    const currentUserName = ref(null);
    const showGreeting = ref(false);
    const targetPath = ref(null);
    const memberships = ref(null);
    const selectedMembership = ref(null);
    const router = useRouter();
    const route = useRoute();
    const { locale } = useI18n();
    const questionEditorStore = useQuestionEditorStore();
    const authStore = useAuthStore();
    const appStore = useAppStore();

    onMounted(() => {
        loadProfiles();

        if (route?.query?.target) {
            l("[LoginSuccess] existing target path: " + route.query.target);

            targetPath.value = route.query.target;
        }

        document.querySelector(".v-application__wrap").classList.add('login-centered');
    });

    onUnmounted(() => {
        document.querySelector(".v-application__wrap").classList.remove('login-centered');
    });

    const loadProfiles = () => {

        profiles().then(
            (response) => {
                l("in loadProfiles, response:", response);
                currentUserName.value = response.data.firstName;
                showGreeting.value = true;

                // Check if there is only one membership
                if (response.data.memberships) {
                    l("in loadProfiles, response.data.memberships:", response.data.memberships);
                    if (response.data.memberships.length > 1) {
                        // Store them in the value
                        memberships.value = response.data.memberships;
                        return;
                    }
                }
                tokenLogin();
            }

        ).catch(
            (error) => {
                le("in loadProfiles, error:", error);
            }
        )
    }

    const tokenLogin = () => {
        l("in tokenLogin");

        authStore.tokenLogin()
            .then(handleLoginResponse)
            .catch(error => {
                le("[LoginFail]: tokenLogin not successful, routing back to /login");
                le(error);
                return authStore.logout();
            });
    }

    const handleLoginResponse = (user) => {
        l("in handleLoginResponse");
        // language can be null, hence fallback check via settings below as well
        if (user?.language) {
            locale.value = user.language;
        } else {
            l("[LoginSuccess]: tokenLogin, user?.language:", user?.language);
        }

        currentUserName.value = user.firstName;
        showGreeting.value = true;

        // set settings in store
        return setSettings(user).then(setNextPath);
    }

    const setSettings = async (user) => {
        l("[LoginSuccess]: setSettings");

        // set settings in store
        const response = await settings(user?.companyId, user?.userId)
        l("[LoginSuccess]: setSettings.then");

        let userSettings = response.data;
        const storeSetSettingsPromise = appStore.setSettings(response.data);
        questionEditorStore.setQuestionIcons(user.companyId);

        const roles = authStore.userRoles;
        console.log("user roles: " + roles);
        let maxRole = "member";
        if (roles.includes("ROLE_ADMIN")) {
            maxRole = "teamAdmin";
        }
        if (roles.includes("ROLE_SUPERADMIN")) {
            maxRole = "companyAdmin";
        }
        //  log some important stats when running locally on dev mode
        if ($devMode) {
            console.group("Main details");
            console.log(
                "%cCOMPANY ID",
                "font-weight: bold",
                user.companyId
            );

            console.log(
                "%cUSER ID",
                "font-weight: bold",
                user ? user.userId : "NOT FOUND"
            );

            console.log(
                "%cSETTINGS",
                "font-weight: bold",
                s(userSettings)
            );
            console.groupEnd();
        }

        if ($devMode) {
            //staging PIWIK
            globalFunctions.piwikAnalytics(['setCustomDimensionValue', 1, user.companyId]);
        } else {
            // prod PIWIK
            globalFunctions.piwikAnalytics(['setCustomDimensionValue', 4, user.companyId]);
        }

        setLanguageLocale(appStore.userSettings?.userLanguage);

        return storeSetSettingsPromise;
    }

    const setNextPath = () => {
        l("in setNextPath");

        let userSetPromise = new Promise((success, error) => {
            let needsUserAsyncAccess = true;

            try {
                needsUserAsyncAccess = !(JSON.parse(localStorage.getItem('user')));
            } catch (ex) { window.le(ex) }

            l('in userSetPromise, needsUserAsyncAccess:', needsUserAsyncAccess);

            if (!needsUserAsyncAccess) {
                l('in userSetPromise, had user');
                success(true);
            } else {
                l('in userSetPromise, NO user yet');

                appStore.setEventHandler('localStorage:setItem:user', (value) => {
                    l('in userSetPromise, eventHandler; value:', value);

                    if (value) {
                        success(value);
                    }
                });
            }

        })

        return Promise.allSettled([getSurveys(), userSetPromise])
            .then(([response, userSet]) => {
                if (response.status !== 'fulfilled') {
                    throw response.reason;
                }

                if (userSet.status !== 'fulfilled') {
                    throw userSet.reason;
                }

                let activeSurveyFlag = response.value.data.length > 0;
                let nextPath;

                if (activeSurveyFlag) {
                    window.sessionStorage.setItem('hasActiveSurvey', 'true');
                }

                // TODO: review if this is needed or if the routerSecurity guards are enough once Meteor is removed
                let user = JSON.parse(localStorage.getItem("user"));
                if (user.showIntro) {
                    nextPath = `/intro${targetSuffix.value}`;
                }
                else if (!user.gdprAccepted) {
                    nextPath = `/gdpr${targetSuffix.value}`;
                }
                else if (activeSurveyFlag) {
                    nextPath = "/survey/start";
                    window.sessionStorage.removeItem('hasActiveSurvey');
                }
                else {
                    //no active surveys, intro / GDPR flags -> go to either targetPath or TB dashboard
                    l(`[LoginSuccess] setNextPath: no pre-redirect`);

                    nextPath = targetPath.value || "/tb";
                }

                l("[LoginSuccess]: setting next path to " + nextPath);
                return goNext(nextPath);
            })
            .catch(error => {
                le("[LoginStatus]: can't retrieve surveys to calculate next path, falling back to TB");
                le(error);

                return goNext("/tb");
            });
    }

    const goNext = (path) => {
        showGreeting.value = false;
        return router.push(path);
    }
    
    const onMembershipChange = () => {
        if (selectedMembership.value) {
            l("selectedMembership changed to:", selectedMembership.value);
            selectProfile(selectedMembership.value).then(tokenLogin);
        }
    };

    const targetSuffix = computed(() => {
        return targetPath.value ? `?target=${targetPath.value}` : "";
    })
</script>
