<template>
    <div>
        <v-dialog v-model="dialogVal" max-width="600">
            <v-card>
                <div class="d-flex bg-blue-darken-1">
                    <v-icon color="white" class="ml-3"
                        >mdi-email-outline</v-icon
                    >
                    <v-card-title class="text-h5 text-white" primary-title>
                        {{ $t("tasks.sendEmail") }}
                    </v-card-title>
                </div>

                <v-card-text class="mt-4">
                    <v-form v-model="isFormValid">
                        <v-text-field
                            placeholder="example@email.com"
                            hide-details
                            :rules="emailRules"
                            label="E-mail"
                            v-model="email"
                        ></v-text-field>
                        <!-- TODO: VUE 3: END density-->
                        <v-checkbox :ripple="false" density="compact" v-model="calendarEntry" color="grey" :true-icon="'mdi-checkbox-marked-outline'" :label="calendarEntry ? $t('tasks.calendarEntry') : $t('tasks.calendarEntryNo')"></v-checkbox>
                    </v-form>
                </v-card-text>

                <v-alert
                    v-if="errorMessage"
                    variant="outlined"
                    type="error"
                    class="mx-2"
                    >{{ errorMessage }}</v-alert
                >

                <v-card-actions class="px-6 pb-6">
                    <v-btn variant="text" theme="dark" color="grey" @click="closeDialog">{{
                        $t("buttons.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        id="emailTaskBtn"
                        :loading="loading"
                        :disabled="!isFormValid || loading"
                        color="primary"
                        @click="sendEmail"
                        >{{ $t("buttons.send") }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { useSnackbarStore } from "@/store/snackbar.store";
import { shareTask } from "../../api/tasks.api";
import globalFunctions from "@/utils/globalFunctions";
import { mapStores } from "pinia";
import { useAuthStore } from "@/store/auth.store";
export default {
    name: "",
    props: ["task", "emailDialog"],
    mounted() {
        //setting default to user's own e-mail. if null will default to placeholder example@email.com
        this.email = this.authStore.user.email;
    },
    data: () => ({
        isFormValid: false,
        calendarEntry: true,
        email: null,
        emailRules: [
            v => !!v || "",
            v => /.+@.+\..+/.test(v) || "",
            v => (v || "").indexOf(" ") < 0 || ""
        ],
        loading: false,
        errorMessage: null
    }),
    methods: {
        closeDialog() {
            this.$emit("emailDialogStatus", false);
            this.errorMessage = null;
            this.email = this.authStore.user.email;
        },
        sendEmail() {
            this.loading = true;
            this.errorMessage = null;

            shareTask(this.task.id, this.email, this.calendarEntry)
                .then(() => {
                    globalFunctions.piwikAnalytics(['trackEvent','Task actions', 'Send via email']);
                    this.closeDialog();

                    const snackbarStore = useSnackbarStore();
                    snackbarStore.showSnackbar([true, "emailSent"]);
                })
                .catch(error => (this.errorMessage = globalFunctions.parseError(error)))
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    computed: {
        ...mapStores( useAuthStore ),
        
        dialogVal: {
            get() {
                return this.emailDialog;
            },
            set() {
                this.closeDialog();
            }
        }
    }
};
</script>

<style>
/*  if we need to make the checkbox icon smaller
    div#calendarCheckBox .v-input--selection-controls__input i{
    font-size:1.1rem;
} */
</style>
