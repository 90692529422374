<template>
    <v-dialog v-model="dialogState" max-width="400">
        <v-card>
            <v-toolbar flat color="grey-lighten-5">
                <v-toolbar-title>{{ $t("updateDetails") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="$emit('dialog', false)" icon variant="text">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="updateForm" class="my-4">
                    <v-text-field
                        v-for="field in fields"
                        :key="field.key"
                        v-model="updatedValues[field.key]"
                        :rules="field.rules"
                        :label="field.label"
                    ></v-text-field>
                </v-form>
                <v-alert v-if="error" type="error" variant="outlined" class="mb-4">
                    {{ error }}
                </v-alert>
                <v-btn
                    :disabled="loading"
                    size="large"
                    @click="updateDetails"
                    color="primary"
                >
                    {{ $t("buttons.save") }}
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { nextTick } from 'vue';
import globalFunctions from "@/utils/globalFunctions";
import { mapStores } from 'pinia';
import { useSnackbarStore } from '@/store/snackbar.store';

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        dialog: {
            type: Boolean,
            required: true
        },
        fields: {
            type: Array,
            default: () => [],
            required: true
        },
        updateMethod: {
            type: Function,
            required: true
        }
    },
    data: () => ({
        updatedValues: {},
        loading: false,
        error: null
    }),
    mounted() {
        this.setFields();
    },
    methods: {
        setFields() {
            this.updatedValues = {};
            this.fields.forEach(field => {
                this.updatedValues[field.key] = this.user[field.key];
            });
        },
        async updateDetails() {
            this.error = null;
            await nextTick();
            if (this.$refs.updateForm.validate()) {
                this.loading = true;
                const updatedUser = { ...this.user, ...this.updatedValues };

                try {
                    await this.updateMethod(updatedUser);
                    this.snackbarStore.showSnackbar([true, "detailsUpdated"]);
                    this.$emit('update-success', updatedUser);
                    this.$emit('dialog', false);
                } catch (error) {
                    this.error = globalFunctions.parseError(error);
                } finally {
                    this.loading = false;
                }
            }
        }
    },
    computed: {
        ...mapStores(useSnackbarStore),
        dialogState: {
            get() {
                return this.dialog;
            },
            set(val) {
                this.$emit("dialog", val);
            }
        }
    },
    watch: {
        dialog(val) {
            if (val) {
                this.setFields();
            }
        }
    }
};
</script>

<style scoped>
.langFlag {
    margin-right: 6px;
    margin-top: -2px;
}
</style>
