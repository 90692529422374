<template>

    <div class="d-flex align-center justify-center" v-if="topGrade" :class="{ 'font-l': $vuetify.display.smAndUp }">
        <AvgTooltip :stats="statistics" :questionAverage="true">
            <template #trigger>
                <div class="stats">
                    <span class="stat-text">Ø {{ curatedNumber(statistics.average) }}</span>
                    <span v-if="statistics.changeAverage" :class="{
                        'text-green': avgChangeGood,
                        'text-red': !avgChangeGood
                    }">
                        {{ avgChangeGood ? "&UpperRightArrow;" : "&LowerRightArrow;" }}
                        <span>{{ curatedNumber(avgChange) }}%</span>
                    </span>
                </div>
            </template>
        </AvgTooltip>

        <!-- deviation -->

        <DevTooltip :change="statistics.changeDeviation" :deviation="statistics.deviation" :questionDev="true">
            <template #trigger>
                <div class="stats">
                    <span class="stat-text ml-6">&LeftRightArrow;
                        {{ curatedNumber(statistics.deviation) }}
                    </span>
                    <span v-if="statistics.changeDeviation" :class="{
                        'text-green': devChangeGood,
                        'text-red': !devChangeGood
                    }">
                        {{ devChangeGood ? "&LowerRightArrow;" : "&UpperRightArrow;" }}
                        <span>{{ curatedNumber(statistics.changeDeviation) }}</span>
                    </span>
                </div>
            </template>
        </DevTooltip>

    </div>
</template>

<script>
    import ScaleGraph from "@/components/ScaleGraph";
    import { useAppStore } from "@/store/app.store";
    import {
        calculateAvgChange,
        devTextColor,
        avgTextColor
    } from "@/utils/dashboardCode.js";
    import { mapStores } from "pinia";
    import AvgTooltip from "./global/AvgTooltip.vue";
    import DevTooltip from "./global/DevTooltip.vue";

    export default {
        name: "",
        props: ["statistics"],
        components: { ScaleGraph, AvgTooltip, DevTooltip },
        data: () => ({
            //    for testing
            // statistics: {
            //     average: 3.8,
            //     deviation: 1.3038404810405297,
            //     changeAverage: -0.13333333333333333,
            //     changeDeviation: -0.3038404810405297
            // }
        }),
        computed: {
            ...mapStores(useAppStore),

            avgChangeGood() {
                return this.statistics.changeAverage >= 0;
            },
            devChangeGood() {
                return this.statistics.changeDeviation <= 0;
            },
            avgChange() {
                return calculateAvgChange(
                    this.topGrade,
                    this.statistics.changeAverage
                );
            },
            topGrade() {
                return this.appStore.topGrade;
            }
        },
        methods: {
            curatedNumber(value) {
                let nr = Number(value.toFixed(2));
                return nr < 0 ? nr * -1 : nr;
            },
            avgTextColor(avg) {
                return this.topGrade ? avgTextColor(this.topGrade, avg) : "";
            },
            devTextColor(dev) {
                return this.topGrade ? devTextColor(this.topGrade, dev) : "";
            }
        }
    };
</script>

<style scoped>
    .stats {
        display: inline-block;
    }

    .stat-text {
        font-weight: bold;
    }
</style>
