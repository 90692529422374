import { createApp } from "vue";
import vueDebounce from 'vue-debounce';
import YouTube from 'vue3-youtube'

import window from './globals';
import registerGlobalComponents from '@/components/global/register'; // globally register reusables

import App from './App.vue';
import i18n  from './i18n'; //set up translations
import vuetify from './plugins/vuetify';
import { vuetifyProTipTap } from './plugins/vuetify-pro-tiptap';
import router from './router';
import globalFunctions from './utils/globalFunctions';
import { createPinia } from 'pinia';

import '@mdi/font/css/materialdesignicons.css'
import '@/styles/main.css'

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(i18n);
app.use(router);
app.use(vuetify);
app.use(vuetifyProTipTap);
app.component('YouTube', YouTube);

app.directive(
  "debounce",
  vueDebounce({
    lock: false,
    listenTo: ['keyup', 'change', 'input'],
    defaultTime: '300ms',
    fireOnEmpty: false,
    cancelOnEmpty: false,
    trim: false,
  })
);

app.config.globalProperties.$devMode = window.$devMode;
app.config.globalProperties.$localMode = window.$localMode;
app.config.globalProperties.l = (...args: any[]) => window.l(...args);
app.config.globalProperties.le = (...args: any[]) => window.le(...args);
app.config.globalProperties.lj = (...args: any[]) => window.lj(...args);
app.config.globalProperties.ls = (...args: any[]) => window.ls(...args);
app.config.globalProperties.lw = (...args: any[]) => window.lw(...args);
app.config.globalProperties.j = (...args: any[]) => window.j(...args);
app.config.globalProperties.s = (...args: any[]) => window.s(...args);
app.config.globalProperties.$mixin = globalFunctions;
app.config.globalProperties.$moment = window.$moment;
app.config.performance = false;

window.l('i18n', i18n);

window.jvm = app;

app.mount("#app");
