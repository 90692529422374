<template>
    <v-dialog v-model="dialogState" max-width="400">
        <v-card>
            <v-toolbar flat color="grey-lighten-5">
                <v-toolbar-title>{{ $t("updateMobile") }}</v-toolbar-title>
                <v-btn @click="$emit('dialog', false)" icon variant="text"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="mt-3">
                {{ $t("enterMobileText") }}
                <v-form
                    ref="updateNumberForm"
                    class="my-4"
                    @update:modelValue="isFormValid=$event"
                >
                    <v-text-field
                        :rules="[
                            requireEmailOrMobile,
                            fullNumberRulesLocalised
                        ]"
                        :label="$t('labelMobilePhone')"
                        name="phone"
                        placeholder=" "
                        v-model.trim="newMobile"
                        prepend-icon="mdi-cellphone"
                        type="text"
                        autocomplete="off"
                        @keydown.enter.prevent="updateMobile"
                    ></v-text-field>
                </v-form>
                <v-alert v-if="error" type="error" variant="outlined" class="mb-4">
                    <div>
                        <strong> {{ $t("errorMobile") }}</strong>
                    </div>
                    <div class="mt-2">
                        {{ error }}
                    </div>
                </v-alert>
                <v-btn
                    :disabled="loading || !isFormValid || user.mobile === newMobile?.trim() || (!user.mobile && !newMobile?.trim())"
                    size="large"
                    @click="updateMobile"
                    color="primary"
                    >{{ $t("buttons.save") }}
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { nextTick } from 'vue'
import { fullNumberRules, required } from "@/utils/formHelpers";
import { updateUserDetails } from "@/api/users.api";
import { updateMyDetails } from "@/api/users.api";
import globalFunctions from "@/utils/globalFunctions";
import { mapStores } from 'pinia';
import { useSnackbarStore } from '@/store/snackbar.store';

export default {
    props: ["user", "dialog", "isMyProfile"],
    data: () => ({
        newMobile: null,
        loading: false,
        error: null,
        isFormValid: null,
    }),
    mounted() {
        this.setFields();
    },
    methods: {
        requireEmailOrMobile(number) {
            return (this.requiredLocalised(this.user.email) === true)
                || this.requiredLocalised(number);
        },
        updateMobile() {
            this.error = null;
            globalFunctions.piwikAnalytics([
                'trackEvent',
                'User management actions',
                'Updated mobile via Profile'
            ]);

            nextTick(() => {
                if (this.isFormValid) {
                    this.loading = true;
                    let updatedUser = { ...this.user };
                    updatedUser.mobile = this.newMobile
                        ? this.newMobile.trim()
                        : null;

                    if (this.isMyProfile) {
                        updateMyDetails(updatedUser)
                            .then(() => {
                                this.snackbarStore.showSnackbar([true, "detailsUpdated"]);
                                this.$emit("dialog", false);
                                this.$emit("refresh");
                            })
                            .catch((error) => {
                                this.error = globalFunctions.parseError(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    } else {
                        updateUserDetails(this.user.id, updatedUser)
                            .then(() => {
                                this.snackbarStore.showSnackbar([true, "detailsUpdated"]);
                                this.$emit("dialog", false);
                                this.$emit("refresh");
                            })
                            .catch((error) => {
                                this.error = globalFunctions.parseError(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                }
            });
        },
        setFields() {
            this.error = null;

            if (this.user?.mobile) {
                this.newMobile = this.user.mobile.trim();
            } else {
                this.newMobile = null;
            }
        },
    },
    computed: {
        ...mapStores( useSnackbarStore ),

        dialogState: {
            get() {
                return this.dialog;
            },
            set(val) {
                this.$emit("dialog", val);
            },
        },
        fullNumberRulesLocalised() {
            return fullNumberRules(this.$t)[0];
        },
        requiredLocalised() {
            return required(this.$t, 'noDeleteMobileError')[0];
        },
    },
    watch: {
        dialog(val) {
            if (val) {
                this.setFields();
            }
        },
    },
};
</script>
