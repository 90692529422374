import 'vuetify-pro-tiptap/styles/editor.css';

import {
  BaseKit,
  Bold,
  BulletList,
  Clear,
  Color,
  FontSize,
  History,
  HorizontalRule,
  Italic,
  Link,
  OrderedList,
  Underline,
  VuetifyTiptap,
  VuetifyViewer,
  createVuetifyProTipTap,
  defaultBubbleList,
  locale,
} from 'vuetify-pro-tiptap';

import { TextAlign } from './VuetifyProTipTapTextAlignExtension';
import { DEFAULT_LANGUAGE } from "@/utils/languageHelpers";

Object.assign(locale.loadLangMessage('de'), {
  'editor.clear.tooltip': 'Löschen',
  'editor.horizontalrule.tooltip': 'Horizontale Regel',
  'editor.redo.tooltip': 'Wiederholen',
  'editor.undo.tooltip': 'Rückgängig machen',
});

import LinkDialog from './VuetifyProTipTapLinkDialog.vue';


export const vuetifyProTipTap = createVuetifyProTipTap({
  lang: DEFAULT_LANGUAGE === 'de' ? 'de' : 'en',
  components: {
    VuetifyTiptap,
    VuetifyViewer
  },
  extensions: [
    BaseKit.configure({
      placeholder: {
        placeholder: ''
      },
      bubble: {
        defaultBubbleList: editor => {
          return defaultBubbleList(editor);
        }
      }
    }),
    Bold,
    Italic,
    Underline,
    TextAlign.configure({ alignments: ['left', 'center', 'right', 'justify'] }),
    FontSize,
    Color,
    Clear.configure({ divider: true }),
    BulletList,
    OrderedList,
    Link.configure({
      dialogComponent: () => LinkDialog
    }),
    HorizontalRule,
    History.configure({ divider: true }),
  ]
});
