<template>
    <v-dialog v-model="dialogState" max-width="400">
        <v-card>
            <v-toolbar flat color="grey-lighten-5">
                <v-toolbar-title>{{ $t("updateEmail") }}</v-toolbar-title>
                <v-btn @click="$emit('dialog', false)" icon variant="text"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
                <v-form
                    ref="updateEmailForm"
                    class="my-4"
                    @update:modelValue="isFormValid=$event"
                >
                    <v-text-field
                        :rules="[
                            requireEmailOrMobile,
                            emailRulesLocalised
                        ]"
                        placeholder=" "
                        label="E-mail"
                        name="email"
                        v-model="newEmail"
                        prepend-icon="mdi-email"
                        type="text"
                        autocomplete="off"
                        @keydown.enter.prevent="updateEmail"
                    ></v-text-field>
                </v-form>
                <v-alert v-if="error" type="error" variant="outlined" class="mb-4">
                    <div>
                        <strong> {{ $t("errorEmail") }}</strong>
                    </div>
                    <div class="mt-2">
                        {{ error }}
                    </div>
                </v-alert>
                <v-btn
                    :disabled="loading || !isFormValid || user.email === newEmail || (!user.email && !newEmail)"
                    size="large"
                    @click="updateEmail"
                    color="primary"
                    >{{ $t("buttons.save") }}
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { nextTick } from 'vue'
import { emailRules, required } from "@/utils/formHelpers";
import {initUserEmailChange, updateUserDetails} from "@/api/users.api";
import { updateMyDetails } from "@/api/users.api";
import globalFunctions from "@/utils/globalFunctions";
import { mapStores } from 'pinia';
import { useSnackbarStore } from '@/store/snackbar.store';
import { useAuthStore } from '@/store/auth.store';
import {initEmailChange} from "@/api/auth.api.js";

export default {
    props: ["user", "dialog", "isMyProfile"],
    data: () => ({
        newEmail: null,
        loading: false,
        error: null,
        isFormValid: null,
    }),
    mounted() {
        this.setFields();
    },
    methods: {
        requireEmailOrMobile(email) {
            return (this.requiredLocalised(this.user.mobile) === true)
                || this.requiredLocalised(email);
        },
        updateEmail() {
            this.error = null;
            globalFunctions.piwikAnalytics([
                'trackEvent',
                'User management actions',
                'Updated email via Profile'
            ]);

            nextTick(() => {
                if (this.isFormValid) {
                    this.loading = true;
                    let updatedUser = { ...this.user };
                    updatedUser.email = this.newEmail ? this.newEmail : null;

                    if (this.isMyProfile) {
                        if(window.$devMode){
                            l("Updating my email");
                        }

                        initEmailChange(this.newEmail)
                            .then((resp) => {
                                this.snackbarStore.showSnackbar([true, "emailConfirmationSent"]);
                                this.$emit("dialog", false);
                                this.$emit("refresh");

                            })
                            .catch((error) => {
                                this.error = globalFunctions.parseError(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    } else {
                        if(window.$devMode){
                            l("Updating user email")
                        }
                        initUserEmailChange(this.user.id, this.newEmail)
                            .then(() => {
                                this.snackbarStore.showSnackbar([true, "emailConfirmationSent"]);
                                this.$emit("dialog", false);
                                this.$emit("refresh");
                            })
                            .catch((error) => {
                                this.error = globalFunctions.parseError(error);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                } else {
                    l("rules not passing")
                }
            });
        },
        setFields() {
            this.error = null;

            if (this.user.email) {
                this.newEmail = JSON.parse(JSON.stringify(this.user.email));
            } else {
                this.newEmail = null;
            }
        },
    },
    computed: {
        ...mapStores( useSnackbarStore, useAuthStore ),

        dialogState: {
            get() {
                return this.dialog;
            },
            set(val) {
                this.$emit("dialog", val);
            },
        },
        emailRulesLocalised() {
            return emailRules(this.$t)[1];
        },
        requiredLocalised() {
            return required(this.$t, 'noDeleteEmailError')[0];
        },
    },
    watch: {
        dialog(val) {
            if (val) {
                this.setFields();
            }
        },
    },
};
</script>
