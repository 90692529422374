<template>
    <div class="d-flex mb-1" :class="{'align-center': !isExpanded}">
        <v-expansion-panels
            v-model="panel"
            class="openTask quick-fade-in"

        >
            <v-expansion-panel class="task">
                <v-expansion-panel-title
                    class="py-2"
                    @click="isExpanded = !isExpanded"
                    @keyup.space.prevent
                >
                    <v-row align="center" no-gutters>
                        <v-col style="max-width: 40px" class="taskIcon">
                            <v-icon
                                v-if="!overdue"
                                color="yellow-darken-1"
                                class="px-1"
                                >mdi-clipboard-text-outline</v-icon
                            >
                            <!-- TODO: V3-end -->
                            <v-icon v-else color="#E96B24" class="px-1"
                                >mdi-calendar</v-icon
                            >
                        </v-col>
                        <v-col>
                            <div v-if="!edit">
                                {{ task.summary }}
                                <v-icon
                                    class="fade-in"
                                    v-show="isExpanded"
                                    size="small"
                                    color="grey-lighten-1"
                                    @click.stop="edit = true"
                                    >mdi-pencil</v-icon
                                >
                            </div>
                            <!-- TODO: VUE 3: END density-->
                            <v-text-field
                                @click.stop
                                ref="editTitleRef"
                                v-else
                                hide-details
                                variant="solo"
                                density="compact"
                                flat
                                bg-color="transparent"
                                v-model="task.summary"
                                @keyup.enter="editTitle"
                                @blur="editTitle"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-expansion-panel-title>

                <v-expansion-panel-text>
                    <v-container
                        :class="{ 'px-0': $vuetify.display.smAndDown }"
                    >
                        <div class="text-caption text-grey">
                            {{ $t("admin.created") }}:
                            {{ formattedCreatedAt }}
                        </div>

                        <!-- task origin -->
                        <span class="boxHeader">{{ $t("tasks.origin") }}:</span>
                        <v-card border flat>
                            <TaskOrigin :task="task" />
                        </v-card>
                        <!-- /task origin -->

                        <!-- comments -->
                        <div v-if="task.comments.length > 0">
                            <span class="boxHeader"
                                >{{ $t("tasks.comments") }}:</span
                            >

                            <v-card width="100%" border flat>
                                <v-container>
                                    <div
                                        v-for="(comment, k) in task.comments"
                                        :key="comment.id"
                                    >
                                        <Comment
                                            :comment="comment"
                                            :task-id="task.id"
                                            :openTask="true"
                                        />
                                        <v-divider
                                            v-if="k + 1 < task.comments.length"
                                            class="my-1"
                                        ></v-divider>
                                    </div>
                                </v-container>
                            </v-card>
                        </div>

                        <!-- /comments -->
                        <div :class="{ 'mt-2': task.comments.length === 0 }">
                            <div class="d-flex align-center">
                                <div
                                    v-show="task.comments.length == 0"
                                    class="text-light text-grey ml-3"
                                >
                                    {{ $t("tasks.noComments") }}
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    :ripple="false"
                                    variant="text"
                                    size="small"
                                    color="accent-darken-1"
                                    @click="addComment = !addComment"
                                    class="ma-1"
                                >
                                    <v-icon size="small" class="mr-1">{{
                                        addComment
                                            ? "mdi-chevron-up"
                                            : "mdi-comment-plus-outline"
                                    }}</v-icon
                                    >{{ $t("tooltips.addComment") }}
                                </v-btn>
                            </div>
                        </div>
                        <v-expand-transition>
                            <div v-show="addComment">
                                <AddComment :task="task" />
                            </div>
                        </v-expand-transition>
                        <v-row justify="space-between" class="pt-4" no-gutters>
                            <!-- date picker -->
                            <v-col cols="12" sm="4">
                                <v-menu
                                    v-model="pickermenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template #activator="{ props }">
                                        <!-- TODO: VUE 3: END density-->
                                        <v-text-field
                                            clearable
                                            @click:clear="clearDeadline"
                                            density="compact"
                                            :model-value="
                                                task.dueDate
                                                    ? renderDate(task.dueDate)
                                                    : ''
                                            "
                                            :label="pickerLabel"
                                            readonly
                                            v-bind="props"
                                        >
                                            <template #prepend>
                                                <v-icon
                                                    :color="
                                                        overdue
                                                            ? 'red'
                                                            : 'grey-darken-1'
                                                    "
                                                    >mdi-calendar</v-icon
                                                >
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <!-- TODO V3-end -->
                                    <v-date-picker
                                        hide-header
                                        v-model="pickerDeadline"
                                        @update:model-value="pickermenu = false"
                                        :locale="$i18n.locale"
                                        :first-day-of-week="1"
                                        :min="today"
                                        show-week
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <!-- /date picker -->

                            <!-- person picker -->
                            <v-col cols="12" sm="5">
                            <v-autocomplete
                                auto-select-first
                                density="compact"
                                v-model="person"
                                :items="assigneeList"
                                v-model:search-input="personSearch"
                                item-title="displayName"
                                item-value="id"
                                hide-details
                                :no-data-text="'emptyResult'"
                                :label="assignLabel"
                            ></v-autocomplete>
                            </v-col>
                            <!-- /person picker -->
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="$route.name !== 'Task'"
                                color="grey"
                                class="text-caption ml-n2"
                                style="text-transform: none"
                                variant="text"
                                size="x-small"
                                :to="`/task/${task.id}`"
                                ><v-icon
                                    class="mr-1"
                                    size="x-small"
                                    style="color: inherit"
                                    >mdi-page-next-outline</v-icon
                                >
                                {{ $t("tasks.taskPage") }}</v-btn
                            >
                        </div>
                    </v-container>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <!-- btns -->
        <div :class="{'mt-1': isExpanded}">
            <v-tooltip location="bottom" content-class="bg-green" open-delay="1000">
                <template #activator="{ props }">
                    <task-button
                        class="closeTaskBtn"
                        v-bind="props"
                        @click="close"
                        :label="$t('buttons.done')"
                        :icon="'mdi-check'"
                        :iconColor="'green'"
                    ></task-button>
                </template>
                <span>{{ $t("tooltips.closeTask") }}</span>
            </v-tooltip>

            <v-tooltip location="bottom" content-class="bg-orange text-white" open-delay="1000">
                <template #activator="{ props }">
                    <task-button
                        v-show="isExpanded"
                        v-bind="props"
                        @click="escalateDialog = true"
                        :label="$t('buttons.moveUp')"
                        :icon="'mdi-account-arrow-right'"
                        :iconColor="'orange'"
                    ></task-button>
                </template>
                <span>{{ $t("tooltips.moveUp") }}</span>
            </v-tooltip>

            <EscalateDialog
                :task="task"
                :dialog="escalateDialog"
                @dialogStatus="escalateDialog = $event"
            />

            <v-tooltip location="bottom" content-class="bg-grey-darken-1" open-delay="1000">
                <template #activator="{ props }">
                    <task-button
                        v-show="isExpanded"
                        v-bind="props"
                        @click="emailDialog = true"
                        :label="'E-mail'"
                        :icon="'mdi-email-outline'"
                        :iconColor="'blue lighten-1'"
                    ></task-button>
                </template>
                <span>{{ $t("tasks.sendEmail") }}</span>
            </v-tooltip>

            <EmailDialog
                :task="task"
                :emailDialog="emailDialog"
                @emailDialogStatus="emailDialog = $event"
            />

            <v-tooltip location="bottom" content-class="bg-grey-darken-2" open-delay="1000">
                <template #activator="{ props }">
                    <task-button
                        v-show="isExpanded"
                        v-bind="props"
                        @click="deleteDialog = true"
                        :label="$t('buttons.delete')"
                        :icon="'mdi-trash-can'"
                    ></task-button>
                </template>
                <span>{{ $t("tooltips.deleteTask") }}</span>
            </v-tooltip>

            <v-dialog v-model="deleteDialog" max-width="450">
                <v-card>
                    <v-card-title
                        class="text-h5 text-white bg-grey-darken-2"
                        primary-title
                    >
                        <v-icon color="white" class="mr-2"
                            >mdi-trash-can</v-icon
                        >
                        {{ $t("tasks.deleteTitle") }}
                    </v-card-title>

                    <v-card-text class="mt-2">
                        {{ $t("tasks.deleteBody") }}

                        <div class="d-flex align-center mt-3">
                            <v-icon color="yellow-darken-1" class="px-1"
                                >mdi-clipboard-text-outline</v-icon
                            >

                            <div>
                                <div class="text-medium text-body-1">
                                    {{ task.summary }}
                                </div>

                                <div class="text-caption text-grey">
                                    {{ $t("admin.created") }}:
                                    {{ formattedCreatedAt }}
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pb-4">
                        <v-btn
                            color="grey-darken-1"
                            variant="text"
                            @click="deleteDialog = false"
                            >{{ $t("buttons.cancel") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            theme="dark"
                            class="deleteTaskBtn"
                            color="grey"
                            @click="removeTask"
                            >{{ $t("buttons.delete") }}</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <!-- btns-->
    </div>
</template>

<script lang="ts">
import {
    assignTask,
    changeDate,
    closeTask,
    changeTitle,
    deleteTask
} from "@/api/tasks.api";

import EscalateDialog from "@/components/Taskboard/EscalateDialog.vue";
import EmailDialog from "@/components/Taskboard/EmailDialog.vue";
import Comment from "@/components/Taskboard/Comment.vue";
import AddComment from "@/components/AddComment.vue";
import { getTeamMembers } from "@/api/teams.api";
import { defineComponent, ref, computed, watch, onMounted, nextTick } from "vue";
import { task } from "@/models/task";
import { DateISO8601 } from "@/models/dates";
import { userIdName } from "@/models/user";
import {getEscalateAdmins} from "@/api/admin.api";
import {getAdhocSurveyMembers} from "@/api/adhoc.api";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import globalFunctions from "@/utils/globalFunctions";
import { useSnackbarStore } from "@/store/snackbar.store";
import TaskOrigin from "../global/TaskOrigin.vue";
import TaskButton from "../global/TaskButton.vue";

export default defineComponent({
    name: "OpenTask",
    components: {
        EscalateDialog,
        AddComment,
        EmailDialog,
        Comment,
        TaskOrigin,
        TaskButton,
    },
    props: {
        task: {
            type: Object as () => task,
            required: true,
        },
        startOpen: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
    	const editTitleRef = ref<HTMLInputElement | null>(null);
        const router = useRouter();
        const route = useRoute();
        const { t } = useI18n();
        const snackbarStore = useSnackbarStore();

        onMounted(() => {
            if (props.startOpen) {
                isExpanded.value = true;
                panel.value = 0;
            }

            if (props.task.delegated) {
                getEscalateAdmins().then((response) => {
                    assignees.value = response.data;
                });
            } else {

                if (props.task.entityId) {
                    getTeamMembers(props.task.entityId).then((response) => {
                        assignees.value = response.data;
                    });
                } else if (props.task.surveyId) {
                    getAdhocSurveyMembers(props.task.surveyId).then((response) => {
                        assignees.value = response.data;
                    });
                } else {
                    assignees.value = [];
                }
            }
        })

        const panel = ref<number | null>(null); //when 0, means that panel is expanded
        const personSearch = ref<string>('');
        const assignees = ref<userIdName[]>([]);
        const addComment = ref<boolean>(false); //add comment field visibility state
        const isExpanded = ref<boolean>(false); //expansion panel state
        const escalateDialog = ref<boolean>(false); //move up dialog
        const deleteDialog = ref<boolean>(false); //deletetask dialog
        const emailDialog = ref<boolean>(false); //send task via e-mail dialog
        const pickermenu = ref<boolean>(false); //date picker visibility state
        const today: string = new Date().toISOString().substr(0, 10); //for disabling past dates in date picker
        const edit = ref<boolean>(false); //edit task title

        const renderDate = (date: string): DateISO8601 => {
            //@ts-ignore
            return moment(date).format("DD/MM/YYYY") as DateISO8601;
        }
        const close = (): void => {
            closeTask(props.task.id).then(() => {
                props.task.status = "CLOSED";

                globalFunctions.piwikAnalytics(["trackEvent", "Task actions", "Completed"]);
                snackbarStore.showSnackbar([true, "taskClosed"]);
                emit("showNotification", 1);
            });
        }
        const removeTask = (): void => {
            deleteTask(props.task.id).then(() => {
                props.task.status = "DELETED";

                globalFunctions.piwikAnalytics(["trackEvent", "Task actions", "Deleted"]);
                if(separatePage.value) {
                   //redirect to taskboard
                   router.push("/team-tasks");
                }
            });
            deleteDialog.value = false;
            snackbarStore.showSnackbar([true, "taskDeleted"]);
        }
        const clearDeadline = (): void => {
            props.task.dueDate = null;
            changeDate(props.task.id, { date: null }).then(() => {
                snackbarStore.showSnackbar([true, "detailsUpdated"]);  
            });;
        }
        const editTitle = (): void => {
            edit.value = false;
            changeTitle(props.task.id, props.task.summary);

            globalFunctions.piwikAnalytics(["trackEvent", "Task actions", "Title edited"]);
        }
        const findTeamMember = (userId): userIdName => {
            return assignees.value.filter((member) => member.id === userId)[0];
        }

        const pickerLabel = computed(() => {
            return !props.task.dueDate
                ? `${t("tasks.setDueDate")}:`
                : `${t("tasks.dueDate")}:`;
        })
        const assignLabel = computed(() => {
            return !props.task.assignedTo.id
                ? `${t("tasks.assign")}:`
                : `${t("tasks.assignedTo")}:`;
        })

        const separatePage = computed(() => {
            return route.name === 'task'
        })

        const pickerDeadline = computed({
            get() {
                if (!props.task.dueDate) {
                    return new Date();
                } else {
                    return new Date(props.task.dueDate);
                }
            },

            set(value) {
                //@ts-ignore
                props.task.dueDate = new Date(value);
                changeDate(props.task.id, { date: props.task.dueDate }).then(() => {
                    snackbarStore.showSnackbar([true, "detailsUpdated"]);
                });


                globalFunctions.piwikAnalytics(["trackEvent", "Task actions", "Due date changed"]);
            }
        });

        const person = computed({
            get() {
                return props.task.assignedTo;
            },

            set (value) {
                if (!value) {
                    window.lw('person is:', value);
                    return;
                } else {
                    window.lw('person is:', value);
                }
                props.task.assignedTo = findTeamMember(value);
                assignTask(props.task.id, value).then(() => {
                    snackbarStore.showSnackbar([true, "detailsUpdated"]);
                });

                globalFunctions.piwikAnalytics(["trackEvent", "Task actions", "Reassigned"]);
            },
        });

        const assignedToExistingMember = computed(() => {
            // returns true if assignee is still an active part of the team
            if (assignees.value) {
                for (let i = 0; i < assignees.value.length; i++) {
                    if (assignees.value[i].id === props.task.assignedTo.id) {
                        return true;
                    }
                }
                return false;
            } else {
                return true;
            }
        });

        const assigneeList = computed(() => {
            if (assignedToExistingMember.value || !props.task.assignedTo.id) {
                return assignees.value;
            } else {
                //  generate a new assignees list where the past team member is a disabled option
                let newAssignees = [...assignees.value];
                newAssignees.unshift({
                    //@ts-ignore
                    disabled: true,
                    id: props.task.assignedTo.id,
                    displayName: props.task.assignedTo.displayName,
                });
                return newAssignees;
            }
        });

        const overdue = computed(() => {
            // true if task due date is set and it's in the past
            if (
                //@ts-ignore
                new Date(props.task.dueDate).setHours(0, 0, 0, 0) -
                    new Date().setHours(0, 0, 0, 0) >=
                    0 ||
                !props.task.dueDate
            ) {
                //first date is in future, or today
                return false;
            }

            return true;
        });

        const formattedCreatedAt = computed(() => {
            return moment(props.task.createdAt).format("DD/MM/YYYY, kk:mm");
        });

        watch(edit, (value) => {
            if (value) {
                nextTick(() => {
                    editTitleRef.value?.focus()
                });
            }
        });

        return {
            panel,
            personSearch,
            assignees,
            addComment,
            isExpanded,
            escalateDialog,
            deleteDialog,
            emailDialog,
            pickermenu,
            today,
            edit,
            renderDate,
            close,
            removeTask,
            clearDeadline,
            editTitle,
            findTeamMember,
            pickerLabel,
            assignLabel,
            separatePage,
            pickerDeadline,
            person,
            assignedToExistingMember,
            assigneeList,
            overdue,
            formattedCreatedAt,
        }
    }
});
</script>

<style scoped>
.v-label {
    font-size: 0.9rem;
}
</style>
