<template>
    <v-tooltip
        location="bottom"
        content-class="bg-transparent"
        :max-width="$vuetify.display.xs ? '100vw' : 500"
    >
        <template #activator="{ props }">
            <span v-bind="props">
                <slot name="trigger"> </slot>
            </span>
        </template>

        <v-card color="grey-lighten-4" class="elevation-8">
            <v-card-text class="text-body-1">
                <span class="text-bold pr-1"
                    >&LeftRightArrow; {{ curatedDeviation ? curatedDeviation + '.' : '' }}</span
                >
                <span v-if="generalDev">
                    {{ $t("devGeneralExplanation") }}
                </span>
                <span v-if="questionDev">
               {{ $t("stats.dev1") }}
                </span>

                <div v-if="change && !hideChange" class="mt-2">
                    <i18n-t keypath="stats.dev2">
                        <span :class="change < 0 ? 'text-green' : 'text-red'">
                            {{
                                change < 0
                                    ? $t("stats.decreased")
                                    : $t("stats.increased")
                            }} </span>
                        <span>{{ curatedChange }}</span></i18n-t>
                </div>

                <div class="mt-3">
                    {{ $t("stats.graphIntro") }}

                    <ScaleGraph :mode="'dev'" />
                </div>
            </v-card-text>
        </v-card>
    </v-tooltip>
</template>

<script>
import ScaleGraph from "@/components/ScaleGraph";
import { isValidNr } from "@/utils/dashboardCode.js";
export default {
    props: ["deviation", "generalDev", "questionDev", "change", "hideChange", "valid"],
    components: { ScaleGraph },
    data: () => ({}),
    methods: {},
    computed: {
        curatedDeviation() {
            if ((this.valid === false) || !isValidNr(this.deviation)) {
                return null;
            } else {
                return this.deviation.toFixed(2);
            }
        },
        curatedChange() {
            if ((this.valid === false) || !isValidNr(this.change)) {
                return null;
            } else {
                return this.change < 0
                    ? (this.change * -1).toFixed(2)
                    : this.change.toFixed(2);
            }
        },
    },
};
</script>
