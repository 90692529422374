<template>
    <div class="votingButtons notranslate">
        <span class="text-caption dark-text">{{ upVoteCount }}</span>
         <!-- {{reactions}} -->
        <v-btn
         title="Upvote this Feedforward for discussion"
            icon
            variant="plain"
            :disabled="loadingUpvote"
            :color="upvoted ? 'green' : 'grey-lighten-1'"
            :ripple="false"
            density="compact"
            @click="toggleUpvote"
            class="mr-1"
            ><v-icon>mdi-arrow-up-bold</v-icon>
        </v-btn>
        <span class="text-caption dark-text">{{ celebrateCount }}</span>
        <v-btn
        title="Celebrate this Feedforward"
            icon
            density="compact"
            variant="plain"
            :disabled="loadingCelebrate"
            :color="celebrated ? 'yellow-darken-1' : 'grey-lighten-1'"
            :ripple="false"
            @click="toggleCelebrate"
            ><v-icon>mdi-star</v-icon>
        </v-btn>
    </div>
</template>

<script>
import {
    addFeedForwardReact,
    removeFeedForwardReact
} from "@/api/feedforward.api.js";
import globalFunctions from "@/utils/globalFunctions";
export default {
    props: ["feedforwardId", "reactions", "userId"],
    components: {},
    mounted() {},
    data: () => ({
        loadingUpvote: false,
        loadingCelebrate: false
    }),
    methods: {
        toggleUpvote() {
            if (!this.upvoted) {
                globalFunctions.piwikAnalytics(['trackEvent','Discussion actions', 'Reactions', 'Feedforward upvoted']);
                this.$emit("addUpvote");
                this.addReact("upvote");
            } else {
                globalFunctions.piwikAnalytics(['trackEvent','Discussion actions', 'Reactions', 'Feedforward upvote removed']);
                this.$emit("removeUpvote");
                this.removeReact("upvote");
            }
        },
        toggleCelebrate() {
            if (!this.celebrated) {
                globalFunctions.piwikAnalytics(['trackEvent','Discussion actions', 'Reactions', 'Feedforward celebrated']);
                this.$emit("addCelebrate");
                this.addReact("celebrate");
            } else {
                globalFunctions.piwikAnalytics(['trackEvent','Discussion actions', 'Reactions', 'Feedforward celebrate removed']);
                this.$emit("removeCelebrate");
                this.removeReact("celebrate");
            }
        },
        addReact(type) {
            if (type == "celebrate") {
                this.loadingCelebrate = true;
            }
            if (type == "upvote") {
                this.loadingUpvote = true;
            }
            addFeedForwardReact(this.feedforwardId, type)
                .then(() => {
            /*         if (type == "celebrate") {
                        this.$emit("addCelebrate");
                    }
                    if (type == "upvote") {
                        this.$emit("addUpvote");
                    } */

                    console.log("added " + type + " react!");
                })
                .finally(() => {
                    if (type == "celebrate") {
                        this.loadingCelebrate = false;
                    }
                    if (type == "upvote") {
                        this.loadingUpvote = false;
                    }
                });
        },
        removeReact(type) {
            removeFeedForwardReact(this.feedforwardId, type)
                .then(() => {
        /*             if (type == "celebrate") {
                        this.$emit("removeCelebrate");
                    }
                    if (type == "upvote") {
                        this.$emit("removeUpvote");
                    } */
                    console.log("removed " + type + " react!");
                })
                .finally(() => {
                    if (type == "celebrate") {
                        this.loadingCelebrate = false;
                    }
                    if (type == "upvote") {
                        this.loadingUpvote = false;
                    }
                });
        }
    },
    computed: {
        upVoteCount() {
            if (this.reactions) {
                return this.reactions.upvote.length;
            } else {
                return null;
            }
        },
        celebrateCount() {
            if (this.reactions) {
                return this.reactions.celebrate.length;
            } else {
                return null;
            }
        },

        upvoted() {
            if (this.reactions) {
                if (this.reactions.upvote.length > 0) {
                    return this.reactions.upvote.includes(this.userId);
                }else{
                    return null
                }
            }else{
 return null;
            }

        },
        celebrated() {
            if (this.reactions) {
                if (this.reactions.celebrate.length > 0) {
                    return this.reactions.celebrate.includes(this.userId);
                }else{
                    return null
                }
            }else{

            } return null;

        }
    }
};
</script>
<style scoped>
.votingButtons {
    position: absolute;
    top: 1px;
    right: 1px;
}
</style>
