<template>
    <v-dialog
        class="modalContainer"
        :content-class="'sizeMax ' + (fullscreen ? '' : 'display-flex') "
        :fullscreen="fullscreen"
        :persistent="true"
        :height="height"
        v-model="isVideoDialogVisible"
        :width="width"
    >
        <v-card>
            <v-card-title
                    v-if="fullscreen">
                <v-btn
                    class="ma-1"
                    @click="closeDialog()"
                    icon
                    size="large"
                    variant="text"
                    ><v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="video-panel">
                <div
                    v-if="isPlayOverlayVisible"
                    class="fullSizeOverlay"
                    @click="play(); isPlayOverlayVisible = false"
                >
                    <div class="d-flex fullSizeOverlayInner">
                        <v-icon class="margin-auto" size="25vmin">mdi-play</v-icon>
                    </div>
                </div>
                <video
                    :autoplay="(isVideoDialogVisible && autoplay) ? 'autoplay' : null"
                    class="pt-3 object-fit_contain sizeMax"
                    controls
                    disablepictureinpicture
                    :id="videoElementId"
                    ref="videoPlayerEl"
                    :src="url"
                    type="video/mp4"
                    v-on:play="onplay"
                    v-on:pause="onpause"
                    v-on:ended="onend"
                ></video>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    v-if="!fullscreen"
                    color="primary"
                    variant="elevated"
                    @click="closeDialog()"
                    size="large"
                    >{{ $t('videoModal.gotIt') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { ref, onMounted, nextTick, watch, defineComponent } from 'vue';
import globalFunctions from "@/utils/globalFunctions";
export default defineComponent ({
    name: 'ModalVideo',
    props: {
        autoplay: {
            type: Number,
            default: 0,
        },
        isVideoDialogVisible: {
            type: Boolean,
            required: true,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
            default: null,
        },
        width: {
            type: Number,
            default: null,
        },
        url: {
            type: String,
            required: true,
        },
    },
    emits: ['update:isVideoDialogVisible', 'update:onend'],
    components: {},
    setup(props, { emit }) {
        const isPlayOverlayVisible = ref(false);
        const videoElementId = ref(null);
        const wasPlaying = ref(false);
        const videoPlayerEl = ref(null);

        onMounted(() => {
            videoElementId.value = 'video_' + new Date().valueOf() + Math.random().toString().substr(2);
            isPlayOverlayVisible.value = props.autoplay;

                if (isPlayOverlayVisible.value) {
                const handleOverlay = () => {
                    if (videoPlayerEl.value && videoPlayerEl.value.paused) {
                        videoPlayerEl.value.addEventListener('play', () => { isPlayOverlayVisible.value = false; }, { once: true });
                    } else {
                        isPlayOverlayVisible.value = false;
                    }
                };

                if (videoPlayerEl.value) {
                    handleOverlay();
                } else {
                    nextTick(handleOverlay);
                }
            }
        });

        const closeDialog = () => {
            emit('update:isVideoDialogVisible', false);
            videoPlayerEl.value && videoPlayerEl.value.pause();
            globalFunctions.piwikAnalytics([
                'trackEvent',
                'Video',
                'player closed manually',
                props.url,
            ]);
        }
        const play = () => {
            l('play starting');

            videoPlayerEl.value.paused && videoPlayerEl.value.play();
        }
        const onplay = () => {
            l('play: ', videoElementId.value);

            globalFunctions.piwikAnalytics([
                'trackEvent',
                'Video',
                `play ${wasPlaying.value ? 'resumed' : 'started'}`,
                props.url,
            ]);

            wasPlaying.value = true;
        }
        const onpause = () => {
            l('pause: ', videoElementId.value);

            globalFunctions.piwikAnalytics([
                'trackEvent',
                'Video',
                'play paused',
                props.url,
                `${videoPlayerEl.value?.currentTime} / ${videoPlayerEl.value?.duration}`,
            ]);
        }
        const onend = () => {
            l('end: ', videoElementId.value);

            globalFunctions.piwikAnalytics([
                'trackEvent',
                'Video',
                'play ended',
                props.url,
            ]);

            emit('update:onend', true);
        }

        return {
            isPlayOverlayVisible,
            videoElementId,
            wasPlaying,
            videoPlayerEl,
            closeDialog,
            play,
            onplay,
            onpause,
            onend,
        };
    },
});
</script>

<style>
.display-flex {
    display: flex !important;
    flex-direction: inherit !important;
}
.sizeMax {
    height: 100%;
    width: 100%;
}
</style>
<style scoped>
.fullSizeOverlay {
    background-color: lightgray;
    font-size: 15em !important;
    height: 100%;
    left: 0;
    opacity: .4;
    position: absolute !important;
    top: 0;
    width: 100%;
    z-index: 99999;
}
.fullSizeOverlayInner {
    height: 100%;
}
.margin-auto {
    margin: auto;
}
.modalContainer {
    z-index: 9999;
}
.object-fit_contain {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}
.video-panel {
    height: calc(100% - 56px);
    width: 100%;
    position: relative;
}
</style>
