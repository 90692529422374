<template>
    <v-dialog v-model="dialogState" max-width="700">
        <v-card>
            <v-toolbar flat color="grey-lighten-5">
                <v-toolbar-title>{{ $t("changeTeam") }} </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn @click="$emit('dialog', false)" icon variant="text"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text class="mt-4">
                <i18n-t keypath="pickNewTeam">
                    <v-icon size="small" color="primary">mdi-account-group</v-icon>
                </i18n-t>
                <!-- TODO: VUE 3: END density-->
                <v-alert
                    type="info"
                    color="primary"
                    density="compact"
                    variant="outlined"
                    class="my-2 font-normal"
                    v-if="surveyRunning"
                >
                    <div>
                        <strong>{{ $t("activeSurvey") }} </strong>
                    </div>
                    <div class="font-s">
                        {{ $t("activeSurveyMoveWarning") }}
                    </div>
                </v-alert>
                <TeamTree
                    v-if="teams"
                    :teams="teams"
                    :loading="loading"
                    :currentTeamId="currentTeam.id"
                    @entityChosen="moveUser($event)"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { addMember } from "@/api/teams.api";
import MoveUserTeamTree from "@/components/Teamtrees/MoveUserTeamTree";
import { getEntities } from "@/api/teams.api.js";
import globalFunctions from "@/utils/globalFunctions";
import { mapStores } from "pinia";
import { useAppStore } from "@/store/app.store";
export default {
    props: ["user", "dialog", "isMyProfile", "currentTeam"],
    components: { TeamTree: MoveUserTeamTree },
    data: () => ({
        loading: false,
        error: null,
        teams: [],
        newEntityId: null,
    }),
    mounted() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.loading = true;
            this.error = null;
            getEntities()
                .then((response) => {
                    this.teams = response.data;
                })
                .catch((error) => {
                    this.error = globalFunctions.parseError(error);
                })
                .finally(() => (this.loading = false));
        },
        moveUser(entity) {
            if (window.$devMode) {
                console.log(
                    "Moving user " + this.user.id + " to: " + entity.name
                );
            }
            this.loading = true;
            this.error = null;
            addMember(this.user.id, entity.id)
                .then(() => {
                    this.$emit("dialog", false);
                    this.$emit("refresh");
                })

                .catch((error) => {
                    this.error = globalFunctions.parseError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
        ...mapStores( useAppStore ),

        surveyRunning() {
            return this.appStore.surveyRunning;
        },

        dialogState: {
            get() {
                return this.dialog;
            },
            set(val) {
                this.$emit("dialog", val);
            },
        },
    },
    watch: {},
};
</script>

<style scoped>
.langFlag {
    margin-right: 6px;
    margin-top: -2px;
}
</style>
