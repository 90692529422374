<template>
    <div class="ff-container">
        <v-card class="ff-card quick-fade-in" color="#F8FEEF">
            <v-container class=" pos-relative">
                           <VotingBar
                       v-if="showReactions"
                        :userId="userId"
                        :feedforwardId="closedFeedforwardItem.id"
                        :reactions="closedFeedforwardItem.reactions"
                        @addUpvote="closedFeedforwardItem.reactions.upvote.push(userId)"
                        @removeUpvote="
                            closedFeedforwardItem.reactions.upvote = closedFeedforwardItem.reactions.upvote.filter(
                                id => id !== userId
                            )
                        "
                        @addCelebrate="closedFeedforwardItem.reactions.celebrate.push(userId)"
                        @removeCelebrate="
                            closedFeedforwardItem.reactions.celebrate = closedFeedforwardItem.reactions.celebrate.filter(
                                id => id !== userId
                            )
                        "
                    />
                <p class="text-small font-normal font-italic">
                    {{
                        closedFeedforwardItem.author
                            ? closedFeedforwardItem.author.displayName
                            : `${$t("discussionMode.anonym")}`
                    }}:
                </p>
                <div class="text-dark-grey font-italic">
                    {{ closedFeedforwardItem.answer }}
                </div>
            </v-container>
        </v-card>

        <div class="mt-1">
            <v-tooltip location="bottom" open-delay="800">
                <template #activator="{ props }">
                    <v-btn
                        class="ff-btn reopenFeedforwardBtn"
                        v-bind="props"
                        size="small"
                        block
                        @click="openFF"
                        :ripple="false"
                    >
                        <v-icon
                            :start="$vuetify.display.smAndUp"
                            color="grey-lighten-1"
                            >mdi-upload</v-icon
                        ><span v-if="$vuetify.display.smAndUp">{{
                            $t("buttons.reopen")
                        }}</span>
                    </v-btn>
                </template>
                <span>{{ $t("tooltips.reopenFF") }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import { useAppStore } from "@/store/app.store";
import { openFeedForward } from "../api/feedforward.api";
import VotingBar from "@/components/Voting/VotingBar.vue";
import { useSnackbarStore } from "@/store/snackbar.store";
import globalFunctions from "@/utils/globalFunctions";
import { mapStores } from "pinia";
import { useAuthStore } from "@/store/auth.store";
export default {
    name: "ClosedFeedforward",
    
    props: ["closedFeedforwardItem"],
    
    components: {VotingBar},
    
    data: () => ({}),

    methods: {
        openFF() {
            openFeedForward(this.closedFeedforwardItem.id);
            globalFunctions.piwikAnalytics(['trackEvent','Discussion actions', 'Feedforward reopened']);
            this.closedFeedforwardItem.status = "OPEN";
            this.snackbarStore.showSnackbar([true, "ffOpened"]);
        }
    },

    computed: {
        ...mapStores( useSnackbarStore, useAppStore, useAuthStore ),

        showReactions() {
            return this.appStore.checkFeature("REACTIONS_ENABLED");
        },         
        
        userId() {
            return this.authStore.user.userId;
        }
    }
};
</script>

<style>
.createTask {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
</style>
