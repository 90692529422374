<template>
    <div class="ff-container">
        <v-card
            :class="[
                {
                    'grey-lighten-4': feedforwardItem.status !== null && feedforwardItem.status.toUpperCase() === 'HIDDEN'
                }
            ]"
            class="quick-fade-in ff-card"
        >
            <v-container class="pos-relative">
                       <VotingBar
                       v-if="showReactions"
                        :userId="userId"
                        :feedforwardId="feedforwardItem.id"
                        :reactions="feedforwardItem.reactions"
                        @addUpvote="feedforwardItem.reactions.upvote.push(userId)"
                        @removeUpvote="
                            feedforwardItem.reactions.upvote = feedforwardItem.reactions.upvote.filter(
                                id => id !== userId
                            )
                        "
                        @addCelebrate="feedforwardItem.reactions.celebrate.push(userId)"
                        @removeCelebrate="
                            feedforwardItem.reactions.celebrate = feedforwardItem.reactions.celebrate.filter(
                                id => id !== userId
                            )
                        "
                    />
                <!-- TODO: VUE 3: END density-->
                <v-row density="compact" align="center" no-gutters>
                    <v-col class="pl-3">
                        <p
                            class="text-small font-normal"
                        >
                            {{
                                feedforwardItem.author
                                    ? feedforwardItem.author.displayName
                                    : `${$t("discussionMode.anonym")}`
                            }}:
                        </p>
                        <p class="mb-1 mt-0">
                            {{ feedforwardItem.answer }}
                        </p>
                    </v-col>
                </v-row>
            </v-container>
            <v-expand-transition>
                <div v-if="showTask">
                    <CreateTask
                        @panelClosed="togglePanel"
                        :discussionId="discussionId"
                        :feedforwardItem="feedforwardItem"
                        :surveyQuestion="surveyQuestion"
                        :assignees="assignees"
                        :mode="'ffTask'"
                        :assignee-required="assigneeRequired"
                        @FFTasked="feedforwardItem.status = $event"
                    />
                </div>
            </v-expand-transition>
        </v-card>

        <!-- buttons -->
        <div
            class="mt-1"
            style="z-index: 1;"
            v-if="feedforwardItem.status === null ||  feedforwardItem.status.toUpperCase() !== 'HIDDEN'"
        >
            <v-tooltip location="bottom" content-class="bg-green" open-delay="800">
                <template #activator="{ props }">
                    <div v-bind="props">
                        <v-btn
                            class="ff-btn closeFeedforwardBtn"
                            :aria-label="$t('tooltips.discussed')"
                            @click="closeFF"
                            block
                            size="small"
                            v-ripple="{ class: 'text-success' }"
                        >
                            <v-icon
                                color="green"
                                :start="$vuetify.display.smAndUp"
                                >mdi-check</v-icon
                            >
                            <span v-if="$vuetify.display.smAndUp">{{
                                $t("buttons.markDone")
                            }}</span>
                        </v-btn>
                    </div>
                </template>
                <span>{{$t("tooltips.discussed")}}
                </span>
            </v-tooltip>

            <v-tooltip location="bottom" content-class="bg-yellow-darken-2" open-delay="800">
                <template #activator="{ props }">
                    <div v-bind="props">
                        <v-btn
                            class="ff-btn openCreateTaskBtn"
                            :disabled="showTask"
                            block
                            size="small"
                            @click="showTask = !showTask"
                            :aria-label="$t('tooltips.createTask')"
                            v-ripple="{ class: 'text-yellow' }"
                        >
                            <v-icon
                                color="yellow-darken-1"
                                :start="$vuetify.display.smAndUp"
                                >mdi-clipboard-plus-outline</v-icon
                            ><span v-if="$vuetify.display.smAndUp">{{
                                $t("buttons.newTask")
                            }}</span>
                        </v-btn>
                    </div>
                </template>
                <span>{{ $t("tooltips.createTask") }}</span>
            </v-tooltip>
        </div>
        <!-- buttons -->
    </div>
</template>

<script>

import {
    closeFeedForward,
    hideFeedForward,
    openFeedForward
} from "../api/feedforward.api";
import globalFunctions from "@/utils/globalFunctions";
import CreateTask from "@/components/CreateTask";
import VotingBar from "@/components/Voting/VotingBar.vue"
import { useSnackbarStore } from "@/store/snackbar.store";
import { mapStores } from "pinia";
import { useAppStore } from "@/store/app.store";
import { useAuthStore } from "@/store/auth.store";
export default {
    name: "OpenFeedforward",
    components: {
        CreateTask,
        VotingBar
    },
    props: {
      feedforwardItem: {
            type: Object,
            required: true
        },
        surveyQuestion: {
            type: Object,
            required: true
      },
      assignees: {
            type: Array,
            required: true
      },
      assigneeRequired: {
            type: Boolean,
            default: false
      },
    },
    data: () => ({
        showTask: false
    }),
    computed: {

        ...mapStores( useAppStore, useAuthStore ),

        discussionId() {
            return this.$route.params.discussion_id;
        },
        
        showReactions() {
            return this.appStore.checkFeature("REACTIONS_ENABLED");
        },
        
        userId() {
            return this.authStore.user.userId;
        }
    },
    methods: {
        togglePanel() {
            this.showTask = false;
        },
        
        closeFF() {
            closeFeedForward(this.feedforwardItem.id);
            globalFunctions.piwikAnalytics(['trackEvent','Discussion actions', 'Feedforward closed']);
            this.feedforwardItem.status = "CLOSED";

            const snackbarStore = useSnackbarStore();
            snackbarStore.showSnackbar([true, "ffClosed"]);
        },

        hideFF() {
            hideFeedForward(this.feedforwardItem.id);
            this.feedforwardItem.status = "HIDDEN";
        },
        
        openFF() {
            openFeedForward(this.feedforwardItem.id);
            this.feedforwardItem.status = "OPEN";
        }
    }
};
</script>
