<template>
    <v-btn
        size="small"
        block
        class="task-btn"
        :ripple="false"
        v-bind="$attrs"
    >
        <v-icon
            :color="iconColor ? iconColor : 'grey-darken-1'"
            :start="$vuetify.display.smAndUp"
            >{{ icon }}</v-icon
        ><span v-if="$vuetify.display.smAndUp">{{ label }}</span>
    </v-btn>
</template>

<script>
export default {
    props: ["label", "icon", "iconColor"],
    components: {},
    data: () => ({}),
    methods: {},
    computed: {},
};
</script>

<style>
.task-btn {
    justify-content: start !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    margin-bottom: 3px;
    padding-left: 15px !important;
    margin-left: -5px;
    width: 100%;
}

.task-btn:hover i {
    padding-left: 5px;
}
</style>
