import languageHelperConstants from '@/utils/languageHelpers.constants.js';
import { createI18n, I18nT } from 'vue-i18n';
import * as vuetifyLocale from 'vuetify/locale';
const locales = import.meta.glob('./locales/*.js', { eager: true });

function loadLocaleMessages () {
  const messages = {}

  Object.keys(locales).forEach(key => {
    const matched = key.match(/locales\/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales[key].default;
      messages[languageHelperConstants.getSpecificLocale(locale)] = messages[locale];
      messages[locale].$vuetify = vuetifyLocale[locale];
    }
  })

  return messages
}

I18nT.props.scope.default = 'global';

const i18n = createI18n({
  locale: languageHelperConstants.DEFAULT_LOCALE,
  fallbackLocale: languageHelperConstants.FALLBACK_LOCALE,
  messages: loadLocaleMessages(),
  legacy: false,
  silentTranslationWarn: false,
  fallbackWarn: false,
});

window.l('i18n', i18n);

// enable for translation debugging
if (false) {
  const ___t = i18n.global.t.bind(i18n);
  i18n.global.t = (...args) => {
    window.l('[global.t]', ...args);
    return ___t(...args);
  };
}

if (false) {
  const ___te = i18n.global.te.bind(i18n);
  i18n.global.te = (...args) => {
    window.l('[global.te]', ...args);
    return ___te(...args);
  };
}

export default i18n;
