<template>
    <v-card-text>
        <p class="mb-0 text-bold" v-if="task.question"  v-html="$mixin.parseLinks($mixin.translatedText(task.question, $i18n.locale))">
        </p>
        <div v-if="task.feedForward">
            <p class="text-small">
                {{
                    task.feedForward.author
                        ? task.feedForward.author.displayName
                        : `${$t("discussionMode.anonym")}`
                }}:
            </p>
            <p class="comment-format">
                {{ task.feedForward.answer }}
            </p>
        </div>
    </v-card-text>
</template>

<script>
export default {
    props: ["task"],
    components: {},
    data: () => ({}),
    methods: {
    },
    computed: {}
};
</script>

