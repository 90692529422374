<template>
    <div>

        <v-progress-circular
            class="bounce-in pos-relative"
            :size="size"
            :width="width"
            :model-value="fillValue"
            :color="chartColor"
        >
            <!-- if result better than 90%, show some happy icons -->
            <div v-if="fillValue > 90" class="fade-in">
                <v-icon
                    color="yellow"
                    style="transform:rotate(20deg); position: absolute; right: -35px; top: 40px"
                    >mdi-star</v-icon
                >
                <v-icon
                    size="small"
                    color="pink-lighten-4"
                    style="transform:rotate(50deg); position: absolute; left: -35px; top: 35px"
                    >mdi-star</v-icon
                >
                <v-icon
                    color="green-accent-2"
                    style=" position: absolute; left: -15px; top: -10px"
                    >mdi-star</v-icon
                >
                <v-icon
                    color="cyan-lighten-4"
                    style="transform:rotate(-15deg); position: absolute; right: -15px; top: -10px"
                    >mdi-thumb-up-outline</v-icon
                >
            </div>

            <div class="centered">
                <div class="radialLabel">
                    {{ label }}
                </div>
                <div v-if="avgChange && !hideChange" :class="` text-${avgChangeColor} mt-1`">
                    <v-icon v-if="avgChange !== 0" :color="avgChangeColor">
                        {{
                            avgChange > 0 ? "mdi-menu-up" : "mdi-menu-down"
                        }}</v-icon
                    ><span v-if="avgChange === 0">🠖</span
                    >{{ avgChange < 0 ? avgChange * -1 : avgChange
                    }}{{ addOnChar }}
                </div>
            </div>
        </v-progress-circular>
    </div>
</template>

<script>
import ScaleGraph from "@/components/ScaleGraph";
import {
    isValidNr,
    avgChartColor,
    avgChangeColor,
    calculateAvgChange
} from "../../utils/dashboardCode.js";
export default {
    data() {
        return {};
    },
    props: ["statistics", "size", "width", "topGrade", "addOnChar", "hideChange"],
    components: { ScaleGraph },
    methods: {},
    computed: {
        chartColor() {
            return avgChartColor(this.topGrade, this.statistics.average);
        },
        fillValue() {
            // the visual % of the round chart
            if (isValidNr(this.statistics.average)) {
                return (this.statistics.average / this.topGrade) * 100;
            } else {
                return 0;
            }
        },
        label() {
            // label in the middle
            if (this.statistics.average === 0) {
                return "N/A";
            } else if (isValidNr(this.statistics.average)) {
                return Number(this.statistics.average.toFixed(2));
            } else {
                return "N/A";
            }
        },
        avgChangeColor() {
            return avgChangeColor(this.statistics.changeAverage);
        },
        avgChange() {
            return calculateAvgChange(
                this.topGrade,
                this.statistics.changeAverage
            );
        }
    }
};
</script>
