<template>
    <div>
        <v-dialog
            :fullscreen="$vuetify.display.xs"
            v-model="dialogVal"
            max-width="650"
        >
            <v-card>
                <v-card-title
                    class="text-white bg-blue-lighten-1"
                    primary-title
                >
                    <v-row no-gutters>
                        <v-col style="max-width: 40px">
                            <v-icon color="white" start
                                >mdi-account-arrow-right</v-icon
                            >
                        </v-col>
                        <v-col>
                            {{ $t("discussionMode.highlight") }}
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="mt-4">
                    <!-- task origin -->
                    <span class="boxHeader">Feedforward:</span>

                    <v-card border flat>
                        <v-card-text>
                            <p class="text-bold mb-1" v-html="$mixin.parseLinks($mixin.translatedText(surveyQuestion, $i18n.locale))">
                            </p>
                            <p class="text-small">
                                {{
                                    feedforwardItem.author
                                        ? feedforwardItem.author.displayName
                                        : `${$t("discussionMode.anonym")}`
                                }}:
                            </p>
                            <p class="comment-format">
                                {{ feedforwardItem.answer }}
                            </p>
                        </v-card-text>
                    </v-card>
                    <!-- /task origin -->

                    <v-form v-model="isFormValid" ref="form">
                        <v-text-field
                            v-model="escalateSummary"
                            :label="`${$t('tasks.title')}*`"
                            class="mb-1"
                            :rules="[rules.required]"
                        ></v-text-field>

                        <v-card border flat class="mt-1">
                            <v-textarea
                                :rules="[rules.required]"
                                class="text-body-2"
                                variant="solo"
                                flat
                                rows="3"
                                name="comment"
                                :placeholder="$t('discussionMode.describe')"
                                v-model="escalateComment"
                            ></v-textarea>
                        </v-card>
                        <v-row class="mt-4" no-gutters>
                            <v-col cols="6">
                                <v-combobox
                                    ref="combo"
                                    hint="tooltips.pressEnter"
                                    :rules="[rules.required]"
                                    item-title="displayName"
                                    prepend-icon="mdi-account"
                                    v-model="selectedAdmin"
                                    :items="escalateAdmins"
                                    :label="$t('discussionMode.selectLabel')"
                                    @keydown="resetValidation"
                                    @keydown.enter="blurCombo"
                                >
                                    <template #message="{ message }">
                                        {{ $t(message) }}
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions class="px-6 pb-6">
                    <v-btn
                        variant="text"
                        min-width="100"
                        theme="dark"
                        color="grey"
                        @click="closeDialog"
                        >{{ $t("buttons.cancel") }}</v-btn
                    >
                    <v-spacer></v-spacer>

                    <v-tooltip location="top" open-delay="1000" >
                        <template #activator="{ props }">
                            <div v-bind="props">
                                <v-btn
                                    min-width="150"
                                    color="primary"
                                    :disabled="!isFormValid "
                                    class="escalateFeedforwardBtn"
                                    @click="escalateFF"
                                    >{{ $t("buttons.send") }}</v-btn
                                >
                            </div>
                        </template>
                        <span>{{ $t("tooltips.disabled") }}</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { createTask, delegateTask } from "../api/tasks.api";
import { taskFeedForward } from "../api/feedforward.api";
import globalFunctions from "@/utils/globalFunctions";
import { mapStores } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";
import { useAppStore } from "@/store/app.store";

export default {
    name: "DiscussionEscalate",
    
    props: ["feedforwardItem", "discussionId", "dialog", "surveyQuestion"],
    
    data: () => ({
        isFormValid: false,
        selectedAdmin: null,
        escalateComment: null,
        escalateSummary: null
    }),
    
    mounted() {
        this.appStore.fetchEscalateAdmins();
    },

    methods: {
        escalateFF() {
            let escalatedFF = {
                discussionId: this.discussionId,
                feedForwardId: this.feedforwardItem.id,
                comment: this.escalateComment,
                summary: this.escalateSummary
            };

            // check if preselected admin or freeform
            if (this.selectedAdmin.id) {
                escalatedFF.assignedTo = this.selectedAdmin;
            } else {
                escalatedFF.assignedTo = {
                    displayName: this.selectedAdmin,
                    id: null
                };
            }

            taskFeedForward(this.feedforwardItem.id);
            this.feedforwardItem.status = "TASKED";
            // creating task from FF and sending it to admin board:
            createTask(escalatedFF).then(response => {
                escalatedFF = response.data;
                delegateTask(escalatedFF.id, escalatedFF.assignedTo);
                globalFunctions.piwikAnalytics(['trackEvent','Discussion actions', 'Feedforward delegated']);
            });

            this.resetFields();
            this.closeDialog();
            this.snackbarStore.showSnackbar([true, "ffEscalated"]);
            this.$emit("escalated");
        },
        closeDialog() {
            this.$emit("dialogStatus", false);
        },
        resetFields() {
            this.selectedAdmin = null;
            this.escalateComment = null;
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        blurCombo() {
            this.$refs.combo.blur();
        }
    },
    computed: {
        ...mapStores( useSnackbarStore, useAppStore ),

        escalateAdmins() {
            return this.appStore.escalateAdmins;
        },

        dialogVal: {
            get() {
                return this.dialog;
            },
            set() {
                this.closeDialog();
            }
        },
        
        // rules for mandatory fields
        rules() {
            return {
                required: value => !!value || this.$t("tooltips.required")
            };
        }
    }
};
</script>

<style>

</style>
