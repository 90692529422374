<script setup lang="ts">

import { computed, ref } from 'vue'
import { ActionButton  } from 'vuetify-pro-tiptap'
import type { Item, Props } from './VuetifyProTipTapTypes.d.ts'

const mdiFormatAlignCenter = "M3,3H21V5H3V3M7,7H17V9H7V7M3,11H21V13H3V11M7,15H17V17H7V15M3,19H21V21H3V19Z"
const mdiFormatAlignJustify = "M3,3H21V5H3V3M3,7H21V9H3V7M3,11H21V13H3V11M3,15H21V17H3V15M3,19H21V21H3V19Z"
const mdiFormatAlignLeft = "M3,3H21V5H3V3M3,7H15V9H3V7M3,11H21V13H3V11M3,15H15V17H3V15M3,19H21V21H3V19Z"
const mdiFormatAlignRight = "M3,3H21V5H3V3M9,7H21V9H9V7M3,11H21V13H3V11M9,15H21V17H9V15M3,19H21V21H3V19Z"

interface IconsOptions {
  left: string
  center: string
  right: string
  justify: string
}

const icons: IconsOptions = {
  left: mdiFormatAlignLeft,
  center: mdiFormatAlignCenter,
  right: mdiFormatAlignRight,
  justify: mdiFormatAlignJustify,
}

function getIcon(iconName?: keyof IconsOptions): string | undefined {
  if (!iconName) return undefined
  return `svg:${icons[iconName]}`
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  color: undefined,
  maxHeight: undefined,
  icon: undefined,
  tooltip: '',
  items: () => []
})

const menu = ref<boolean>(false)

const active = computed<Item>(() => {
  const find = props.items.find(k => k.isActive())
  if (find && !find.default) {
    return {
      ...find,
      icon: find.icon ? find.icon : props.icon
    }
  }

  const item: Item = {
    title: props.tooltip,
    icon: props.icon,
    isActive: () => false
  }

  return item
})

</script>

<template>
  <ActionButton
    :icon="active.icon"
    :tooltip="active.title"
    :disabled="disabled"
    :color="color"
    :is-active="active.isActive"
  >
    <VMenu v-model="menu" activator="parent">
      <VList density="compact" :max-height="maxHeight">
        <template v-for="(item, i) in items" :key="i">
          <VListItem :active="item.isActive()" :disabled="item.disabled" @click="item.action">
            <template #prepend>
              <VIcon v-if="item.icon" :icon="getIcon(item.icon)" />
            </template>

            <VListItem-title :style="item.style">{{ item.title }}</VListItem-title>
          </VListItem>

          <VDivider v-if="item.divider" />
        </template>
      </VList>
    </VMenu>
  </ActionButton>
</template>
